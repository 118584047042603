import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import {
  DialogActions,
  DialogContent,
  Dialog,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import {
  deleteTasks,
  updateTaskVideosIds,
  updateTaskContacts,
  patchTask,
} from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function CombineDialog(props) {
  let [state, setState] = useState({});
  let [comments, setComments] = useState("");

  const fullScreen = useMobileDialog();

  let doWork = async (e) => {
    try {
      e.preventDefault();

      if (comments === "") {
        throw new Error("Please add comments for the new task");
      }

      setState({ buttonState: "loading" });

      if (props.selectedItems.length === 0) {
        throw new Error("No tasks selected");
      }

      const taskIdsToDelete = [];
      const taskVideos = [];
      const taskContacts = [];
      let primaryTask = null;

      for (const task of props.selectedItems) {
        if (!primaryTask) {
          primaryTask = task;
        } else {
          taskIdsToDelete.push(task.id);
        }

        for (const taskContact of task.taskContacts) {
          if (
            !taskContacts.some(
              (currentTaskContact) =>
                currentTaskContact.contactId === taskContact.contactId
            )
          ) {
            if (taskContacts.length > 100) {
              throw new Error(
                "Too many contacts. 1 task can only have up to 100 contacts."
              );
            }

            taskContacts.push(taskContact);
          }
        }

        for (const taskVideo of task.taskVideos) {
          if (
            !taskVideos.some(
              (currentTaskVideo) => currentTaskVideo.videoId === taskVideo.videoId
            )
          ) {
            taskVideos.push(taskVideo);
          }
        }
      }

      const updates = {
        needsVid: !primaryTask.taskVideos?.length,
        noteId: null,
        comments,
        crmMeta: null,
      };

      primaryTask = { ...primaryTask, ...updates, taskVideos, taskContacts };

      const taskPromises = [
        deleteTasks(taskIdsToDelete),
        updateTaskVideosIds(
          primaryTask.id,
          taskVideos.map((taskVideo) => taskVideo.videoId)
        ),
        updateTaskContacts(
          primaryTask.id,
          taskContacts.map((taskContact) => taskContact.contactId)
        ),
        patchTask(primaryTask.id, updates),
      ];
      await Promise.all(taskPromises);

      const tasks = props.tasks.filter(
        (task) => !taskIdsToDelete.some((id) => id === task.id)
      );

      props.setEditTaskSelected(null);
      props.setTasks(
        tasks.map((task) => {
          if (task.id === primaryTask.id) {
            return primaryTask;
          }

          return task;
        })
      );
      setState({ buttonState: "saved" });
      props.setSelectedItems([]);
      props.setAllSelected(false);
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let { buttonState, errorMessage } = state;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete task dialog">
      <DialogContent style={{ minHeight: "500px", width: fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Combine Tasks</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12" style={{ marginTop: 5 }}>
          <p>
            Are you sure you want to combine the {props.selectedItems.length} selected
            tasks into one? You can't undo this action.
          </p>
          <br />
        </div>

        <div className="col-12">
          <TextField
            label="New Task Comments"
            id="comments"
            variant="outlined"
            style={{ marginTop: 15 }}
            onChange={(e) => setComments(e.target.value)}
            fullWidth
            value={comments}
          />
        </div>

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button onClick={props.closeDialog} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel={"Done"}
          label={"Combine Now"}
          clickedRegularButton={doWork}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
