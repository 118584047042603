import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

export default function SearchInput(props) {
  let searchRef = useRef(null);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  return (
    <div
      style={{ flex: 1, display: "flex", padding: "10px", backgroundColor: "#eaf0f6" }}>
      <div style={{ width: "30px" }} className="align-items-center">
        {props.isSearching ? (
          <CircularProgress style={{ height: "20px", width: "20px" }} />
        ) : (
          <i className="material-icons" style={{ color: "grey" }}>
            search
          </i>
        )}
      </div>
      <TextField
        variant="outlined"
        fullWidth
        inputRef={searchRef}
        id="search input"
        placeholder="Search contacts"
        style={{ backgroundColor: "white" }}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: {
            height: "30px",
            padding: "5px 10px",
          },
        }}
      />
    </div>
  );
}

SearchInput.propTypes = {
  isSearching: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
