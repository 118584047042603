import React, { useState } from "react";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { Dialog, Button, IconButton } from "@mui/material";
import { deleteVideo } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function ReSendDialog(props) {
  let [state, setState] = useState({});
  let [dialogOpen, setDialogOpen] = useState(false);

  const fullScreen = useMobileDialog();

  let deleteVidResetRequest = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });

      await deleteVideo(props.videoId);
      props.videoUploadStatusChanged(
        "requested",
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.log(errorMessage);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setDialogOpen(true)}>
        Re-send Request
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="simple-dialog-title">
        <div style={{ minWidth: "250px", padding: "20px" }}>
          <div
            className="row col-12 pagePadding"
            style={{ padding: "20px", minWidth: "250px" }}>
            <div className="col-10">
              <h2>Delete To Resend</h2>
              <p className="greyDesc" style={{ fontSize: "14px" }}>
                Delete the current video and show the options to resend the request.
              </p>
            </div>

            <div className="col-2 justify-content-end">
              <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>

            {state.errorMessage && (
              <div className="errorMessageBox">
                <b>{state.errorMessage}</b>
              </div>
            )}

            <div className="col-12 justify-content-center" style={{ marginTop: "25px" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                buttonState={state.buttonState}
                savedLabel={"Deleted"}
                label={"Delete Video"}
                clickedRegularButton={deleteVidResetRequest}
                clickedSavedButton={() => {
                  return null;
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
