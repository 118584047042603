import React from "react";
import styles from "./inputSwitch.module.css";

import Switch from "@mui/material/Switch";

const InputSwitch = React.forwardRef((props, ref) => (
  <Switch className={styles.switch} {...props} ref={ref} />
));

InputSwitch.displayName = "InputSwitch";

export default InputSwitch;
