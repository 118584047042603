import React, { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import Snackbar from "@mui/material/Snackbar";

export default function PublicURL(props) {
  let [modalOpen, setModalOpen] = useState(false);
  let [snackbarOpen, setSnackbarOpen] = useState(false);
  const publicURLRef = useRef(null);

  const fullScreen = useMobileDialog();

  const link = `${process.env.REACT_APP_FRONTEND}/notes/?noteId=${props.noteId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setSnackbarOpen(true);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor="publicURL">Public URL</InputLabel>
        <Input
          id="publicURL"
          inputRef={publicURLRef}
          type="text"
          value={link}
          disabled
          onChange={(e) => props.passedSetState(props.stateName, e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="copy public url" onClick={copyToClipboard}>
                <i className="material-icons">content_copy</i>
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="help button, view more info"
                size="small"
                onClick={() => setModalOpen(true)}>
                <i
                  className="material-icons"
                  style={{ fontSize: "16px", color: "rgb(142, 148, 157)" }}>
                  help
                </i>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <Dialog
        open={modalOpen}
        fullScreen={fullScreen}
        onClose={() => setModalOpen(false)}>
        <div style={{ minWidth: "250px", padding: "10px" }}>
          <div
            className="row col-12 pagePadding"
            style={{ padding: "20px", minWidth: "250px" }}>
            <div className="col-10 align-items-center">
              <h2>More Info</h2>
            </div>

            <div className="col-2 justify-content-end">
              <IconButton aria-label="close" onClick={() => setModalOpen(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>

            <div className="col-12">
              <p style={{ marginBottom: "10px" }}>
                Copying this public url allows you to share this Gratavid note anywhere.
                You can use this link with social media managment tools (eg - Buffer,
                Hootsuite, etc.), social feeds, text messages, email marketers (eg -
                Mailchimp, Constant Contact, etc.), and any place you can share a link.
              </p>
            </div>
          </div>
        </div>
      </Dialog>

      <Snackbar
        autoHideDuration={2500}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        ContentProps={{ "aria-describedby": "public url was copied" }}
        message={<span id="message-id">URL copied</span>}
      />
    </>
  );
}
