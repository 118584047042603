import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ContactItem from "./contactItem";
import SearchInput from "./searchInput";
import AddEditContactDialog from "../addEdit/addEditContactDialog";
import { getAccountContacts } from "services/api.service";
import { debounce } from "util/helpers";

export default function Contacts(props) {
  let [addEditContactDialogOpen, setAddEditContactDialogOpen] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [contactCount, setContactCount] = useState(0);

  async function getContacts(isInit, searchQuery, searchOffset) {
    let startIndex = searchOffset;
    if (isInit) {
      startIndex = 0;
    }

    const limit = 20;
    const contacts = await getAccountContacts(startIndex, limit, searchQuery);

    setOffset(startIndex + 20);
    setContactCount(contacts.count);
    setSearchList(contacts.rows);
  }

  const debouncedGetContacts = useCallback(debounce(getContacts), [props]);

  useEffect(() => {
    debouncedGetContacts(true, searchTerm, offset);
  }, [searchTerm]);

  return (
    <div className="contactPopover col-12" style={{ marginBottom: "50px" }}>
      <div style={{ height: "60px" }}>
        <SearchInput
          handleSearchTextField={(e) => setSearchTerm(e.target.value)}
          searchTerm={searchTerm}
          setAddEditContactDialogOpen={setAddEditContactDialogOpen}
        />
      </div>
      <div className="row" style={{ maxHeight: "300px", overflow: "auto" }}>
        <div className="row col-12">
          <div
            className="row col-12 editNoteListSelectableItem align-items-center"
            style={{ height: "55px", display: "flex", padding: "10px 5px" }}
            onClick={() => setAddEditContactDialogOpen(true)}>
            <div
              className="align-items-center justify-content-center"
              style={{ width: 37 }}>
              <i
                className="material-icons"
                style={{ color: "#0091ae", fontSize: "20px" }}>
                add
              </i>
            </div>

            <div
              style={{ flex: 1, fontSize: 16, color: "#0091ae", fontWeight: 600 }}
              className="col-12 align-items-center">
              Add {searchTerm ? searchTerm : "contact"}
            </div>
          </div>

          {addEditContactDialogOpen && (
            <AddEditContactDialog
              accountInfo={props.accountInfo}
              contactData={{ contact: { email: searchTerm } }}
              customContactProperties={props.customContactProperties}
              closeDialog={() => setAddEditContactDialogOpen(null)}
              addContactToTask={props.addContactToTask}
              taskContacts={props.taskContacts}
              setTaskContacts={props.setTaskContacts}
              setShowSearchContacts={props.setShowSearchContacts}
              userIsAdmin={props.userIsAdmin}
            />
          )}

          <div style={{ width: "100%", marginBottom: "10px" }}>
            {searchList.length ? (
              searchList.map((contact) => {
                return (
                  <ContactItem
                    currentContact={contact}
                    selectedContacts={props.taskContacts}
                    key={contact.id}
                    {...props}
                  />
                );
              })
            ) : (
              <div
                className="col-12"
                key={"none"}
                style={{ padding: "5px 5px 5px 15px", color: "grey" }}>
                No results found
              </div>
            )}
          </div>

          {contactCount > offset && (
            <div className="col-12 justify-content-center" style={{ padding: "10px" }}>
              <Button size="small" onClick={(e) => getContacts()} variant="outlined">
                Load More
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Contacts.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
