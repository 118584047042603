import React from "react";
import styles from "./drawerItem.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router";
import clsx from "clsx";

export default function DrawerItem(props) {
  const { isDesktopOpen = true, isOutlinedIcon = true } = props;

  const isExternalLink = props.to?.startsWith("http");
  const LinkItem = isExternalLink ? "a" : Link;
  const linkProps = isExternalLink
    ? { href: props.to, target: "_blank", rel: "noopener noreferrer" }
    : { to: props.to };

  return (
    <LinkItem
      className={clsx(
        styles.nav,
        props.isActive && styles.active,
        !isDesktopOpen && styles.closed,
        props.howTo && styles.howTo
      )}
      onClick={props.onClick}
      {...linkProps}>
      <i
        className={isOutlinedIcon ? "material-icons-outlined" : "material-icons"}
        title={props.label}>
        {props.icon}
      </i>
      <span>{props.label}</span>
    </LinkItem>
  );
}

DrawerItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isDesktopOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  howTo: PropTypes.bool,
  isOutlinedIcon: PropTypes.bool,
};
