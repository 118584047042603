import React from "react";
import AutoSuggestInput from "./autoSuggestInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

export default function SelectNote(props) {
  return (
    <div className="row col-12" style={{ marginTop: "10px" }}>
      <div className="col-12" style={{ display: "flex" }}>
        {props.noteId ? (
          <TextField
            label="Select Note"
            variant="outlined"
            fullWidth
            value={props.noteTitle}
            id="textInput-selected"
            onClick={() => props.noteSelectedFromSearch(null)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton
                    aria-label="help button, view more info"
                    onClick={() => props.noteSelectedFromSearch(null)}
                    id="closeButton">
                    <i className="material-icons">close</i>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <div style={{ flex: 1 }}>
            <AutoSuggestInput
              accountId={props.accountInfo.id}
              itemSelected={props.noteSelectedFromSearch}
            />
          </div>
        )}
      </div>
    </div>
  );
}
