import React, { useState } from "react";
import {
  Badge,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import DeleteDialog from "./type/delete";
import MarkAsDoneDialog from "./type/markAsDone";
import AssignToDialog from "./type/assignTo";
import SendTasksDialog from "./type/sendTasks";
import EditNoteDialog from "./type/editNote";
import NotifyDialog from "./type/notify";
import CombineDialog from "./type/combineDialog";
import DownloadDialog from "./type/download";
import useMediaQuery from "@mui/material/useMediaQuery";
import style from "./actionRow.module.css";

export default function ActionRow(props) {
  let [actionDialogKey, setActionDialogKey] = useState(false);
  let {
    selectedItems = {},
    allSelected = false,
    tasks = {},
    sortBy,
    calledFromHistory = false,
  } = props;
  let selectedCount = allSelected ? tasks.length : selectedItems.length;
  let btnDisabled = selectedCount > 0 || allSelected ? false : true;
  const isMobile = useMediaQuery("(max-width:1250px)");

  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

  const handleSort = (value) => {
    props.setSortBy(value);
    setSortAnchorEl(null);
  };

  function handleAction(action) {
    setActionDialogKey(action);
    setActionsAnchorEl(null);
  }

  const actions = [
    {
      icon: "notifications",
      label: "Notify",
      onClick: () => handleAction("notify"),
    },
    {
      icon: "delete",
      label: "Delete",
      onClick: () => handleAction("delete"),
      disabled: btnDisabled,
    },
    {
      icon: "done",
      label: "Mark done",
      onClick: () => handleAction("markAsDone"),
      disabled: btnDisabled,
    },
    {
      icon: "assignment_ind",
      label: "Assign",
      onClick: () => handleAction("assignedTo"),
      disabled: btnDisabled,
    },
    {
      icon: "edit_note",
      label: "Edit note",
      onClick: () => handleAction("editNote"),
      disabled: btnDisabled,
    },
    {
      icon: "file_download",
      label: "Download",
      onClick: () => handleAction("download"),
      disabled: btnDisabled,
    },
    {
      icon: "merge",
      label: "Combine",
      onClick: () => handleAction("combine"),
      disabled: selectedCount >= 2 || allSelected ? false : true,
    },
    null, // divider
    {
      icon: "send",
      label: "Send",
      onClick: () => setActionDialogKey("sendTasks"),
      disabled: btnDisabled,
    },
  ];

  return (
    <>
      <div className={style.menu}>
        {isMobile ? (
          <div></div>
        ) : (
          <div className={style.buttons}>
            {actions.map((action, index) => {
              return (
                action && (
                  <Button
                    key={index}
                    size="small"
                    className={style.button}
                    startIcon={<i className="material-icons-outlined">{action.icon}</i>}
                    disabled={action.disabled}
                    onClick={action.onClick}>
                    {action.label}
                  </Button>
                )
              );
            })}
          </div>
        )}

        <div>
          {!calledFromHistory && (
            <>
              <IconButton
                className={style.icon}
                onClick={(e) => setSortAnchorEl(e.currentTarget)}>
                <Badge color="secondary" variant="dot" invisible={sortBy === "newToOld"}>
                  <i className="material-icons">sort</i>
                </Badge>
              </IconButton>

              <Menu
                id="sort-menu"
                keepMounted
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorEl={sortAnchorEl}
                open={Boolean(sortAnchorEl)}
                onClose={() => setSortAnchorEl(null)}>
                <div
                  className="align-items-center"
                  style={{ padding: "5px 16px", fontSize: "18px" }}>
                  <i
                    className="material-icons"
                    style={{ fontSize: "22px", marginRight: "5px" }}>
                    sort
                  </i>
                  Sort
                </div>
                <MenuItem
                  selected={sortBy === "newToOld"}
                  onClick={() => handleSort("newToOld")}>
                  Newest to oldest
                </MenuItem>
                <MenuItem
                  selected={sortBy === "oldToNew"}
                  onClick={() => handleSort("oldToNew")}>
                  Oldest to newest
                </MenuItem>
                <MenuItem
                  selected={sortBy === "commentsAZ"}
                  onClick={() => handleSort("commentsAZ")}>
                  Comments A to Z
                </MenuItem>
                <MenuItem
                  selected={sortBy === "commentsZA"}
                  onClick={() => handleSort("commentsZA")}>
                  Comments Z to A
                </MenuItem>
                <MenuItem
                  selected={sortBy === "noVids"}
                  onClick={() => handleSort("noVids")}>
                  Show tasks with no vids
                </MenuItem>
              </Menu>
            </>
          )}

          {isMobile && (
            <>
              <IconButton
                className={style.icon}
                onClick={(e) => setActionsAnchorEl(e.currentTarget)}>
                <i className="material-icons">more_vert</i>
              </IconButton>

              <Menu
                id="actions-menu"
                keepMounted
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorEl={actionsAnchorEl}
                open={!!actionsAnchorEl}
                onClose={() => setActionsAnchorEl(null)}>
                {actions.map((action, index) => {
                  return action ? (
                    <MenuItem
                      key={index}
                      size="small"
                      disabled={action.disabled}
                      onClick={action.onClick}>
                      <ListItemIcon>
                        <i className="material-icons-outlined">{action.icon}</i>
                      </ListItemIcon>
                      {action.label}
                    </MenuItem>
                  ) : (
                    <Divider key={index} />
                  );
                })}
              </Menu>
            </>
          )}
        </div>
      </div>

      {actionDialogKey === "notify" && (
        <NotifyDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "delete" && (
        <DeleteDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "markAsDone" && (
        <MarkAsDoneDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "assignedTo" && (
        <AssignToDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "editNote" && (
        <EditNoteDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "sendTasks" && (
        <SendTasksDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "download" && (
        <DownloadDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
      {actionDialogKey === "combine" && (
        <CombineDialog
          {...props}
          dialogOpen={true}
          closeDialog={() => setActionDialogKey(null)}
          selectedCount={selectedCount}
        />
      )}
    </>
  );
}
