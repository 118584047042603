import React from "react";
import Chip from "@mui/material/Chip";
import { toFullName } from "util/helpers";

export default function ContactItem(props) {
  const label = toFullName(
    props.currentItem.contact.firstName,
    props.currentItem.contact.lastName,
    props.currentItem.contact.email
  );

  let icon = "",
    iconColor = "";

  if (props.currentItem.contact.textOptIn) {
    iconColor = "green";
    icon = "mobile_friendly";
  } else {
    icon = null;
  }

  if (props.currentItem.contact.emailOptOut) {
    iconColor = "red";
    icon = "error";
  }

  return (
    <Chip
      style={{
        margin: "3px",
        borderRadius: "8px",
        backgroundColor: "rgb(240, 241, 250)",
        border: "1px solid rgb(180, 187, 232)",
        padding: 4,
      }}
      label={label}
      onDelete={() => props.deleteContactFromTask(props.currentItem)}
      onClick={(e) => props.handleEditContactClick(e, props.currentItem)}
      icon={
        icon && (
          <i className="material-icons" style={{ fontSize: "12px", color: iconColor }}>
            {icon}
          </i>
        )
      }
    />
  );
}
