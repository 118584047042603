import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function AutoSuggestInput(props) {
  return (
    <>
      <Autocomplete
        id="country-select-demo"
        options={props.accountVideos}
        onChange={(e, item) => props.itemSelected(item)}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <img
                src={option.thumbnailUrl || option.thumbnail}
                style={{
                  width: "75px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  backgroundColor: "#ddd",
                }}
                alt="video thumbnail"
              />
              <span style={{ marginLeft: 10 }}>{option.title}</span>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select video"
            variant="outlined"
            fullWidth
            placeholder="Search existing videos"
            autoComplete="off"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </>
  );
}
