import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import { IconButton, Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { Link } from "react-router";
import { sendNote } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function SendNowDialog(props) {
  let {
    dialogOpen,
    closeDialog,
    contacts,
    videoIds,
    noteId,
    shareViaText,
    shareViaEmail,
    resendActive,
    resendInXDays,
    contactCounts = {},
  } = props;
  let { text = 0, email = 0 } = contactCounts;
  let [state, setState] = useState({});
  let [gvSendId, setGvSendId] = useState(null);
  let [notesSentThisSession, setNotesSentThisSession] = useState(0);
  let [contactsIncludeLists, setContactsIncludeLists] = useState(null);
  let [confirmedNoTaskSending, setConfirmedNoTaskSending] = useState(null);

  const fullScreen = useMobileDialog();

  useEffect(() => {
    //we only create a new gvSendId if dialog is open. this is to prevent double sending on accident
    if (!dialogOpen) return;
    const tempGvSendId = "sid_" + crypto.randomUUID();
    setGvSendId(tempGvSendId);

    let tempNotesSentThisSession = window.sessionStorage.getItem("notesSentThisSession");
    if (!tempNotesSentThisSession) tempNotesSentThisSession = 0;
    else tempNotesSentThisSession = parseInt(tempNotesSentThisSession, 10);
    setNotesSentThisSession(tempNotesSentThisSession);

    const tempContactsIncludeLists = contacts.some((contact) => contact.folder);
    setContactsIncludeLists(tempContactsIncludeLists);
  }, [dialogOpen]);

  let onSendNote = async () => {
    try {
      if (contacts.length === 0)
        throw new Error(
          'No contacts selected. You must add contacts under the "Send To" section before sending.'
        );
      if (!videoIds || videoIds.length === 0)
        throw new Error("No video. You must select a video before sending a note.");
      if (!shareViaText && !shareViaEmail)
        throw new Error(
          "You must select either send email or send text under Send To section."
        );

      setState({ buttonState: "loading" });

      const sendObject = {
        noteId,
        contentId: nanoid(10),
        gvSendId,
        status: "processing",
        calledFrom: "editNote",
        contactIds: contacts
          .filter(({ contactId }) => contactId)
          .map(({ contactId }) => contactId),
        folderIds: contacts
          .filter(({ folderId }) => folderId)
          .map(({ folderId }) => folderId),
        type: "sendNote",
        emailProvider: "default",
      };
      await sendNote(sendObject);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.sendNote" });

      let newNotesSentThisSession = notesSentThisSession + 1;
      window.sessionStorage.setItem("notesSentThisSession", newNotesSentThisSession);

      //this will autosegue into sendhistory
      setState({ buttonState: "saved" });
      closeDialog();
      props.setTabSelected("history");
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage, buttonState: "regular" });
    }
  };

  let disableBulkSends = props.userRole?.isBulkSender ?? false;
  let resendOnData = new Date();
  resendOnData.setDate(resendOnData.getDate() + parseInt(resendInXDays)); //number of days to add, e.x. 15 days
  let resendDateFormated = resendOnData.toDateString();
  let resendTimeFormated = resendOnData.toLocaleTimeString();
  let textContactPluralForm = text > 1 ? "contacts" : "contact";
  let emailContactPluralForm = email > 1 ? "contacts" : "contact";

  if (
    notesSentThisSession >= 1 &&
    !contactsIncludeLists &&
    !confirmedNoTaskSending &&
    contacts.length > 0
  ) {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={() => closeDialog()}
        aria-labelledby="send now dialog">
        <DialogContent style={{ minHeight: 275 }}>
          <div className="col-12" style={{ display: "flex" }}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "18px" }}>Heads up!</span>
            </div>
            <div style={{ width: "48px" }}>
              <IconButton aria-label="close" onClick={() => closeDialog()}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>
          </div>

          <div className="col-12">
            <p>
              We highly recommend using the{" "}
              <Link to={`/a/${props.accountInfo.id}/task`} style={{ fontSize: 16 }}>
                task list
              </Link>{" "}
              to send personalized (one-to-one) videos. You are currently sending a note
              which is best for sending in bulk.
            </p>
            <br />
            <p>
              To learn more, watch{" "}
              <a
                style={{ fontSize: 16 }}
                href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid"
                target="_blank"
                rel="noopener noreferrer">
                How To Send A Gratavid
              </a>
              .
            </p>
          </div>

          <div className="col-12 justify-content-center" style={{ margin: "25px 0px" }}>
            <Button
              variant="outlined"
              style={{ marginRight: 15 }}
              onClick={() => closeDialog()}>
              Cancel Send
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setConfirmedNoTaskSending(true)}>
              Got it - Continue
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={() => closeDialog()}
      aria-labelledby="send now dialog">
      <DialogContent>
        <div className="col-12" style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Confirm Send Now</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={() => closeDialog()}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {disableBulkSends && (
          <div className="col-12 notification-message-box">
            <b>
              You can only send a Gratavid to 3 or fewer contacts at once. Contact your
              Gratavid admin about sending Gratavids in bulk.
            </b>
          </div>
        )}

        {!contactsIncludeLists && contacts.length > 0 && (
          <div className="col-12 tipMessageBox">
            <p style={{ fontSize: 14 }}>
              Tip: We highly recommend using the{" "}
              <Link to={`/a/${props.accountInfo.id}/task`} style={{ fontSize: 14 }}>
                task list
              </Link>{" "}
              to send personalized (one-to-one) videos. To learn more, watch{" "}
              <a
                style={{ fontSize: 14 }}
                href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid"
                target="_blank"
                rel="noopener noreferrer">
                How To Send A Gratavid
              </a>
              .
            </p>
          </div>
        )}

        <div
          className="col-12"
          style={{ display: "flex", alignItems: "center", marginTop: "25px" }}>
          {shareViaEmail ? (
            <i className="material-icons" style={{ color: "green", marginRight: "3px" }}>
              task_alt
            </i>
          ) : (
            <i className="material-icons" style={{ color: "grey", marginRight: "3px" }}>
              highlight_off
            </i>
          )}
          Send email {shareViaEmail && " to " + email + " " + emailContactPluralForm}
        </div>

        <div
          className="col-12"
          style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
          {shareViaText ? (
            <i className="material-icons" style={{ color: "green", marginRight: "3px" }}>
              task_alt
            </i>
          ) : (
            <i className="material-icons" style={{ color: "grey", marginRight: "3px" }}>
              highlight_off
            </i>
          )}
          Send text {shareViaText && " to " + text + " " + textContactPluralForm}
        </div>

        <div
          className="col-12"
          style={{ display: "flex", alignItems: "center", margin: "8px 0px 30px 0px" }}>
          {resendActive ? (
            <i className="material-icons" style={{ color: "green", marginRight: "3px" }}>
              task_alt
            </i>
          ) : (
            <i className="material-icons" style={{ color: "grey", marginRight: "3px" }}>
              highlight_off
            </i>
          )}
          Resend if not opened{" "}
          {resendActive && " on " + resendDateFormated + " at " + resendTimeFormated}
        </div>

        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Sent!"
          label="Send Now"
          clickedRegularButton={() => onSendNote()}
          clickedSavedButton={() => closeDialog()}
        />
      </DialogActions>
    </Dialog>
  );
}
