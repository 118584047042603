import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { debounce } from "util/helpers";

export default function TextInput(props) {
  const [state, setState] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(props.value);

  const editVideo = useCallback(
    debounce((value) => {
      setState("loading");
      try {
        props.onChange(value);
        setState("saved");
      } catch (error) {
        setError(error);
      }
    }, props?.debounce ?? 0),
    [props.onChange]
  );

  const onChange = (event) => {
    setValue(event.target.value);
    editVideo(event.target.value);
  };

  let statusIcon = null;
  if (state === "loading") {
    statusIcon = (
      <CircularProgress
        style={{ width: "15px", height: "15px", margin: "0px 4px 0px 6px" }}
      />
    );
  }
  if (state === "saved") {
    statusIcon = (
      <i
        className="material-icons"
        style={{ color: "green", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        check_circle
      </i>
    );
  }
  return (
    <TextField
      {...props}
      value={value}
      onChange={onChange}
      label={
        <div className="align-items-center">
          <>{props.label}</>
          <>{statusIcon}</>
        </div>
      }
      error={error}
    />
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  debounce: PropTypes.number,
};
