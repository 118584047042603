import React from "react";
import { Button, Radio } from "@mui/material";

export default function SelectTypes(props) {
  let { userType, setUserType } = props;

  return (
    <>
      <div style={{ flex: 1, margin: "15px 0px" }}>
        <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
          What type of user would you like to add?
        </h3>
      </div>

      {props.isUserAdmin && (
        <Button
          style={{
            border: "1px solid rgb(223, 227, 235)",
            width: "100%",
            display: "flex",
            padding: "25px 10px",
          }}
          onClick={() => setUserType("internal")}>
          <div style={{ width: "50px" }}>
            <Radio checked={userType === "internal"} />
          </div>

          <div style={{ flex: 1, display: "block", textAlign: "start" }}>
            <div className="col-12 align-items-center" style={{ overflow: "hidden" }}>
              <h5
                style={{ fontSize: "16px", fontWeight: "normal", textTransform: "none" }}>
                Internal User
              </h5>
              <div
                style={{
                  color: "#636363",
                  fontSize: "12px",
                  background: "#ebebeb",
                  borderRadius: "2px",
                  padding: "5px 8px",
                  marginLeft: "5px",
                }}>
                Recommended
              </div>
            </div>
            <p style={{ fontSize: "14px", textTransform: "none" }}>
              Internal users have full access to Gratavid. They can log in, edit contacts,
              edit notes, and send Gratavids. We recommend adding your team as internal
              users.
            </p>
          </div>
        </Button>
      )}

      <Button
        style={{
          border: "1px solid rgb(223, 227, 235)",
          width: "100%",
          display: "flex",
          padding: "25px 10px",
          marginTop: "15px",
        }}
        onClick={() => setUserType("external")}>
        <div style={{ width: "50px" }}>
          <Radio checked={userType === "external"} />
        </div>

        <div style={{ flex: 1, display: "block", textAlign: "start" }}>
          <h5 style={{ fontSize: "16px", fontWeight: "normal", textTransform: "none" }}>
            External Assignee
          </h5>
          <p style={{ fontSize: "14px", textTransform: "none" }}>
            External assignees can't log in to your Gratavid account, but you can assign
            tasks to them. Once a task has been assigned to them, they can record videos
            via their task{" "}
            <a
              href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6654092311452-What-is-an-assignee-link-"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: "14px" }}>
              "assignee link."
            </a>{" "}
            Add your top volunteers, board members, and advocates as external assignees.
          </p>
        </div>
      </Button>
    </>
  );
}
