import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import RequestVideo from "./request/request";
import LinearProgress from "@mui/material/LinearProgress";
import PageNotFound444 from "../../../util/pageNotFound444";
import TrimVideo from "./trim/trimVideo";
import { getVideo } from "services/api.service";
import ManageVideo from "./manageVideo";

export default function EditVideo(props) {
  const [state, setState] = useState({
    title: "",
    uploadType: "loading",
    status: null,
    mediaURL: null,
    webmURL: null,
    thumbnail: null,
    addNewDialogOpen: false,
    deletedVideoState: null,
  });

  const params = useParams();

  useEffect(() => {
    if (!params.videoId) {
      return;
    }
    getVideo(params.videoId)
      .then((video) => {
        const title = video?.title ?? "";
        const thumbnail =
          video?.thumbnailUrl ??
          "https://account.gratavid.com/static/img/playThumbnailNoPlayButton.png";
        const mediaURL = video?.videoUrl;
        const audioSrc = video?.audioUrl;
        const gif = video?.gifUrl;
        const duration = video?.duration ?? 0;
        const status = video?.status ?? "create";
        setState({
          ...video,
          thumbnail,
          mediaURL,
          audioSrc,
          gif,
          duration,
          status,
          title,
        });
      })
      .catch((error) => {
        setState({ errorMessage: error.message, uploadType: "error" });
      });
  }, [params.videoId]);

  //used by upload
  const videoUploadStatusChanged = (
    status,
    webmURL,
    mediaURL,
    errorMessage,
    thumbnail,
    gif,
    audioSrc,
    captions
  ) => {
    setState({
      status,
      webmURL,
      mediaURL,
      errorMessage,
      thumbnail,
      gif,
      audioSrc,
      captions,
    });
  };

  //used by upload
  const updateStatus = (status, errorMessage) => {
    setState({ status, errorMessage });
  };

  const passedSetEditVideoState = (state, value) => {
    setState({ [state]: value });
  };

  let child;
  if (state.uploadType === "error") {
    child = (
      <div className="row col-12 pagePadding">
        <PageNotFound444 errorMessage={state.errorMessage} />
      </div>
    );
  } else if (["trimming", "trimUploading"].includes(state.status)) {
    child = (
      <div className="row col-12">
        <TrimVideo
          accountId={props.accountId}
          videoId={state.id}
          mediaURL={state.mediaURL}
          status={state.status}
          videoUploadStatusChanged={videoUploadStatusChanged}
          updateStatus={updateStatus}
        />
      </div>
    );
  } else if (state.uploadType === "loading") {
    return (
      <div className="row col-12">
        <LinearProgress />
      </div>
    );
  } else if (state.uploadType === "request") {
    child = (
      <RequestVideo
        {...props}
        {...state}
        videoId={state.id}
        passedSetEditVideoState={passedSetEditVideoState}
        updateStatus={updateStatus}
        videoUploadStatusChanged={videoUploadStatusChanged}
      />
    );
  } else if (state.uploadType === "upload") {
    child = (
      <ManageVideo
        accountId={props.accountId}
        videoId={state.id}
        title={state.title}
        recorderName={props.recorderName}
        uploadType={state.uploadType}
        gif={state.gif}
        speakerName={state.speakerName}
        thumbnail={state.thumbnail}
        mediaURL={state.mediaURL}
        audioSrc={state.audioSrc}
        duration={state.duration}
        status={state.status}
        captions={state.captions}
        videoUploadStatusChanged={videoUploadStatusChanged}
        updateStatus={updateStatus}
        passedSetEditVideoState={passedSetEditVideoState}
        deletedVideoState={state.deletedVideoState}
      />
    );
  }

  return (
    <div className="row col-12">
      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      {child}
    </div>
  );
}

EditVideo.propTypes = {
  accountId: PropTypes.string.isRequired,
  recorderName: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
};
