import React, { useState } from "react";
import { Tooltip, Slider, Button } from "@mui/material";
import { withStyles } from "@mui/styles";

const TrappTip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    width: 250,
    height: 100,
    color: "black",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    padding: "50px 50px",
    zIndex: 999999999,
  },
}))(Tooltip);

export default function TimeControl(props) {
  let {
    currentStartTime = 0,
    currentEndTime = 0,
    minStartTime = 0,
    maxEndTime = 0,
    newPhrases,
    phraseId,
  } = props;
  let [sliderValue, setSliderValue] = useState([currentStartTime, currentEndTime]);

  function valuetext(value) {
    return `${value}`;
  }

  const handleSliderChange = (e, value) => {
    setSliderValue(value);
  };

  const handleSliderCommitted = (e, value) => {
    //once mouse is raised then we can update main state
    //otherwise would be too many renders hurting performance
    let newStartTime = value[0];
    let newEndTime = value[1];

    let startTimeDate = new Date(newStartTime * 1000);
    let endTimeDate = new Date(newEndTime * 1000);
    let st = startTimeDate.toISOString().substr(11, 12); //"00:00:06.300
    let et = endTimeDate.toISOString().substr(11, 12);

    let stMinutes = startTimeDate.getMinutes();
    let stSeconds = startTimeDate.getSeconds() + stMinutes * 60;
    let stNanos = startTimeDate.getMilliseconds() * 1000000;

    let etMinutes = endTimeDate.getMinutes();
    let etSeconds = endTimeDate.getSeconds() + etMinutes * 60;
    let etNanos = endTimeDate.getMilliseconds() * 1000000;

    let tempNewPhrases = { ...newPhrases };
    tempNewPhrases[phraseId].et = et;
    tempNewPhrases[phraseId].st = st;
    tempNewPhrases[phraseId].startTime = { seconds: stSeconds, nanos: stNanos };
    tempNewPhrases[phraseId].endTime = { seconds: etSeconds, nanos: etNanos };

    props.setNewPhrases(tempNewPhrases);
  };

  let minLabel = new Date(minStartTime * 1000).toISOString().substr(14, 9);
  let maxLabel = new Date(maxEndTime * 1000).toISOString().substr(14, 9);
  const marks = [
    { value: minStartTime, label: minLabel },
    { value: maxEndTime, label: maxLabel },
  ];

  let startLabel = new Date(currentStartTime * 1000).toISOString().substr(14, 9);
  let endLabel = new Date(currentEndTime * 1000).toISOString().substr(14, 9);

  return (
    <TrappTip
      placement="top"
      title={
        <React.Fragment>
          <Slider
            orientation="horizontal"
            color="primary"
            valueLabelDisplay="on"
            value={sliderValue}
            min={minStartTime}
            step={0.1}
            max={maxEndTime}
            marks={marks}
            getAriaLabel={valuetext}
            onChangeCommitted={handleSliderCommitted}
            onChange={handleSliderChange}
          />
        </React.Fragment>
      }>
      <Button style={{ lineHeight: "20px" }}>
        {startLabel}
        <br />
        {endLabel}
      </Button>
    </TrappTip>
  );
}
