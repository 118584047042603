import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, IconButton } from "@mui/material";
import LoadingButton from "../../util/buttons/loadingbutton";
import { emailAnalytics } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function DownloadContactsDialog(props) {
  let { lastXDays } = props;
  let [state, setState] = useState({});
  let [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  const fullScreen = useMobileDialog();

  let downloadContacts = async () => {
    try {
      setState({ buttonState: "loading" });
      await emailAnalytics(lastXDays);

      setState({ buttonState: "saved" });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      setState({ errorMessage: errorMessage });
      console.error(errorMessage);
    }
  };

  let closeDialog = () => {
    setState({});
    setDownloadDialogOpen(false);
  };

  let title = props.noteId
    ? "Download Data For Note Over Past " + lastXDays + " Days"
    : "Download Data For Account Over Past " + lastXDays + " Days";

  return (
    <>
      <IconButton
        aria-label="download contact metrics"
        onClick={() => setDownloadDialogOpen(true)}>
        <i className="material-icons" style={{ color: "black" }}>
          get_app
        </i>
      </IconButton>

      <Dialog
        fullScreen={fullScreen}
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        aria-labelledby="add note dialog">
        <div style={{ minWidth: "250px", padding: "20px" }}>
          <div
            className="row col-12 pagePadding"
            style={{ padding: "20px", minWidth: "250px" }}>
            <div className="col-10">
              <h2>{title}</h2>
            </div>

            <div className="col-2 justify-content-end">
              <IconButton aria-label="close" onClick={() => setDownloadDialogOpen(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>

            <div className="col-12">
              <p style={{ fontSize: "16px" }}>
                We will send an email to you with the data attached.
              </p>
            </div>

            {state.errorMessage && (
              <div className="errorMessageBox">
                <b>{state.errorMessage}</b>
              </div>
            )}

            <div
              className="col-12 justify-content-center"
              style={{ padding: "20px 0px" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                buttonState={state.buttonState}
                savedLabel={"Sent. Check Inbox"}
                label={"Send Now"}
                clickedRegularButton={downloadContacts}
                clickedSavedButton={closeDialog}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

DownloadContactsDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  noteId: PropTypes.string,
  lastXDays: PropTypes.number.isRequired,
};
