import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@mui/material";
import EditNote from "./editNote";
import NoteAnalytics from "./analytics/noteAnalytics";
import NoteRecipients from "./analytics/recipients/recipients";
import SendHistory from "./sendHistory/sendHistory";
import ScheduledHistory from "./scheduledHistory/scheduledHistory";
import BigBreadcrumb from "../../../util/breadcrumb/bigBreadcrumb";
import { getSearchParams } from "util/helpers";
import { useGetNoteContent } from "../../../util/hooks/useGetNoteContent";

export default function ManageNote(props) {
  let [tabSelected, setTabSelected] = useState("edit");
  let [noteTitle, setNoteTitle] = useState("loading");
  let [analyticsSendItemSelected, setAnalyticsSendItemSelected] = useState({});
  let [recipientsEventSelected, setRecipientsEventSelected] = useState("");

  const params = useParams();

  // FIXME: avoid multiple calls
  const [noteContent] = useGetNoteContent(params.noteId, props.taskId, props.contentId);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const searchParams = getSearchParams();
    setTabSelected(searchParams.get("tab") ?? "edit");
    setNoteTitle("Edit Note");
  }, []);

  useEffect(() => {
    setNoteTitle(noteContent.title);
  }, [noteContent]);

  let editNoteShowSettings = {
    showContacts: true,
    showBirthdayContacts: false,
    showBirthdaySettings: false,
    showSendNote: true,
    showCTA: true,
    showBranding: true,
    showEmail: true,
    showText: true,
    showReply: true,
    showResend: true,
    showExternalShare: true,
    showEmailLocalPart: true,
    showEmailReplyTo: true,
  };

  return (
    <div className="row col-12">
      <BigBreadcrumb
        indexName="notes"
        accountId={props.accountInfo.id}
        id={params.noteId}
        title={noteTitle}
        setTitle={setNoteTitle}
        editDisabled={props.userNoteWriteNoteDisabled}
      />

      <div
        className="col-12"
        style={{
          borderBottom: "1px #ddd solid",
          padding: "0px 8.5px",
          maxWidth: "100vw",
        }}>
        <Tabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="task tabs"
          variant="scrollable"
          scrollButtons="auto">
          <Tab
            label={props.userNoteWriteNoteDisabled ? "Preview Note" : "Edit Note"}
            {...a11yProps("edit")}
            value="edit"
          />
          <Tab label="Scheduled" {...a11yProps("schedule")} value="schedule" />
          <Tab label="Send History" {...a11yProps("history")} value="history" />
          <Tab label="Analytics" {...a11yProps("analytics")} value="analytics" />
          <Tab label="Recipients" {...a11yProps("recipients")} value="recipients" />
        </Tabs>
      </div>

      {tabSelected === "edit" && (
        <EditNote
          {...props}
          noteId={params.noteId}
          noteContent={noteContent}
          setTabSelected={setTabSelected}
          showSettings={editNoteShowSettings}
        />
      )}
      {tabSelected === "schedule" && (
        <ScheduledHistory {...props} noteId={params.noteId} />
      )}
      {tabSelected === "history" && (
        <SendHistory
          {...props}
          noteId={params.noteId}
          setTabSelected={setTabSelected}
          setAnalyticsSendItemSelected={setAnalyticsSendItemSelected}
          setRecipientsEventSelected={setRecipientsEventSelected}
        />
      )}
      {tabSelected === "analytics" && (
        <NoteAnalytics
          {...props}
          noteId={params.noteId}
          analyticsSendItemSelected={analyticsSendItemSelected}
          setAnalyticsSendItemSelected={setAnalyticsSendItemSelected}
        />
      )}
      {tabSelected === "recipients" && (
        <NoteRecipients
          {...props}
          noteId={params.noteId}
          noteTitle={noteTitle}
          recipientsEventSelected={recipientsEventSelected}
          setRecipientsEventSelected={setRecipientsEventSelected}
        />
      )}
    </div>
  );
}

ManageNote.propTypes = {
  accountInfo: PropTypes.object,
  noteId: PropTypes.string.isRequired,
  taskId: PropTypes.string,
  contentId: PropTypes.string,
};
