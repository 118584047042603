import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DailyChart(props) {
  let [dataType, setDataType] = useState("note");
  let { dataByShortDateArray } = props;
  let areaArray;

  if (dataType === "note") {
    let events = [
      { id: "note_pageView", name: "Page Views", color: "#8884d8" },
      { id: "note_playVideo", name: "Video Plays", color: "#82ca9d" },
      { id: "note_ctaClick", name: "CTA Clicks", color: "#ffc658" },
      { id: "note_reply", name: "Replies", color: "#84d0d8" },
      { id: "note_share", name: "Shares", color: "#d88684" },
    ];
    areaArray = events.map((current) => {
      return (
        <Bar
          key={current.id}
          type="monotone"
          dataKey={current.id}
          name={current.name}
          stroke={current.color}
          fill={current.color}
        />
      );
    });
  } else if (dataType === "email") {
    let events = [
      { id: "email_delivered", name: "Emails Delivered", color: "#8884d8" },
      { id: "email_open", name: "Emails Opened", color: "#82ca9d" },
      { id: "email_click", name: "Emails Clicked", color: "#ffc658" },
    ];
    areaArray = events.map((current) => {
      return (
        <Bar
          key={current.id}
          type="monotone"
          dataKey={current.id}
          name={current.name}
          stroke={current.color}
          fill={current.color}
        />
      );
    });
  } else if (dataType === "text") {
    let events = [
      { id: "text_delivered", name: "Texts Delivered", color: "#8884d8" },
      { id: "text_click", name: "Texts Clicked", color: "#82ca9d" },
    ];
    areaArray = events.map((current) => {
      return (
        <Bar
          key={current.id}
          type="monotone"
          dataKey={current.id}
          name={current.name}
          stroke={current.color}
          fill={current.color}
        />
      );
    });
  }

  if (props.loading) return null;

  return (
    <div className="row col-12" style={{ padding: "10px" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "15px", borderRadius: "3px" }}>
        <div className="col-6">
          <h2 style={{ textTransform: "capitalize" }}>Daily {dataType} Stats</h2>
        </div>

        <div className="col-6 justify-content-end">
          <FormControl style={{ width: "150px" }}>
            <InputLabel id="dateType">Select Breakdown</InputLabel>
            <Select
              label="Select Breakdown"
              labelId="dateType"
              value={dataType}
              native
              onChange={(e) => setDataType(e.target.value)}
              inputProps={{ name: "dateType", id: "dateType" }}>
              <option value="note">Notes</option>
              <option value="email">Emails</option>
              <option value="text">Text</option>
            </Select>
          </FormControl>
        </div>

        <div className="col-12" style={{ marginTop: "25px" }}>
          <ResponsiveContainer height={200}>
            <BarChart
              data={dataByShortDateArray}
              margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {areaArray}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
