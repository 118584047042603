import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";

const WarningTip = withStyles((theme) => ({
  tooltip: {
    background:
      "#ffecec url('https://account.gratavid.com/static/img/info.svg') no-repeat 10px 50%",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    border: "2px solid #f5aca6",
    borderRadius: 10,
    padding: "10px 10px 10px 40px",
    margin: "10px 0px",
    width: "100%",
    fontSize: 15,
    color: "black",
  },
}))(Tooltip);

function LoadingButtonCheckProductLimits(props) {
  let {
    color,
    id,
    size = "medium",
    icon = null,
    label,
    variant,
    fullWidth,
    type = "button",
    disabled = false,
    productKey,
    savedLabel = "Success",
    buttonState = "",
    clickedSavedButton,
    clickedRegularButton,
  } = props;
  const { count = 0, limit = 0 } = props?.accountInfo?.productCounts?.[productKey] ?? {};

  let atOrAboveLimit = count >= limit ? true : false;

  if (buttonState === "saved") {
    return (
      <Button
        type="button"
        color={color}
        style={{ backgroundColor: "green" }}
        variant={variant}
        fullWidth={fullWidth}
        onClick={clickedSavedButton}>
        <i className="material-icons" style={{ color: "white" }}>
          check_circle
        </i>
        {savedLabel}
      </Button>
    );
  } else if (buttonState === "loading") {
    return (
      <Button type="button" color={color} variant={variant} fullWidth={fullWidth}>
        <CircularProgress size={30} color="inherit" />
      </Button>
    );
  } else if (atOrAboveLimit && props.productKey !== "videos") {
    // Videos don't have a limit yet
    //enterprise we never block on front end, just show warning
    let warning = (
      <>
        <b>Warning</b>: Limit exceeded for {productKey}. Your account has used {count} out
        of {limit} {productKey}. You can still add {productKey} but we recommend you
        delete existing {productKey}, or email sales@gratavid.com to increase your plan.
      </>
    );

    return (
      <WarningTip title={warning}>
        <Button
          color={color}
          id={id}
          size={size}
          disabled={disabled}
          type={type}
          variant={variant}
          fullWidth={fullWidth}
          onClick={clickedRegularButton}>
          {icon && (
            <i className="material-icons" style={{ color: "black", marginRight: "3px" }}>
              {icon}
            </i>
          )}
          {label}
        </Button>
      </WarningTip>
    );
  }

  return (
    <Button
      color={color}
      id={id}
      size={size}
      disabled={disabled}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      onClick={(event) => clickedRegularButton(event)}>
      {icon && (
        <i className="material-icons" style={{ color: "black", marginRight: "3px" }}>
          {icon}
        </i>
      )}
      {label}
    </Button>
  );
}

LoadingButtonCheckProductLimits.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  clickedRegularButton: PropTypes.func.isRequired,
  clickedSavedButton: PropTypes.func,
  color: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  productKey: PropTypes.string,
  savedLabel: PropTypes.string,
  buttonState: PropTypes.string,
};

export default LoadingButtonCheckProductLimits;
