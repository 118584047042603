import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./checkbox.module.css";
import MUICheckbox from "@mui/material/Checkbox";
import LinearProgress from "@mui/material/LinearProgress";

const VIEW_STATES = {
  regular: "regular",
  loading: "loading",
  saved: "saved",
  edit: "edit",
  delete: "delete",
};

export default function Checkbox(props) {
  let [viewState, setViewState] = useState(VIEW_STATES.regular);
  let [editLabel, setEditLabel] = useState(props.label);

  let deleteFolder = async () => {
    try {
      setViewState(VIEW_STATES.loading);
      await props.onDelete(props.itemId);
      setViewState(VIEW_STATES.regular);
    } catch (err) {
      console.log(err.message);
    }
  };

  let editFolder = async () => {
    try {
      setViewState(VIEW_STATES.loading);
      await props.onEdit(props.itemId, editLabel);
      setViewState(VIEW_STATES.regular);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleToggle = () => {
    props.onToggle(props.itemId);
  };

  const makeButtons = function (
    leftOnClick,
    rightOnClick,
    leftClass = "cancel",
    rightClass = "save",
    leftIcon = "cancel",
    rightIcon = "check_circle"
  ) {
    return (
      <div className={`folderItemButtons ${styles.buttons}`}>
        <i
          className={`material-icons folderButtons ${styles.iconButton} ${styles[leftClass]}`}
          onClick={leftOnClick}>
          {leftIcon}
        </i>
        <i
          className={`material-icons folderButtons ${styles.iconButton} ${styles[rightClass]}`}
          onClick={rightOnClick}>
          {rightIcon}
        </i>
      </div>
    );
  };

  if (viewState === VIEW_STATES.loading) {
    return (
      <div className={styles.loading}>
        <LinearProgress />
      </div>
    );
  } else if (viewState === VIEW_STATES.edit) {
    return (
      <div className={`folderItem ${styles.edit}`}>
        <div className={styles.textBox}>
          <input value={editLabel} onChange={(e) => setEditLabel(e.target.value)} />
        </div>
        {makeButtons(
          () => setViewState(VIEW_STATES.regular),
          () => editFolder()
        )}
      </div>
    );
  } else if (viewState === VIEW_STATES.delete) {
    return (
      <div className={`folderItem ${styles.edit}`}>
        <div style={{ flex: 1, cursor: "pointer" }}>
          <span>Delete this folder?</span>
        </div>
        {makeButtons(
          () => setViewState(VIEW_STATES.regular),
          () => deleteFolder()
        )}
      </div>
    );
  }

  return (
    <div className={`folderItem ${styles.folderItem}`}>
      <div style={{ width: "29px" }} onClick={handleToggle}>
        <MUICheckbox checked={props.value} style={{ padding: "0px 5px 0px 0px" }} />
      </div>
      <div style={{ flex: 1, cursor: "pointer" }} onClick={handleToggle}>
        <span className={props.isAll ? styles.allLabel : styles.label}>
          {props.label}
        </span>
      </div>
      {!props.isAll &&
        !props.hideEdit &&
        makeButtons(
          () => setViewState(VIEW_STATES.edit),
          () => setViewState(VIEW_STATES.delete),
          "grey",
          "grey",
          "edit",
          "delete"
        )}
    </div>
  );
}

Checkbox.propTypes = {
  itemId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  hideEdit: PropTypes.bool,
  isAll: PropTypes.bool,
};
