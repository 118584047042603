import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { MenuItem, LinearProgress } from "@mui/material";
import timeSinceDate from "../../../util/functions/timeSinceDate";
import { getTask } from "services/api.service";
import { toFullName } from "util/helpers";

export default function NewTask(props) {
  let [state, setState] = useState({});
  const [contactLabel, setContactLabel] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let getData = async () => {
      if (props.current.taskId) {
        const task = await getTask(props.current.taskId);
        setState({ comments: task?.comments || "" });

        const contact = task?.taskContacts?.[0]?.contact;
        if (contact) {
          setContactLabel(toFullName(contact.firstName, contact.lastName, contact.email));
        }
      }

      props.updateMenuPosition();
      setIsLoading(false);
    };

    getData();
  }, []);

  if (!state.comments) return null;

  let timeSinceLastSent = timeSinceDate(new Date(props.current.createdDate));

  return (
    <MenuItem
      style={{ display: "flex" }}
      component={Link}
      to={`/a/${props.accountId}/task?taskId=${props.current.taskId}`}
      onClick={() => props.setAnchorEl(null)}>
      <div style={{ width: "30px" }}>
        <i
          className="material-icons"
          style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
          list
        </i>
      </div>
      <div style={{ flex: 1 }} className="row">
        <div className="col-12">
          {isLoading ? (
            <LinearProgress />
          ) : (
            "Task created: " + contactLabel + " " + state.comments
          )}
        </div>
        <div className="col-12">
          <p className="greyDesc" style={{ fontSize: "14px" }}>
            {timeSinceLastSent}
          </p>
        </div>
      </div>
    </MenuItem>
  );
}

NewTask.propTypes = {
  current: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  updateMenuPosition: PropTypes.func.isRequired,
};
