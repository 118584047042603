import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "@mui/material";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import { isValidEmail } from "util/helpers";

export default function LocalPart(props) {
  const [localPart, setLocalPart] = useState(
    props.emailLocalPart || props.domainLocalPart || "hello"
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const domain = props.domain || "gratavid.com";

  async function validateInputEmail(state, value) {
    const email = value + "@" + domain;
    setLocalPart(value);

    if (value === "" || isValidEmail(email)) {
      setErrorMessage(null);
      return props.passedSetState(state, value);
    } else {
      setErrorMessage("Invalid email. Make sure no spaces.");
    }
  }

  return (
    <div className="col-12 row" style={{ display: "flex" }}>
      <div style={{ width: "140px" }}>
        <TextInputToApi
          initialValue={localPart}
          parentErrorMessage={errorMessage}
          type="text"
          apiCall={validateInputEmail}
          field="emailLocalPart"
          variant="standard"
          label="Send From Email"
        />
      </div>
      <div>
        <Input
          disabled
          readOnly
          defaultValue={"@" + domain}
          inputProps={{ "aria-label": "Email domain url", height: "30px" }}
          style={{ marginTop: "16px" }}
        />
      </div>
    </div>
  );
}

LocalPart.propTypes = {
  emailLocalPart: PropTypes.string,
  domainLocalPart: PropTypes.string,
  domain: PropTypes.string,
  passedSetState: PropTypes.func,
};
