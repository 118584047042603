import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import DefaultAction from "../../../actions/action";
import VirtuousAction from "./action";
import AddAction from "../../../actions/addAction";
import SortableList from "components/util/sortable/SortableList";
import { updateAccountIntegrations } from "services/api.service";
import { manageIntegrations } from "services/api.service";

export default function GiftAdded(props) {
  let { eventData = {}, accountInfo, users = [] } = props;
  eventData = eventData || {};
  let initialActions = eventData.actions || {};
  let [actions, setActions] = useState(initialActions);
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  let saveActions = async () => {
    try {
      setState({ buttonState: "loading" });

      const updates = [
        {
          path: "endpoint/virtuous/events/giftAdded/actions",
          value: actions,
        },
      ];

      await updateAccountIntegrations(updates);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });
      const subscribe = newActiveValue;
      const requestData = {
        subscribe: subscribe,
        event: "giftAdded",
        source: "virtuous",
      };

      await manageIntegrations(
        { source: requestData.source, accountId: props.accountInfo.id },
        requestData
      );

      setState({});
      setActive(newActiveValue);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      window.alert(errorMessage);
      setState({ errorMessage });
    }
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>New Gift Added In Virtuous</h2>
          <span className="greyDesc">
            Create a task or send a note when a new gift is added in Virtuous.
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails className="row col-12" style={{ cursor: "auto" }}>
        <div className="row col-12">
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <span className="circleStep">1</span>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When a new gift is added in Virtuous...
            </h2>
          </div>
        </div>

        <div className="col-12">
          <SortableList
            items={actions.list || []}
            onChange={(items) => setActions({ ...actions, list: items })}
            renderItem={(item, index) => (
              <SortableList.Item id={item.id}>
                <VirtuousAction
                  currentAction={item}
                  actionIndex={index}
                  order={index + 1}
                  accountInfo={props.accountInfo}
                  actions={actions}
                  setActions={setActions}
                  users={users}
                />
              </SortableList.Item>
            )}
          />
        </div>

        <AddAction {...props} actions={actions} setActions={setActions} />

        <DefaultAction
          {...props}
          users={users}
          defaultAction={true}
          currentAction={actions}
          actions={actions}
          setActions={setActions}
        />

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
GiftAdded.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  eventData: PropTypes.any,
  users: PropTypes.array,
};
