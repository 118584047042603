import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, FormControlLabel, Switch } from "@mui/material";
import EmailAutoSuggestInput from "./emailAutoSuggestInput";

export default function ListContactInputs(props) {
  let { variant = "outlined" } = props;

  const defaultProperties = [
    { id: "email", label: "Email" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "salutation", label: "Salutation" },
    { id: "cellNumber", label: "Cell Number" },
    { id: "textOptIn", label: "Opted In To Text" },
    { id: "customUserId", label: "Custom User Id" },
    { id: "customAccountId", label: "Custom Account Id" },
  ];

  let checkForPHI = false;
  let [phiDataContainsPHI, setPhiDataContainsPHI] = useState(true);
  let [phiReleaseAuthorized, setPhiReleaseAuthorized] = useState(false);

  const handleFieldChange = (fieldId, value, isCustom) => {
    const updatedContact = {
      ...props.contactData,
      contact: {
        ...props.contactData.contact,
      },
    };

    if (isCustom) {
      updatedContact.contact.customProperties = {
        ...updatedContact.contact.customProperties,
        [fieldId]: value,
      };
    } else {
      updatedContact.contact[fieldId] = value;
    }

    props.setContactData(updatedContact);
  };

  let PhiHTML = () => {
    return (
      <div className="row" style={{ marginTop: 25 }}>
        <div style={{ display: "flex" }} className="col-12">
          <div style={{ width: 80 }}>
            <input
              className="toggle toggle-yes-no"
              type="checkbox"
              checked={phiDataContainsPHI}
              onClick={(e) => setPhiDataContainsPHI(e.target.checked)}
              onChange={() => {
                return null;
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <p>
              Does the contact data contain any protected health information as defined by
              HIPAA?
            </p>
          </div>
        </div>

        {phiDataContainsPHI && (
          <div style={{ display: "flex", marginTop: 20 }} className="col-12">
            <div style={{ width: 80 }}>
              <input
                className="toggle toggle-yes-no"
                type="checkbox"
                checked={phiReleaseAuthorized}
                onClick={(e) => setPhiReleaseAuthorized(e.target.checked)}
                onChange={() => {
                  return null;
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <p>
                Has the patient or legal representative authorized release of the
                protected health information?
              </p>
            </div>
          </div>
        )}

        {phiDataContainsPHI && !phiReleaseAuthorized && (
          <div style={{ marginTop: 25 }}>
            <b style={{ fontSize: 16 }}>
              Protected Health Information may{" "}
              <span style={{ textDecoration: "underline", fontSize: 16 }}>not</span> be
              uploaded into Gratavid unless the patient / legal representative has
              provided written authorization and understands that the data is no longer
              protected by the HIPAA regulations.
            </b>
          </div>
        )}
      </div>
    );
  };

  if (checkForPHI && phiDataContainsPHI && !phiReleaseAuthorized) return <PhiHTML />;
  const customKeys = Object.values(props.customContactProperties).map(
    ({ id, label }) => ({
      id,
      label,
      isCustom: true,
    })
  );
  return (
    <div className="col-12" style={{ marginBottom: 40 }}>
      {checkForPHI && <PhiHTML />}

      {defaultProperties.concat(customKeys || []).map(({ id, label, isCustom }) => {
        const disabled = props?.contactData?.contact?.id && id === "email";

        if (isCustom) {
          return (
            <TextField
              variant={variant}
              disabled={disabled}
              onChange={(e) => handleFieldChange(id, e.target.value, isCustom)}
              id={id}
              label={label}
              fullWidth
              value={props?.contactData?.contact?.customProperties?.[id] || ""}
              key={id + label}
              style={{ marginTop: "20px" }}
            />
          );
        } else if (id === "textOptIn") {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={props?.contactData?.contact?.[id] ? true : false}
                  onChange={(e) => handleFieldChange("textOptIn", e.target.checked)}
                  name={id}
                  color="primary"
                />
              }
              label="Opted in to text"
              key={id + label}
              style={{ marginTop: "20px" }}
            />
          );
        } else if (id === "email" && !props.disableAutoSuggest) {
          return (
            <div className="col-12" style={{ marginTop: "20px" }} key={id + label}>
              <EmailAutoSuggestInput
                key={props.emailAutoSuggestInputKey || 1}
                accountInfo={props.accountInfo}
                email={props?.contactData?.contact?.[id] || ""}
                handleFieldChange={handleFieldChange}
                contactData={props.contactData}
                setContactData={props.setContactData}
                variant={variant}
              />
            </div>
          );
        } else {
          return (
            <TextField
              variant={variant}
              disabled={disabled}
              onChange={(e) => handleFieldChange(id, e.target.value)}
              id={id}
              label={label}
              fullWidth
              value={props?.contactData?.contact?.[id] || ""}
              key={id + label}
              style={{ marginTop: "20px" }}
            />
          );
        }
      })}
    </div>
  );
}

ListContactInputs.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  setContactData: PropTypes.func.isRequired,
  contactData: PropTypes.shape({
    contact: PropTypes.object,
  }),
  variant: PropTypes.string,
  customContactProperties: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disableAutoSuggest: PropTypes.bool,
  emailAutoSuggestInputKey: PropTypes.number,
};
