import React, { useState } from "react";
import Authenticate from "./authenticate";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function AuthenticateDialog(props) {
  let [dialogOpen, setDialogOpen] = useState(false);

  const fullScreen = useMobileDialog();

  return (
    <>
      <div>
        <button className="link-button" onClick={() => setDialogOpen(true)}>
          <span
            style={{ textTransform: "none", textDecoration: "underline", fontSize: 12 }}>
            Connect
          </span>
        </button>
        <span style={{ fontSize: 12, marginLeft: 4 }}>another site</span>
      </div>

      {dialogOpen && (
        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="edit task dialog">
          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "18px" }}>Connect to another Advance site</span>
              </div>
              <div style={{ width: "48px" }}>
                <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
            </div>

            <Authenticate {...props} setDialogOpen={setDialogOpen} />

            <div className="col-12" style={{ height: "100px" }} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
