import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import NoteBackground from "./noteBackground";
import ListImagesDialog from "../../../../util/images/listImagesDialog";
import SelectColor from "../../../../util/selectColor";

export default function NoteBranding(props) {
  let [dialogOpen, setDialogOpen] = useState(false);
  let [open, setOpen] = useState(false);
  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  return (
    <div className="row col-12 adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <h2>Branding</h2>
          <span className="greyDesc">Logo, background image, and colors</span>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div
          className="col-12"
          style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
          <div style={{ marginRight: "20px" }}>
            <label
              htmlFor="selectLogo"
              style={{ fontSize: "12px", color: "rgba(0,0,0,0.54)", marginTop: "10px" }}>
              Sender Logo
            </label>
            <br />
            <Button
              variant="outlined"
              style={{ width: "134px", position: "relative", borderRadius: "16px" }}
              onClick={() => setDialogOpen(true)}
              aria-label="select logo"
              id="selectLogo">
              <img src={props.logo} width="100%" alt="logo" aria-label="logo" />
            </Button>
          </div>
          <NoteBackground
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
            background={props.background}
            accountInfo={props.accountInfo}
            saveNoteState={props.saveNoteState}
            logo={props.logo}
          />
        </div>

        <div className="col-12" style={{ marginTop: "20px" }}>
          <label htmlFor="primaryColor">Primary Color</label>
          <SelectColor
            color={props.primaryColor}
            colorKey="primaryColor"
            passedSetState={props.saveNoteState}
          />
        </div>

        <div className="col-12" style={{ margin: "20px 0px" }}>
          <label htmlFor="secondaryColor">Secondary Color</label>
          <SelectColor
            color={props.secondaryColor}
            colorKey="secondaryColor"
            passedSetState={props.saveNoteState}
          />
        </div>

        <ListImagesDialog
          accountInfo={props.accountInfo}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          passedSetState={props.saveNoteState}
          logo={props.logo}
          type="logo"
        />
      </div>
    </div>
  );
}

NoteBranding.propTypes = {
  accountInfo: PropTypes.object,
  logo: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  background: PropTypes.object,
  saveNoteState: PropTypes.func,
};
