import React, { useState, useEffect, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  StepButton,
} from "@mui/material";
import Videos from "./videos";
import EditNote from "./note/editNote";
import Contacts from "./contacts/contacts";
import InlineEditor from "../../../../util/hooks/input/inlineEditor";
import { patchTask, getTaskVideo } from "services/api.service";
import style from "./editTask.module.css";

export default function EditTask(props) {
  let { tasks, accountInfo, accountCustomProperties, customContactProperties } = props;
  let [activeStep, setActiveStep] = useState(0);
  let [taskVideos, setTaskVideos] = useState(props.currentTask.taskVideos || []);
  let [videoIdsProcessing, setVideoIdsProcessing] = useState([]);
  let [activeContactCountForTask, setActiveContactCountForTask] = useState(0);
  let [forceLoadFreshNoteContent, setForceLoadFreshNoteContent] = useState(0);
  let [taskContacts, setTaskContacts] = useState("loading");
  let [atleastOneContactOptedInToText, setAtleastOneContactOptedInToText] =
    useState(null);
  const videoIntervals = useRef([]);
  //in videos getData we add a videoId to videoIdsProcessing if the video is processing
  //this effect listens for any videoIdsProcessing, and adds a listener to them
  //this is what tells us its safe to send, embed, and preview
  useEffect(() => {
    const pollForVidUpdates = (videoIds) => {
      //listen for video to finish encoding
      videoIntervals.current.forEach(({ interval }) => clearInterval(interval));
      videoIntervals.current = [
        ...videoIds.map((videoId) => {
          return {
            id: videoId,
            interval: setInterval(async () => {
              const data = await getTaskVideo(props.currentTask.id, videoId);

              if (["mediaURLUploaded", "error"].includes(data?.video?.status)) {
                if (data.video.status === "mediaURLUploaded") {
                  //remove video id from processing list
                  setVideoIdsProcessing(
                    videoIdsProcessing.filter(
                      (videoIdProcessing) => videoIdProcessing !== data.videoId
                    )
                  );
                } else if (data.video.status === "error") {
                  data.video = { errorMessage: true };
                }

                const videos = taskVideos.map((taskVideo) => {
                  if (taskVideo.videoId === data.videoId) {
                    return data;
                  }

                  return taskVideo;
                });

                setTaskVideos(videos);
              }
            }, 5000),
          };
        }),
      ];
    };

    pollForVidUpdates(videoIdsProcessing);
    return () => {
      videoIntervals.current.forEach(({ interval }) => clearInterval(interval));
    };
  }, [videoIdsProcessing]);

  //get all contact data and build map
  useEffect(() => {
    let getContacts = () => {
      try {
        if (props.currentTask.sentByUserId) {
          return;
        }

        setActiveContactCountForTask(props.currentTask.taskContacts.length);
        setAtleastOneContactOptedInToText(
          props.currentTask.taskContacts.some(
            (taskContact) =>
              taskContact?.contact?.textOptIn && taskContact?.contact?.cellNumber
          )
        );
        setTaskContacts(props.currentTask.taskContacts);

        //if changing from 1 to multiple contacts then reload note content so merge tags show
        if (
          props.currentTask.taskContacts.length === 0 ||
          props.currentTask.taskContacts.length === 1 ||
          props.currentTask.taskContacts.length === 2
        ) {
          setForceLoadFreshNoteContent(Date.now());
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    getContacts();
  }, [props.currentTask.taskContacts]);

  let firstContactData = taskContacts[0] || {};

  let commentsUpdated = (value) => {
    //save comments back to tasks object
    const tempTasks = props.tasks.map((tempTask) => {
      if (tempTask.id === props.currentTask.id) {
        return { ...tempTask, comments: value };
      } else {
        return tempTask;
      }
    });

    patchTask(props.currentTask.id, { comments: value });

    props.setTasks(tempTasks);
  };

  return (
    <div className={style.container}>
      <div className="col-12 row" style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <div className="col-12" style={{ display: "flex" }}>
            <span style={{ color: "#777", fontSize: 12 }}>
              Comments or reason for task
            </span>
          </div>
          <div className="col-12" style={{ marginTop: 2 }}>
            <InlineEditor
              value={props.currentTask.comments || "Say Thanks"}
              fontSize={17}
              passedSetState={commentsUpdated}
            />
          </div>
        </div>
        <div style={{ width: "55px" }}>
          <IconButton onClick={() => props.setEditTaskSelected(null)}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>

        {props.currentTask.crmMeta && (
          <div className="col-12" style={{ marginBottom: 5 }}>
            <a
              style={{ fontSize: "12px" }}
              href={props.currentTask.crmMeta.href}
              target="_blank"
              rel="noopener noreferrer">
              View in{" "}
              <span style={{ textTransform: "capitalize", fontSize: "12px" }}>
                {props.currentTask.crmMeta.source
                  ? props.currentTask.crmMeta.source
                  : "crm"}
              </span>
            </a>
          </div>
        )}

        {props.currentTask.showSendTutorial && (
          <div className="col-12 tipMessageBox" style={{ marginTop: 15 }}>
            <p style={{ fontSize: 14 }}>
              Tip: For step-by-step directions, watch
              <a
                style={{ fontSize: 14 }}
                href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="material-icons"
                  style={{ color: "#26305B", fontSize: 12, margin: "0px 2px 0px 4px" }}>
                  play_circle
                </i>
                How To Send A Gratavid
              </a>
            </p>
          </div>
        )}

        {props.currentTask.showRequestTutorial && (
          <div className="col-12 tipMessageBox" style={{ marginTop: 15 }}>
            <p style={{ fontSize: 14 }}>
              Tip: For step-by-step directions, watch
              <a
                style={{ fontSize: 14 }}
                href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653407264028-Sending-your-first-video-request-in-Gratavid"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="material-icons"
                  style={{ color: "#26305B", fontSize: 12, margin: "0px 2px 0px 4px" }}>
                  play_circle
                </i>
                Sending Your First Video Request In Gratavid
              </a>
            </p>
          </div>
        )}
      </div>

      {!props.currentTask.sentByUserId && (
        <div className="col-12 row">
          <div className="col-12" style={{ marginTop: 10 }}>
            <span style={{ color: "#777", fontSize: 12 }}>Send video to</span>
          </div>
          <div className="col-12" style={{ marginBottom: 5 }}>
            <Contacts
              contacts={props.currentTask.taskContacts}
              accountInfo={accountInfo}
              customContactProperties={customContactProperties}
              task={props.currentTask}
              tasks={tasks}
              setTasks={props.setTasks}
              taskContacts={taskContacts}
              setTaskContacts={setTaskContacts}
              userIsAdmin={props.userIsAdmin}
            />
          </div>
        </div>
      )}

      {props.currentTask.sentByUserId ? (
        <div className="row col-12" style={{ marginTop: "20px" }}>
          <div className="col-12" style={{ textAlign: "center", marginBottom: "20px" }}>
            <h2
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img
                style={{ width: "35px", marginRight: "5px" }}
                alt="checkmark"
                src="https://gratavid.com/static/img/orangeCircleCheck.svg"
              />
              Task sent!
            </h2>
          </div>
          <div className="col-12 justify-content-center align-items-start">
            <img src="/static/img/superthankyou.svg" width="150px" alt="thank you" />
          </div>
        </div>
      ) : (
        <div className="col-12">
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{
              width: "100%",
              marginTop: "20px",
              padding: "0px",
              backgroundColor: "white",
            }}>
            <Step key={0} color="primary">
              <StepButton
                onClick={() => setActiveStep(0)}
                className="justify-content-start">
                Create Video
              </StepButton>
              <StepContent
                style={{
                  padding: "2px",
                  width: "100%",
                  marginRight: "0px",
                  paddingRight: "20px",
                }}>
                <Videos
                  {...props}
                  taskVideos={taskVideos}
                  setTaskVideos={setTaskVideos}
                  setActiveStep={setActiveStep}
                  taskId={props.currentTask.id}
                  noteId={props.currentTask?.noteId}
                  setVideoIdsProcessing={setVideoIdsProcessing}
                  firstContactData={firstContactData}
                  forceShowAddVideoVsRecord={props.currentTask.showRequestTutorial}
                />
              </StepContent>
            </Step>

            <Step key={1} color="primary">
              <StepLabel>Send Note</StepLabel>
              <StepContent
                style={{
                  padding: "2px",
                  width: "100%",
                  marginRight: "0px",
                  paddingRight: "20px",
                }}>
                <EditNote
                  {...props}
                  taskVideos={taskVideos}
                  noteId={props.currentTask?.noteId}
                  videoIds={props.currentTask?.taskVideos?.map(
                    (taskVideo) => taskVideo.videoId
                  )}
                  setActiveStep={setActiveStep}
                  forceLoadFreshNoteContent={forceLoadFreshNoteContent}
                  atleastOneContactOptedInToText={atleastOneContactOptedInToText}
                  videoIdsProcessing={videoIdsProcessing}
                  firstContactData={firstContactData}
                  activeContactCountForTask={activeContactCountForTask}
                  accountCustomProperties={accountCustomProperties}
                  customContactProperties={customContactProperties}
                  taskContacts={taskContacts}
                />
              </StepContent>
            </Step>
          </Stepper>
        </div>
      )}
    </div>
  );
}
