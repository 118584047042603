import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Thumbnail from "./thumbnail/thumbnail";
import AddNoteDialog from "../../notes/addNote/addNoteDialog";
import DeleteSearchItemDialog from "../../../util/search/deleteSearchItemDialog";
import EditItemFolders from "../../../util/folders/search/editItemFolders";
import Checkbox from "@mui/material/Checkbox";
import timeSinceDate from "../../../util/functions/timeSinceDate";

export default function VideoItem(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [addNewDialogOpen, setAddNewDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editItemFoldersDialogOpen, setEditItemFoldersDialogOpen] = useState(false);

  const navigate = useNavigate();

  if (props.calledFromSearchExistingVid) {
    return (
      <div
        className="col-12 editNoteListSelectableItem"
        style={{ display: "flex", padding: "5px 0px", cursor: "pointer" }}
        key={props.video.id}>
        <div style={{ width: "40px" }} className="align-items-center">
          <Checkbox
            checked={props.selectedItems.some(
              (selectedVideo) => selectedVideo?.id === props.video.id
            )}
            onClick={() => props.videoSelectedForNote(props.video)}
            value={props.video.id}
            name="select-video"
            inputProps={{ "aria-label": "select-video" }}
          />
        </div>
        <div style={{ width: "75px", height: "50px" }}>
          <Thumbnail {...props} />
        </div>
        <div
          style={{ flex: 1, padding: "5px", overflow: "hidden" }}
          className="align-items-center"
          onClick={() => props.videoSelectedForNote(props.video)}>
          {props.video.title}
        </div>
      </div>
    );
  } else {
    const editVid = () => navigate(`/a/${props.accountId}/videos/` + props.video.id);

    const onClick = () => {
      const videoIndex = props.selectedItems.findIndex(({ id }) => id === props.video.id);
      if (videoIndex >= 0) {
        props.selectedItems.splice(videoIndex, 1);
      } else {
        props.selectedItems.push(props.video);
      }
      props.setSelectedItems([...props.selectedItems]);
      props.setAllSelected(false);
    };

    let createNote = () => {
      setAddNewDialogOpen(true);
      setAnchorEl(null);
    };

    let editItemFolders = () => {
      setEditItemFoldersDialogOpen(true);
      setAnchorEl(null);
    };

    let deleteNote = () => {
      setDeleteDialogOpen(true);
      setAnchorEl(null);
    };

    let menuOptions = [
      { label: "Edit Video", action: editVid },
      { label: "Share Video via Note", action: createNote },
      { label: "Edit Tags", action: editItemFolders },
      { label: "Delete Video", action: deleteNote },
    ];

    let menuList = menuOptions.map((currentMenu, index) => {
      return (
        <MenuItem key={index} button onClick={currentMenu.action}>
          {currentMenu.label}
        </MenuItem>
      );
    });

    let checked =
      props.selectedItems.some((video) => video.id === props.video.id) ||
      props.allSelected
        ? true
        : false;

    let timeSinceLastNoteSent =
      props.video?.status === "requested"
        ? timeSinceDate(
            props.video.dateRequested,
            "Last requested ",
            "Request not yet sent"
          )
        : "";

    return (
      <div
        className="col-12 listItem-item"
        style={{
          display: "flex",
          height: "64px",
          padding: "6px 4px",
          borderBottom: "1px #ddd solid",
          borderLeft: "2px transparent solid",
          borderRight: "2px transparent solid",
        }}>
        <div className="align-items-center" style={{ width: "40px" }}>
          <Checkbox
            checked={checked}
            size="small"
            color="default"
            style={{ padding: "5px" }}
            onClick={onClick}
          />
        </div>

        <div className="align-items-center" style={{ width: "75px", height: "50px" }}>
          <Thumbnail {...props} />
        </div>

        <div style={{ flex: 1, paddingLeft: "10px", overflow: "hidden" }}>
          <div className="row">
            <div className="col-12">
              <Link
                className="mainlistItemTitle link"
                to={`/a/${props.accountId}/videos/${props.video.id}`}>
                {props.video.title}
              </Link>
            </div>
            <div className="col-12">
              <span style={{ color: "grey", fontSize: "12px" }}>
                {timeSinceLastNoteSent}
              </span>
            </div>
          </div>
        </div>

        {props.videoSelectedForNote ? (
          <div className="align-items-center justify-content-center">
            <Button
              variant="contained"
              color="secondary"
              style={{ height: "40px" }}
              onClick={() => props.videoSelectedForNote(props.video)}>
              Select
            </Button>
          </div>
        ) : (
          <div
            className="align-items-center justify-content-end"
            style={{ width: "48px", marginRight: "10px" }}>
            <IconButton
              aria-label="More"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{ padding: "0px" }}>
              <i className="material-icons">more_vert</i>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={anchorEl ? true : false}
              onClose={(e) => setAnchorEl(null)}
              PaperProps={{ style: { maxHeight: 200, width: 200 } }}>
              {menuList}
            </Menu>
          </div>
        )}

        {addNewDialogOpen && (
          <AddNoteDialog
            accountId={props.accountId}
            videoId={props.video.id}
            addNewDialogOpen={addNewDialogOpen}
            setAddNewDialogOpen={setAddNewDialogOpen}
          />
        )}
        {deleteDialogOpen && (
          <DeleteSearchItemDialog
            accountId={props.accountId}
            indexName="video"
            objectIdsToUpdate={[props.video.id]}
            searchList={props.searchList}
            setSearchList={props.setSearchList}
            setSelectedItems={props.setSelectedItems}
            allSelected={props.allSelected}
            searchState={props.searchState}
            deleteDialogOpen={deleteDialogOpen}
            setDeleteDialogOpen={setDeleteDialogOpen}
          />
        )}
        {editItemFoldersDialogOpen && (
          <EditItemFolders
            accountId={props.accountId}
            dialogTitle="Video"
            currentItem={props.video}
            indexName="video"
            dialogOpen={editItemFoldersDialogOpen}
            setDialogOpen={setEditItemFoldersDialogOpen}
          />
        )}
      </div>
    );
  }
}

const videoType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  createdDate: PropTypes.string,
});

VideoItem.propTypes = {
  accountId: PropTypes.string.isRequired,
  video: videoType.isRequired,
  selectedItems: PropTypes.arrayOf(videoType).isRequired,
  setSelectedItems: PropTypes.func,
  setAllSelected: PropTypes.func,
  allSelected: PropTypes.bool,
  videoSelectedForNote: PropTypes.func,
  calledFromSearchExistingVid: PropTypes.bool,
  searchList: PropTypes.array.isRequired,
  setSearchList: PropTypes.func.isRequired,
};
