import React from "react";
import { Checkbox, Button, useMediaQuery, IconButton, Tooltip } from "@mui/material";

export default function SelectTable(props) {
  const {
    selectAll,
    unSelectAll,
    nextCursorDoc = null,
    ItemComponent,
    tableActions = [],
    tableHeader = [],
    load100More,
    headerBackgroundColor = "white",
  } = props;
  const showText = useMediaQuery("(min-width:840px)");
  const ButtonComponent = showText ? Button : IconButton;

  const listItemsHTML = Object.keys(props.items).map((key) => (
    <ItemComponent
      key={key}
      {...props}
      selectItem={props.selectItem}
      current={props.items[key]}
    />
  ));

  const tableHeaderHTML = tableHeader.map((current) => {
    const { className = "", label = "", style = {}, helperText = null } = current;
    return (
      <div style={style} className={className} key={label}>
        <div
          style={{
            fontSize: "12px",
            fontColor: "33475B",
            display: "flex",
            alignItems: "center",
          }}>
          {label}
          {helperText && (
            <Tooltip title={helperText}>
              <i
                className="material-icons"
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "rgb(142, 148, 157)",
                  marginLeft: "3px",
                }}>
                info
              </i>
            </Tooltip>
          )}
        </div>
      </div>
    );
  });

  const actionsHTML = tableActions.map((current) => (
    <ButtonComponent
      size="small"
      onClick={() => current.actionFunc(current)}
      key={current.label}>
      <i className="material-icons" style={{ fontSize: "18px" }}>
        {current.icon}
      </i>
      {showText && (
        <span
          style={{ textTransform: "capitalize", fontSize: "14px", marginLeft: "3px" }}>
          {current.label}
        </span>
      )}
    </ButtonComponent>
  ));

  const handleHeaderCheckboxClicked = () => {
    // If all selected already, unselect everything
    if (
      Object.keys(props.items).filter((key) => !props.items[key].selectionDisabled)
        .length === Object.keys(props.selectedItems).length
    ) {
      unSelectAll();
    } else {
      selectAll();
    }
  };

  const oneItemSelected = props.selectedItems.length > 0;
  // Current user cannot be selected so we need to subtract 1
  const allSelected =
    Object.keys(props.items).filter((key) => !props.items[key].selectionDisabled)
      .length === props.selectedItems.length && Object.keys(props.items).length > 0;

  return (
    <>
      <div
        className="row col-12"
        style={{
          height: "42px",
          display: "flex",
          padding: "4px",
          borderBottom: "2px #999 solid",
          borderRadius: "2px",
          backgroundColor: headerBackgroundColor,
        }}>
        <div style={{ width: "40px" }} className="align-items-center">
          <Checkbox
            checked={allSelected}
            indeterminate={!allSelected && oneItemSelected}
            onClick={handleHeaderCheckboxClicked}
            size="small"
            style={{ padding: "5px" }}
          />
        </div>

        {oneItemSelected ? (
          <div style={{ flex: 1, display: "flex" }} className="align-items-center">
            <span style={{ fontSize: "12px", color: "#757575", marginRight: "5px" }}>
              {props.selectedItems.length} selected
            </span>

            {actionsHTML}
          </div>
        ) : (
          <div style={{ flex: 1, display: "flex" }} className="align-items-center">
            {tableHeaderHTML}
          </div>
        )}
      </div>

      {allSelected && (
        <div
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e8f4f8",
            padding: "6px 16px",
            minHeight: "48px",
            margin: "4px 0px",
            borderRadius: "4px",
            color: "#5f6368",
            border: "1px #add8e6 solid",
          }}>
          <i
            className="material-icons"
            style={{ fontSize: "18px", color: "orange", marginRight: "15px" }}>
            warning
          </i>
          {nextCursorDoc ? (
            <>
              Selected all {props.selectedItems.length} items that are showing.
              <Button onClick={load100More}>Show 100 More</Button>
            </>
          ) : (
            <>All {props.selectedItems.length} items selected</>
          )}
        </div>
      )}

      {listItemsHTML.length > 0 ? (
        <div className="row col-12">{listItemsHTML}</div>
      ) : (
        <div className="col-12 justify-content-center" style={{ padding: "10px" }}>
          <span style={{ color: "grey" }}>No items</span>
        </div>
      )}
    </>
  );
}
