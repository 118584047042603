import React, { useState } from "react";
import { Dialog, DialogContent, DialogActions, IconButton } from "@mui/material";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { cancelSendNote, cancelSendTask } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function CancelSendDialog(props) {
  let [state, setState] = useState({});

  const fullScreen = useMobileDialog();

  let cancelNow = async () => {
    try {
      setState({ buttonState: "loading" });

      if (props.taskId) {
        await cancelSendTask(props.taskId, props.resending);
      } else {
        await cancelSendNote(props.itemId);
      }

      props.setScheduledItems(
        props.scheduledItems.filter((scheduledItem) => scheduledItem.id !== props.itemId)
      );
      props.setTotalItems(props.totalItems - 1);

      setState({ buttonState: "saved" });
    } catch (err) {
      setState({
        errorMessage:
          "Failed to cancel scheduled item. It may have already been cancelled.",
      });
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="cancel scheduled send dialog">
      <DialogContent>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Cancel Scheduled Send</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div style={{ display: "flex", padding: "20px 0px" }}>
          <p>Are you sure you want to cancel this scheduled send?</p>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Canceled"}
          label="Cancel Now"
          clickedRegularButton={cancelNow}
          clickedSavedButton={() => props.setDialogOpen(false)}
        />
      </DialogActions>
    </Dialog>
  );
}
