import React, { useState } from "react";
import General from "./general/general";
import Notifications from "./notifications/notifications";
import { Tabs, Tab } from "@mui/material";
import { getSearchParams } from "util/helpers";

export default function ManageSettings(props) {
  let tempTab = getSearchParams().get("tab") || "general";
  let [tabSelected, setTabSelected] = useState(tempTab);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  return (
    <div className="row col-12 pagePadding">
      <div className="row col-12 " style={{ marginBottom: "10px" }}>
        <h1>My Settings</h1>
      </div>

      <div className="col-12" style={{ borderBottom: "1px #ddd solid" }}>
        <Tabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="edit video tabs">
          <Tab label="Profile" {...a11yProps(0)} value="general" />
          <Tab label="Notifications" {...a11yProps(1)} value="notifications" />
        </Tabs>
      </div>

      {tabSelected === "general" && <General {...props} />}
      {tabSelected === "notifications" && <Notifications {...props} />}
    </div>
  );
}
