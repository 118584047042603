import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContactItem from "./contactItem";
import FolderItem from "./folderItem";
import Chip from "@mui/material/Chip";
import { ALL_CONTACTS_FOLDER_ID } from "components/util/data/constants";

export default function ListSelectedContacts(props) {
  const [mergeFields, setMergeFields] = useState([]);

  //if all contacts is selected, just show all contacts chip and return without looping through others
  const allContactsIndex = props.noteContacts.findIndex(
    (nc) => nc.folderId === ALL_CONTACTS_FOLDER_ID
  );

  //mergeFields will let us know which variables we need to show in the contact hover box
  useEffect(() => {
    let tempMergeFields = ["salutation"];
    props.customContactProperties.forEach((current) => {
      tempMergeFields.push(current.id);
    });

    setMergeFields(tempMergeFields);
  }, [props.customContactProperties]);

  return (
    <div
      className="col-12"
      style={{
        border: "1px solid #cbd6e2",
        backgroundColor: "#f5f8fa",
        padding: "5px",
        cursor: "pointer",
        minHeight: "42px",
      }}
      onClick={props.onClick}>
      {allContactsIndex >= 0 ? (
        <Chip
          style={{
            margin: "3px",
            height: "24px",
            borderRadius: "5px",
            backgroundColor: "rgb(240, 241, 250)",
            border: "1px solid rgb(180, 187, 232)",
          }}
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="material-icons"
                style={{ fontSize: "20px", color: "#616161", marginRight: "3px" }}>
                group
              </i>
              <span style={{ fontSize: "13px" }}>Send to all contacts</span>
            </div>
          }
          onDelete={() => props.onRemoveNoteContact(allContactsIndex)}
        />
      ) : (
        <>
          {props.noteContacts.map((noteContact, index) => {
            if (noteContact.folderId) {
              return (
                <FolderItem
                  accountInfo={props.accountInfo}
                  folder={noteContact.folder}
                  key={noteContact.folderId}
                  mergeFields={mergeFields}
                  onRemoveFolder={() => props.onRemoveNoteContact(index)}
                />
              );
            } else if (noteContact.contactId) {
              return (
                <ContactItem
                  contact={noteContact.contact}
                  key={noteContact.contactId}
                  mergeFields={mergeFields}
                  onRemoveContact={() => props.onRemoveNoteContact(index)}
                />
              );
            }
          })}
        </>
      )}
    </div>
  );
}

ListSelectedContacts.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteContacts: PropTypes.array.isRequired,
  customContactProperties: PropTypes.array,
  onClick: PropTypes.func,
  onRemoveNoteContact: PropTypes.func,
  onFolderContactsLoaded: PropTypes.func,
};
