import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { MenuItem, LinearProgress } from "@mui/material";
import timeSinceDate from "../../../util/functions/timeSinceDate";
import { getContact } from "services/api.service";
import { toFullName } from "util/helpers";

export default function UnsubscribeItem(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [contactLabel, setContactLabel] = useState("");

  useEffect(() => {
    async function getContactData() {
      const contact = await getContact(props.current.contactId);
      if (contact) {
        setContactLabel(toFullName(contact.firstName, contact.lastName, contact.email));
      }
      props.updateMenuPosition();
      setIsLoading(false);
    }

    getContactData();
  }, []);

  let timeSinceLastSent = timeSinceDate(new Date(props.current.createdDate));

  return (
    <MenuItem
      style={{ display: "flex" }}
      component={Link}
      to={`/a/${props.accountId}/unsubscribes`}
      onClick={() => props.setAnchorEl(null)}>
      <div style={{ width: "30px" }}>
        <i
          className="material-icons"
          style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
          {props.current.optOutSource === "email" ? "unsubscribe" : "phonelink_erase"}
        </i>
      </div>
      <div style={{ flex: 1 }} className="row">
        <div className="col-12">
          {isLoading ? (
            <div style={{ height: "22px" }}>
              <LinearProgress />
            </div>
          ) : (
            contactLabel + " unsubscribed from " + props.current.optOutSource
          )}
        </div>
        <div className="col-12">
          <p className="greyDesc" style={{ fontSize: "14px" }}>
            {timeSinceLastSent}
          </p>
        </div>
      </div>
    </MenuItem>
  );
}

UnsubscribeItem.propTypes = {
  current: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  updateMenuPosition: PropTypes.func.isRequired,
};
