import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import { DialogActions, DialogContent, Dialog, IconButton, Button } from "@mui/material";
import { markTaskAsDone } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function MarkAsDone(props) {
  let [saveErrors, setSaveErrors] = useState([]);
  let [state, setState] = useState({});

  const fullScreen = useMobileDialog();

  let doWork = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });

      let tempSaveErrors = [...saveErrors];
      const selectedItems = [];
      for (const task of props.selectedItems) {
        let errorMessage = null;
        if (!task.taskVideos?.length) {
          errorMessage =
            "Can't mark task done if no video. Did you mean to delete this task?";
        } else if (!task.noteId) {
          errorMessage =
            "Can't mark task done if no note selected. Did you mean to delete this task?";
        } else {
          selectedItems.push(task);
        }

        if (errorMessage) {
          tempSaveErrors.push({
            taskId: task.id,
            label: task.comments,
            errorMessage,
          });
        }
      }

      const selectedTaskIds = selectedItems.map((selectedItem) => selectedItem.id);
      if (selectedTaskIds.length) {
        const promises = selectedTaskIds.map(async (selectedTaskId) =>
          markTaskAsDone(selectedTaskId)
        );
        await Promise.all(promises);
      }
      setSaveErrors(tempSaveErrors);

      props.setEditTaskSelected(null);
      props.setTasks(props.tasks.filter((task) => !selectedTaskIds.includes(task.id)));
      setState({ buttonState: "saved" });
      props.setSelectedItems([]);
      props.setAllSelected(false);
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let { buttonState, errorMessage } = state;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete task dialog">
      <DialogContent style={{ minHeight: "500px", width: fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Mark As Done</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        {saveErrors.length > 0 ? (
          <>
            <div style={{ marginTop: "15px" }}>{saveErrors.length} errors:</div>

            {saveErrors.map((current) => {
              return (
                <div
                  key={current.taskId}
                  style={{
                    width: "100%",
                    display: "flex",
                    borderTop: "1px #ddd solid",
                    alignItems: "center",
                    padding: "6px 0px",
                  }}>
                  <div style={{ width: "28px" }}>
                    <i className="material-icons" style={{ color: "red" }}>
                      error
                    </i>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span style={{ cursor: "pointer" }}>
                      {current.label}: {current.errorMessage}
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="col-12">
            <p>
              Mark {props.selectedCount} task(s) as done? This will move them to the
              history tab.
            </p>
          </div>
        )}

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button onClick={props.closeDialog} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel={"Done"}
          label={"Confirm"}
          clickedRegularButton={doWork}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
