import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { List } from "@mui/material";
import HistoryItem from "./item/historyItem";
import ArrangeRow from "../listTask/arrange/arrangeRow";
import LoadingButton from "../../../util/buttons/loadingbutton";
import ActionRow from "./actions/actionRow";
import { getTasks } from "services/api.service";

export default function ListHistory(props) {
  let [tasks, setTasks] = useState("loading");
  let [state, setState] = useState({});
  let [sentByUserUID, setSentByUserUID] = useState("");
  let [loadingMoreList, setLoadingMoreList] = useState(null);
  let [userEmailProvider, setUserEmailProvider] = useState(
    props.userInfo.emailProvider || "default"
  );
  let [selectedItems, setSelectedItems] = useState([]);
  let [totalTasks, setTotalTasks] = useState(0);
  const [offset, setOffset] = useState(0);

  const getTask = async (isInit) => {
    try {
      let limit = 20;
      let startIndex = offset;
      if (isInit) {
        startIndex = 0;
        setTasks("loading");
      } else {
        limit = 100;
        setLoadingMoreList(true);
      }

      const data = await getTasks(sentByUserUID, "SENT", {
        sortBy: "sendDate",
        sortDirection: "desc",
        limit,
        offset: startIndex,
      });

      if (startIndex === 0) {
        setOffset(20);
      } else {
        setOffset(startIndex + 100);
      }

      if (isInit) {
        setTotalTasks(data.count);
        setTasks(data.rows);
      } else {
        setTasks([...tasks, ...data.rows]);
      }

      setLoadingMoreList(false);
    } catch (err) {
      console.error(err.message);
      setTasks([]);
      setState({ errorMessage: err.message });
    }
  };

  //initial call to get data, and any query updates which will restart task list
  useEffect(() => {
    getTask(true);
  }, [sentByUserUID]);

  let clickedLoadMore = () => {
    //this appends data to existing task vs restarting it
    getTask();
  };

  const listTasks =
    tasks === "loading"
      ? []
      : tasks.map((task) => {
          return (
            <HistoryItem
              {...props}
              key={task.id}
              current={task}
              userEmailProvider={userEmailProvider}
              setUserEmailProvider={setUserEmailProvider}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          );
        });

  //don't want to show external users in send history
  let historyUsers = [];
  props.users.forEach((current) => {
    if (!current.externalUser) {
      historyUsers.push(current);
    }
  });

  return (
    <div style={{ height: "100%" }} className="row col-12">
      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <ArrangeRow
        {...props}
        assignedToUserUID={sentByUserUID}
        setAssignedToUserUID={setSentByUserUID}
        emptyLabel="All senders"
        hideSort={true}
        calledFromHistory={true}
        users={historyUsers}
      />

      <div className="col-12 row align-items-start" style={{ height: "100%" }}>
        <div
          className="row col-12"
          style={{
            display: "flex",
            height: "45px",
            padding: "4px 8px",
            border: "1px #ddd solid",
            borderBottom: "2px #999 solid",
            borderRadius: "5px 3px 0px 0px",
            backgroundColor: "white",
          }}>
          <div style={{ width: "3px" }} />

          <div style={{ flex: 1 }} className="align-items-center">
            <ActionRow
              {...props}
              tasks={tasks}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              userEmailProvider={userEmailProvider}
              setUserEmailProvider={setUserEmailProvider}
            />
          </div>
        </div>

        {tasks === "loading" ? (
          <div className="col-12" style={{ padding: "20px" }}>
            {" "}
            <LinearProgress />
          </div>
        ) : (
          <div
            className="row col-12"
            style={{
              backgroundColor: "white",
              border: "1px #ddd solid",
              borderTop: "none",
              borderRadius: "3px",
            }}>
            {listTasks.length === 0 ? (
              <div style={{ padding: "20px" }}>No items on your task list</div>
            ) : (
              <>
                <List className="col-12" dense={true}>
                  {listTasks}
                </List>
                {totalTasks > tasks.length && (
                  <div
                    className="col-12 justify-content-center"
                    style={{ margin: "10px 0px 20px 0px" }}>
                    <LoadingButton
                      type="button"
                      variant="outlined"
                      size="small"
                      buttonState={loadingMoreList ? "loading" : null}
                      savedLabel={"Loaded"}
                      label="Show more"
                      clickedRegularButton={clickedLoadMore}
                      clickedSavedButton={() => {
                        return null;
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

ListHistory.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  ssoProvider: PropTypes.string,
  users: PropTypes.array.isRequired,
};
