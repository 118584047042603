import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { MenuItem, LinearProgress } from "@mui/material";
import timeSinceDate from "../../../util/functions/timeSinceDate";
import { getContactReply, getContact } from "services/api.service";
import { toFullName } from "util/helpers";

export default function RepliesItem(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [contactLabel, setContactLabel] = useState("");
  const [state, setState] = useState({});

  useEffect(() => {
    if (!props.current.contactId) {
      return null;
    }

    let getData = async () => {
      const contact = await getContact(props.current.contactId);
      if (contact) {
        setContactLabel(toFullName(contact.firstName, contact.lastName, contact.email));

        if (props.current.replyId) {
          const reply = await getContactReply(
            props.current.contactId,
            props.current.replyId
          );
          setState({
            replyText: reply?.replyText || "",
            replyVideoSrc: reply?.replyVideoSrc || null,
          });
        }
      }

      props.updateMenuPosition();
      setIsLoading(false);
    };

    getData();
  }, []);

  if (!props.current.contactId || !state.replyText) {
    return null;
  }

  let timeSinceLastSent = timeSinceDate(new Date(props.current.createdDate));
  let { replyVideoSrc, replyText } = state;
  let message = replyVideoSrc
    ? contactLabel + " replied with a video"
    : contactLabel + ' replied, "' + replyText + '"';

  return (
    <MenuItem
      style={{ display: "flex" }}
      component={Link}
      to={`/a/${props.accountId}/contacts/` + props.current.contactId}
      onClick={() => props.setAnchorEl(null)}>
      <div style={{ width: "30px" }}>
        <i
          className="material-icons"
          style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
          chat
        </i>
      </div>
      <div style={{ flex: 1 }} className="row">
        <div className="col-12">
          {isLoading ? (
            <div style={{ height: "22px" }}>
              <LinearProgress />
            </div>
          ) : (
            message
          )}
        </div>
        <div className="col-12">
          <p className="greyDesc" style={{ fontSize: "14px" }}>
            {timeSinceLastSent}
          </p>
        </div>
      </div>
    </MenuItem>
  );
}

RepliesItem.propTypes = {
  current: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  updateMenuPosition: PropTypes.func.isRequired,
};
