import React, { useState, useEffect } from "react";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import { Dialog, DialogContent, IconButton, InputBase } from "@mui/material";
import UserItem from "./userItem";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function AssigneeUsersDialog(props) {
  let { dialogOpen, userId, calledFromHistory = false, users = [] } = props;
  let [searchTerm, setSearchTerm] = useState("");
  let [searchUsers, setSearchUsers] = useState("loading");

  const fullScreen = useMobileDialog();

  useEffect(() => {
    if (searchTerm === "") {
      setSearchUsers(users);
      return;
    }

    //do search
    const term = searchTerm.toLowerCase();
    const results = users.filter(
      (user) =>
        user.fullName?.toLowerCase().includes(term) ||
        user.email?.toLowerCase().includes(term)
    );
    setSearchUsers(results);
  }, [searchTerm, users]);

  useEffect(() => {
    setSearchUsers(users);
  }, [users]);

  let assigneeSelected = (user) => {
    props.setAssignedToUserUID(user.userUID);
    props.closeDialog();

    //make selected user 3rd assigneesChip unless selected self
    if (userId === user.userUID) return;
    let tempAssigneesChips = [...props.assigneesChips];
    tempAssigneesChips[2] = { userUID: user.userUID, label: user.label };
    props.setAssigneesChips(tempAssigneesChips);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="assign task to a user dialog">
      <DialogContent style={{ minHeight: "500px", width: fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>
              {calledFromHistory ? "Filter Tasks By Sender" : "Filter Tasks By Assignee"}
            </span>
            {!calledFromHistory && (
              <QuestionToLink id="taskAssignee" desc="task assignees" />
            )}
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div
          style={{
            marginTop: "5px",
            flex: 1,
            display: "flex",
            backgroundColor: "hsl(220, 12%, 95%)",
            padding: "12px 6px",
            borderRadius: "3px",
          }}>
          <div style={{ width: "30px" }} className="align-items-center">
            <i className="material-icons" style={{ color: "grey" }}>
              search
            </i>
          </div>
          <InputBase
            value={searchTerm}
            style={{ flex: 1 }}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search users"
          />
        </div>

        <div className="col-12 row" style={{ padding: "5px 0px 20px 0px" }}>
          {searchUsers === "loading" ? (
            <>
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
            </>
          ) : (
            <>
              {searchTerm === "" && (
                <UserItem
                  key="allAssignees"
                  currentUser={{ label: "All assignees", userUID: "" }}
                  setSelectedAssignee={assigneeSelected}
                />
              )}

              {searchUsers.map((user) => {
                return (
                  <UserItem
                    key={user.userUID}
                    currentUser={user}
                    setSelectedAssignee={assigneeSelected}
                  />
                );
              })}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
