import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@mui/material";
import InputText from "../../../util/inputText";
import SelectItem from "../../../util/selectItem";
import InputNumber from "../../../util/inputNumber";
import transactionAmountsOptions from "../../../util/data/transactionAmountsOptions";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric, metricValue = "" } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "transactionAmount")
      handleItemChange("transactionAmount", "", "greaterEqual");
    if (metric === "gratavidCustomAmount1__c")
      handleItemChange("gratavidCustomAmount1__c", "", "greaterEqual");
    if (metric === "gratavidCustomField1__c")
      handleItemChange("gratavidCustomField1__c", "", "equal");
    if (metric === "gratavidCustomField2__c")
      handleItemChange("gratavidCustomField2__c", "", "equal");
    if (metric === "gratavidCustomField3__c")
      handleItemChange("gratavidCustomField3__c", "", "equal");
  };

  let handleItemChange = (metric, value, operator) => {
    let item = { metric, metricValue: value, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let giftAmountOptions = transactionAmountsOptions();

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "540px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            label="Select Condition"
            labelId="conditionType"
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <option value="transactionAmount">
              The record amount is greater than or equal to
            </option>
            <option value="gratavidCustomAmount1__c">
              The custom field 'gratavidCustomAmount1__c' is greater than or equal to
            </option>
            <option value="gratavidCustomField1__c">
              The custom field 'gratavidCustomField1__c' equals
            </option>
            <option value="gratavidCustomField2__c">
              The custom field 'gratavidCustomField2__c' equals
            </option>
            <option value="gratavidCustomField3__c">
              The custom field 'gratavidCustomField3__c' equals
            </option>
          </Select>
        </FormControl>

        {metric === "transactionAmount" && (
          <SelectItem
            options={giftAmountOptions}
            selectedItem={metricValue}
            handleChange={(value) =>
              handleItemChange("transactionAmount", value, "greaterEqual")
            }
            label="Amount"
            alt="Select gift amount"
            width="150px"
          />
        )}
        {metric.includes("gratavidCustomAmount") && (
          <InputNumber
            label="Value (Type must be number)"
            value={metricValue}
            type="number"
            handleChange={(value) =>
              handleItemChange("gratavidCustomAmount1__c", value, "greaterEqual")
            }
            width="250px"
          />
        )}
        {metric.includes("gratavidCustomField") && (
          <InputText
            label="Value (Type must be text)"
            value={metricValue}
            handleChange={(value) => handleItemChange(metric, value, "equal")}
            width="250px"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
