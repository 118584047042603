import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import LoadingSwitch from "../../util/buttons/loadingSwitch";
import { patchAccountUser } from "../../../services/api.service.js";

export default function Users(props) {
  const {
    userUID: signedInUserUID,
    current = {},
    selectedItems = {},
    allSelected = false,
  } = props;
  const {
    userUID,
    email,
    fullName,
    admin: tempAdmin = false,
    disableBulkSends: tempDisableBulkSends = false,
    disableNoteWrite: tempDisableNoteWrite = false,
  } = current;
  const [admin, setAdmin] = useState(tempAdmin);
  const [disableBulkSends, setDisableBulkSends] = useState(tempDisableBulkSends);
  const [disableNoteWrite, setDisableNoteWrite] = useState(tempDisableNoteWrite);
  const [state, setState] = useState({});

  const updateAdmin = async (newStatus) => {
    try {
      setState({ buttonState: "loading" });
      const updates = {
        role: {
          isAdmin: newStatus,
        },
      };

      await patchAccountUser(userUID, updates);
      setAdmin(newStatus);
      setState({ buttonState: "saved" });
    } catch (err) {
      setState({});
      props.setState({ errorMessage: err.message });
      console.error(err.message);
    }
  };

  const handlePermissionChange = async (state, newStatus) => {
    try {
      setState({ buttonState: "loading" });
      const backendFieldName =
        state === "disableBulkSends" ? "isBulkSender" : "isNoteEditor";
      const updates = {
        role: {
          [backendFieldName]: !newStatus,
        },
      };
      await patchAccountUser(userUID, updates);

      if (state === "disableBulkSends") {
        setDisableBulkSends(newStatus);
        props.passedSetStateFromApp("bulkNoteSendingDisabled", newStatus);
      } else if (state === "disableNoteWrite") {
        setDisableNoteWrite(newStatus);
        props.passedSetStateFromApp("userNoteWriteNoteDisabled", newStatus);
      }

      setState({ buttonState: "saved" });
    } catch (err) {
      setState({});
      props.setState({ errorMessage: err.message });
      console.error(err.message);
    }
  };

  const buttonDisabled = userUID === signedInUserUID ? true : false;
  const checked = selectedItems.includes(userUID) || allSelected ? true : false;

  return (
    <div
      className="col-12"
      style={{
        display: "flex",
        borderBottom: "1px #ddd solid",
        padding: "5px",
        alignItems: "center",
        backgroundColor: "white",
      }}>
      <div style={{ width: "40px" }} className="align-items-center">
        <Checkbox
          checked={checked}
          disabled={buttonDisabled}
          onClick={() => props.selectItem(userUID)}
          size="small"
          style={{ padding: "5px" }}
        />
      </div>

      <div style={{ flex: 1 }}>
        {buttonDisabled ? (
          <span style={{ opacity: 0.5 }}>
            {fullName ? fullName : email} (Your account)
            <br />
            <span style={{ fontSize: "12px", color: "grey" }}>{email}</span>
          </span>
        ) : (
          <span>
            {fullName ? fullName : email}
            <br />
            <span style={{ fontSize: "12px", color: "grey" }}>{email}</span>
          </span>
        )}
      </div>
      <div style={{ width: "120px" }} className="hidden-md-down">
        <LoadingSwitch
          checked={!disableNoteWrite}
          handleSwitch={() =>
            handlePermissionChange("disableNoteWrite", !disableNoteWrite)
          }
          buttonState={state && state.buttonState}
        />
      </div>
      <div style={{ width: "120px" }} className="hidden-md-down">
        <LoadingSwitch
          checked={!disableBulkSends}
          handleSwitch={() =>
            handlePermissionChange("disableBulkSends", !disableBulkSends)
          }
          buttonState={state && state.buttonState}
        />
      </div>
      <div style={{ width: "120px" }}>
        <LoadingSwitch
          checked={admin}
          disabled={buttonDisabled}
          handleSwitch={() => updateAdmin(!admin)}
          buttonState={state && state.buttonState}
        />
      </div>
    </div>
  );
}
