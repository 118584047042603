import React from "react";
import styles from "./inputRadioGroup.module.css";
import PropTypes from "prop-types";

import Radio from "@mui/material/Radio";
import { TextField } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";

function createButtons(options, customValue, setCustomValue) {
  return options.map((option) => (
    <div className={styles.label_wrapper} key={option.value}>
      <FormControlLabel
        value={option.value}
        control={<Radio />}
        label={
          option.type === "numberInput" ? (
            <TextField
              type="number"
              label="Custom"
              placeholder="Custom"
              value={customValue}
              onChange={(e) => setCustomValue(e.target.value)}
            />
          ) : (
            option.label || option.value
          )
        }
      />
      {option.tooltip && (
        <Tooltip title={option.tooltip}>
          <i className={`${styles.icon} material-icons`}>help</i>
        </Tooltip>
      )}
    </div>
  ));
}

const InputRadioGroup = React.forwardRef(function InputRadioGroup(
  { value, onChange, title, options, name, customValue, setCustomValue },
  ref
) {
  return (
    <FormControl component="fieldset">
      {title && <FormLabel component="legend">{title}</FormLabel>}
      <RadioGroup
        aria-label={name}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        ref={ref}>
        {createButtons(options, customValue, setCustomValue)}
      </RadioGroup>
    </FormControl>
  );
});

InputRadioGroup.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  customValue: PropTypes.string,
  setCustomValue: PropTypes.func,
};

InputRadioGroup.defaultProps = {
  title: null,
  labels: null,
};

export default InputRadioGroup;
