import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@mui/material";
import SelectItem from "../../util/selectItem";
import SelectList from "../../util/selectList";
import GetListSelectList from "../../util/getListSelectList";
import SelectSinceMonthAndTier from "../../util/selectSinceMonthAndTier";
import transactionAmountsOptions from "../../util/data/transactionAmountsOptions";
import milestoneTransactionTierOptions from "../../util/data/milestoneTransactionTierOptions";
import milestoneYearsOptions from "../../util/data/milestoneYearsOptions";
import milestoneCountsOptions from "../../util/data/milestoneCountsOptions";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric, metricValue = "" } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "transactionAmount")
      handleItemChange("transactionAmount", "", "greaterEqual");
    if (metric === "isFirstTransaction")
      handleItemChange("isFirstTransaction", true, "equal");
    if (metric === "didTransactionAmountIncrease")
      handleItemChange("didTransactionAmountIncrease", true, "equal");
    if (metric === "transactionTypes")
      handleListChange("transactionTypes", [], "includes");
    if (metric === "funds") handleListChange("funds", [], "includes");
    if (metric === "campaigns") handleListChange("campaigns", [], "includes");
    if (metric === "appeals") handleListChange("appeals", [], "includes");
    if (metric === "states") handleListChange("states", [], "includes");
    if (metric === "milestoneSinceMonthAmountReached")
      handleItemChange(
        "milestoneSinceMonthAmountReached",
        "",
        "milestoneSinceMonthAmountReached"
      );
    if (metric === "milestoneLifetimeAmountReached")
      handleItemChange(
        "milestoneLifetimeAmountReached",
        "",
        "milestoneLifetimeAmountReached"
      );
    if (metric === "milestoneConsecYearsGivingReached")
      handleItemChange(
        "milestoneConsecYearsGivingReached",
        "",
        "milestoneConsecYearsGivingReached"
      );
    if (metric === "milestoneGiftCountReached")
      handleItemChange("milestoneGiftCountReached", "", "milestoneGiftCountReached");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let handleItemChange = (metric, value, operator) => {
    let item = { metric, metricValue: value, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  let giftAmountOptions = transactionAmountsOptions();
  let tierAmountOptions = milestoneTransactionTierOptions();
  let yearsGivenOptions = milestoneYearsOptions();
  let giftCountsOptions = milestoneCountsOptions("donations");

  let statesOptions = [
    {
      label: "Alabama",
      id: "AL",
    },
    {
      label: "Alaska",
      id: "AK",
    },
    {
      label: "American Samoa",
      id: "AS",
    },
    {
      label: "Arizona",
      id: "AZ",
    },
    {
      label: "Arkansas",
      id: "AR",
    },
    {
      label: "California",
      id: "CA",
    },
    {
      label: "Colorado",
      id: "CO",
    },
    {
      label: "Connecticut",
      id: "CT",
    },
    {
      label: "Delaware",
      id: "DE",
    },
    {
      label: "District Of Columbia",
      id: "DC",
    },
    {
      label: "Federated States Of Micronesia",
      id: "FM",
    },
    {
      label: "Florida",
      id: "FL",
    },
    {
      label: "Georgia",
      id: "GA",
    },
    {
      label: "Guam",
      id: "GU",
    },
    {
      label: "Hawaii",
      id: "HI",
    },
    {
      label: "Idaho",
      id: "ID",
    },
    {
      label: "Illinois",
      id: "IL",
    },
    {
      label: "Indiana",
      id: "IN",
    },
    {
      label: "Iowa",
      id: "IA",
    },
    {
      label: "Kansas",
      id: "KS",
    },
    {
      label: "Kentucky",
      id: "KY",
    },
    {
      label: "Louisiana",
      id: "LA",
    },
    {
      label: "Maine",
      id: "ME",
    },
    {
      label: "Marshall Islands",
      id: "MH",
    },
    {
      label: "Maryland",
      id: "MD",
    },
    {
      label: "Massachusetts",
      id: "MA",
    },
    {
      label: "Michigan",
      id: "MI",
    },
    {
      label: "Minnesota",
      id: "MN",
    },
    {
      label: "Mississippi",
      id: "MS",
    },
    {
      label: "Missouri",
      id: "MO",
    },
    {
      label: "Montana",
      id: "MT",
    },
    {
      label: "Nebraska",
      id: "NE",
    },
    {
      label: "Nevada",
      id: "NV",
    },
    {
      label: "New Hampshire",
      id: "NH",
    },
    {
      label: "New Jersey",
      id: "NJ",
    },
    {
      label: "New Mexico",
      id: "NM",
    },
    {
      label: "New York",
      id: "NY",
    },
    {
      label: "North Carolina",
      id: "NC",
    },
    {
      label: "North Dakota",
      id: "ND",
    },
    {
      label: "Northern Mariana Islands",
      id: "MP",
    },
    {
      label: "Ohio",
      id: "OH",
    },
    {
      label: "Oklahoma",
      id: "OK",
    },
    {
      label: "Oregon",
      id: "OR",
    },
    {
      label: "Palau",
      id: "PW",
    },
    {
      label: "Pennsylvania",
      id: "PA",
    },
    {
      label: "Puerto Rico",
      id: "PR",
    },
    {
      label: "Rhode Island",
      id: "RI",
    },
    {
      label: "South Carolina",
      id: "SC",
    },
    {
      label: "South Dakota",
      id: "SD",
    },
    {
      label: "Tennessee",
      id: "TN",
    },
    {
      label: "Texas",
      id: "TX",
    },
    {
      label: "Utah",
      id: "UT",
    },
    {
      label: "Vermont",
      id: "VT",
    },
    {
      label: "Virgin Islands",
      id: "VI",
    },
    {
      label: "Virginia",
      id: "VA",
    },
    {
      label: "Washington",
      id: "WA",
    },
    {
      label: "West Virginia",
      id: "WV",
    },
    {
      label: "Wisconsin",
      id: "WI",
    },
    {
      label: "Wyoming",
      id: "WY",
    },
    {
      label: "Alberta (Canada)",
      id: "AB",
    },
    {
      label: "British Columbia (Canada)",
      id: "BC",
    },
    {
      label: "Manitoba (Canada)",
      id: "MB",
    },
    {
      label: "New Brunswick (Canada)",
      id: "NB",
    },
    {
      label: "Newfoundland and Labrador (Canada)",
      id: "NL",
    },
    {
      label: "Northwest Territories (Canada)",
      id: "NT",
    },
    {
      label: "Nova Scotia (Canada)",
      id: "NS",
    },
    {
      label: "Nunavut (Canada)",
      id: "NU",
    },
    {
      label: "Ontario (Canada)",
      id: "ON",
    },
    {
      label: "Prince Edward Island (Canada)",
      id: "PE",
    },
    {
      label: "Quebec (Canada)",
      id: "QC",
    },
    {
      label: "Saskatchewan (Canada)",
      id: "SK",
    },
    {
      label: "Yukon (Canada)",
      id: "YT",
    },
  ];
  //let ifLabel = actionIndex === 0 ? "IF" : "ELSE IF"

  let transactionTypeOptions = [
    { id: "Donation", label: "Donation" },
    { id: "Pledge", label: "Pledge" },
    { id: "RecurringDonation", label: "Recurring Donation" },
    { id: "RecurringDonationPayment", label: "Recurring Donation Payment" },
    { id: "InKindGoods", label: "In Kind Goods" },
    { id: "InKindServices", label: "In Kind Services" },
  ];

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "350px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            label="Select Condition"
            labelId="conditionType"
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <optgroup label="Recommended">
              <option value="transactionAmount">
                The gift amount is greater than or equal to
              </option>
              <option value="isFirstTransaction">It's a constituents first gift</option>
            </optgroup>
            <optgroup label="Milestone reached (i.e. will only trigger once when milestone reached)">
              <option value="milestoneGiftCountReached">
                Constituents number of donations reached any of these milestones
              </option>
              <option value="milestoneLifetimeAmountReached">
                Constituents total lifetime giving reached any of these milestones
              </option>
              <option value="milestoneSinceMonthAmountReached">
                Since MONTH, constituents cumulative giving reached any of these
                milestones
              </option>
              <option value="milestoneConsecYearsGivingReached">
                Constituent has consecutively given for any of these yearly milestones
              </option>
            </optgroup>
            <optgroup label="Additional filtering">
              <option value="didTransactionAmountIncrease">
                The donation amount is greater than the constituent's previous gift amount
              </option>
              <option value="transactionTypes">The donation type is any of these</option>
              <option value="funds">The donation is from any of these funds</option>
              <option value="campaigns">
                The donation is from any of these campaigns
              </option>
              <option value="appeals">The donation is from any of these appeals</option>
              <option value="states">
                The constituent lives in any of these states/provinces
              </option>
            </optgroup>
          </Select>
        </FormControl>

        {metric === "transactionAmount" && (
          <SelectItem
            options={giftAmountOptions}
            selectedItem={metricValue}
            handleChange={(value) =>
              handleItemChange("transactionAmount", value, "greaterEqual")
            }
            label="Amount"
            alt="Select gift amount"
            width="150px"
          />
        )}
        {metric === "transactionTypes" && (
          <SelectList
            options={transactionTypeOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange("transactionTypes", value, "includes")
            }
            label="Donation Types"
            width="50%"
          />
        )}
        {metric === "campaigns" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "bloomerang", event: "getCampaignList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("campaigns", value, "includes")}
            label="Campaigns"
            width="50%"
          />
        )}
        {metric === "appeals" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "bloomerang", event: "getAppealsList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("appeals", value, "includes")}
            label="Appeals"
            width="50%"
          />
        )}
        {metric === "funds" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "bloomerang", event: "getFundsList" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("funds", value, "includes")}
            label="Funds"
            width="50%"
          />
        )}
        {metric === "states" && (
          <SelectList
            options={statesOptions}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("states", value, "includes")}
            label="States"
            width="50%"
          />
        )}
        {metric === "milestoneSinceMonthAmountReached" && (
          <SelectSinceMonthAndTier
            selectedItems={selectedItems}
            actions={actions}
            setActions={props.setActions}
            currentCondition={current}
            actionIndex={actionIndex}
            conditionId={conditionId}
          />
        )}
        {metric === "milestoneConsecYearsGivingReached" && (
          <SelectList
            options={yearsGivenOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneConsecYearsGivingReached",
                value,
                "milestoneConsecYearsGivingReached"
              )
            }
            label="Milestones"
            width="50%"
          />
        )}
        {metric === "milestoneGiftCountReached" && (
          <SelectList
            options={giftCountsOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneGiftCountReached",
                value,
                "milestoneGiftCountReached"
              )
            }
            label="Milestones"
          />
        )}
        {metric === "milestoneLifetimeAmountReached" && (
          <SelectList
            options={tierAmountOptions}
            selectedItems={selectedItems}
            handleChange={(value) =>
              handleListChange(
                "milestoneLifetimeAmountReached",
                value,
                "milestoneLifetimeAmountReached"
              )
            }
            label="Milestones"
            width="200px"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
