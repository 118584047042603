import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import PageNotFound444 from "../../../util/pageNotFound444";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import { useGetAccountCustomProperties } from "../../../util/contacts/useGetAccountCustomProperties";
import { useGetNoteContent } from "../../../util/hooks/useGetNoteContent";
import CallToAction from "./callToAction/callToAction";
import Videos from "./videos/videos";
import Contacts from "./contacts/contacts";
import NoteBranding from "./branding/noteBranding";
import SendNote from "./sendNote/sendNote";
import Message from "./message/message";
import ExternalShare from "./externalShare/externalShare";
import EmailSettings from "./emailSettings/emailSettings";
import ReplySettings from "./replySettings/replySettings";
import ResendSettings from "./resendSettings/resendSettings";
import TextSettings from "./textSettings/textSettings";
import PreviewNote from "./preview/previewNote";
import BirthdaySettings from "../../birthday/birthdaySettings";
import BirthdayContacts from "../../birthday/contacts";
import {
  patchNoteTaskContent,
  updateNoteTaskContentVideos,
  updateNoteContacts,
  getNoteContactCounts,
  getNoteVideos,
} from "services/api.service";

export default function EditNote(props) {
  let {
    noteId,
    taskId = null,
    contentId = null,
    accountInfo,
    userInfo,
    userRole,
    showSettings = {},
    calledFromBirthday = false,
  } = props;
  let {
    showContacts,
    showBirthdaySettings,
    showBirthdayContacts,
    showSendNote,
    showCTA,
    showBranding,
    showEmail,
    showText,
    showReply,
    showResend,
    showExternalShare,
    showEmailLocalPart,
    showEmailReplyTo,
  } = showSettings;

  const [note, setNote] = useState("loading");
  let [contactCounts, setContactCounts] = useState({
    email: null,
    text: null,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  let [noteContent] = useGetNoteContent(noteId, taskId, contentId);
  let [accountCustomProperties, customContactProperties] = useGetAccountCustomProperties(
    props.accountInfo
  );

  const updateContactCounts = useCallback(() => {
    getNoteContactCounts(noteId).then((result) => {
      setContactCounts(result);
    });
  }, [noteId]);

  useEffect(() => {
    if (noteContent === "loading") return;
    setNote({ ...noteContent });
    updateContactCounts();
  }, [noteContent, updateContactCounts]);

  const timeoutId = useRef();

  const pollVideoStatus = useCallback(async () => {
    clearTimeout(timeoutId);

    if (
      note?.noteVideos?.some(
        (nv) =>
          nv.video.status === "webMUploaded" ||
          nv.video.status === "requested" ||
          !nv.video.status
      )
    ) {
      timeoutId.current = setTimeout(async () => {
        const noteVideos = await getNoteVideos(noteId);
        let updated = false;

        for (const nv of noteVideos) {
          const found = note.noteVideos.find((item) => item.videoId === nv.videoId);
          if (found && nv.video?.status !== found.video?.status) {
            found.video = nv.video;
            updated = true;
          }
        }
        if (updated) {
          setNote((prev) => {
            return { ...prev, noteVideos: [...noteVideos] };
          });
        }
        pollVideoStatus();
      }, 5000);
    }
  }, [note.noteVideos, noteId]);

  useEffect(() => {
    clearTimeout(timeoutId.current);
    pollVideoStatus();
    return () => clearTimeout(timeoutId.current);
  }, [note, pollVideoStatus]);

  async function saveNoteState(field, value) {
    const update = { [field]: value };

    if (field === "primaryColor" || field === "secondaryColor") {
      if (note.background.colorOne && field === "primaryColor") {
        note.background.colorOne = value;
        update.background = note.background;
      } else if (note.background.colorTwo && field === "secondaryColor") {
        note.background.colorTwo = value;
        update.background = note.background;
      }
    }

    try {
      setIsSaving(true);
      setNote({ ...note, ...update });

      if (field === "noteVideos") {
        const videoIds = value.map((item) => item.videoId);
        await updateNoteTaskContentVideos(noteId, taskId, contentId, videoIds);
      } else if (field === "noteContacts") {
        const contactIds = [];
        const folderIds = [];

        for (const noteContact of value) {
          if (noteContact.folderId) {
            folderIds.push(noteContact.folderId);
          } else {
            contactIds.push(noteContact.contactId);
          }
        }

        await updateNoteContacts(noteId, folderIds, contactIds);
        updateContactCounts();
      } else {
        if (taskId && field === "messageHtml") {
          update.message = value;
          delete update.messageHtml;
        }

        await patchNoteTaskContent(noteId, taskId, contentId, update);
      }
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setError("Error saving note");
      return err;
    }
  }

  if (note === "loading" || customContactProperties === "loading") {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  } else if (noteContent.errorMessage) {
    return (
      <div className="row col-12 pagePadding">
        <PageNotFound444
          errorMessage={"Note might have been deleted. " + noteContent.errorMessage}
        />
      </div>
    );
  } else if (!userRole.isNoteEditor) {
    const videoIds = note.noteVideos?.map((nv) => nv.videoId) || [];

    //the Videos component loads the videos, but hide it so can't be edited
    return (
      <div className="col-12 row pagePadding justify-content-center">
        <div className="col-12 notification-message-box">
          <p>
            You do not have permission to edit note templates. You must create, edit, and
            sends Gratavids via{" "}
            <Link to={`/a/${props.accountInfo.id}/task`} style={{ fontSize: 16 }}>
              the task list
            </Link>
            . To learn more about tasks, watch{" "}
            <a
              style={{ fontSize: 16 }}
              href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid"
              rel="noopener noreferrer"
              target="_blank">
              How To Send A Gratavid
            </a>
            .
          </p>
        </div>

        <div
          className="col-xl-8 col-lg-10 col-md-8 col-12"
          style={{ paddingLeft: "20px" }}>
          <PreviewNote
            accountId={accountInfo.id}
            videoIds={videoIds}
            noteVideos={note.noteVideos}
            background={note.background}
            senderName={note.senderName}
            messageHtml={note.messageHtml}
            showEmbedForm={note.showEmbedForm}
            logo={note.logoUrl}
            noteId={noteId}
            taskId={taskId}
            contentId={note.contentId}
            contacts={note.noteContacts}
            buttons={note.buttons}
          />
        </div>
      </div>
    );
  }

  const videoIds = note.noteVideos?.map((nv) => nv.videoId) || [];
  const videosAreProcessing = note.noteVideos?.some(
    (nv) => nv.video?.status === "webMUploaded"
  );

  const advanceSites =
    accountInfo.integrations?.endpoint?.advance?.active &&
    accountInfo.integrations?.endpoint?.advance?.sites;

  return (
    <div className="row" style={{ minHeight: "100vh" }}>
      {isSaving && (
        <div
          style={{
            position: "fixed",
            left: "0",
            right: "0",
            top: "0",
            height: "10px",
            zIndex: 9999,
          }}>
          <LinearProgress />
        </div>
      )}

      <div className="col-12 row pagePadding align-items-start">
        <div className="col-md-12 col-lg-6 row">
          <div className="col-6">
            <h2
              className="greyDesc"
              style={{ padding: "10px 0px", fontSize: "14px", fontWeight: 500 }}>
              Edit Section
            </h2>
          </div>

          {showBirthdaySettings && (
            <BirthdaySettings
              active={props.active}
              localSendTime={props.localSendTime}
              timezone={props.timezone}
              updateBirthdaySettings={props.updateBirthdaySettings}
              setTabSelected={props.setTabSelected}
              accountInfo={accountInfo}
              noteId={noteId}
              contacts={note.noteContacts}
              shareViaEmail={note.shareViaEmail}
              shareViaText={note.shareViaText}
              resendActive={note.resendActive}
            />
          )}

          <Message
            messageHtml={note.messageHtml}
            senderName={note.senderName}
            passedSetState={saveNoteState}
            customContactProperties={customContactProperties}
          />

          <Videos
            accountInfo={accountInfo}
            noteVideos={note.noteVideos}
            videoIds={videoIds}
            noteId={noteId}
            taskId={taskId}
            appendNoteVids={note.appendNoteVids}
            userIsAdmin={userRole.isAdmin}
            saveNoteState={saveNoteState}
          />

          {showBirthdayContacts && (
            <BirthdayContacts
              noteId={noteId}
              accountInfo={accountInfo}
              shareViaEmail={note.shareViaEmail}
              shareViaText={note.shareViaText}
              saveNoteState={saveNoteState}
              getTimeZoneOffset={props.getTimeZoneOffset}
              localSendTime={props.localSendTime}
              timezone={props.timezone}
              userIsAdmin={props.userRole.isAdmin}
            />
          )}
          {showContacts && userRole.isBulkSender && (
            <Contacts
              noteContacts={note.noteContacts}
              contactCounts={contactCounts}
              accountInfo={accountInfo}
              customContactProperties={customContactProperties}
              saveNoteState={saveNoteState}
              shareViaEmail={note.shareViaEmail}
              shareViaText={note.shareViaText}
            />
          )}

          {showCTA && (
            <CallToAction
              website={accountInfo.website}
              buttons={note.buttons}
              primaryColor={note.primaryColor}
              passedSetState={saveNoteState}
              showEmbeddedForm={note.showEmbeddedForm}
              embeddedFormCode={note.embeddedFormCode}
              advanceSites={advanceSites}
            />
          )}

          {showBranding && (
            <NoteBranding
              primaryColor={note.primaryColor}
              secondaryColor={note.secondaryColor}
              background={note.background}
              accountInfo={accountInfo}
              logo={note.logoUrl}
              saveNoteState={saveNoteState}
            />
          )}

          {showEmail && (
            <EmailSettings
              emailBody={note.emailBody}
              customizeEmailBody={note.customizeEmailBody}
              messageHtml={note.messageHtml}
              emailSubject={note.emailSubject}
              accountInfo={accountInfo}
              emailLocalPart={note.emailLocalPart}
              replyToEmails={note.replyToEmails}
              saveNoteState={saveNoteState}
              customContactProperties={customContactProperties}
              showEmailLocalPart={showEmailLocalPart}
              showEmailReplyTo={showEmailReplyTo}
              customEmailFooter={note.customEmailFooter}
              watchNowText={note.watchNowText}
            />
          )}

          {accountInfo.textEnabled && showText && (
            <TextSettings
              accountInfo={accountInfo}
              textMessageBody={note.textMessageBody}
              customContactProperties={customContactProperties}
              saveNoteState={saveNoteState}
            />
          )}

          {showReply && (
            <ReplySettings
              hideNoteReplyInput={note.hideNoteReplyInput}
              showVideoReply={note.showVideoReply}
              passedSetState={saveNoteState}
            />
          )}

          {showResend && (
            <ResendSettings
              resendEmailBody={note.resendEmailBody}
              resendActive={note.resendActive}
              resendEmailSubject={note.resendEmailSubject}
              resendInXDays={note.resendInXDays}
              customContactProperties={customContactProperties}
              passedSetState={saveNoteState}
            />
          )}

          {showExternalShare && (
            <ExternalShare
              accountId={props.accountInfo.id}
              noteId={noteId}
              showShare={note.showShare}
              showDownload={note.showDownload}
              ogDescription={note.ogDescription}
              ogTitle={note.ogTitle}
              taskId={taskId}
              contentId={contentId}
              saveNoteState={saveNoteState}
            />
          )}
        </div>

        <div className="col-xs-0 col-lg-6 hidden-md-down" style={{ paddingLeft: "20px" }}>
          <PreviewNote
            accountId={accountInfo.id}
            videoIds={videoIds}
            noteVideos={note.noteVideos}
            background={note.background}
            senderName={note.senderName}
            messageHtml={note.messageHtml}
            showEmbedForm={note.showEmbeddedForm}
            logo={note.logoUrl}
            noteId={noteId}
            taskId={taskId}
            contentId={contentId}
            contacts={note.noteContacts}
            buttons={note.buttons}
          />
        </div>

        {showSendNote && userRole.isBulkSender && (
          <SendNote
            accountInfo={accountInfo}
            noteId={noteId}
            userInfo={userInfo}
            noteIsLoading={isSaving}
            videosAreProcessing={videosAreProcessing}
            passedSetState={saveNoteState}
            setTabSelected={props.setTabSelected}
            shareViaText={note.shareViaText}
            shareViaEmail={note.shareViaEmail}
            contacts={note.noteContacts}
            contactCounts={contactCounts}
            videoIds={videoIds}
            noteVideos={note.noteVideos}
            senderName={note.senderName}
            messageHtml={note.messageHtml}
            textMessageBody={note.textMessageBody}
            emailSubject={note.emailSubject}
            calledFromBirthday={calledFromBirthday}
            emailBody={note.emailBody}
            resendActive={note.resendActive}
            resendEmailSubject={note.resendEmailSubject}
            resendEmailBody={note.resendEmailBody}
            resendInXDays={note.resendInXDays}
          />
        )}

        {!userRole.isBulkSender && <div className="col-12" style={{ height: 150 }} />}
      </div>

      <Snackbar open={!!error} autoHideDuration={5000} onClose={() => setError("")}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}

EditNote.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.object.isRequired,
  noteId: PropTypes.string.isRequired,
  taskId: PropTypes.string,
  contentId: PropTypes.string,
  showSettings: PropTypes.object,
  calledFromBirthday: PropTypes.bool,
};
