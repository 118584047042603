import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Button, Dialog, useMediaQuery } from "@mui/material";
import VideoPlayer from "./videoPlayer";
import { editVideo } from "services/api.service";
import TextFieldQuestion from "../../../../util/hooks/input/textFieldQuestion";

export default function SortItem(props) {
  let [dialogOpen, setDialogOpen] = useState(false);
  let showLargeVideo = useMediaQuery("(min-width:900px)");
  let { calledFromTask = false } = props;
  //if only one video, we will show big video that can be played inline. else show sort list

  let updateSpeakerName = async (value) => {
    await editVideo(props.video.videoId, { speakerName: value });

    if (props.passedSetState) {
      const videos = props.videos.map((video) => {
        if (video.videoId === props.video.videoId) {
          const result = { ...video };

          if (video.video) {
            result.video.speakerName = value;
          }
          return result;
        } else {
          return video;
        }
      });
      const field = calledFromTask ? "taskVideos" : "noteVideos";
      props.passedSetState(field, videos);
    }
  };

  if (props.video.errorMessage || props.video.video?.status === "error") {
    return (
      <div
        className="col-12"
        style={{ display: "flex", padding: "10px 0px" }}
        key={props.video.videoId}>
        <div className="align-items-center" style={{ width: "80px", paddingLeft: "5px" }}>
          <div className="video-thumbnail-item" />
        </div>

        <div
          style={{
            flex: 1,
            padding: "5px",
            paddingLeft: "15px",
            overflow: "hidden",
            maxHeight: "55px",
          }}
          className="align-items-center">
          <p style={{ fontSize: "14px", color: "red", fontWeight: "bold" }}>
            {props.video.video?.status === "error" ? (
              <>
                Video Error.{" "}
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/a/${props.accountInfo.id}/videos/` + props.video.videoId}>
                  View Now
                </a>
              </>
            ) : (
              "Video deleted. Please remove"
            )}
          </p>
        </div>

        <div
          className="align-items-center"
          style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
          <IconButton onClick={() => props.removeVideo(props.video)}>
            <i className="material-icons">clear</i>
          </IconButton>
        </div>
      </div>
    );
  } else if (props.video.video?.status === "requested") {
    return (
      <div
        className="col-12"
        style={{ display: "flex", padding: "10px 0px" }}
        key={props.video.videoId}>
        <div className="align-items-center" style={{ paddingLeft: "5px" }}>
          <div className="video-thumbnail-item justify-content-center">
            <div>
              <i
                className="material-icons"
                style={{ color: "grey", justifyContent: "center", display: "flex" }}>
                contact_mail
              </i>
              <span style={{ fontSize: "12px", display: "absolute", bottom: "0px" }}>
                Requested
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            padding: "5px",
            paddingLeft: "15px",
            overflow: "hidden",
            maxHeight: "55px",
          }}
          className="align-items-center">
          <p style={{ fontSize: "14px" }}>
            {props.order}:{" "}
            <a
              style={{ fontSize: "14px" }}
              target="_blank"
              rel="noopener noreferrer"
              href={`/a/${props.accountInfo.id}/videos/` + props.video.videoId}>
              {props.video.video?.title || ""}
            </a>
          </p>
        </div>

        <div
          className="align-items-center"
          style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
          <IconButton onClick={() => props.removeVideo(props.video)}>
            <i className="material-icons">clear</i>
          </IconButton>
        </div>
      </div>
    );
  } else if (props.video.video?.status === "mediaURLUploaded") {
    //change max width depending on videoOrientation
    let videoWidth = props.video.video?.width || 1280;
    let videoHeight = props.video.video?.height || 720;
    let videoOrientation = "horizontal";
    videoOrientation = videoHeight > videoWidth ? "vertical" : "horizontal";
    let vidMaxWidth = videoOrientation === "horizontal" ? "350px" : "250px";

    return (
      <>
        <div
          className="col-12"
          style={{
            display: "flex",
            padding: "10px 0px",
            zIndex: 998,
            borderBottom:
              props.videos.length === 1 ? "" : "1px rgba(220,220,220,0.5) solid",
          }}
          key={props.video.videoId}>
          {props.videos.length === 1 && showLargeVideo && calledFromTask ? (
            <div style={{ flex: 1 }}>
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  maxWidth: vidMaxWidth,
                  borderRadius: "14px",
                  overflow: "hidden",
                  backgroundColor: "#ddd",
                }}>
                <VideoPlayer
                  mediaURL={props.video.video?.videoUrl}
                  thumbnail={props.video.video?.thumbnailUrl}
                  buttons={{}}
                />
              </div>
              <TextFieldQuestion
                value={props.video.video?.speakerName}
                for="name"
                label="Speaker Name"
                variant="standard"
                passedSetState={updateSpeakerName}
                dialogTitle="Speaker Name"
                dialogDesc="Speaker name is the primary person speaking in your video. Leave it blank to hide the speaker icon and name."
                dialogImgSrc="https://firebasestorage.googleapis.com/v0/b/gratavid.appspot.com/o/util%2Fimages%2FspeakerName.png?alt=media&token=d01a31f7-d947-4e76-abf1-a60eafb77c05"
              />
            </div>
          ) : (
            <>
              <div
                className="align-items-center"
                style={{ width: "80px", paddingLeft: "5px" }}>
                <Button
                  variant="text"
                  className="video-thumbnail-item"
                  style={{ position: "relative" }}
                  onClick={() => setDialogOpen(true)}>
                  <img
                    src={props.video.video?.thumbnailUrl}
                    style={{
                      width: "75px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                    alt="video thumbnail"
                  />
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      align: "center",
                      justifyContent: "center",
                    }}>
                    <img
                      src={"/static/img/playButton.svg"}
                      style={{ maxWidth: "25px", maxHeight: "25px" }}
                      alt="play thumbnail"
                    />
                  </div>
                </Button>
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "5px",
                  paddingLeft: "15px",
                  overflow: "hidden",
                }}
                className="align-items-center">
                <TextFieldQuestion
                  value={props.video.video?.speakerName}
                  for="name"
                  label="Speaker Name"
                  variant="standard"
                  passedSetState={updateSpeakerName}
                  dialogTitle="Speaker Name"
                  dialogDesc="Speaker name is the primary person speaking in your video. Leave it blank to hide the speaker icon and name."
                  dialogImgSrc="https://firebasestorage.googleapis.com/v0/b/gratavid.appspot.com/o/util%2Fimages%2FspeakerName.png?alt=media&token=d01a31f7-d947-4e76-abf1-a60eafb77c05"
                />
              </div>
            </>
          )}

          <div
            className="align-items-center"
            style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
            <IconButton onClick={() => props.removeVideo(props.video)}>
              <i className="material-icons">clear</i>
            </IconButton>
          </div>
        </div>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <div
            className="thumbnail-bg-image"
            style={{
              backgroundImage: "url(" + props.video.video?.thumbnailUrl + ")",
            }}
          />
          <div
            style={{
              display: "flex",
              padding: "20px",
              backgroundColor: "white",
              zIndex: 998,
            }}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <a
                href={`/a/${props.accountInfo.id}/videos/` + props.video.videoId}
                target="_blank"
                rel="noopener noreferrer">
                <h2>
                  {props.video.video?.title || ""}{" "}
                  <i className="material-icons" style={{ fontSize: "15px" }}>
                    launch
                  </i>
                </h2>
              </a>
            </div>
            <div style={{ width: "48px" }}>
              <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>
          </div>

          <video
            autoPlay
            controls
            src={props.video.video?.videoUrl + "?cacheBuster=123"}
            preload="none"
            width="100%"
            height="auto"
            type="video/mp4"
            style={{ maxHeight: "500px", zIndex: 999 }}>
            Sorry, your browser doesn&apos;t support embedded videos.{" "}
            <a
              href={props.video.video?.videoUrl}
              target="_blank"
              rel="noopener noreferrer">
              Click here to view
            </a>
          </video>
        </Dialog>
      </>
    );
  } else if (props.video.video?.status === "webMUploaded") {
    return (
      <div
        className="col-12"
        style={{ display: "flex", padding: "10px 0px" }}
        key={props.video.videoId}>
        <div className="align-items-center" style={{ width: "80px", paddingLeft: "5px" }}>
          <div className="video-thumbnail-item loading-pulse" />
        </div>

        <div
          style={{
            flex: 1,
            padding: "5px",
            paddingLeft: "15px",
            overflow: "hidden",
            maxHeight: "55px",
          }}
          className="align-items-center">
          <p style={{ fontSize: "14px" }}>
            {props.video.video?.status === "webMUploaded"
              ? "processing..."
              : "loading..."}
          </p>
        </div>

        <div
          className="align-items-center"
          style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
          <IconButton onClick={() => props.removeVideo(props.video)}>
            <i className="material-icons">clear</i>
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className="col-12"
      style={{ display: "flex", padding: "10px 0px" }}
      key={props.video.videoId}>
      <div className="align-items-center" style={{ paddingLeft: "5px" }}>
        <div className="video-thumbnail-item justify-content-center">
          <div>
            <i
              className="material-icons"
              style={{ color: "grey", justifyContent: "center", display: "flex" }}>
              pending_actions
            </i>
            <span style={{ fontSize: "12px", display: "absolute", bottom: "0px" }}>
              Pending
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          padding: "5px",
          paddingLeft: "15px",
          overflow: "hidden",
          maxHeight: "55px",
        }}
        className="align-items-center">
        <p style={{ fontSize: "14px" }}>
          {props.order}:{" "}
          <a
            style={{ fontSize: "14px" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`/a/${props.accountInfo.id}/videos/` + props.video.videoId}>
            {props.video.video?.title || ""}
          </a>
        </p>
      </div>

      <div
        className="align-items-center"
        style={{ cursor: "pointer", width: "45px", padding: "5px" }}>
        <IconButton onClick={() => props.removeVideo(props.video)}>
          <i className="material-icons">clear</i>
        </IconButton>
      </div>
    </div>
  );
}

const videoProp = PropTypes.shape({
  videoId: PropTypes.string,
  order: PropTypes.number,
  video: PropTypes.object,
});

SortItem.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  video: videoProp.isRequired,
  videos: PropTypes.arrayOf(videoProp).isRequired,
  calledFromTask: PropTypes.bool,
  order: PropTypes.number,
  removeVideo: PropTypes.func.isRequired,
  passedSetState: PropTypes.func,
};
