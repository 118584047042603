import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router";
import LoadingButtonCheckProductLimits from "../../../util/buttons/productLimits/loadingbutton";
import {
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
import SelectTags from "../../../util/tags/selectTags";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import { createVideos } from "services/api.service";

export default function AddVideoDialog(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);
  let [videoTitle, setVideoTitle] = useState("");
  let [uploadType, setUploadType] = useState("");
  let [selectedFoldersArray, setSelectedFoldersArray] = useState([]);

  const fullScreen = useMobileDialog();
  const navigate = useNavigate();

  let createVideoObject = async (e) => {
    try {
      e.preventDefault();

      if (videoTitle === "") throw new Error("Insert video title");
      else if (uploadType === "") throw new Error("Select upload type");

      //only add the user selected folders to our firebase data
      let foldersFilter = selectedFoldersArray.map((current) => {
        return current.id;
      });

      setButtonState("loading");

      const videos = await createVideos([
        {
          title: videoTitle,
          foldersFilter,
          uploadType,
        },
      ]);

      const videoId = videos[0].id;

      if (!videoId) {
        throw new Error("Error creating video");
      }

      navigate(`/a/${props.accountInfo.id}/videos/` + videoId);
    } catch (err) {
      let errorMessage = err?.data?.message ?? err.message;
      setErrorMessage(errorMessage);
      setButtonState("regular");
    }
  };

  if (!props.addNewDialogOpen) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.addNewDialogOpen}
      onClose={() => props.setAddNewDialogOpen(false)}
      aria-labelledby="add video dialog">
      <DialogContent
        style={{
          height: fullScreen ? "auto" : "550px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "18px" }}>Create video</span>
            <p style={{ fontSize: "14px", color: "grey" }}>
              Need to request videos in bulk?{" "}
              <Link
                to={`/a/${props.accountInfo.id}/videos/bulkupload`}
                style={{ fontSize: "14px" }}>
                Use bulk editor
              </Link>
            </p>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton
              aria-label="close"
              onClick={() => props.setAddNewDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12" style={{ padding: "10px 0px", marginTop: "10px" }}>
          <TextField
            variant="outlined"
            id="videoTitle"
            placeholder="e.g. Donor Appreciation"
            label="Video Title"
            style={{ width: "100%" }}
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ marginTop: "15px" }}>
          <SelectTags
            accountId={props.accountInfo.id}
            selectedFoldersArray={selectedFoldersArray}
            setSelectedFoldersArray={setSelectedFoldersArray}
            indexName="videos"
          />
        </div>

        <div className="col-12" style={{ padding: "10px 0px", marginTop: "15px" }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Upload Type</FormLabel>
            <RadioGroup
              aria-label="uploadType"
              name="uploadType"
              value={uploadType}
              onChange={(e) => setUploadType(e.target.value)}>
              <FormControlLabel
                value="upload"
                control={<Radio />}
                label="Record/upload a video now"
              />
              <FormControlLabel
                value="request"
                control={<Radio />}
                label="Send a request for someone else to record/upload a video"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButtonCheckProductLimits
          accountInfo={props.accountInfo}
          countToAdd={1}
          productKey="videos"
          productLabel="video"
          userIsAdmin={props.userIsAdmin}
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel="View Video"
          label="Create Video"
          clickedRegularButton={createVideoObject}
        />
      </DialogActions>
    </Dialog>
  );
}

AddVideoDialog.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  setAddNewDialogOpen: PropTypes.func.isRequired,
  addNewDialogOpen: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
};
