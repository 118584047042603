import React, { useState } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteSearchItemDialog from "./deleteSearchItemDialog";
import EditItemFolders from "../folders/search/editItemFolders";
import ResendRequestDialog from "../../account/videos/listVideos/resendRequestDialog";
import DownloadPendingRequestDialog from "../../account/videos/listVideos/downloadPendingRequestDialog";

//this is used by note and videos toolbar at top of search to select multiple or all items
//contacts has it's own
export default function BulkActionForSearchTableHeader(props) {
  let [anchorEl, setAnchorEl] = useState(null);
  let [editItemFoldersDialogOpen, setEditItemFoldersDialogOpen] = useState(false);
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let [resendRequestDialogOpen, setResendRequestDialogOpen] = useState(false);
  let [downloadPendingRequestDialogOpen, setDownloadPendingRequestDialogOpen] =
    useState(false);

  let deleteMenuClicked = () => {
    setAnchorEl(null);
    setDeleteDialogOpen(true);
  };

  let editItemFolders = () => {
    setAnchorEl(null);
    setEditItemFoldersDialogOpen(true);
  };

  let resendRequestClicked = () => {
    setAnchorEl(null);
    setResendRequestDialogOpen(true);
  };

  let downloadPendingRequestClicked = () => {
    setAnchorEl(null);
    setDownloadPendingRequestDialogOpen(true);
  };

  const selectedCount = props.allSelected
    ? props.searchState.nbHits
    : props.selectedItems.length;
  if (props.selectedItems.length === 0 && !props.allSelected) return null;

  return (
    <>
      <div className="col-12 align-items-center justify-content-end">
        {selectedCount} selected
        <div style={{ marginRight: "10px" }}>
          <IconButton
            aria-label="More"
            variant="contained"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            style={{ padding: "0px" }}>
            <i className="material-icons" style={{ color: "#000" }}>
              more_vert
            </i>
          </IconButton>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={anchorEl ? true : false}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ style: { maxHeight: 200, minWidth: 200 } }}>
          {!props.allSelected && (
            <MenuItem key={1} button onClick={editItemFolders}>
              <span>
                <span style={{ fontWeight: "bold" }}>Edit tags </span> for selected
              </span>
            </MenuItem>
          )}
          {props.indexName === "videos" && (
            <MenuItem key={2} button onClick={downloadPendingRequestClicked}>
              {props.allSelected ? (
                <span>
                  <span style={{ fontWeight: "bold" }}>Download</span> all pending request
                </span>
              ) : (
                <span>
                  <span style={{ fontWeight: "bold" }}>Download</span> pending request for
                  selected
                </span>
              )}
            </MenuItem>
          )}
          {props.indexName === "videos" && (
            <MenuItem key={3} button onClick={resendRequestClicked}>
              {props.allSelected ? (
                <span>
                  <span style={{ fontWeight: "bold" }}>Resend</span> all pending request
                </span>
              ) : (
                <span>
                  <span style={{ fontWeight: "bold" }}>Resend</span> pending request for
                  selected
                </span>
              )}
            </MenuItem>
          )}
          <MenuItem key={4} button onClick={deleteMenuClicked}>
            <span>
              <span style={{ fontWeight: "bold" }}>Delete</span> selected
            </span>
          </MenuItem>
        </Menu>
      </div>

      {deleteDialogOpen && (
        <DeleteSearchItemDialog
          accountId={props.accountId}
          indexName={props.indexName}
          objectIdsToUpdate={props.selectedItems.map(({ id }) => id)}
          searchState={props.searchState}
          hideTaskVids={props.hideTaskVids}
          searchList={props.searchList}
          setSearchList={props.setSearchList}
          setSelectedItems={props.setSelectedItems}
          allSelected={props.allSelected}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
      )}
      {editItemFoldersDialogOpen && (
        <EditItemFolders
          indexName={props.indexName}
          currentItem={null}
          calledFromBulkEdit={true}
          objectIdsToUpdate={props.selectedItems}
          dialogTitle={props.indexName}
          {...props}
          dialogOpen={editItemFoldersDialogOpen}
          setDialogOpen={setEditItemFoldersDialogOpen}
        />
      )}
      {resendRequestDialogOpen && (
        <ResendRequestDialog
          objectIdsToUpdate={props.selectedItems.map(({ id }) => id)}
          allSelected={props.allSelected}
          dialogOpen={resendRequestDialogOpen}
          setDialogOpen={setResendRequestDialogOpen}
          accountId={props.accountId}
        />
      )}
      {downloadPendingRequestDialogOpen && (
        <DownloadPendingRequestDialog
          dialogOpen={downloadPendingRequestDialogOpen}
          objectsToDownload={props.selectedItems}
          allSelected={props.allSelected}
          setDialogOpen={setDownloadPendingRequestDialogOpen}
          hideTaskVids={props.hideTaskVids}
          accountId={props.accountId}
          indexName={props.indexName}
        />
      )}
    </>
  );
}

BulkActionForSearchTableHeader.propTypes = {
  accountId: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  searchState: PropTypes.shape({
    nbHits: PropTypes.number.isRequired,
  }).isRequired,
  searchList: PropTypes.array.isRequired,
  setSearchList: PropTypes.func.isRequired,
  hideTaskVids: PropTypes.bool,
  allSelected: PropTypes.bool,
};
