import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ButtonItem from "./buttonItem";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import SortableList from "components/util/sortable/SortableList";
import { FormControl, FormHelperText, InputLabel, Select, Tooltip } from "@mui/material";
import { getAdvanceEverydayForms } from "services/api.service";

export default function CallToAction(props) {
  const [showEverydayForms, setShowEverydayForms] = React.useState(false);
  const [advanceSiteId, setAdvanceSiteId] = React.useState("");
  const [everydayForms, setEverydayForms] = React.useState([]);
  const [everydayFormSlug, setEverydayFormSlug] = React.useState("");
  const [advanceSiteError, setAdvanceSiteError] = React.useState("");

  function addButton() {
    const buttons = [
      ...props.buttons,
      {
        id: crypto.randomUUID(),
        label: "Edit Label",
        link: props.website || "https://example.com",
        color: props.primaryColor,
      },
    ];

    return props.passedSetState("buttons", buttons);
  }

  function deleteButton(index) {
    return props.passedSetState("buttons", props.buttons.toSpliced(index, 1));
  }

  function updateButton(index, field, value) {
    const buttons = [...props.buttons];
    buttons[index][field] = value;
    return props.passedSetState("buttons", buttons);
  }

  function handleAdvanceSiteChange(e) {
    const siteId = e.target.value;
    setAdvanceSiteId(siteId);
    setEverydayForms([]);
    setEverydayFormSlug("");
    setAdvanceSiteError("");

    getAdvanceEverydayForms(siteId)
      .then((data) => {
        setEverydayForms(data);
      })
      .catch((err) => {
        if (err.status === 401) {
          setAdvanceSiteError("Connection to Advance failed");
        } else {
          setAdvanceSiteError("Error fetching forms");
        }
      });
  }

  function handleEverydayFormChange(e) {
    const slug = e.target.value;
    setEverydayFormSlug(slug);
    const site = advanceDomains.find((s) => s.siteId === advanceSiteId);
    const url = new URL(`/forms/${slug}?embed`, "https://" + site.siteDomain);
    const code = `<iframe src="${url.href}" width="100%" height="600px" frameborder="0"></iframe>`;
    props.passedSetState("embeddedFormCode", code);
  }

  const advanceDomains = Object.values(props.advanceSites)
    ?.map((s) => s.connectionInfo)
    .filter(Boolean);

  return (
    <div className="row col-12 adSectionBox" style={{ marginTop: "10px" }}>
      <div className="col-12 row align-items-center">
        <h2 style={{ marginRight: "2px" }}>Call To Action</h2>
        <QuestionToLink
          id="noteCallToAction"
          desc="call to action settings"
          size="small"
        />
      </div>

      <div className="col-12">
        <CheckBoxToApi
          initialValue={!!props.showEmbeddedForm}
          field="showEmbeddedForm"
          apiCall={props.passedSetState}
          label="Use embedded form"
        />
      </div>

      {props.showEmbeddedForm ? (
        <div className="col-12" style={{ margin: "15px 0px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginBottom: "25px",
            }}>
            {showEverydayForms ? (
              <>
                <FormControl fullWidth error={!!advanceSiteError}>
                  <InputLabel shrink id="advance-sites-label">
                    Site
                  </InputLabel>
                  <Select
                    labelId="advance-sites-label"
                    label="Site"
                    native
                    value={advanceSiteId}
                    onChange={handleAdvanceSiteChange}>
                    {!advanceSiteId && (
                      <option value="" disabled>
                        Select a site
                      </option>
                    )}
                    {advanceDomains.map((site) => (
                      <option key={site.siteId} value={site.siteId}>
                        {site.siteDomain}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>{advanceSiteError}</FormHelperText>
                </FormControl>
                {everydayForms.length > 0 && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel shrink id="everyday-form-label">
                        Everyday Form
                      </InputLabel>
                      <Select
                        labelId="everyday-form-label"
                        label="Everyday Form"
                        native
                        value={everydayFormSlug}
                        onChange={handleEverydayFormChange}>
                        {!everydayFormSlug && (
                          <option value="" disabled>
                            Select a form
                          </option>
                        )}
                        {everydayForms.map((form) => (
                          <option key={form.id} value={form.slug}>
                            {form.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            ) : (
              <Tooltip
                title={
                  props.advanceSites
                    ? "Embedded an Advance everyday form"
                    : "Contact us about this feature"
                }>
                <span>
                  <Button
                    variant="outlined"
                    disabled={!props.advanceSites}
                    onClick={() => setShowEverydayForms(true)}
                    startIcon={<i className="material-icons">add</i>}>
                    Advance Everyday Form
                  </Button>
                </span>
              </Tooltip>
            )}
          </div>

          <TextInputToApi
            value={props.embeddedFormCode}
            multiline
            apiCall={props.passedSetState}
            field="embeddedFormCode"
            placeholder="<script><div><div>...."
            label="Embedded Form Code"
            variant="outlined"
          />
        </div>
      ) : (
        <>
          <SortableList
            items={props.buttons}
            onChange={(items) => props.passedSetState("buttons", items)}
            renderItem={(item, index) => (
              <SortableList.Item id={item.id}>
                <ButtonItem
                  index={index}
                  current={item}
                  onDelete={() => deleteButton(index)}
                  onChange={(field, value) => updateButton(index, field, value)}
                />
              </SortableList.Item>
            )}
          />

          <div className="col-12" style={{ marginTop: "5px" }}>
            <Button
              variant={props.buttons.length === 0 ? "outlined" : "text"}
              onClick={addButton}>
              <i className="material-icons">add</i>
              Add Button
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

CallToAction.propTypes = {
  website: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  primaryColor: PropTypes.string,
  showEmbeddedForm: PropTypes.bool,
  embeddedFormCode: PropTypes.string,
  advanceSites: PropTypes.object,
  passedSetState: PropTypes.func,
};
