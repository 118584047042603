import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import styles from "./brandedDomain.module.css";
import Description from "./description.js";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller } from "react-hook-form";
import Button from "components/util/button/button.js";
import {
  verifyBrandedDomainEmail,
  verifyBrandedDomainVercel,
  emailBrandedDomain,
} from "services/adminApi.service.js";

const BrandedDomain = ({
  accountInfo,
  onSnackbar,
  isActive,
  touchedFields,
  errors,
  control,
  isSubmitted,
}) => {
  const [emailRecipient, setEmailRecipient] = useState(false);
  const [hostedDomainVerified, setHostedDomainVerified] = useState(
    accountInfo?.customDomainVerified ?? false
  );
  const [emailDomainVerified, setEmailDomainVerified] = useState(
    accountInfo?.domainVerified ?? false
  );

  function handleEmailRecipientChange(event) {
    setEmailRecipient(event.target.value);
  }

  async function handleVerifyEmailDomain() {
    try {
      const response = await verifyBrandedDomainEmail(accountInfo.id, emailRecipient);
      if (response?.domainVerified) {
        setEmailDomainVerified(true);
        return onSnackbar("success", "Email domain verified successfully");
      } else {
        onSnackbar("error", "Failed to verify email domain");
      }
    } catch (error) {
      onSnackbar("error", "Failed to verify email domain");
    }
  }

  async function handleVerifyVercelDomain() {
    try {
      const response = await verifyBrandedDomainVercel(accountInfo.id);
      if (response?.customDomainVerified) {
        setHostedDomainVerified(true);
        return onSnackbar("success", "Hosted domain verified successfully");
      } else {
        onSnackbar("error", "Failed to verify hosted domain");
      }
    } catch (error) {
      onSnackbar("error", "Failed to verify hosted domain");
    }
  }

  async function sendBrandedDomainEmail() {
    try {
      await emailBrandedDomain(accountInfo.id, emailRecipient);
      onSnackbar("success", "Branded domain email sent to account admin successfully");
    } catch (error) {
      onSnackbar("error", "Failed to send branded domain email to account admin");
    }
  }

  const title = "Branded Domains";
  const description =
    "Host your Gratavid site (e.g. thanks.gravyty.com) and send emails (thanks@gravyty.com) from your own domain.";
  return (
    <div className={styles.brandedDomain}>
      <Description title={title} description={description} />
      <FormControl component="fieldset">
        <Controller
          name="domainSettings.isActive"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                key="Included"
                value="true"
                control={<Radio />}
                label="Included"
              />
              {isActive === "true" && (
                <div className={styles.input_wrapper}>
                  <Controller
                    name="domainSettings.hostedDomain"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className={styles.input}
                        label="Hosted Domain"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: hostedDomainVerified && (
                            <InputAdornment position="end" title="Verified">
                              <i className={`material-icons ${styles.checkmark}`}>
                                check
                              </i>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          (isSubmitted || touchedFields?.hostedDomain) &&
                          !!errors?.hostedDomain
                        }
                        helperText={errors?.hostedDomain?.message || " "}
                        required
                        {...field}
                      />
                    )}
                  />
                  <Button
                    disabled={!accountInfo?.customDomain || hostedDomainVerified}
                    onClick={handleVerifyVercelDomain}
                    variant="outlined"
                    color="primary"
                    title="Verify configuration of hosted domain">
                    Verify Hosted Domain
                  </Button>

                  <div className={styles.input_email}>
                    <Controller
                      name="domainSettings.emailLocalPart"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className={styles.input}
                          label="Email Prefix"
                          variant="outlined"
                          size="small"
                          error={
                            (isSubmitted || touchedFields?.emailLocalPart) &&
                            !!errors?.emailLocalPart
                          }
                          helperText={errors?.emailLocalPart?.message || " "}
                          {...field}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">@</InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="domainSettings.emailDomain"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className={styles.input}
                          label="Email Domain"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: emailDomainVerified && (
                              <InputAdornment position="end" title="Verified">
                                <i className={`material-icons ${styles.checkmark}`}>
                                  check
                                </i>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            (isSubmitted || touchedFields?.emailDomain) &&
                            !!errors?.emailDomain
                          }
                          helperText={errors?.emailDomain?.message || " "}
                          required
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Button
                    disabled={!accountInfo?.domain || emailDomainVerified}
                    onClick={handleVerifyEmailDomain}
                    variant="outlined"
                    color="primary"
                    title="Verify configuration of domain used in emails">
                    Verify Email Domain
                  </Button>

                  {(!emailDomainVerified || !hostedDomainVerified) && (
                    <Fragment>
                      <TextField
                        className={styles.input}
                        label="Email Recipient"
                        variant="outlined"
                        size="small"
                        title="If no recipient is specified, the main account admin will be used"
                        onChange={handleEmailRecipientChange}
                      />

                      <Button
                        onClick={sendBrandedDomainEmail}
                        variant="outlined"
                        color="primary"
                        title="Send an email to the account admin with domain setup instructions">
                        Email Instructions
                      </Button>
                    </Fragment>
                  )}
                </div>
              )}

              <FormControlLabel
                key="Not Included"
                value="false"
                control={<Radio />}
                label="Not Included"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </div>
  );
};

BrandedDomain.propTypes = {
  accountInfo: PropTypes.object,
  onSnackbar: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  isActive: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    hostedDomain: PropTypes.shape({ message: PropTypes.string }),
    emailLocalPart: PropTypes.shape({ message: PropTypes.string }),
    emailDomain: PropTypes.shape({ message: PropTypes.string }),
    emailRecipient: PropTypes.shape({ message: PropTypes.string }),
  }),
  touchedFields: PropTypes.shape({
    hostedDomain: PropTypes.bool,
    emailDomain: PropTypes.bool,
    emailLocalPart: PropTypes.bool,
    emailRecipient: PropTypes.string,
  }),
};

export default BrandedDomain;
