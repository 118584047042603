import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@mui/material";
import GetListSelectList from "../../util/getListSelectList";
import GetFormSelectFormData from "../../util/getFormSelectFormData";

export default function FormConditions(props) {
  let { current, actionIndex, conditionId, actions } = props;
  let { metric } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "forms") handleListChange("forms", [], "includes");
    if (metric === "formCampuses") handleListChange("formCampuses", [], "includes");
    if (metric === "campuses") handleListChange("campuses", [], "includes");
    if (metric === "formFields")
      handleListChange("formFields", [], "formFieldValueEquals");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let handleFormChange = (value) => {
    let tempActions = { ...actions };
    let id = value ? value.id : "";
    let label = value ? value.label : "";
    tempActions.list[actionIndex].conditions[conditionId].formId = id;
    tempActions.list[actionIndex].conditions[conditionId].formLabel = label;
    tempActions.list[actionIndex].conditions[conditionId].formFieldId = "";
    tempActions.list[actionIndex].conditions[conditionId].formFieldLabel = "";
    tempActions.list[actionIndex].conditions[conditionId].formFieldType = "";
    delete tempActions.list[actionIndex].conditions[conditionId].formFieldValue;
    props.setActions(tempActions);
  };

  let handleFormFieldChange = (value) => {
    let tempActions = { ...actions };
    let id = value ? value.id : "";
    let label = value ? value.label : "";
    let fieldType = value ? value.fieldType : null;
    tempActions.list[actionIndex].conditions[conditionId].formFieldId = id;
    tempActions.list[actionIndex].conditions[conditionId].formFieldLabel = label;
    tempActions.list[actionIndex].conditions[conditionId].formFieldType = fieldType;
    delete tempActions.list[actionIndex].conditions[conditionId].formFieldValue;

    //let allowedFieldTypes = ["checkbox", "workflow_checkbox", "checkboxes", "workflow_checkboxes", "dropdown", "workflow_dropdown", "boolean" ]
    //each form type will have differnt method. number could be greaterEqual, dropdown could be includes, etc.
    //equal is default value, but planning center api is weird in that it doesn't return true or false, it only returns a value
    //therefore we just check if sometimes exist
    let existTypes = ["checkbox", "workflow_checkbox", "boolean"];
    let formFieldValueMetric = "includes";
    if (existTypes.includes(fieldType)) formFieldValueMetric = "exist";
    tempActions.list[actionIndex].conditions[conditionId].formFieldValueMetric =
      formFieldValueMetric;

    props.setActions(tempActions);
  };

  //need do tEST

  /*
    let handleFormFieldValueChange = (value) => {

      let tempActions = {...actions}
      let id = value ? value.id : ""
      let label = value ? value.label : ""
      tempActions.list[actionIndex].conditions[conditionId].formFieldValueId = id
      tempActions.list[actionIndex].conditions[conditionId].formFieldValueLabel = label
      props.setActions(tempActions)

    }
    */

  let handleFormFieldValueChange = (newItems) => {
    let formFieldValue = {};
    newItems.forEach(({ id, label }) => {
      formFieldValue[id] = { id, label };
    });

    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId].formFieldValue = formFieldValue;
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  let booleanOptions = [
    { id: "", label: "", disabled: true },
    { id: "Is selected", label: "Is selected" },
    { id: "Is not selected", label: "Is not selected" },
  ];
  //{metric === "formFields" && <GetFormSelectFormData handleFormChange={handleFormChange} handleFormFieldChange={handleFormFieldChange} handleFormFieldValueChange={handleFormFieldValueChange} currentCondition={currentCondition} source="planningCenter" booleanOptions={booleanOptions} /> }
  //<option value="formFields">A form response equals</option>

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1 }}>
        <FormControl
          style={{ width: "365px", paddingRight: "15px", margin: "5px 0px" }}
          variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            label="Select Condition"
            labelId="conditionType"
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <option value="forms">Any of these forms are submitted</option>
            <option value="formCampuses">The forms primary campus is any of these</option>
            <option value="campuses">The persons primary campus is any of these</option>
            <option value="formFields">
              A form is submitted with a specific response
            </option>
          </Select>
        </FormControl>

        {metric === "forms" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "planningCenter", event: "getForms" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("forms", value, "includes")}
            label="Forms"
            width="50%"
          />
        )}
        {metric === "formCampuses" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "planningCenter", event: "getCampuses" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("formCampuses", value, "includes")}
            label="Campuses"
            width="50%"
          />
        )}
        {metric === "campuses" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{ source: "planningCenter", event: "getCampuses" }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("campuses", value, "includes")}
            label="Campuses"
            width="50%"
          />
        )}
        {metric === "formFields" && (
          <GetFormSelectFormData
            handleFormChange={handleFormChange}
            handleFormFieldChange={handleFormFieldChange}
            handleFormFieldValueChange={handleFormFieldValueChange}
            currentCondition={currentCondition}
            source="planningCenter"
            booleanOptions={booleanOptions}
            accountId={props.accountId}
          />
        )}
      </div>

      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
