import React, { useState } from "react";
import { Button, DialogActions, Dialog, DialogContent, IconButton } from "@mui/material";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { updateAccountIntegrations } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function DeAuthenticateDialog(props) {
  let [state, setState] = useState({});
  let [dialogOpen, setDialogOpen] = useState(false);

  const fullScreen = useMobileDialog();

  let disconnectNow = async () => {
    try {
      //first get allactive subs
      console.log(props);

      setState({ buttonState: "loading" });

      const updates = [
        {
          path: "endpoint/graduwayEngage/connectionInfo",
          value: null,
        },
        {
          path: "auth/graduwayEngage",
          value: null,
        },
      ];

      await updateAccountIntegrations(updates);

      window.location = `/a/${props.accountInfo.id}/integrations/graduwayEngage`;
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let { errorMessage } = state;

  return (
    <>
      <Button variant="text" onClick={() => setDialogOpen(true)}>
        <span style={{ textTransform: "capitalize", textDecoration: "underline" }}>
          Disconnect
        </span>
      </Button>

      {dialogOpen && (
        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="edit task dialog">
          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "18px" }}>Disconnect From Graduway</span>
              </div>
              <div style={{ width: "48px" }}>
                <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
            </div>

            <p style={{ padding: "20px 0px" }}>
              Are you sure you want to disconnect Gratavid and Graduway? You will lose all
              current integrations and have to login again to reconnect.
            </p>

            {errorMessage && (
              <div className="col-12 errorMessageBox">
                <b>{errorMessage}</b>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
            <Button variant="outlined" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={state.buttonState}
              savedLabel={"Saved"}
              label={"Disconnect Now"}
              clickedRegularButton={disconnectNow}
              clickedSavedButton={() => {
                setState({});
              }}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
