import React, { useState, useEffect, useMemo } from "react";
import styles from "./roles.module.css"; // use same styling as roles
import {
  getSuperAdmins,
  addSuperAdmin,
  deleteSuperAdmin,
} from "services/adminApi.service.js";

import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  TextField,
} from "@mui/material";
import Button from "components/util/button/button";
import SortableTableHead from "components/util/sortableTableHead/sortableTableHead";
import { sort, filterSearch } from "util/arrays";
import InputSearch from "components/util/inputSearch/inputSearch";

function initials(name = "") {
  if (!name) {
    return "";
  }
  const names = name.split(" ");
  return names[0][0] + (names[1]?.[0] ?? "");
}

export default function SuperAdmins() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [toDemoteUser, setToDemoteUser] = useState({});
  const [promoteModal, setPromoteModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong");

  useEffect(() => {
    getSuperAdmins().then(setUsers);
  }, []);

  const filteredUsers = useMemo(() => {
    return filterSearch(users, undefined, ["fullName", "email"], search);
  }, [users, search]);

  function handleUserEmail(e) {
    setUserEmail(e.target.value.trim());
  }

  async function promoteUser() {
    try {
      const response = await addSuperAdmin(userEmail);
      setPromoteModal(false);
      if (!users.find((user) => user.id === response.id)) {
        setUsers((users) => [...users, response]);
      }
    } catch (err) {
      console.log(err);
      if (err.status === 403 || err.status === 404) {
        return showErrorToast(err.data.message);
      }
      showErrorToast();
    }
  }

  async function demoteUser() {
    try {
      await deleteSuperAdmin(toDemoteUser.id);

      const index = users.findIndex((user) => user.id === toDemoteUser.id);
      users.splice(index, 1);
      setUsers([...users]);
    } catch {
      showErrorToast();
    }
    setToDemoteUser({});
  }

  function sortUsers(column, direction) {
    setUsers(sort(users, column, direction));
  }

  function showErrorToast(message = "Something went wrong") {
    setErrorMessage(message);
    setShowError(true);
  }

  return (
    <Paper elevation={3} className={styles.card}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>Manage Super Admins</h2>
        </div>

        <div className={styles.tools}>
          <InputSearch onSearch={setSearch} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPromoteModal(true)}>
            <i className="material-icons">add</i>
            Add Super Admin
          </Button>
        </div>
      </div>

      <Table size="small" className={styles.fixedTable}>
        <SortableTableHead
          columns={[
            { value: "avatar" },
            { name: "Name", value: "fullName" },
            { name: "Email", value: "email" },
            { value: "actions", sortable: false },
          ]}
          onClick={sortUsers}
        />
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user.id} className={styles.tableRow}>
              <TableCell>
                <Avatar className={styles.avatar}>{initials(user.fullName)}</Avatar>
              </TableCell>
              <TableCell>{user.fullName}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell align="right" className={styles.actionButtons}>
                <IconButton aria-label="delete" onClick={() => setToDemoteUser(user)}>
                  <i className="material-icons">delete</i>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={styles.footer}>
        Showing {filteredUsers.length} of {users.length} Super Admins
      </div>

      <Dialog maxWidth="md" open={promoteModal} onClose={() => setPromoteModal(false)}>
        <DialogTitle>Add Super Admin</DialogTitle>
        <DialogContent className={styles.dialog}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth={true}
            label="User Email"
            required
            name="email"
            value={userEmail}
            onChange={handleUserEmail}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={() => setPromoteModal(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={promoteUser}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="md" open={!!toDemoteUser.id} onClose={() => setToDemoteUser({})}>
        <DialogTitle>Remove Super Admin</DialogTitle>
        <DialogContent>
          Remove super admin role for {toDemoteUser.fullName}?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => setToDemoteUser({})}>
            No
          </Button>
          <Button variant="contained" color="secondary" onClick={demoteUser}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={() => setShowError(false)}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Paper>
  );
}

SuperAdmins.propTypes = {};
