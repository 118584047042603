import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import {
  DialogActions,
  DialogContent,
  Dialog,
  IconButton,
  InputBase,
  Checkbox,
  ButtonBase,
} from "@mui/material";
import SelectTable from "../../../../../util/search/table/selectTable";
import { getUserTaskCount, remindUsers } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

function SearchItem(props) {
  const selectedItems = props.selectedItems || [];

  let checked = selectedItems.some((user) => user.id === props.current.id) ? true : false;
  let externalUser = !props.current.uploadToken;

  return (
    <ButtonBase
      className="col-12 dialogSearchItem"
      style={{ display: "flex", padding: "10px 5px", alignItems: "center" }}
      onClick={() => props.selectItem(props.current)}>
      <div style={{ width: "40px" }} className="align-items-center">
        <Checkbox
          checked={checked}
          onClick={() => props.selectItem(props.current)}
          size="small"
          style={{ padding: "5px" }}
        />
      </div>

      <div className="row" style={{ flex: 1, overflow: "hidden", textAlign: "start" }}>
        <div className="col-12 align-items-center" style={{ overflow: "hidden" }}>
          <span style={{ maxHeight: "18px" }}>
            {props.current.fullName || props.current.email}
          </span>
          {externalUser && (
            <div
              style={{
                color: "#636363",
                fontSize: "12px",
                background: "#ebebeb",
                borderRadius: "2px",
                padding: "5px 8px",
                marginLeft: "5px",
              }}>
              External
            </div>
          )}
        </div>
        <div className="col-12">
          <span style={{ color: "grey", fontSize: "12px" }}>
            {props.current.taskCount} assigned{" "}
            {props.current.taskCount === 1 ? "task" : "tasks"}
          </span>
        </div>
      </div>

      <div style={{ width: "100px" }}>
        <a href={props.current.assigneeLink} target="_blank" rel="noopener noreferrer">
          <span style={{ fontSize: "12px" }}>
            Assignee link{" "}
            <i className="material-icons" style={{ fontSize: "12px" }}>
              open_in_new
            </i>
          </span>
        </a>
      </div>
    </ButtonBase>
  );
}

SearchItem.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  current: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired,
};

export default function NotifyUsers(props) {
  let [state, setState] = useState({});
  let [selectedItems, setSelectedItems] = useState([]);
  let [searchTerm, setSearchTerm] = useState("");
  let [searchUsers, setSearchUsers] = useState("loading");
  let [users, setUsers] = useState("loading");

  const fullScreen = useMobileDialog();

  useEffect(() => {
    let getData = async () => {
      try {
        //get every tasks, and group based on assignee
        const data = await getUserTaskCount();

        setUsers(data);
        setSearchUsers(data);
      } catch (err) {
        console.error(err.message);
        setState({ errorMessage: err.message });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchUsers(users);
      return;
    }

    const lowerCaseTerm = searchTerm.toLowerCase();
    setSearchUsers(
      users.filter(
        (user) =>
          user.fullName.toLowerCase().includes(lowerCaseTerm) ||
          user.email.toLowerCase().includes(lowerCaseTerm)
      )
    );
  }, [searchTerm, users]);

  let notifyNow = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });
      selectedItems.forEach((selectedItem) => {
        selectedItem.type = selectedItem.roles?.find(
          (role) => role.accountId === props.accountInfo.id
        )?.userType;
      });
      await remindUsers(selectedItems);

      setState({ buttonState: "saved", notificationsSent: selectedItems.length });
      props.setSelectedItems([]);
      props.setAllSelected(false);
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let selectItem = (user) => {
    if (selectedItems.some((selected) => selected.id === user.id)) {
      setSelectedItems(selectedItems.filter((selected) => selected.id !== user.id));
    } else {
      setSelectedItems([...selectedItems, user]);
    }
  };

  let selectAll = () => {
    setSelectedItems(users);
  };

  let unSelectAll = () => {
    setSelectedItems([]);
  };

  let buttonLabel =
    selectedItems.length === 1
      ? "Notify 1 User"
      : "Notify " + selectedItems.length + " Users";
  let tableActions = [];
  let tableHeader = [{ label: "ASSIGNEES", style: { flex: 1 } }];

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="assign task to a user dialog">
      <DialogContent style={{ minHeight: "500px", width: fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Notify Assignees</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div
          style={{
            marginTop: "5px",
            flex: 1,
            display: "flex",
            backgroundColor: "hsl(220, 12%, 95%)",
            padding: "12px 6px",
            borderRadius: "3px",
          }}>
          <div style={{ width: "30px" }} className="align-items-center">
            <i className="material-icons" style={{ color: "grey" }}>
              search
            </i>
          </div>
          <InputBase
            value={searchTerm}
            style={{ flex: 1 }}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search assignees"
          />
        </div>

        <div className="col-12 row" style={{ padding: "5px 0px 20px 0px" }}>
          {searchUsers === "loading" ? (
            <>
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
              <div
                style={{ width: "60%", height: "20px", marginTop: "12px" }}
                className="col-7 loading-pulse"
              />
            </>
          ) : (
            <div style={{ marginTop: "5px" }} className="row col-12">
              <SelectTable
                items={searchUsers}
                selectAll={selectAll}
                unSelectAll={unSelectAll}
                selectedItems={selectedItems}
                selectItem={selectItem}
                ItemComponent={SearchItem}
                load100More={null}
                tableActions={tableActions}
                tableHeader={tableHeader}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          type="button"
          disabled={selectedItems.length === 0}
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={state.notificationsSent + " User(s) Notified"}
          label={buttonLabel}
          clickedRegularButton={notifyNow}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}

NotifyUsers.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gratavidUploadToken: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  setAllSelected: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
