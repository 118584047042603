import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, FormControlLabel, Checkbox, TextField } from "@mui/material";
import LoadingButtonCheckProductLimits from "../../../util/buttons/productLimits/loadingbutton";
import SelectTags from "../../../util/tags/selectTags";
import { createVideos, requestVideos } from "services/api.service";

export default function ConfirmAndAdd(props) {
  let { properties } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonState, setButtonState] = useState("regular");
  const [shareViaEmail, setShareViaEmail] = useState(true);
  const [senderName, setSenderName] = useState(props.accountInfo.name || "Gratavid");
  const [emailSubject, setEmailSubject] = useState(
    `${props.accountInfo.name} is requesting you to record a video`
  );
  const [emailBody, setEmailBody] = useState(
    `Hello %recorderName%,\n\nI need your help recording a video. Click record now below to learn more.\n\n%uploadLink%\n\nThank you!\n-${props.accountInfo.name}`
  );
  const [shareViaText, setShareViaText] = useState(false);
  const [selectedFoldersArray, setSelectedFoldersArray] = useState([]);

  const confirmAndUploadList = async () => {
    try {
      if (!emailBody.includes("%uploadLink%")) {
        throw new Error("Email body must include %uploadLink%");
      }

      if (properties.speakerName.mapped !== true) {
        throw new Error(
          "Must include recorder name in csv. Make sure to map the column on the right side of step 3."
        );
      }

      if (properties.title.mapped !== true) {
        throw new Error(
          "Must include title in csv. Make sure to map the column on the right side of step 3."
        );
      }

      if (properties.directions.mapped !== true) {
        throw new Error(
          "Must include directions in csv. Make sure to map the column on the right side of step 3."
        );
      }

      if (shareViaEmail && properties.requestEmail.mapped !== true) {
        throw new Error(
          "Email must be mapped if 'Send request via email' is selected in step 4. Make sure to map the column on the right side of step 3."
        );
      }

      if (shareViaText && properties.requestCell.mapped !== true) {
        throw new Error(
          "Cell number must be mapped if 'Send request via text' is selected in step 4. Make sure to map the column on the right side of step 3."
        );
      }

      if (!props.csvData?.rows?.length) {
        throw new Error("No videos to upload.");
      }

      setButtonState("loading");
      const foldersFilter = selectedFoldersArray.map(({ id }) => id);
      const videosToInsert = props.csvData.rows.map((row, index) => {
        if (
          !row[properties.speakerName.columnHeader] ||
          !row[properties.title.columnHeader] ||
          !row[properties.directions.columnHeader] ||
          (shareViaEmail ? !row[properties.requestEmail.columnHeader] : false) ||
          (shareViaText ? !row[properties.requestCell.columnHeader] : false)
        ) {
          throw new Error(
            `Row ${
              index + 2
            } is missing speaker name, title, directions, email, or phone number`
          );
        }

        const video = { foldersFilter };
        Object.values(props.properties)
          .filter(({ id }) => id !== "videoEmailSubject")
          .forEach(({ id, columnHeader }) => {
            video[id] = row[columnHeader];
          });
        video.uploadType = "request";
        return video;
      });
      const videoIds = await createVideos(videosToInsert);
      await requestVideos(
        videoIds.map(({ id }, index) => {
          const video = {
            videoId: id,
            shareViaEmail,
            shareViaText,
            emailSubject:
              props.csvData.rows?.[index]?.[properties.videoEmailSubject.columnHeader] ||
              emailSubject,
            emailBody,
            senderName,
          };

          return video;
        })
      );

      props.setCsvData({
        ...props.csvData,
        rows: videoIds.map(({ id }, index) => {
          if (!props.csvData.rows?.[index]) {
            return { videoIds: id, email: "", comments: "", firstName: "", lastName: "" };
          }

          return {
            email: "",
            comments: "",
            firstName: "",
            lastName: "",
            ...props.csvData.rows?.[index],
            videoIds: id,
          };
        }),
      });
      props.setActiveStep(2);
    } catch (err) {
      setErrorMessage(err?.data?.message ?? err.message);
    } finally {
      setButtonState("regular");
    }
  };

  return (
    <>
      <div className="col-12">
        <h2 style={{ marginTop: "25px" }}>4) Send request</h2>
        <div className="col-12 row" style={{ padding: "10px 10px 0px 13px" }}>
          <div className="col-12">
            <FormControlLabel
              style={{ height: "32px" }}
              control={
                <Checkbox
                  checked={shareViaEmail}
                  onChange={(e) => setShareViaEmail(e.target.checked)}
                  name="shareViaText"
                />
              }
              label="Send request via email"
            />
          </div>
          {shareViaEmail && (
            <>
              <TextField
                label="Sender Name"
                id="senderName"
                variant="outlined"
                style={{ marginTop: "15px" }}
                onChange={(e) => setSenderName(e.target.value)}
                fullWidth
                value={senderName}
              />

              <TextField
                label="Default Email Subject"
                id="emailSubject"
                variant="outlined"
                style={{ marginTop: "20px" }}
                helperText="The email subject Gratavid will use if a subject was not provided for the individual request (in the spreadsheet)"
                onChange={(e) => setEmailSubject(e.target.value)}
                fullWidth
                value={emailSubject}
              />

              <TextField
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
                multiline={true}
                label="Email Body"
                variant="outlined"
                fullWidth={true}
                style={{ margin: "20px 0px 20px" }}
              />
            </>
          )}
          <div className="col-12">
            <FormControlLabel
              style={{ height: "32px" }}
              control={
                <Checkbox
                  checked={shareViaText}
                  onChange={(e) => setShareViaText(e.target.checked)}
                  name="shareViaText"
                />
              }
              label="Send request via text. Recorder has explicitly agreed to receive text messages from my organization"
            />
          </div>
        </div>
      </div>

      <div className="col-12" style={{ padding: "20px 0px 10px 0px" }}>
        <h2>5) Add a tag to these videos</h2>
        <div style={{ marginTop: "20px" }}>
          <SelectTags
            accountId={props.accountInfo.id}
            selectedFoldersArray={selectedFoldersArray}
            setSelectedFoldersArray={setSelectedFoldersArray}
            indexName="videos"
          />
        </div>
      </div>

      <div className="col-12" style={{ height: "70px" }} />
      <div className="bulkUploadActionBar">
        <div style={{ flex: 1 }} className="justify-content-end">
          {errorMessage && (
            <div className="bulkUploadActionBarErrorMessageBox">
              <i
                className="material-icons"
                style={{ marginRight: "5px", color: "darkorange" }}>
                warning
              </i>
              <b>{errorMessage}</b>
            </div>
          )}
        </div>
        <div className="justify-content-end" style={{ width: "240px" }}>
          {buttonState === "regular" && (
            <Button
              variant="outlined"
              onClick={() => props.setActiveStep(0)}
              style={{ marginRight: "20px" }}>
              Back
            </Button>
          )}

          <LoadingButtonCheckProductLimits
            countToAdd={props.csvData.rows.length}
            productKey="videos"
            productLabel="video"
            userIsAdmin={props.userIsAdmin}
            variant="contained"
            color="primary"
            buttonState={buttonState}
            savedLabel="Success"
            label="Click to send"
            clickedRegularButton={confirmAndUploadList}
            clickedSavedButton={() => () =>
              (window.location = `/a/${props.accountInfo.id}/videos`)}
            showErrorAsTooltip={true}
            accountInfo={props.accountInfo}
          />
        </div>
      </div>
      <div className="col-12" style={{ height: "70px" }} />
    </>
  );
}

ConfirmAndAdd.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setProperties: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
  csvData: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
  }).isRequired,
  setCsvData: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool,
};
