import React, { useState } from "react";
import PropTypes from "prop-types";
import SortVids from "../../../videos/sortVids/sort/sortVids";
import AddVideo from "../../../videos/sortVids/addVideo/addVideo";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";

export default function Videos(props) {
  let [addState, setAddState] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const videoIds = props.noteVideos.map((nv) => nv.videoId);

  let saveVideos = async (noteVideos) => {
    props.saveNoteState("noteVideos", noteVideos);
  };

  const removeVideo = (noteVideo) => {
    const noteVideos = props.noteVideos.filter((nv) => nv.videoId !== noteVideo.videoId);
    props.saveNoteState("noteVideos", noteVideos);
  };

  const videoAdded = (video) => {
    const noteVideos = [...props.noteVideos];
    const existingIndex = noteVideos.findIndex((nv) => nv.videoId === video.id);

    if (existingIndex >= 0) {
      noteVideos.splice(existingIndex, 1);
    } else {
      noteVideos.push({
        id: video.id,
        videoId: video.id,
        video,
      });
    }
    props.saveNoteState("noteVideos", noteVideos);
  };

  return (
    <div className="row col-12 adSectionBox pagePadding" style={{ marginTop: "10px" }}>
      <div className="col-12 row">
        <h2>Videos</h2>
      </div>

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}

      {props.taskId && (
        <div className="col-12" style={{ marginTop: "15px" }}>
          <CheckBoxToApi
            initialValue={props.appendNoteVids}
            field="appendNoteVids"
            apiCall={props.saveNoteState}
            label="Include note videos after the task video"
            bold={true}
          />
        </div>
      )}

      {addState === "record" ? null : (
        <div className="col-12 row" style={{ marginTop: "15px" }}>
          <SortVids
            accountInfo={props.accountInfo}
            videos={props.noteVideos}
            removeVideo={removeVideo}
            saveVideos={saveVideos}
            passedSetState={props.saveNoteState}
          />
        </div>
      )}

      <div className="col-12 row justify-content-center" style={{ marginTop: "15px" }}>
        <AddVideo
          {...props}
          accountId={props.accountInfo.id}
          setAddState={setAddState}
          addState={addState}
          videoAdded={videoAdded}
          videos={props.noteVideos}
          videoIds={videoIds}
        />
      </div>
    </div>
  );
}

Videos.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteVideos: PropTypes.array.isRequired,
  taskId: PropTypes.string,
  saveNoteState: PropTypes.func.isRequired,
  appendNoteVids: PropTypes.bool,
};
