import React from "react";
import { IconButton } from "@mui/material";

export default function QuestionToLink(props) {
  let { id, size = "small", desc = "this section" } = props;
  let fontSize = size === "small" ? "16px" : "20px";
  let href = "";

  if (id === "task") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid";
  } else if (id === "note") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653342062876-How-To-Send-A-Gratavid";
  } else if (id === "birthday") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653584971036-How-To-Send-Birthday-Gratavids";
  } else if (id === "video") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653463948828-How-To-Collect-Request-Videos";
  } else if (id === "contacts") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653507685660-How-To-Add-Update-Contacts";
  } else if (id === "integrations") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/sections/6614351262492-Integrations";
  } else if (id === "analytics") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653626865308-Understanding-Analytics";
  } else if (id === "emailSettings") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6615680767644-Note-Template-Email-Settings";
  } else if (id === "unsubscribes") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656919806876-Managing-Unsubscribes-Text-Email-";
  } else if (id === "blackbaudIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616387050140-Blackbaud-Integration";
  } else if (id === "salesforceIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616948402076-Salesforce-Integration";
  } else if (id === "bloomerangIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616544788636-Bloomerang-Integration";
  } else if (id === "planningCenterIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6617041872412-Planning-Center-Integration";
  } else if (id === "virtuousIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6617338612380-Virtuous-Integration";
  } else if (id === "donorPerfectIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652868543772-DonorPerfect-Integration";
  } else if (id === "neonIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652989750940-Neon-One-Integration";
  } else if (id === "shopifyIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6654957509660-Shopify-Integration";
  } else if (id === "resendOptions") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6654277504028-How-to-resend-a-Gratavid";
  } else if (id === "replySettings") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6615847238940-Reply-Settings";
  } else if (id === "noteCallToAction") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6615744271644-Call-To-Action-Buttons-and-Forms";
  } else if (id === "manageUsers") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656599048476-Managing-Users";
  } else if (id === "captions") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6654011845404-Create-Closed-Captions-Translate-To-Over-100-Languages";
  } else if (id === "textCallForwarding") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6656163915164-Text-call-forwarding";
  } else if (id === "resendSettings") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6615803449884-Automatic-Resending";
  } else if (id === "taskAssignee") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6616052015004-Task-Assignees";
  } else if (id === "requestVidsBulk") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653463948828-How-To-Collect-Request-Videos";
  } else if (id === "advanceIntegrationMapVids") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652609570460-Advance-Integration";
  } else if (id === "advanceIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652609570460-Advance-Integration";
  } else if (id === "graduwayEngageIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652559506844-Graduway-Integration";
  } else if (id === "kindfulIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6652910973852-Kindful-Integration";
  } else if (id === "slateIntegration") {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/articles/6653072064924-Slate-Integration";
  } else {
    href =
      "https://graduwayhelp.zendesk.com/hc/en-us/categories/6614279424924-Gratavid-Support";
  }

  return (
    <a
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      className="align-items-center">
      <IconButton size={size} title={"Learn more about " + desc}>
        <i style={{ fontSize, color: "#8E949D" }} className="material-icons">
          help
        </i>
      </IconButton>
    </a>
  );
}
