import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { getIdToken, getSelf } from "services/api.service";

const LEGACY_ROUTES = [
  "/task",
  "/createTaskFromHyperlink",
  "/analytics",
  "/birthday",
  "/subscription",
  "/branding",
  "/accountsettings",
  "/settings",
  "/contacts",
  "/unsubscribes",
  "/integration",
  "/integrations",
  "/notes",
  "/videos/bulkupload",
  "/videos",
  "/users",
  "/editvideo",
  "/editnote",
  "/contact",
  "/admin/accounts",
  "/admin/accounts/new",
  "/admin/super-admins",
];

const LEGACY_ROUTE_CONVERSIONS = {
  "/editvideo": "/videos",
  "/editnote": "/notes",
  "/contact": "/contacts",
};

export default function PageNotFound444(props) {
  const location = useLocation();
  const navigate = useNavigate();

  if (!props.static) {
    const pathname = location.pathname;

    const legacyRoute =
      pathname === "/"
        ? "/"
        : LEGACY_ROUTES.find((baseRoute) => pathname.startsWith(baseRoute));

    if (legacyRoute) {
      getIdToken().then((authState) => {
        if (!authState) {
          navigate("/a/auth");
          return;
        }

        getSelf().then((userInfo) => {
          const userRole = userInfo.roles[0];
          const legacyRouteConversion =
            LEGACY_ROUTE_CONVERSIONS[legacyRoute] || legacyRoute;
          navigate(
            `/a/${userRole.accountId}${pathname.replace(
              legacyRoute,
              legacyRouteConversion
            )}${window.location.search}`
          );
          return;
        });
      });

      return (
        <div>
          <div style={{ padding: "20px" }}>
            <LinearProgress />
          </div>
        </div>
      );
    }
  }
  return (
    <div className="row col-12 pagePadding">
      <div className="col-12 col-md-7" style={{ padding: "30px 20px" }}>
        <h2 style={{ padding: "10px 0px", color: "#f76c6c", display: "block" }}>
          Oof, how did you get here?
        </h2>
        <h1 style={{ marginBottom: "10px" }}>Page not found</h1>
        <p>{props.errorMessage}</p>
      </div>

      <div
        className="row col-12 col-md-5 align-items-center justify-content-center"
        style={{ padding: "20px" }}>
        <img
          src="/static/img/404.svg"
          style={{ maxHeight: "200px", maxWidth: "100%" }}
          alt="Contact Us"
        />
      </div>

      <div className="row col-12 justify-content-center">
        <Button variant="contained" color="primary" component={Link} to={"/"}>
          Go Home
        </Button>
      </div>
    </div>
  );
}

PageNotFound444.propTypes = {
  errorMessage: PropTypes.string,
  static: PropTypes.bool,
};
