import React from "react";
import { ButtonBase, Radio } from "@mui/material";

export default function ContactItem(props) {
  let { currentUser = {}, selectedAssignee = {} } = props;
  let { label, assigneeLink, email = "", userUID, externalUser = false } = currentUser;

  return (
    <ButtonBase
      className="col-12 dialogSearchItem"
      style={{
        padding: "10px 0px",
        margin: "2px 0px",
        display: "flex",
        borderRadius: "3px",
      }}
      onClick={() => props.setSelectedAssignee(currentUser)}>
      <div
        style={{
          width: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Radio
          checked={selectedAssignee.userUID === userUID}
          color="secondary"
          name="radio-button-demo"
          inputProps={{ "aria-label": "E" }}
          size="small"
        />
      </div>

      {label === "All assignees" || label === "Unassigned" ? (
        <div style={{ flex: 1 }} className="align-items-center">
          <span style={{ color: "black" }}>{label}</span>
          <br />
        </div>
      ) : (
        <>
          <div
            className="row"
            style={{ flex: 1, overflow: "hidden", textAlign: "start" }}>
            <div className="col-12 align-items-center" style={{ overflow: "hidden" }}>
              <span style={{ maxHeight: "18px" }}>{label}</span>
              {externalUser && (
                <div
                  style={{
                    color: "#636363",
                    fontSize: "12px",
                    background: "#ebebeb",
                    borderRadius: "2px",
                    padding: "5px 8px",
                    marginLeft: "5px",
                  }}>
                  External
                </div>
              )}
            </div>
            <div className="col-12">
              <span style={{ color: "grey", fontSize: "12px" }}>{email}</span>
              <br />
            </div>
          </div>
          <div style={{ width: "100px" }}>
            <a href={assigneeLink} target="_blank" rel="noopener noreferrer">
              <span style={{ fontSize: "12px" }}>
                Assignee link{" "}
                <i className="material-icons" style={{ fontSize: "12px" }}>
                  open_in_new
                </i>
              </span>
            </a>
          </div>
        </>
      )}
    </ButtonBase>
  );
}
