import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { setupMessaging } from "services/firebaseInit";
import { Link } from "react-router";
import ShowNotifications from "./showNotifications";
import Badge from "@mui/material/Badge";
import { IconButton, Snackbar, Button } from "@mui/material";
import { getUserNotifications, updateSelf, updateSelfRole } from "services/api.service";

export default function Notifications(props) {
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(
    props.userRole?.unreadNotifications || false
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbarData, setSnackbarData] = useState({ open: false, title: "", href: "" });
  const [anchorEl, setAnchorEl] = useState(null);

  //setup browser notifications
  useEffect(() => {
    let getBrowserToken = async () => {
      try {
        if ("serviceWorker" in navigator) {
          //because of next hosting, we have to register service worker manually
          const workerRegistration = await navigator.serviceWorker.register(
            "/static/firebase-messaging-sw.js"
          );

          const currentToken = await setupMessaging(
            workerRegistration,
            async (payload) => {
              console.log("Message received. ", payload);
              let data = payload && payload.data;
              let { type, contactId, taskId, videoId, title = "", href = "" } = data;
              if (!type) return;
              await getNotifications(true);
              setHasUnreadNotifications(true);

              if (type === "replies") {
                title = "New Reply";
                href = `/a/${props.accountInfo.id}/contacts/` + contactId;
              } else if (type === "newTask") {
                title = "New Task";
                href = `/a/${props.accountInfo.id}/task?taskId=` + taskId;
              } else if (type === "videoRequest") {
                title = "Video Request Done";
                href = `/a/${props.accountInfo.id}/videos/` + videoId;
              } else if (type === "bounce") {
                title = "Email Bounced";
                href = `/a/${props.accountInfo.id}/contacts/` + contactId;
              } else if (type === "unsubscribe") {
                title = "Contact Unsubscribed";
                href = `/a/${props.accountInfo.id}/contacts/` + contactId;
              } else if (type === "noteWatched") {
                title = "Task Watched";
                href = `/a/${props.accountInfo.id}/contacts/` + contactId;
              }

              setSnackbarData({ title, href, open: true });
            }
          );
          const webTokens = props.userInfo?.webTokens || [];

          if (!webTokens.some((token) => token === currentToken)) {
            webTokens.push(currentToken);
            updateSelf({ webTokens });
          }
        }
      } catch (err) {
        console.error("Unable to retrieve refreshed token ", err);
      }
    };

    getBrowserToken();
    getNotifications(true);
  }, []);

  async function getNotifications(isInit) {
    try {
      setIsLoading(true);
      const data = await getUserNotifications(notifications.length, 8);
      if (isInit) {
        setNotifications(data.rows);
      } else {
        setNotifications([...notifications, ...data.rows]);
      }

      if (data.count) {
        setCount(data.count);
      }
      setErrorMessage(null);
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  let openNotifications = async (e) => {
    try {
      setAnchorEl(e);
      updateSelfRole({ unreadNotifications: false });
      setHasUnreadNotifications(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  let notificationIcon = hasUnreadNotifications ? "notifications" : "notifications_none";

  return (
    <>
      <IconButton
        style={{ padding: "8px" }}
        aria-label="notifications"
        onClick={(e) => openNotifications(e.currentTarget)}>
        <Badge badgeContent={hasUnreadNotifications ? "1+" : 0} color="secondary">
          <i className="material-icons-outlined" style={{ color: "#4d4d4d" }}>
            {notificationIcon}
          </i>
        </Badge>
      </IconButton>

      {anchorEl && (
        <ShowNotifications
          {...props}
          isLoading={isLoading}
          notifications={notifications}
          count={count}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          getMore={() => getNotifications()}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarData.open}
        autoHideDuration={6000}
        onClose={() => setSnackbarData({ open: false, title: "", href: "" })}
        message={snackbarData.title}
        action={
          <React.Fragment>
            <Button
              component={Link}
              to={snackbarData.href}
              color="secondary"
              size="small"
              onClick={() => setSnackbarData({ open: false, title: "", href: "" })}>
              View
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnackbarData({ open: false, title: "", href: "" })}>
              <i className="material-icons">close</i>
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

Notifications.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    webTokens: PropTypes.arrayOf(PropTypes.string),
  }),
  userRole: PropTypes.shape({
    notifications: PropTypes.object,
    unreadNotifications: PropTypes.bool,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
