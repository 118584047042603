import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@mui/material";
import VideoPreview from "./videoPreview";
import Trimmer from "./trim/trimVideo";
import Captions from "./captions/captions";
import BigBreadcrumb from "../../../util/breadcrumb/bigBreadcrumb";
import { useNavigate, useLocation } from "react-router";
import UploadNeeded from "./upload/uploadNeeded";
import Breadcrumb from "../../../util/breadcrumb/breadcrumb";
import { getSearchParams } from "util/helpers";

export default function ManageVideo(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = getSearchParams();
  const [tabSelected, setTabSelected] = useState(urlParams.get("tab") || "video");

  const onTabSelect = (tabValue) => {
    urlParams.set("tab", tabValue);
    navigate(
      { pathname: location.pathname, search: urlParams.toString() },
      { replace: true }
    );
    setTabSelected(tabValue);
  };

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  if (props.status === "mediaURLUploaded") {
    return (
      <div className="row col-12">
        <BigBreadcrumb
          indexName="videos"
          accountId={props.accountId}
          id={props.videoId}
          title={props.title ?? ""}
          setTitle={(value) => props.passedSetEditVideoState("title", value)}
        />

        <div
          className="col-12"
          style={{ borderBottom: "1px #ddd solid", padding: "0px 8.5px" }}>
          <Tabs
            value={tabSelected}
            onChange={(e, newValue) => onTabSelect(newValue)}
            aria-label="edit video tabs">
            <Tab label="Video" {...a11yProps(0)} value="video" />
            <Tab label="Editor" {...a11yProps(1)} value="edit" />
            <Tab label="Captions" {...a11yProps(2)} value="captions" />
          </Tabs>
        </div>

        {tabSelected === "video" && (
          <VideoPreview
            accountId={props.accountId}
            videoId={props.videoId}
            uploadType={props.uploadType}
            gif={props.gif}
            speakerName={props.speakerName}
            thumbnail={props.thumbnail}
            mediaURL={props.mediaURL}
            status={props.status}
            audioSrc={props.audioSrc}
            videoUploadStatusChanged={props.videoUploadStatusChanged}
            passedSetEditVideoState={props.passedSetEditVideoState}
          />
        )}
        {tabSelected === "edit" && (
          <Trimmer
            videoId={props.videoId}
            mediaURL={props.mediaURL}
            status={props.status}
            videoUploadStatusChanged={props.videoUploadStatusChanged}
            updateStatus={props.updateStatus}
            accountId={props.accountId}
          />
        )}
        {tabSelected === "captions" && (
          <Captions
            accountId={props.accountId}
            videoId={props.videoId}
            audioSrc={props.audioSrc}
            duration={props.duration}
            mediaURL={props.mediaURL}
            status={props.status}
            captions={props.captions}
            passedSetEditVideoState={props.passedSetEditVideoState}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="row col-12 pagePadding">
        <Breadcrumb
          accountId={props.accountId}
          indexName="videos"
          id={props.videoId}
          title={props.title}
          titleKey="title"
          passedSetState={props.passedSetEditVideoState}
        />
        <UploadNeeded
          {...props}
          recorderName={props.recorderName}
          videoId={props.videoId}
          status={props.status}
          speakerName={props.speakerName}
          videoUploadStatusChanged={props.videoUploadStatusChanged}
          deletedVideoState={props.deletedVideoState}
        />
      </div>
    );
  }
}

ManageVideo.propTypes = {
  accountId: PropTypes.string.isRequired,
  recorderName: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  uploadType: PropTypes.string.isRequired,
  gif: PropTypes.string,
  thumbnail: PropTypes.string,
  mediaURL: PropTypes.string,
  audioSrc: PropTypes.string,
  status: PropTypes.string.isRequired,
  videoUploadStatusChanged: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  passedSetEditVideoState: PropTypes.func.isRequired,
  speakerName: PropTypes.string,
  deletedVideoState: PropTypes.object,
};
