import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { LinearProgress, TextField } from "@mui/material";
import TextInputToApi from "../../../../util/hooks/input/textInputToApi";
import LoadingSwitch from "../../../../util/buttons/loadingSwitch";
import { patchAccount } from "../../../../../services/api.service.js";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import phone from "phone";

export default function TextSettings(props) {
  const { accountInfo } = props;
  const accountUID = accountInfo?.accountUID;
  const [state, setState] = useState("loading");
  const [tollNumber, setTollNumber] = useState("");
  const [textEnabled, setTextEnabled] = useState(false);
  const [callForwardingActive, setCallForwardingActive] = useState("loading");
  const [callForwardingInActiveResponse, setCallForwardingInActiveResponse] =
    useState("");
  const [callForwardingPhoneNumber, setCallForwardingPhoneNumber] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        setTollNumber(accountInfo?.appTollNum || "");
        setTextEnabled(accountInfo?.textEnabled || false);

        const tempCallForwardingInActiveResponse =
          accountInfo?.callForwardingInactiveText ||
          "Thanks for your call. Unfortunately, this number is not eligible to receive calls. Please reply via text.";
        const tempCallForwardingPhoneNumber = accountInfo?.callForwardingNumber || "";
        const tempCallForwardingActive = accountInfo?.callForwardingActive || false;
        setCallForwardingInActiveResponse(tempCallForwardingInActiveResponse);
        setCallForwardingPhoneNumber(tempCallForwardingPhoneNumber);
        setCallForwardingActive(tempCallForwardingActive);
      } catch (err) {
        console.error(err.message);
      }
    };

    getData();
  }, [
    accountInfo,
    accountInfo.callForwardingActive,
    accountInfo.callForwardingInactiveText,
    accountInfo.callForwardingNumber,
    accountUID,
  ]);

  const handleInlineEdits = (field, value) => {
    if (field === "callForwardingActive") {
      setCallForwardingActive(value);
    } else if (field === "callForwardingInactiveText") {
      setCallForwardingInActiveResponse(value);
    } else if (field === "callForwardingNumber") {
      setCallForwardingPhoneNumber(value);
    }
  };

  const onChange = async (field, value) => {
    handleInlineEdits(field, value);

    if (field === "callForwardingNumber") {
      const formatted = phone(value);
      if (!formatted.isValid) {
        throw new Error("Invalid phone number");
      }
      value = formatted.phoneNumber;
    }

    try {
      await patchAccount({ [field]: value });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  if (callForwardingActive === "loading") {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  if (!textEnabled) {
    return (
      <div className="col-12" style={{ marginTop: "20px" }}>
        <p>
          Your plan does not include texting or payment has not been received.{" "}
          <a
            href="https://graduwayhelp.zendesk.com/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer">
            Submit a request/ticket to upgrade your plan.
          </a>
        </p>
      </div>
    );
  }

  return (
    <div
      className="row col-12 align-items-start"
      style={{ marginTop: "30px", padding: "5px" }}>
      <div className="col-12" style={{ marginTop: "25px" }}>
        <TextField
          disabled
          value={tollNumber}
          label="Your Gratavid Toll Number"
          variant="outlined"
          style={{ width: "250px" }}
        />
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center", marginTop: "35px" }}>
        <h2>Call forwarding</h2>
        <QuestionToLink id="textCallForwarding" desc="call forwarding" />
      </div>

      <div className="col-12">
        <p className="greyDesc">
          When off, Gratavid will respond to calls with an automated reply. When on,
          Gratavid will forward calls to another number you provide. Gratavid will reject
          a call if the calling number has never received a Gratavid text from you to
          prevent spam.
        </p>
      </div>

      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="col-12" style={{ marginTop: "20px" }}>
        <LoadingSwitch
          checked={callForwardingActive}
          handleSwitch={() => onChange("callForwardingActive", !callForwardingActive)}
          buttonState={state?.buttonState}
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        {callForwardingActive && (
          <TextInputToApi
            initialValue={callForwardingPhoneNumber}
            value={callForwardingPhoneNumber}
            label="Call Forwarding Phone Number"
            variant="outlined"
            field="callForwardingNumber"
            apiCall={onChange}
          />
        )}
        {!callForwardingActive && (
          <TextInputToApi
            initialValue={callForwardingInActiveResponse}
            value={callForwardingInActiveResponse}
            label="Automated Reply When Called"
            variant="outlined"
            field="callForwardingInactiveText"
            apiCall={onChange}
          />
        )}
      </div>
    </div>
  );
}

Text.propTypes = {
  accountInfo: PropTypes.shape({
    accountUID: PropTypes.string.isRequired,
    appTollNum: PropTypes.string,
    callForwardingInactiveText: PropTypes.string,
    callForwardingActive: PropTypes.bool,
    callForwardingNumber: PropTypes.string,
    textEnabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
  }),
};
