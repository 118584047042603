import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import Track from "./track";
import PhraseItem from "./phraseItem";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import { editVideo } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function EditCaptionsDialog(props) {
  let {
    currentCaption = {},
    mediaURL,
    videoId,
    dialogOpen,
    editCaptionISOLanguageCodeSelected,
    setEditCaptionISOLanguageCodeSelected,
    captions = {},
    passedSetEditVideoState,
    duration,
  } = props;
  let { phrases = null, primaryISOLanguageCode, label } = currentCaption;
  let [newPhrases, setNewPhrases] = useState(phrases);
  let [videoPlaying, setVideoPlaying] = useState(phrases);
  let [videoCurrentTime, setVideoCurrentTime] = useState(0);
  let [state, setState] = useState(false);

  const fullScreen = useMobileDialog();

  useEffect(() => {
    //make a brand new copy, otherwise any edits will update the existing phrases object
    let tempPhrases = JSON.parse(JSON.stringify(phrases));
    setNewPhrases(tempPhrases);
  }, [JSON.stringify(phrases)]);

  const videoRef = useRef(null);

  //update play currentTime
  let videoPlayTimeUpdated = (e) => {
    let time = e.target.currentTime ? e.target.currentTime : 0;
    let formatTime = Math.round(time * 10) / 10;
    setVideoCurrentTime(formatTime);
  };

  let addFirstPhrase = () => {
    let tempNewPhrases = {};
    let newPhrase = {
      phrase: "",
      st: "00:00:00.000",
      et: "00:00:03.000",
      startTime: { seconds: 0 },
      endTime: { seconds: 3 },
    };

    let newPhraseId = crypto.randomUUID();
    tempNewPhrases[newPhraseId] = newPhrase;
    setNewPhrases(tempNewPhrases);
  };

  let pausePlayVideo = async (currentStartTime) => {
    try {
      let video = videoRef.current;
      if (videoPlaying) {
        video.pause();
      } else {
        video.currentTime = currentStartTime;
        await video.play();
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const saveCaptions = async () => {
    try {
      setState({ buttonState: "loading" });
      const tempCaptions = { ...captions };
      tempCaptions.languages.list[editCaptionISOLanguageCodeSelected].phrases =
        newPhrases;
      await editVideo(props.videoId, { captions: tempCaptions });
      props.passedSetEditVideoState(tempCaptions);
      setState({ buttonState: "saved" });
    } catch (err) {
      setState({ errorMessage: err.message });
      console.error(err.message);
    }
  };

  let closeDialog = () => {
    setState({});
    setEditCaptionISOLanguageCodeSelected("");
  };

  let phrasesKeys = newPhrases ? Object.keys(newPhrases) : [];

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="xl"
      open={dialogOpen}
      onClose={(evt, reason) => (reason === "backdropClick" ? false : closeDialog(evt))}
      aria-labelledby="edit captions dialog"
      disableEnforceFocus={true}>
      <DialogContent style={{ padding: "0px" }}>
        <div className="row col-12">
          <div className="row col-12 col-lg-5" style={{ padding: "20px" }}>
            <div className="col-12">
              <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                Edit {label} Captions
              </h2>
            </div>
            <div className="col-12" style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: "10px",
                  width: "75px",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {videoCurrentTime}
                </span>
              </div>
              <video
                src={mediaURL}
                ref={videoRef}
                width="100%"
                controls
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "400px",
                  backgroundColor: "#ddd",
                  borderRadius: "10px",
                }}
                playsInline
                onPlay={() => setVideoPlaying(true)}
                onPause={() => setVideoPlaying(false)}
                onTimeUpdate={videoPlayTimeUpdated}>
                <Track
                  newPhrases={newPhrases}
                  primaryISOLanguageCode={primaryISOLanguageCode}
                  label={label}
                />
              </video>
            </div>
          </div>

          <div
            className="col-12 col-lg-7"
            style={{
              backgroundColor: "#f5f7f8",
              height: "70vh",
              borderLeft: "1px solid #dddfe2",
            }}>
            <div style={{ position: "relative", height: "100%", width: "100%" }}>
              <div style={{ overflowY: "scroll", height: "100%", width: "100%" }}>
                <div style={{ height: "25px" }} />

                {phrasesKeys.map((phraseId, index) => {
                  return (
                    <PhraseItem
                      key={phraseId}
                      phraseId={phraseId}
                      videoId={videoId}
                      newPhrases={newPhrases}
                      setNewPhrases={setNewPhrases}
                      phrasesKeys={phrasesKeys}
                      arrayIndex={index}
                      captions={captions}
                      passedSetEditVideoState={passedSetEditVideoState}
                      editCaptionISOLanguageCodeSelected={
                        editCaptionISOLanguageCodeSelected
                      }
                      duration={duration}
                      videoPlaying={videoPlaying}
                      pausePlayVideo={pausePlayVideo}
                      videoCurrentTime={videoCurrentTime}
                    />
                  );
                })}

                <div style={{ height: "25px" }} />

                {phrasesKeys.length === 0 && duration >= 4 && (
                  <div
                    style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => addFirstPhrase()} variant="outlined">
                      +Add first row
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions style={{ padding: "16px", borderTop: "1px #ddd solid" }}>
        {!state.buttonState && (
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
        )}
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Saved!"
          label="Save Captions"
          clickedRegularButton={saveCaptions}
          clickedSavedButton={closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}

EditCaptionsDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  currentCaption: PropTypes.object,
  captions: PropTypes.shape({
    languages: PropTypes.shape({
      list: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  mediaURL: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  editCaptionISOLanguageCodeSelected: PropTypes.string.isRequired,
  setEditCaptionISOLanguageCodeSelected: PropTypes.func.isRequired,
  passedSetEditVideoState: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool,
};
