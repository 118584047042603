import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SelectTags from "components/util/tags/selectTags";
import Button from "@mui/material/Button";
import LoadingButtonCheckProductLimits from "../../../util/buttons/productLimits/loadingbutton";
import ListContactInputs from "../../../util/contacts/listContactInputs";
import { isValidEmail } from "util/helpers";
import { getAccountFolders, createContact } from "services/api.service";
import { consolidateCustomProperties } from "util/contacts";

export default function AddSingleContact(props) {
  let [state, setState] = useState({});
  let [selectedFoldersArray, setSelectedFoldersArray] = useState([]);
  let [contactData, setContactData] = useState({});
  let [emailAutoSuggestInputKey, setEmailAutoSuggestInputKey] = useState(1);

  let addContact = async (e) => {
    if (e) e.preventDefault();

    try {
      if (contactData.contact) {
        //remove spaces from email
        let { email = "" } = contactData.contact;
        email = email.replace(/ /g, "");
        if (!isValidEmail(email)) throw new Error("Provide valid email address");

        setState({ buttonState: "loading" });

        let newContactData = {};
        Object.entries(contactData.contact).forEach(([key, value]) => {
          if (value) {
            newContactData[key] = value;
          }
        });

        newContactData = consolidateCustomProperties(props.accountInfo, newContactData);
        let folderIds = selectedFoldersArray.map(({ id }) => id);
        if (folderIds.length > 0) {
          newContactData.folderIds = folderIds;
        }

        const newContact = await createContact(newContactData);

        let contacts = props.contacts ? props.contacts : {};
        contacts[newContact.id] = newContact;

        setState({ buttonState: "saved" });

        if (props.calledFromNotes) {
          newContactData.type = "contact";
          props.addContactOrFolder("contact", newContact);
        } else {
          props.setSearchList(contacts);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "gv.addContact" });
      }
    } catch (err) {
      let errorMessage = err?.data?.message ? err.data.message : err.message;
      console.error(errorMessage);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  let addAnotherContact = (e) => {
    if (e) e.preventDefault();

    let tempContactData = {};
    let contactDataKeys = contactData ? Object.keys(contactData) : [];
    contactDataKeys.forEach((id) => {
      tempContactData[id] = "";
    });

    setSelectedFoldersArray([]);
    setContactData(tempContactData);

    //reset email suggestion
    setEmailAutoSuggestInputKey(Date.now());

    setState({ buttonState: "regular" });
  };

  //listen if contact is selected, then grab their current folders
  useEffect(() => {
    let setFolders = async () => {
      try {
        if (contactData?.contact?.contactFolders) {
          const { contactFolders = [] } = contactData.contact;
          const contactFolderIds = contactFolders.map(({ folderId }) => folderId);

          //the contact foldersFilter just contains the id, but we need to get label from firebase
          let accountFolders = await getAccountFolders("contact");

          const folders = [];
          if (contactFolders?.length) {
            for (const accountFolder of accountFolders) {
              if (contactFolderIds.includes(accountFolder.id)) {
                folders.push({
                  ...accountFolder,
                  label: accountFolder.name,
                  selected: true,
                });
              }
            }
          }
          setSelectedFoldersArray(folders);
          delete contactData.contact.contactFolders;
          setContactData(contactData);
        }
      } catch (err) {
        console.err(err.message);
      }
    };

    setFolders();
  }, [contactData]);

  let { errorMessage, buttonState } = state;
  //<LoadingButton variant='contained' color='primary' type="submit"  buttonState={buttonState} savedLabel={'Add another'} label={'Save Contact'} clickedRegularButton={ (e)=> addContact(e) } clickedSavedButton={ (e)=> addAnotherContact(e) } />

  return (
    <div className="row col-12">
      <div
        className="col-12"
        style={{ height: 425, overflowY: "scroll", padding: "0px 20px 20px 20px" }}>
        <ListContactInputs
          contactData={contactData}
          setContactData={setContactData}
          accountInfo={props.accountInfo}
          customContactProperties={props.accountInfo.customContactProperties}
          contactId={null}
          variant="standard"
          emailAutoSuggestInputKey={emailAutoSuggestInputKey}
        />

        {contactData?.contact?.email && (
          <div className="col-12" style={{ margin: "15px 0px" }}>
            <SelectTags
              accountId={props.accountInfo.id}
              selectedFoldersArray={selectedFoldersArray}
              setSelectedFoldersArray={setSelectedFoldersArray}
              indexName="contact"
            />
          </div>
        )}
      </div>

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}

      <div
        className="col-12 justify-content-center"
        style={{
          padding: "20px 12px",
          display: "flex",
          borderTop: "1px hsl(220, 12%, 95%) solid",
        }}>
        {buttonState === "saved" && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.setAddNewDialogOpen(false);
            }}
            style={{ marginRight: 15 }}>
            I&apos;m Done
          </Button>
        )}

        <LoadingButtonCheckProductLimits
          accountInfo={props.accountInfo}
          productKey="contacts"
          productLabel="contact"
          countToAdd={1}
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Add another"
          label="Add Contact"
          clickedRegularButton={addContact}
          clickedSavedButton={addAnotherContact}
          userIsAdmin={props.userIsAdmin}
        />
      </div>
    </div>
  );
}

AddSingleContact.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customContactProperties: PropTypes.object.isRequired,
  }).isRequired,
  contacts: PropTypes.object,
  calledFromNotes: PropTypes.bool,
  addContactOrFolder: PropTypes.func,
  setSearchList: PropTypes.func,
  setAddNewDialogOpen: PropTypes.func,
  userIsAdmin: PropTypes.bool,
};
