import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import { DialogActions, DialogContent, Dialog, Button, IconButton } from "@mui/material";
import { deleteTasks } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function Delete(props) {
  let [state, setState] = useState({});

  const fullScreen = useMobileDialog();

  let doWork = async (e) => {
    try {
      e.preventDefault();
      setState({ buttonState: "loading" });
      const selectedTaskIds = props.selectedItems.map((selectedItem) => selectedItem.id);
      if (selectedTaskIds.length) {
        await deleteTasks(selectedTaskIds);
        props.setEditTaskSelected(null);
        props.setTasks(props.tasks.filter((task) => !selectedTaskIds.includes(task.id)));
      }
      setState({ buttonState: "saved" });
      props.setSelectedItems([]);
      props.setAllSelected(false);
    } catch (err) {
      console.error(err.message);
      setState({
        buttonState: "regular",
        errorMessage:
          err.message +
          " Make sure you are not trying to delete a tasks that has already been sent.",
      });
    }
  };

  let { buttonState, errorMessage } = state;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete task dialog">
      <DialogContent style={{ minHeight: "500px", width: fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Delete Task</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        <div className="col-12">
          <p>Are you sure you want to delete {props.selectedCount} task(s)?</p>
          <br />
        </div>

        <div className="col-12">
          <p>
            If you are using the embed code, close this popup and make sure to select
            "Done" vs "Delete". Clicking done will keep the task but move it into the
            history section.
          </p>
        </div>
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button onClick={props.closeDialog} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel={"Deleted"}
          label={"Delete Now"}
          clickedRegularButton={doWork}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
