import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  InputBase,
} from "@mui/material";
import ContactItem from "./contactItem";
import { getAccountContacts } from "services/api.service";
import useMobileDialog from "../hooks/useMobileDialog";

export default function ShareByContactDialog(props) {
  const { title, type, dialogOpen, setDialogOpen } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState(props.taskContacts);

  const fullScreen = useMobileDialog();

  useEffect(() => {
    const searchContacts = async () => {
      let newSearchList = props.taskContacts;
      if (searchTerm) {
        const lowerCaseTerm = searchTerm.toLowerCase();
        const result = await getAccountContacts(0, 0, lowerCaseTerm, []);
        newSearchList = result.rows.map((contact) => ({
          contactId: contact.id,
          contact,
        }));
      }
      setSearchList(newSearchList);
    };
    searchContacts();
  }, [searchTerm, props.taskContacts]);

  let hideEmbedBtn = navigator.userAgent.match(/ipad|ipod|iphone/i);
  if (type === "embed" && hideEmbedBtn) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="edit task dialog">
      <DialogContent
        style={{
          height: fullScreen ? "auto" : "550px",
          width: fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>{title}</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div
          style={{
            marginTop: "5px",
            flex: 1,
            display: "flex",
            backgroundColor: "hsl(220, 12%, 95%)",
            padding: "12px 6px",
            borderRadius: "3px",
          }}>
          <div style={{ width: "30px" }} className="align-items-center">
            <i className="material-icons" style={{ color: "grey" }}>
              search
            </i>
          </div>
          <InputBase
            value={searchTerm}
            style={{ flex: 1 }}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={"Search contacts"}
          />
        </div>

        {searchTerm === "" && (
          <ContactItem key={"anonymous"} {...props} taskContact={{ contactId: null }} />
        )}

        {searchList.map((taskContact) => {
          return (
            taskContact.contact && (
              <ContactItem
                key={taskContact.contact.id}
                {...props}
                taskContact={taskContact}
              />
            )
          );
        })}
      </DialogContent>
      <DialogActions
        style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}
        onClick={() => setDialogOpen(false)}>
        <Button variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ShareByContactDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  taskContacts: PropTypes.arrayOf(
    PropTypes.shape({
      contact: PropTypes.object,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool,
  type: PropTypes.string,
};
