import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import DefaultAction from "../../../actions/action";
import AdvanceAction from "./action";
import AddAction from "../../../actions/addAction";
import { updateAccountIntegrations } from "services/api.service";
import SortableList from "components/util/sortable/SortableList";

export default function DonationCreated(props) {
  let {
    accountInfo,
    selectedSiteId,
    users = {},
    eventData = {},
    sites,
    data = {},
  } = props;
  let { actions = {}, active = false, pollDetails = {} } = eventData;
  let [open, setOpen] = useState(true);
  let [state, setState] = useState({});
  let [justTurnedOn, setJustTurnedOn] = useState(false);
  let currencyLabel =
    data && data.settings && data.settings.currencyLabel
      ? data.settings.currencyLabel
      : "$";

  let setActions = (tempActions) => {
    let tempSites = { ...sites };
    tempSites[selectedSiteId].events = tempSites[selectedSiteId].events
      ? tempSites[selectedSiteId].events
      : {};
    tempSites[selectedSiteId].events.donationCreated = tempSites[selectedSiteId].events
      .donationCreated
      ? tempSites[selectedSiteId].events.donationCreated
      : {};
    tempSites[selectedSiteId].events.donationCreated.actions = tempSites[selectedSiteId]
      .events.donationCreated.actions
      ? tempSites[selectedSiteId].events.donationCreated.actions
      : {};
    tempSites[selectedSiteId].events.donationCreated.actions = tempActions;
    props.setSites(tempSites);
  };

  let saveActions = async () => {
    try {
      setState({ buttonState: "loading" });
      const path = `endpoint/advance/sites/${selectedSiteId}/events/donationCreated`;

      await updateAccountIntegrations([{ path, value: { actions, active: true } }]);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });

      const updates = [];

      updates.push({
        path: `endpoint/advance/sites/${selectedSiteId}/events/donationCreated/active`,
        value: !!newActiveValue,
      });

      await updateAccountIntegrations(updates);

      setState({});

      let tempSites = { ...sites };
      tempSites[selectedSiteId].events = tempSites[selectedSiteId].events
        ? tempSites[selectedSiteId].events
        : {};
      tempSites[selectedSiteId].events.donationCreated = tempSites[selectedSiteId].events
        .donationCreated
        ? tempSites[selectedSiteId].events.donationCreated
        : {};
      tempSites[selectedSiteId].events.donationCreated.active = newActiveValue;

      props.setSites(tempSites);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  var currentMinutes = new Date().getMinutes();
  let runsInMinutes = null;
  if (pollDetails && pollDetails.minute) {
    runsInMinutes =
      pollDetails.minute > currentMinutes
        ? pollDetails.minute - currentMinutes
        : 60 - (currentMinutes - pollDetails.minute);
  }

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>New Donation In Advance</h2>
          <span className="greyDesc">
            Create a task or send a note when a donation is created in Advance.
          </span>
          {runsInMinutes && (
            <div className="greyDesc" style={{ fontSize: "14px" }}>
              Status ON: Runs once per hour. {justTurnedOn ? "First " : "Next "} run is in{" "}
              {runsInMinutes} minutes
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className="row col-12" style={{ cursor: "auto" }}>
        <div className="row col-12">
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <span className="circleStep">1</span>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When a new donation is added in Advance...
            </h2>
          </div>
        </div>

        <div className="col-12">
          <SortableList
            items={actions.list || []}
            onChange={(items) => setActions({ ...actions, list: items })}
            renderItem={(item, index) => (
              <SortableList.Item id={item.id}>
                <AdvanceAction
                  currentAction={item}
                  actionIndex={index}
                  order={index + 1}
                  accountInfo={accountInfo}
                  actions={actions}
                  setActions={setActions}
                  users={users}
                  selectedSiteId={selectedSiteId}
                  currencyLabel={currencyLabel}
                />
              </SortableList.Item>
            )}
          />
        </div>

        <AddAction {...props} actions={actions} setActions={setActions} />

        <DefaultAction
          {...props}
          users={users}
          defaultAction={true}
          currentAction={actions}
          actions={actions}
          setActions={setActions}
        />

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
