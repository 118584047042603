import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingCircleSVG from "../../../../../util/svg/loadingCircleSVG";
import { IconButton } from "@mui/material";
import ShareButtons from "./shareButtons";

export default function PlayButton(props) {
  const [initialState, setInitialState] = useState(true);

  function playVideoInitial() {
    setInitialState(false);
    props.playVideo();
  }

  if (!props.isReady) {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "default",
          zIndex: 900,
        }}>
        <div className="story">
          <img src={props.logo} alt=" loading logo" />
          <LoadingCircleSVG />
        </div>
      </div>
    );
  } else if (!initialState) {
    let imgSrc, buttonAction;
    if (props.isPlaying) {
      imgSrc = "https://gratavid.com/static/img/pauseButtonWhite.svg";
      buttonAction = props.pauseVideo;
    } else {
      imgSrc = "https://gratavid.com/static/img/playButtonWhite.svg";
      buttonAction = props.playVideo;
    }

    const volumeIcon = props.muted
      ? "https://gratavid.com/static/icon/volume_off-white-18dp.svg"
      : "https://gratavid.com/static/icon/volume_up-white-18dp.svg";

    return (
      <div
        style={{
          position: "absolute",
          right: 10,
          left: 10,
          height: 50,
          top: 22,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 900,
        }}>
        <div style={{ flex: 1, overflow: "hidden", whiteSpace: "nowrap" }}>
          {props.speakerName && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.4)",
                  padding: "3px 6px",
                  borderRadius: "10px",
                  alignItems: "center",
                  display: "flex",
                  maxWidth: "100%",
                }}>
                <img
                  src="https://gratavid.com/static/icon/record_voice_over-white-24dp.svg"
                  width="18px"
                  alt="video speaker"
                />
                <p
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    marginLeft: "5px",
                    fontWeight: 600,
                    textShadow: "0 1px 1px rgb(0 0 0 / 0.1)",
                    color: "white",
                  }}>
                  {props.speakerName}
                </p>
              </div>
            </div>
          )}
        </div>

        <div style={{ display: "flex" }}>
          <IconButton
            size="small"
            style={{
              width: "40px",
              WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
              filter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
              cursor: "pointer",
            }}
            onClick={buttonAction}>
            <img
              className="videoPlayPauseButton"
              src={imgSrc}
              alt="pause icon"
              height="34px"
            />
          </IconButton>
          <IconButton
            size="small"
            style={{
              width: "40px",
              WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
              filter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
              cursor: "pointer",
            }}
            onClick={() => props.setMuted(!props.muted)}>
            <img
              className="videoPlayPauseButton"
              src={volumeIcon}
              alt="mute or unmute icon"
              height="34px"
            />
          </IconButton>
        </div>

        {props.showDownload && (
          <a href={props.videoUrl} download target="_blank" rel="noopener noreferrer">
            <IconButton
              size="small"
              style={{
                width: "36px",
                WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
                filter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
                cursor: "pointer",
              }}
              aria-label="open download video page">
              <img
                className="videoPlayPauseButton"
                src="https://gratavid.com/static/icon/get_app-white-18dp.svg"
                alt="download icon"
                height="30px"
              />
            </IconButton>
          </a>
        )}

        {props.showShare && <ShareButtons {...props} />}
      </div>
    );
  }

  return (
    <div
      onClick={playVideoInitial}
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 900,
      }}>
      <div
        style={{
          height: "75px",
          color: "#fff",
          WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,.5))",
          filter: "drop-shadow(0 6px 22px rgba(0,0,0,.5))",
        }}>
        <img
          className="videoPlayPauseButton"
          src="https://gratavid.com/static/img/playButtonWhite.svg"
          alt="play icon"
          height="75px"
        />
      </div>
    </div>
  );
}

PlayButton.propTypes = {
  videoUrl: PropTypes.string,
  speakerName: PropTypes.string,
  logo: PropTypes.string,
  muted: PropTypes.bool,
  isReady: PropTypes.bool,
  isPlaying: PropTypes.bool,
  showDownload: PropTypes.bool,
  showShare: PropTypes.bool,
  playVideo: PropTypes.func.isRequired,
  pauseVideo: PropTypes.func.isRequired,
  setMuted: PropTypes.func.isRequired,
};
