import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import PublicURL from "./publicURL";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import TextInputToApi from "components/util/hooks/input/textInputToApi";

export default function ExternalShare(props) {
  let [open, setOpen] = useState(false);
  let [urls, setURLs] = useState({});

  //we have to use useEffect here to add a new cacheBuster when ogTitle or ogDescription is updated
  //without the cacheBuster facebook will load an old url and won't show the new title and description
  useEffect(() => {
    let now = Date.now();
    let noteURL =
      `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
      props.noteId +
      "&socialshare=true&cb=" +
      now;
    let encodedUrl = encodeURIComponent(noteURL);
    let fbURL = "https://www.facebook.com/sharer/sharer.php?u=" + encodedUrl;
    let twitterURL =
      "https://twitter.com/intent/tweet?url=" +
      encodedUrl +
      "&text=" +
      props.senderName +
      " shared a video ";
    setURLs({ noteURL, fbURL, twitterURL });
  }, [props.ogDescription, props.ogTitle, props.noteId, props.senderName]);

  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <h2>Share Settings</h2>
          <span className="greyDesc">Social share title and description</span>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>
      <div className={"col-12 row " + expansionPanelClass}>
        <div className="col-12">
          <CheckBoxToApi
            field="showShare"
            initialValue={props.showShare || false}
            apiCall={props.saveNoteState}
            label="Show share button"
          />
        </div>
        <div className="col-12">
          <CheckBoxToApi
            field="showDownload"
            initialValue={props.showDownload || false}
            apiCall={props.saveNoteState}
            label="Show video download button"
          />
        </div>

        <div className="col-12">
          <TextInputToApi
            initialValue={props.ogTitle}
            label="Share Title"
            variant="standard"
            field="ogTitle"
            placeholder="Share title shows when your note is shared on social feeds and in text message previews"
            apiCall={props.saveNoteState}
          />
        </div>

        <div className="col-12" style={{ marginTop: "15px" }}>
          <TextInputToApi
            initialValue={props.ogDescription}
            label="Share Description"
            variant="standard"
            field="ogDescription"
            placeholder="Share description shows when your note is shared on social feeds and in text messages"
            apiCall={props.saveNoteState}
          />
        </div>

        <div className="col-12" style={{ marginTop: "10px" }}>
          <PublicURL noteId={props.noteId} />
        </div>

        <div className="col-12 row " style={{ marginTop: "10px" }}>
          <div className="col-12">
            <span style={{ fontSize: "12px", color: "rgba(0,0,0,0.57)" }}>
              Share On Social
            </span>
          </div>

          <div className="col-12" style={{ marginTop: "10px" }}>
            <a href={urls.fbURL} target="_blank" rel="noopener noreferrer">
              <button className="socialShareButton facebookBtn align-items-center">
                <img
                  src="/static/img/facebook_logo.svg"
                  style={{ height: "16px", width: "16px" }}
                  alt="Facebook logo"
                />
                <span className="socialShareButtonText">Share</span>
              </button>
            </a>
          </div>

          <div className="col-12" style={{ marginTop: "10px" }}>
            <a href={urls.twitterURL} target="_blank" rel="noopener noreferrer">
              <button className="socialShareButton twitterBtn align-items-center">
                <img
                  src="/static/img/twitter_logo.svg"
                  style={{ height: "16px", width: "16px" }}
                  alt="Twitter logo"
                />
                <span className="socialShareButtonText">Tweet</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
