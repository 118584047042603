import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Branding from "./branding/branding";
import Text from "./text/text";
import { Tabs, Tab } from "@mui/material";
import { getSearchParams } from "util/helpers";
import MergeTags from "./mergeTags";

export default function ManageSettings(props) {
  const queryParams = getSearchParams();
  const tempTab = queryParams.get("tab") || "branding";
  const [tabSelected, setTabSelected] = useState(tempTab);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!props.userRole.isAdmin) {
      setErrorMessage(
        "You can not view this page. Only admins can manage account settings such as branding."
      );
    }
  }, [props]);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  return (
    <div className="row col-12 pagePadding">
      {errorMessage ? (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      ) : (
        <>
          <div className="row col-12 " style={{ marginBottom: "10px" }}>
            <h1>Account Settings</h1>
          </div>

          <div className="col-12" style={{ borderBottom: "1px #ddd solid" }}>
            <Tabs
              value={tabSelected}
              onChange={(e, newValue) => setTabSelected(newValue)}
              aria-label="edit video tabs">
              <Tab label="Branding" {...a11yProps(0)} value="branding" />
              <Tab label="Texting" {...a11yProps(0)} value="text" />
              <Tab label="Merge Tags" {...a11yProps(0)} value="mergeTags" />
            </Tabs>
          </div>

          {tabSelected === "branding" && <Branding {...props} />}
          {tabSelected === "text" && <Text {...props} />}
          {tabSelected === "mergeTags" && <MergeTags {...props} />}
        </>
      )}
    </div>
  );
}

ManageSettings.propTypes = {
  userRole: PropTypes.object,
};
