import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import Menu from "@mui/material/Menu";
import RepliesItem from "./repliesItem";
import NewTaskItem from "./newTaskItem";
import GeneralItem from "./generalItem";
import NoteWatchedItem from "./noteWatchedItem";
import VideoRequestItem from "./videoRequestItem";
import BounceItem from "./bounceItem";
import UnsubscribeItem from "./unsubscribeItem";
import LoadingButton from "components/util/buttons/loadingbutton";

export default function Notifications(props) {
  const open = Boolean(props.anchorEl);
  const menuActions = useRef();

  function updateMenuPosition() {
    menuActions.current.updatePosition();
  }

  if (props.errorMessage) {
    return (
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        anchorEl={props.anchorEl}
        open={open}
        onClose={() => props.setAnchorEl(null)}>
        <div
          className="row"
          style={{
            maxHeight: "500px",
            overflow: "scroll",
          }}>
          <div className="col-12" style={{ padding: "10px" }}>
            <b>Notifications</b>
          </div>
          <div
            className="col-12"
            style={{ height: "1px", backgroundColor: "#ddd", margin: "5px 0px" }}
          />
          <div className="errorMessageBox">
            <b>{props.errorMessage}</b>
          </div>
        </div>
      </Menu>
    );
  }

  let listNotifications = props.notifications.map((notification) => {
    if (notification.type === "replies")
      return (
        <RepliesItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          accountId={props.accountInfo.id}
          updateMenuPosition={updateMenuPosition}
        />
      );
    else if (notification.type === "newTask")
      return (
        <NewTaskItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          accountId={props.accountInfo.id}
          updateMenuPosition={updateMenuPosition}
        />
      );
    else if (
      notification.type === "taskAssigned" ||
      notification.type === "general" ||
      (notification.type === "videoRequest" &&
        notification.typeOfRequest === "externalTaskList")
    )
      return (
        <GeneralItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          icon={notification.type === "videoRequest" ? "backup" : "notifications"}
        />
      );
    else if (notification.type === "videoRequest")
      return (
        <VideoRequestItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          accountId={props.accountInfo.id}
          updateMenuPosition={updateMenuPosition}
        />
      );
    else if (notification.type === "bounce")
      return (
        <BounceItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          accountId={props.accountInfo.id}
        />
      );
    else if (notification.type === "unsubscribe")
      return (
        <UnsubscribeItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          accountId={props.accountInfo.id}
          updateMenuPosition={updateMenuPosition}
        />
      );
    else if (notification.type === "noteWatched")
      return (
        <NoteWatchedItem
          current={notification}
          key={notification.id}
          setAnchorEl={props.setAnchorEl}
          accountId={props.accountInfo.id}
        />
      );
    return null;
  });

  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      anchorEl={props.anchorEl}
      open={open}
      action={menuActions}
      onClose={() => props.setAnchorEl(null)}>
      <div className="row">
        <div style={{ flex: 1, padding: "10px" }}>
          <b>Notifications</b>
        </div>
        <div style={{ width: "90px", padding: "10px" }}>
          <Link
            to={`/a/${props.accountInfo.id}/settings`}
            className="link"
            onClick={() => props.setAnchorEl(null)}>
            Settings
          </Link>
        </div>
        <div
          className="col-12"
          style={{ height: "1px", backgroundColor: "#ddd", margin: "5px 0px" }}
        />
        <div className="col-12" style={{ maxHeight: "450px", overflowY: "scroll" }}>
          {props.notifications.length === 0 ? (
            <div className="greyDesc" style={{ padding: "10px" }}>
              No notifications
            </div>
          ) : (
            <>
              <div className="col-12">{listNotifications}</div>
              <div
                className="col-12 justify-content-center"
                style={{ margin: "10px 0px" }}>
                {props.count > props.notifications.length && (
                  <div
                    className="col-12 justify-content-center"
                    style={{ marginTop: "20px" }}>
                    <LoadingButton
                      type="button"
                      variant="outlined"
                      size="small"
                      buttonState={props.isLoading ? "loading" : null}
                      savedLabel={"Loaded"}
                      label="Show more"
                      clickedRegularButton={props.getMore}
                      clickedSavedButton={() => {
                        return null;
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Menu>
  );
}

Notifications.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  notifications: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  getMore: PropTypes.func.isRequired,
};
