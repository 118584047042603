import React, { useState, useEffect } from "react";
import { TextField, LinearProgress } from "@mui/material";
import LoadingButton from "../../buttons/loadingbutton";
import { getUserByToken, login, logout } from "services/api.service";
import { createPassword, forgotPassword } from "services/api.service";
import { getSearchParams } from "util/helpers";

export default function EmailPassword() {
  let [email, setEmail] = useState("");
  let [tokenExpired, setTokenExpired] = useState("loading");
  let [newPassword, setNewPassword] = useState("");
  let [newPasswordTwo, setNewPasswordTwo] = useState("");
  let [state, setState] = useState({});

  useEffect(() => {
    let checkToken = async () => {
      try {
        const token = getSearchParams().get("token");
        const user = await getUserByToken(token);
        if (!user?.email) {
          throw new Error("User email does not exist");
        }

        setEmail(user.email);
        setTokenExpired(false);
      } catch (err) {
        console.error(err.data.message);
        setTokenExpired(true);
      }
    };

    checkToken();
  }, []);

  let sendNewresetLink = async (e) => {
    try {
      e.preventDefault();
      if (email === "") throw new Error("Please insert email");
      setState({ buttonState: "loading" });
      await forgotPassword(email);
      setState({ buttonState: "saved" });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  let updateUserPassword = async (e) => {
    e.preventDefault();
    setState({ buttonState: "loading" });

    try {
      if (newPassword.length < 8) {
        throw new Error("Please must be at least 8 characters");
      } else if (newPasswordTwo === "") {
        throw new Error("Please re-enter password");
      } else if (newPassword !== newPasswordTwo) {
        throw new Error("Passwords must match");
      }

      const token = getSearchParams().get("token");
      await createPassword(newPassword, token);

      // logout of current session if any and login to new account
      await logout();
      await login(email, newPassword);
      window.location = "/";
    } catch (err) {
      // Invalid or expired action code. Ask user to try to reset the password
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  if (tokenExpired === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  else if (tokenExpired) {
    return (
      <>
        <div
          className="col-12 row"
          style={{
            borderTop: "1px solid #e3e8ee",
            borderBottom: "1px solid #e3e8ee",
            backgroundColor: "#f7fafc",
            padding: "32px",
          }}>
          <div className="col-12">
            <h1 style={{ fontSize: "25px" }}>Email login link expired</h1>
            <p className="greyDesc">
              The link you are using to sign in has expired. You must email youself a new
              link to login.
            </p>
          </div>

          <div className="col-12" style={{ marginTop: "20px" }}>
            <TextField
              fullWidth={true}
              id="email"
              label="Email Address"
              placeholder="john@email.com"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {state.errorMessage && (
            <div className="errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}

          <div className="col-12" style={{ marginTop: "20px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth={true}
              type="submit"
              buttonState={state.buttonState}
              savedLabel={"Link Emailed"}
              label={"Email New Link"}
              clickedRegularButton={(event) => sendNewresetLink(event)}
              clickedSavedButton={() => (window.location = "/")}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="col-12 row"
          style={{
            borderTop: "1px solid #e3e8ee",
            borderBottom: "1px solid #e3e8ee",
            backgroundColor: "#f7fafc",
            padding: "20px",
          }}>
          <div className="col-12">
            <h1 style={{ fontSize: "18px" }}>Create your password</h1>
          </div>
        </div>

        <form
          onSubmit={(event) => updateUserPassword(event)}
          className="col-12 pagePadding"
          style={{ borderTop: "1px #e3e8ee solid", padding: "20px" }}>
          <div className="col-12" style={{ marginTop: "10px" }}>
            <TextField
              fullWidth={true}
              label="Email Address"
              id="email"
              disabled
              placeholder="john@email.com"
              variant="outlined"
              value={email}
            />
          </div>

          <div className="col-12" style={{ marginTop: "25px" }}>
            <TextField
              fullWidth={true}
              label="Create Password"
              type="password"
              id="newPassword"
              placeholder="******"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className="col-12" style={{ marginTop: "25px" }}>
            <TextField
              fullWidth={true}
              label="Re-enter Password"
              type="password"
              id="newPasswordTwo"
              placeholder="******"
              variant="outlined"
              value={newPasswordTwo}
              onChange={(e) => setNewPasswordTwo(e.target.value)}
            />
          </div>

          {state.errorMessage && (
            <div className="errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}

          <div className="col-12" style={{ marginTop: "25px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth={true}
              type="submit"
              buttonState={state.buttonState}
              savedLabel={"Success! View Account"}
              label={"Create Account"}
              clickedRegularButton={(event) => updateUserPassword(event)}
              clickedSavedButton={() => (window.location = "/")}
            />
          </div>

          <div className="col-12" style={{ marginTop: "20px" }}>
            <p style={{ color: "grey", fontSize: "13px" }}>
              Need help? Send an email to support@gratavid.com
            </p>
          </div>
        </form>
      </>
    );
  }
}
