import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { suggestAccountContacts } from "services/api.service";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "util/helpers";

export default function EmailAutoSuggestInput(props) {
  let { variant = "outlined" } = props;
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const debouncedSearch = useCallback(
    debounce((value) => {
      setIsLoading(true);
      suggestAccountContacts(10, value)
        .then(({ rows }) => {
          setResults(rows);
        })
        .catch((e) => setErrorMessage(e))
        .finally(() => setIsLoading(false));
    }),
    []
  );

  const itemSelected = async (item) => {
    if (!item) {
      const contact = props.contactData.contact;
      Object.keys(props?.contactData?.contact).forEach((key) => {
        contact[key] = "";
      });
      props.setContactData({ ...props.contactData, contact });
    } else if (typeof item === "object") {
      props.setContactData({
        ...props.contactData,
        contact: { ...props.contactData.contact, ...item },
      });
    } else {
      props.handleFieldChange("email", item);
    }
  };

  let handleInputChange = (value) => {
    props.handleFieldChange("email", value);
    debouncedSearch(value);
  };

  return (
    <>
      <Autocomplete
        id="task-email-select"
        options={results}
        loading={isLoading}
        onChange={(e, item) => itemSelected(item)}
        onInputChange={(e, value) => handleInputChange(value)}
        autoHighlight
        freeSolo
        filterOptions={(option) => option}
        getOptionLabel={(option) => (option.email ? option.email : option)}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <div>
                <span style={{ fontSize: "14px" }}>
                  {option.firstName} {option.lastName}
                </span>{" "}
                <br />
                <span style={{ color: "grey", fontSize: "12px" }}>{option.email}</span>
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            name="email"
            placeholder="Insert email or search contacts"
            label="Email"
            fullWidth
            value={props.email}
            autoComplete="off"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        )}
      />

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}
    </>
  );
}

EmailAutoSuggestInput.propTypes = {
  email: PropTypes.string.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  contactData: PropTypes.object,
  setContactData: PropTypes.func,
};
