import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DeleteUsersDialog from "./deleteUsersDialog";
import UserItem from "./userItem";
import { LinearProgress } from "@mui/material";
import SelectTable from "../../util/search/table/selectTable";
import { getAccountUsers } from "services/api.service";

export default function ListUsers(props) {
  let { users, setUsers, passedSetStateFromApp, userInfo } = props;
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
  let [selectedItems, setSelectedItems] = useState([]);
  let [state, setState] = useState({ buttonState: "regular" });

  useEffect(() => {
    let getData = async () => {
      try {
        const users = await getAccountUsers();

        let tempUsers = {};
        users.forEach((user) => {
          const { id: userUID, email, fullName } = user;
          const { isAdmin, isBulkSender, isNoteEditor } = user.roles.find(
            (role) => role.accountId === props.accountInfo.id
          );

          tempUsers[userUID] = {
            email,
            userUID,
            admin: !!isAdmin,
            disableBulkSends: !isBulkSender,
            disableNoteWrite: !isNoteEditor,
            fullName,
            selectionDisabled: userUID === userInfo.id,
          };
        });

        setUsers(tempUsers);
      } catch (err) {
        console.error(err.message);
      }
    };
    if (props.isUserAdmin) {
      getData();
    }
  }, [props.isUserAdmin, setUsers]);

  let selectItem = (userUID) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(userUID)) {
        return prevSelectedItems.filter((item) => item !== userUID);
      } else {
        return [...prevSelectedItems, userUID];
      }
    });
  };

  let selectAll = () => {
    let itemsKeys = Object.keys(users);
    itemsKeys = itemsKeys.filter((key) => !users[key].selectionDisabled);
    setSelectedItems(itemsKeys);
  };

  let unSelectAll = () => {
    setSelectedItems([]);
  };

  let load100More = () => {};

  if (!props.isUserAdmin) {
    return (
      <div style={{ paddingTop: "20px" }}>Only admins can manage internal users.</div>
    );
  }

  if (users === "loading") {
    return (
      <div className="col-12 pagePadding">
        <LinearProgress />
      </div>
    );
  }

  let tableActions = [
    { label: "Delete", icon: "delete", actionFunc: () => setDeleteDialogOpen(true) },
  ];
  let tableHeader = [
    { label: "USER", style: { flex: 1 } },
    {
      label: "NOTE EDITOR",
      style: { width: "120px" },
      helperText: "Allow users to create, edit, and delete notes.",
      className: "hidden-md-down",
    },
    {
      label: "BULK SENDER",
      style: { width: "120px" },
      helperText: "Allow users to send a Gratavid to 4 or more contacts at once.",
      className: "hidden-md-down",
    },
    {
      label: "ADMIN",
      style: { width: "120px" },
      helperText:
        "Admins can add/delete users (including you), manage sign-in methods, and manage integrations. Note: You can't edit your own account.",
    },
  ];

  return (
    <div className="row col-12 align-items-start">
      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div style={{ marginTop: "5px" }} className="row col-12">
        <SelectTable
          items={users}
          selectAll={selectAll}
          unSelectAll={unSelectAll}
          selectedItems={selectedItems}
          selectItem={selectItem}
          nextCursorDoc={null}
          ItemComponent={UserItem}
          load100More={load100More}
          tableActions={tableActions}
          tableHeader={tableHeader}
          userUID={userInfo.id}
          accountInfo={props.accountInfo}
          setState={setState}
          passedSetStateFromApp={passedSetStateFromApp}
        />
      </div>

      {deleteDialogOpen && (
        <DeleteUsersDialog
          {...props}
          dialogOpen={deleteDialogOpen}
          closeDialog={() => setDeleteDialogOpen(false)}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          users={users}
          setUsers={setUsers}
        />
      )}
    </div>
  );
}

ListUsers.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  setUsers: PropTypes.func.isRequired,
  passedSetStateFromApp: PropTypes.func.isRequired,
};
