import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import Button from "../../util/button/button";
import ListUsers from "./listUsers";
import ListExternalUsers from "./externalUsers/listExternalUsers";
import QuestionToLink from "../../util/explainers/questionToLink";
import AddUserDialog from "./add/addUserDialog";
import SignInMethods from "./signInMethods";
import { patchAccount } from "../../../services/api.service.js";

function a11yProps(index) {
  return {
    id: `task-tab-${index}`,
    "aria-controls": `users-tabpanel-${index}`,
  };
}

export default function Users(props) {
  let [tabSelected, setTabSelected] = useState(0);
  let [addDialogOpen, setAddDialogOpen] = useState(false);
  let [users, setUsers] = useState("loading");
  let [externalUsers, setExternalUsers] = useState("loading");
  let [signInProviders, setSignInProviders] = useState({
    emailPassword: true,
    microsoftSso: props.accountInfo.microsoftSso,
    googleSso: props.accountInfo.googleSso,
  });
  let [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    const isAdmin = props.userInfo?.roles?.find(
      (user) => user.accountId === props.accountInfo.id
    )?.isAdmin;

    setIsUserAdmin(isAdmin);

    if (!isAdmin) {
      setTabSelected(2);
    }
  }, [props]);

  function updateSignInProviders(providers) {
    patchAccount({
      microsoftSso: signInProviders.microsoftSso,
      googleSso: signInProviders.googleSso,
    });
    setSignInProviders(providers);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(243, 244, 244)",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}>
      <div className="row col-12 pagePadding align-items-start">
        <div className="row col-12 " style={{ marginBottom: "10px" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h1>Manage Users</h1>
            <QuestionToLink id="manageUsers" desc="manageUsers" />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddDialogOpen(true)}
            startIcon={<i className="material-icons">add</i>}>
            New User
          </Button>
        </div>

        <div className="col-12" style={{ borderBottom: "1px #ddd solid" }}>
          <Tabs
            value={tabSelected}
            onChange={(e, newValue) => setTabSelected(newValue)}
            aria-label="task tabs">
            <Tab label="Users" {...a11yProps(0)} value={0} />
            <Tab label="Sign-in methods" {...a11yProps(1)} value={1} />
            <Tab label="External Assignees" {...a11yProps(2)} value={2} />
          </Tabs>
        </div>

        <div className="row col-12">
          {tabSelected === 0 && (
            <ListUsers
              userInfo={props.userInfo}
              accountInfo={props.accountInfo}
              users={users}
              setUsers={setUsers}
              isUserAdmin={isUserAdmin}
              passedSetStateFromApp={props.passedSetStateFromApp}
            />
          )}
          {tabSelected === 1 && (
            <SignInMethods
              signInProviders={signInProviders}
              setSignInProviders={updateSignInProviders}
              isUserAdmin={isUserAdmin}
            />
          )}
          {tabSelected === 2 && (
            <ListExternalUsers
              userId={props.userInfo.id}
              accountId={props.accountInfo.id}
              users={externalUsers}
              setUsers={setExternalUsers}
            />
          )}
        </div>

        {addDialogOpen && (
          <AddUserDialog
            {...props}
            addDialogOpen={addDialogOpen}
            closeDialog={() => setAddDialogOpen(false)}
            externalUsers={externalUsers}
            setExternalUsers={setExternalUsers}
            users={users}
            setUsers={setUsers}
            isUserAdmin={isUserAdmin}
          />
        )}
      </div>
    </div>
  );
}
