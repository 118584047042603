import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./folders.module.css";
import List from "@mui/material/List";
import LinearProgress from "@mui/material/LinearProgress";
import AddItem from "components/util/folders/addItem";
import Checkbox from "components/util/folders/checkbox";
import { deleteAccountFolder, updateAccountFolder } from "services/api.service";

export default function Folders(props) {
  const [errorMessage, setErrorMessage] = useState(null);

  const onEdit = async (itemId, newLabel) => {
    try {
      const newFolder = await updateAccountFolder(itemId, {
        name: newLabel,
      });
      const found = props.folders.find(({ id }) => id === newFolder.id);
      found.label = newFolder.name;
      found.name = newFolder.name;
      props.setFolders([...props.folders]);
    } catch (err) {
      setErrorMessage(err?.data?.message ?? err.message);
    }
  };

  const onDelete = async (itemId) => {
    try {
      await deleteAccountFolder(itemId);
      const index = props.folders.findIndex(({ id }) => id === itemId);
      props.folders.splice(index, 1);
      props.setFolders([...props.folders]);
    } catch (err) {
      setErrorMessage(err?.data?.message ?? err.message);
    }
  };

  if (!props?.folders?.length) {
    return (
      <div className={styles.loading}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <>
      <List className="col-12">
        {props.folders.map((folder) => (
          <Checkbox
            itemId={folder.id}
            key={folder.id}
            label={folder.label}
            value={folder.selected}
            onDelete={onDelete}
            onEdit={onEdit}
            onToggle={props.onToggle}
            isAll={folder.label === "All"}
          />
        ))}
      </List>

      <div className="row col-12 justify-content-center">
        <AddItem
          accountId={props.accountId}
          folders={props.folders}
          updateFolders={props.setFolders}
          indexName={props.indexName}
        />
      </div>

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}
    </>
  );
}

Folders.propTypes = {
  accountId: PropTypes.string.isRequired,
  setFolders: PropTypes.func.isRequired,
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    })
  ),
  onToggle: PropTypes.func,
  onSave: PropTypes.func,
  indexName: PropTypes.string,
};
