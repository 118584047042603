const { createTheme } = require("@mui/material/styles");

//yellow f8e9a1
//light blue #a8d0e6
//light navy #374785
//dark navy #24305e
//orange #f76c6c

export default createTheme({
  palette: {
    secondary: { main: "#f76c6c", contrastText: "#ffffff" },
    primary: { light: "#a8d0e6", main: "#24305e", contrastText: "#fff" },
    background: { default: "#fafafa" },
  },
  typography: { useNextVariants: true },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard", // outlined is the default
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover", // always is default
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard", // outlined is the default
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          lineHeight: "1rem", // fix for 15px font-sze
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: "auto",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          padding: "12px 0 16px",
          "&$selected": {
            color: "inherit",
            paddingTop: "12px",
          },
        },
        label: {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 500,
          paddingTop: "4px",
          color: "hsl(0, 0%, 30%)",
          "&$selected": {
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 700,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          borderRadius: "9999px",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "9999px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "hsl(0, 0%, 30%)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "400",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "16px",
          boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.08)",
          marginTop: "12px",
        },
        list: {
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "12px",
          minWidth: "190px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          paddingRight: "8px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: 72,
          fontSize: "14px",
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid hsl(0, 0%, 90%)",
        },
        flexContainer: {
          gap: "10px",
        },
        indicator: {
          backgroundColor: "#f76c6c",
        },
      },
    },
  },
});
