import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import LoadingButton from "../../../util/buttons/loadingbutton";
import { isValidEmail } from "util/helpers";
import { updateContact } from "services/api.service";
import { isCustomProperty } from "util/contacts";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function EditInput(props) {
  let [editing, setEditing] = useState(false);
  let [value, setValue] = useState("");
  let [state, setState] = useState({});
  let [optInDialogOpen, setOptInDialogOpen] = useState(false);

  const fullScreen = useMobileDialog();

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  let handleChange = (e) => {
    setValue(e.target.value);
    setEditing(true);
  };

  let cancelEdit = () => {
    setValue(props.value);
    setEditing(false);
    setState({});
  };

  let handleOptInChange = () => {
    let newState = value === true ? false : true;
    if (newState === true) {
      setState({});
      setOptInDialogOpen(true);
    } else {
      setValue(false);
      saveEdit(false);
    }
  };

  let saveEdit = async (passedValue) => {
    try {
      let newValue = passedValue ?? value;
      const { id, contactData } = props;
      if (id === "email" && !isValidEmail(value)) throw new Error("Provide valid email");
      if (["dobDay", "dobMonth"].includes(id)) {
        newValue = Number(newValue);
      }
      setState({ buttonState: "loading" });
      setValue(newValue);
      const { email, firstName, lastName, customProperties } = contactData;
      const updates = { email, firstName, lastName };

      if (!isCustomProperty(props.accountInfo, id)) {
        updates[id] = newValue;
      } else {
        updates.customProperties = { ...customProperties, [id]: newValue };
      }

      const result = await updateContact(contactData.id, updates);

      //pass data back. this way is user closes our exit box then re-opens it the latest data will show
      props.passedSetState(id, newValue);

      setState({ buttonState: "saved" });
      setEditing(false);

      if (id === "email") {
        let newContactId =
          result &&
          result.data &&
          result.data.newAlogliaContact &&
          result.data.newAlogliaContact.contactId;
        if (newContactId)
          window.location = `/a/${props.accountInfo.id}/contacts/` + newContactId;
      }
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(err.message);
      setState({ errorMessage });
    }
  };

  let closeDialog = () => {
    setState({});
    setOptInDialogOpen(false);
  };

  //hide birthday string
  if (props.id === "birthday") return null;

  //render Text Opt In Status
  if (props.id === "textOptIn") {
    let optInStatus = value === "true" || value === true ? true : false;
    return (
      <>
        <FormControlLabel
          style={{ marginTop: "12px" }}
          control={
            <Switch
              checked={optInStatus}
              onChange={() => handleOptInChange()}
              value={optInStatus}
            />
          }
          label="Opted In To Text"
        />
        {state.buttonState && state.buttonState === "loading" ? <LinearProgress /> : null}
        <Dialog
          fullScreen={fullScreen}
          open={optInDialogOpen}
          onClose={closeDialog}
          aria-labelledby="set text opt-in status">
          <div style={{ minWidth: "250px", padding: "20px" }}>
            <div
              className="row col-12 pagePadding"
              style={{ padding: "20px", minWidth: "250px" }}>
              <div className="col-10">
                <h2>Change Opt-In Status</h2>
              </div>
              <div className="col-2 justify-content-end">
                <IconButton aria-label="close" onClick={closeDialog}>
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
              <div className="col-12">
                <p style={{ marginBottom: "10px" }}>
                  By setting opt-in status to true, I agree that I have explicit consent
                  to send text messages to this contact.
                </p>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  buttonState={state.buttonState}
                  savedLabel={"Saved"}
                  label={"I Agree, Set To True"}
                  clickedRegularButton={() => saveEdit(true)}
                  clickedSavedButton={closeDialog}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  if (props.id === "dobMonth") {
    return (
      <>
        <FormControl fullWidth style={{ marginTop: "12px" }}>
          <InputLabel id="dobMonth">{props.label}</InputLabel>
          <Select
            label={props.label}
            labelId="dobMonth"
            value={value}
            native
            displayEmpty
            onChange={(e) => saveEdit(e.target.value)}
            inputProps={{ name: "dobMonth", id: "dobMonth" }}>
            <option value="" disabled></option>
            <option value={0}>NA</option>
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </Select>
          {state.errorMessage && (
            <FormHelperText>
              <span style={{ color: "red" }}>Error: {state.errorMessage}</span>
            </FormHelperText>
          )}
        </FormControl>
        {state.buttonState && state.buttonState === "loading" ? <LinearProgress /> : null}
      </>
    );
  }

  if (props.id === "dobDay") {
    let days = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 25, 26, 27, 28, 29, 30, 31,
    ];
    return (
      <>
        <FormControl style={{ marginTop: "12px" }} fullWidth>
          <InputLabel id="dobDay">{props.label}</InputLabel>
          <Select
            label={props.label}
            labelId="dobDay"
            value={value}
            native
            onChange={(e) => saveEdit(e.target.value)}
            inputProps={{ name: "dobDay", id: "dobDay" }}>
            <option value="" disabled></option>
            <option value={0}>NA</option>
            {days.map((current) => {
              return (
                <option key={current} value={current}>
                  {current}
                </option>
              );
            })}
          </Select>
          {state.errorMessage && (
            <FormHelperText>
              <span style={{ color: "red" }}>Error: {state.errorMessage}</span>
            </FormHelperText>
          )}
        </FormControl>
        {state.buttonState && state.buttonState === "loading" ? <LinearProgress /> : null}
      </>
    );
  }

  return (
    <form onSubmit={() => saveEdit()}>
      <FormControl style={{ marginTop: "12px" }} fullWidth>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <Input
          id={props.id}
          type="text"
          autoComplete="off"
          rows={props.id === "notes" ? 3 : 1}
          multiline={props.id === "notes" ? true : false}
          value={value}
          onChange={handleChange}
          endAdornment={
            editing &&
            state.buttonState !== "loading" && (
              <>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle edit input"
                    onClick={cancelEdit}
                    style={{ padding: "0px" }}>
                    <i className="material-icons" style={{ color: "red" }}>
                      cancel
                    </i>
                  </IconButton>
                </InputAdornment>
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    aria-label="Toggle edit input"
                    onClick={() => saveEdit()}
                    style={{ padding: "0px" }}>
                    <i className="material-icons" style={{ color: "green" }}>
                      check_circle
                    </i>
                  </IconButton>
                </InputAdornment>
              </>
            )
          }
        />
        {state.errorMessage && (
          <FormHelperText>
            <span style={{ color: "red" }}>Error: {state.errorMessage}</span>
          </FormHelperText>
        )}
      </FormControl>
      {state.buttonState && state.buttonState === "loading" ? <LinearProgress /> : null}
    </form>
  );
}

EditInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  accountId: PropTypes.string.isRequired,
  accountInfo: PropTypes.object.isRequired,
  passedSetState: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  contactData: PropTypes.object,
  fullScreen: PropTypes.bool,
};
