import React, { useEffect, useRef } from "react";
import styles from "./uploadNeeded.module.css";
import PropTypes from "prop-types";
import VideoRecorder from "./videoRecorder";
import Button from "@mui/material/Button";
import { getVideo } from "services/api.service";
import { LinearProgress, Box } from "@mui/material";

export default function UploadNeeded(props) {
  const pollId = useRef(null);

  useEffect(() => {
    //if page restarts and status is then mediaURLUploaded then listen for updates
    if (props.status === "webMUploaded") {
      pollForUploadStatus(props.videoId);
    }

    return () => {
      clearInterval(pollId.current);
    };
  }, []);

  const pollForUploadStatus = (videoId) => {
    pollId.current = setInterval(async () => {
      const video = await getVideo(videoId);
      if (!video) {
        return;
      }

      const status = video?.status ?? "create";
      if (status === "error") {
        props.videoUploadStatusChanged(
          status,
          null,
          null,
          "There was an error transcoding your video. Try removing or deleting this video and starting over.",
          null,
          null,
          null
        );
      } else if (status === "mediaURLUploaded") {
        clearInterval(pollId.current);
        props.videoUploadStatusChanged(
          status,
          video.videoUrl,
          video.videoUrl,
          null,
          video.thumbnailUrl,
          video.gifUrl,
          video.audioUrl,
          video?.phrases
        );
      }
    }, 5000);
  };

  const reviveDeletedVideoState = () => {
    let { status, webmURL, mediaURL, thumbnail, gif, audioSrc, captions } =
      props.deletedVideoState;
    props.videoUploadStatusChanged(
      status,
      webmURL,
      mediaURL,
      null,
      thumbnail,
      gif,
      audioSrc,
      captions
    );
  };

  const onComplete = async (video) => {
    props.videoUploadStatusChanged(
      video.status,
      video.videoUrl,
      video.videoUrl,
      null,
      video.thumbnailUrl,
      video.gifUrl,
      video.audioUrl,
      video?.phrases ?? null
    );
  };

  return (
    <div className={styles.video_wrapper}>
      <div className={styles.row}>
        {props.deletedVideoState && (
          <Button onClick={reviveDeletedVideoState}>
            <i className="material-icons" style={{ color: "black" }}>
              settings_backup_restore
            </i>
            Undo Delete
          </Button>
        )}
        {props.status === "webMUploaded" && (
          <Box className={styles.loader_wrapper}>
            <Box width="100%">
              <LinearProgress />
            </Box>
            <span className={styles.loader_text}>Processing Previous Video</span>
          </Box>
        )}
      </div>

      <VideoRecorder
        video={{ id: props.videoId }}
        recorderName={props.recorderName}
        onComplete={onComplete}
        width={Math.floor(window.innerWidth / 2)}
      />
    </div>
  );
}

UploadNeeded.propTypes = {
  recorderName: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  videoUploadStatusChanged: PropTypes.func.isRequired,
  deletedVideoState: PropTypes.shape({
    status: PropTypes.string.isRequired,
    webmURL: PropTypes.string,
    mediaURL: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    gif: PropTypes.string.isRequired,
    audioSrc: PropTypes.string.isRequired,
    captions: PropTypes.object,
  }),
};
