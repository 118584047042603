import React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function ContactItem(props) {
  let selectContact = () => {
    if (
      props.taskContacts.some(
        (taskContact) => taskContact.contactId === props.currentContact.id
      )
    ) {
      props.deleteContactFromTask(props.currentContact);
    } else {
      props.addContactToTask(props.currentContact);
    }
  };

  return (
    <div
      className="row col-12 editNoteListSelectableItem"
      style={{ height: "60px", display: "flex", padding: "10px 5px" }}
      onClick={() => selectContact()}>
      <div className="align-items-center">
        <Checkbox
          checked={props.taskContacts.some(
            (taskContact) => taskContact.contactId === props.currentContact.id
          )}
          size="small"
          style={{ height: "15px", width: "15px", margin: "0px 10px" }}
          color="default"
        />
      </div>

      <div style={{ flex: 1, overflow: "hidden" }} className="row col-12">
        <div className="col-12">
          <span>
            {!props.currentContact.firstName && !props.currentContact.lastName
              ? props.currentContact.email
              : props.currentContact.firstName ||
                "" + " " + props.currentContact.lastName ||
                ""}
          </span>
          {props.currentContact.textOptIn && (
            <i
              className="material-icons"
              style={{ fontSize: "14px", color: "green", marginLeft: "3px" }}>
              mobile_friendly
            </i>
          )}
        </div>
        <div className="col-12">
          <span style={{ fontSize: 12, color: "grey" }}>
            {props.currentContact.email}
          </span>
        </div>
      </div>
    </div>
  );
}
