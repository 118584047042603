import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router";
import GrataStripes from "../stripes/grataStripes";
import { LinearProgress, Button } from "@mui/material";
import { oAuthLogin } from "../../../services/api.service";
import { getSearchParams, capitalize } from "util/helpers";

export default function AuthOpenIdRedirect(props) {
  let [state, setState] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const loginWithProviderState = window.localStorage.getItem("loginWithProviderState");
    const searchParams = getSearchParams();
    const urlState = searchParams.get("state");
    const authCode = searchParams.get("code");

    console.log(urlState);

    if (urlState !== loginWithProviderState) {
      setState({ errorMessage: "Invalid link. Try to login again." });
    } else if (authCode) {
      sendOAuthCode(authCode, urlState);
    } else {
      setState({ errorMessage: "Invalid link. Try to login again." });
    }
  }, []);

  function userLoggedIn(urlState) {
    // wait 1.5 seconds for other screen to react to auth change then redirect
    setTimeout(() => {
      let redirectTo = urlState.includes("createAccount") ? "/task" : "/";
      const ssoRedirectURL = window.localStorage.getItem("ssoRedirectURL");
      if (ssoRedirectURL) {
        redirectTo = ssoRedirectURL;
        window.localStorage.removeItem("ssoRedirectURL");
      }
      navigate(redirectTo);
    }, 1500);
  }

  async function sendOAuthCode(code, urlState) {
    if (!code) {
      return;
    }
    const provider = urlState.split("_")[0];
    const redirectUri = window.location.origin + window.location.pathname;

    await oAuthLogin(code, provider, redirectUri)
      .then(() => userLoggedIn(urlState))
      .catch((err) => {
        console.error(err);
        let errorMessage =
          "Error signing in. Reach out to support@gratavid.com if problem persist.";

        if (err.status === 401) {
          errorMessage =
            "Sign in failed. Token is invalid or expired. Try refreshing the page and signing in again.";
        } else if (err.status === 403) {
          const ssoProvider = capitalize(provider);
          errorMessage = `Sign in failed. Your ${ssoProvider} login is not associated with a Gratavid account. Please ask your Gratavid admin to add your email to your organization's Gratavid account. If you are a Gratavid admin and would like to set up single sign-on (SSO), please visit https://graduwayhelp.zendesk.com/hc/en-us/articles/6656599048476 to learn more.`;
        }
        setState({ errorMessage });
      });
    window.localStorage.removeItem("loginWithProviderState");
  }

  return (
    <>
      <GrataStripes />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "100vh",
        }}>
        <div className="row justify-content-center" style={{ marginTop: "64px" }}>
          <div
            className="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 box-shadow"
            style={{
              position: "relative",
              minHeight: "350px",
              backgroundColor: "#fff",
              borderRadius: "4px",
            }}>
            <div
              className="justify-content-center"
              style={{ width: "100%", backgroundColor: "#fff" }}>
              <div
                className="box-shadow"
                style={{
                  zIndex: 998,
                  position: "absolute",
                  width: "64px",
                  height: "64px",
                  top: "-35px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "1px #fff solid",
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                }}>
                <img
                  src="https://gratavid.com/static/img/icon_padded.jpg"
                  width="100%"
                  alt="logo"
                />
              </div>
            </div>

            <div className="row col-12" style={{ padding: "0px" }}>
              <div className="col-12" style={{ margin: "42px 0px 20px 0px" }}>
                <h1 style={{ fontSize: "22px", textAlign: "center" }}>
                  Gratavid Sign In
                </h1>
                <p
                  className="greyDesc"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <i
                    className="material-icons"
                    title="assignment icon"
                    style={{ fontSize: "18px", marginRight: "5px" }}>
                    assignment
                  </i>
                  Sign In Form
                </p>
              </div>

              <div className="col-12 row" style={{ padding: "20px" }}>
                {state.errorMessage ? (
                  <>
                    <div className="errorMessageBox">
                      <b>{state.errorMessage}</b>
                    </div>
                    <div className="col-12 justify-content-center">
                      <Button
                        component={Link}
                        to={"/"}
                        variant="contained"
                        color="primary">
                        Log In
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="col-12" style={{ padding: "20px" }}>
                    <p>Please wait. Signing in...</p>
                    <LinearProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
