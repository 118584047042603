import React, { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";

export default function CopyTextInput(props) {
  let [snackbarOpen, setSnackbarOpen] = useState(false);
  const publicURLRef = useRef(null);

  let { label, copyURL } = props;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyURL);
    setSnackbarOpen(true);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor="publicURL">{label}</InputLabel>
        <Input
          id="publicURL"
          inputRef={publicURLRef}
          type="text"
          value={copyURL}
          disabled
          onChange={(e) => props.passedSetState(props.stateName, e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label={"copy " + label} onClick={copyToClipboard}>
                <i className="material-icons">content_copy</i>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <Snackbar
        autoHideDuration={2500}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        ContentProps={{ "aria-describedby": "public url was copied" }}
        message={<span id="message-id">URL copied</span>}
      />
    </>
  );
}
