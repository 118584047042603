import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import VideoReplyExpireDialog from "../contacts/profile/messenger/videoReplyExpireDialog";
import { toFullName } from "util/helpers";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function ReplyDialog(props) {
  let { accountInfo, currentReply, setReplySelected } = props;
  let {
    replyId,
    replyVideoSrc = null,
    replyText = "",
    convertedToVideoId,
    dateCreated,
  } = currentReply;
  let { contactId, noteId } = currentReply;
  const contact = props.currentReply.contact;

  const contactName = contact ? (
    <a
      className="link"
      target="_blank"
      rel="noopener noreferrer"
      href={`/a/${props.accountInfo.id}/contacts/` + contactId}>
      {toFullName(contact.firstName, contact.lastName, "Anonymous")}
    </a>
  ) : (
    "Anonymous"
  );

  const displayDate = new Date(currentReply.createdDate).toLocaleString();
  const source = currentReply.source === "lp" ? "note" : currentReply.source;

  const fullScreen = useMobileDialog();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={() => setReplySelected(null)}
      aria-labelledby="view reply dialog">
      <DialogContent
        style={{
          height: "auto",
          width: fullScreen ? "auto" : "600px",
          padding: "40px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <span className="greyDesc" style={{ fontSize: "14px" }}>
              {contactName} replied at {displayDate} via {source}
            </span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton
              aria-label="close"
              onClick={() => setReplySelected(false)}
              size="small">
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="row col-12">
          <div className="col-12" style={{ fontSize: "22px", paddingBottom: "5px" }}>
            {replyText && '"' + replyText + '"'}
          </div>
        </div>

        {replyVideoSrc && (
          <VideoReplyExpireDialog
            dateCreated={dateCreated}
            replyVideoSrc={replyVideoSrc}
            noteId={noteId}
            replyId={replyId}
            convertedToVideoId={convertedToVideoId}
            contactData={contact}
            accountId={accountInfo.id}
            history={props.history}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
