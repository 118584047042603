import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../util/buttons/loadingbutton";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import { objectToCsv } from "util/csv";
import { downloadFile } from "util/download";
import { getAccountVideos } from "services/api.service";

//this is used by note and videos toolbar at top of search to select multiple or all items
//contacts has it's own
//this is called from bulkActionForSearchTableHeader and from the individual noteItem and videoItem page

export default function DownloadPendingRequestDialog(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);

  const fullScreen = useMobileDialog();

  const downloadPendingRequest = async () => {
    try {
      setButtonState("loading");

      const fields = [
        "Video Title",
        "Status",
        "Recorder Name",
        "Request Directions",
        "Cell Phone Number",
        "Email",
        "Internal Edit Link",
        "Last Date Requested",
        "Upload Link",
        "videoId",
      ];

      let videos = props.objectsToDownload;
      if (props.allSelected) {
        const result = await getAccountVideos(
          null,
          props.hideTaskVids,
          false,
          0,
          0,
          null
        );
        videos = result?.rows ?? [];
      }

      const data = videos.map((video) => [
        video?.title ?? "Video Title",
        video.status,
        video?.users?.fullName ?? "Deleted User",
        video.directions,
        video.requestCell,
        video.requestEmail,
        `https://account.gratavid.com/videos/${video.id}`,
        video?.dateRequested ?? "NA",
        video.videoUrl,
        video.id,
      ]);

      const stringCsv = objectToCsv(data, {}, fields);
      downloadFile(stringCsv, `${props.indexName}.csv`);

      setButtonState("saved");
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="delete-item-title">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10 align-items-center">
            <h2>Download Pending Request</h2>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton aria-label="Delete" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          <div className="col-12">
            <p style={{ paddingTop: "5px" }}>
              Download {props.objectsToDownload.length} pending request to a csv/excel
              file. This makes it easy to review and share the upload links.
            </p>
          </div>

          {errorMessage && (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={buttonState}
              savedLabel={"Downloaded"}
              label={"Download Now"}
              clickedRegularButton={downloadPendingRequest}
              clickedSavedButton={() => props.setDialogOpen(false)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

DownloadPendingRequestDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  objectsToDownload: PropTypes.array.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  indexName: PropTypes.string.isRequired,
  hideTaskVids: PropTypes.bool,
  allSelected: PropTypes.bool,
  dialogOpen: PropTypes.bool,
};
