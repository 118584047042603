import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../util/buttons/loadingbutton";
import { Dialog, Slider, IconButton, Typography } from "@mui/material";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import { getVideosByDateRange, resendVideoRequests } from "services/api.service";

const DAY_MILLISECONDS = 86_400_000;
export default function ResendRequestDialog(props) {
  const [rangeDays, setRangeDays] = useState([3, 14]);
  const [count, setCount] = useState(0);
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);

  const fullScreen = useMobileDialog();

  let resendNow = async (e) => {
    try {
      //pass the searchConfig we used within doSearch to list all contacts to server
      e.preventDefault();
      if (rangeDays[0] === rangeDays[1])
        throw new Error("Both days in range can not be the same");
      setButtonState("loading");

      let videoIds = [];
      if (props.allSelected) {
        const startDay = rangeDays[0];
        const endDay = rangeDays[1];
        const now = Date.now();
        const endDate = now - DAY_MILLISECONDS * startDay;
        const startDate = now - DAY_MILLISECONDS * endDay;
        const result = await getVideosByDateRange(startDate, endDate);
        videoIds = result.map(({ id }) => id);
      } else {
        videoIds = props.objectIdsToUpdate;
      }

      if (videoIds.length === 0) {
        throw new Error("No videos within provided range");
      }

      const { sentCount } = await resendVideoRequests(videoIds);

      setCount(sentCount);
      setButtonState("saved");
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      setButtonState("regular");
      setErrorMessage(errorMessage);
    }
  };

  let selectedCount = props.allSelected ? "all" : props.objectIdsToUpdate.length;
  let oneDayMili = (24 * 60 * 60 + 1) * 1000;
  let now = Date.now();
  let startDateMili = now - oneDayMili * rangeDays[0];
  let endDateMili = now - oneDayMili * rangeDays[1];
  let formattedStartDate = new Date(startDateMili).toLocaleString();
  let formattedEndDate = new Date(endDateMili).toLocaleString();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="delete-item-title">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10 align-items-center">
            <h2>Resend Request</h2>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton aria-label="Delete" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          {props.allSelected ? (
            <>
              <div className="col-12" style={{ marginTop: "25px" }}>
                <Typography id="range-slider" gutterBottom>
                  <span>
                    Resend all pending request that were last requested between{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {rangeDays[0]} and {rangeDays[1]}
                    </span>{" "}
                    days ago.
                  </span>
                </Typography>
                <Slider
                  max={100}
                  style={{ marginTop: "50px" }}
                  value={rangeDays}
                  onChange={(e, value) => setRangeDays(value)}
                  valueLabelDisplay="on"
                  aria-labelledby="range-slider"
                />
                <p style={{ color: "grey", fontSize: "12px" }}>
                  Videos last requested between {formattedEndDate} and{" "}
                  {formattedStartDate} will be resent.
                </p>
              </div>
            </>
          ) : (
            <div className="col-12">
              <p style={{ paddingTop: "5px" }}>
                Are you sure you want to{" "}
                <b> resend the request for these {selectedCount} videos(s)? </b> Any items
                that already have a video will be skipped.
              </p>
            </div>
          )}

          {errorMessage ? (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          ) : (
            <>
              {props.allSelected && (
                <div className="notification-message-box">
                  <b>
                    This is a new feature and will only work for videos that were
                    requested after June 29th, 2020.
                  </b>
                </div>
              )}
            </>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={buttonState}
              savedLabel={`Resent ${count} request`}
              label={"Resend Now"}
              clickedRegularButton={resendNow}
              clickedSavedButton={() => props.setDialogOpen(false)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

ResendRequestDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  objectIdsToUpdate: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool,
  allSelected: PropTypes.bool,
};
