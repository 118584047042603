import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { getVideo } from "services/api.service";

export default function UploadTrim(props) {
  const connectionInterval = useRef(null);

  useEffect(() => {
    connectionInterval.current = setInterval(async () => {
      const video = await getVideo(props.videoId);
      if (video) {
        const {
          status = "trimUploading",
          videoUrl,
          thumbnailUrl,
          gifUrl,
          audioUrl,
          captions = null,
        } = video;

        props.videoUploadStatusChanged(
          status,
          videoUrl,
          videoUrl,
          null,
          thumbnailUrl,
          gifUrl,
          audioUrl,
          captions
        );
        if (status === "mediaURLUploaded") {
          clearInterval(connectionInterval.current);
          connectionInterval.current = null;
        }
      }
    }, 5000);

    return () => {
      if (connectionInterval?.current) {
        clearInterval(connectionInterval.current);
      }
    };
  }, []);

  let deleteMediaUpload = () => {
    props.videoUploadStatusChanged("create", null, null, null, null);
  };

  return (
    <div className="col-12 row pagePadding">
      <div className="col-12 row  adSectionBox justify-content-center">
        <div className="col-12">
          <h2>Trimming video</h2>
        </div>

        <div className="notification-message-box" style={{ margin: "10px" }}>
          <b>Video uploaded and processing.</b>
        </div>
        <div className="col-12">
          <LinearProgress />
        </div>
        <div
          className="col-12  justify-content-center align-items-center"
          style={{ padding: "10px 0px" }}>
          <Button variant="text" onClick={deleteMediaUpload}>
            <i className="material-icons" style={{ color: "black" }}>
              delete_forever
            </i>
            Delete video
          </Button>
        </div>
      </div>
    </div>
  );
}
