import React from "react";
import { Link } from "react-router";
import Button from "@mui/material/Button";

export default function NavBar() {
  let icon = <img alt="logo" src="/static/img/icon.svg" height="45px" width="45px" />;
  let fullLogo = <img alt="logo" src="/static/img/logo.png" height="45px" />;

  return (
    <div className="appBar">
      <div
        className="row hidden-sm-down"
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          padding: "5px 10px 5px 20px",
          margin: "0px",
        }}>
        <div style={{ width: "172px" }}>
          <a href="https://gratavid.com/">
            <Button>{fullLogo}</Button>
          </a>
        </div>

        <div
          style={{ flex: 1 }}
          className="d-flex align-items-center justify-content-end">
          <Button component={Link} to={"/"} variant="text">
            Log In
          </Button>
        </div>
      </div>

      <div
        className="row hidden-md-up"
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          padding: "5px",
          margin: "0px",
        }}>
        <div style={{ width: "55px" }}>
          <a href="https://gratavid.com/">
            <Button>{icon}</Button>
          </a>
        </div>
        <div
          style={{ flex: 4 }}
          className="d-flex align-items-center justify-content-end">
          <Button component={Link} to={"/"} variant="text">
            Log In
          </Button>
        </div>
      </div>
    </div>
  );
}
