import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import Item from "./item";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function RecipientsDialog({
  dialogOpen,
  closeDialog,
  contactAnalytics,
  taskContacts,
  accountInfo,
}) {
  const fullScreen = useMobileDialog();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="edit task dialog">
      <DialogContent
        style={{
          height: fullScreen ? "auto" : "550px",
          width: fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 22 }}>Recipients</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {taskContacts.map((current) => {
          return (
            <Item
              accountInfo={accountInfo}
              analytics={
                contactAnalytics ? contactAnalytics[current.contactId] : "loading"
              }
              taskContact={current}
              key={current.id}
            />
          );
        })}
      </DialogContent>
    </Dialog>
  );
}
