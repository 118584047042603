import React from "react";
import PropTypes from "prop-types";
import SortItem from "./sortItem";
import SortableList from "components/util/sortable/SortableList";

export default function SortVids(props) {
  return (
    <SortableList
      items={props.videos}
      onChange={props.saveVideos}
      renderItem={(item, index) => (
        <SortableList.Item id={item.id}>
          <div className="align-items-center">
            <SortableList.DragHandle>
              <i className="material-icons">drag_handle</i>
            </SortableList.DragHandle>
            <SortItem
              accountInfo={props.accountInfo}
              video={item}
              videos={props.videos}
              order={index + 1}
              removeVideo={props.removeVideo}
              calledFromTask={props.calledFromTask}
              passedSetState={props.passedSetState}
            />
          </div>
        </SortableList.Item>
      )}
    />
  );
}

SortVids.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      videoId: PropTypes.string,
      order: PropTypes.number,
      video: PropTypes.object,
    })
  ).isRequired,
  calledFromTask: PropTypes.bool,
  saveVideos: PropTypes.func,
  removeVideo: PropTypes.func.isRequired,
  passedSetState: PropTypes.func,
};
