import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Tooltip, Button, Chip } from "@mui/material";
import ContactItem from "./contactItem";
import { getFolderContacts } from "services/api.service";

const TrappTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    maxWidth: 500,
    color: "black",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    padding: "20px",
  },
}))(Tooltip);

export default function FolderItem(props) {
  const { folder } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [contactCount, setContactCount] = useState(0);

  const loadFolderContacts = useCallback(
    (offset = 0) => {
      setIsLoading(true);
      getFolderContacts(folder.id, offset, 20, props.noteHistoryId)
        .then((result) => {
          setContactCount(result.count);
          const deletedContacts = [];
          const existingContacts = [];
          for (const contact of result.rows) {
            if (contact.id || contact.email) {
              existingContacts.push(contact);
            } else {
              deletedContacts.push(contact);
            }
          }

          setContacts((current) => {
            if (deletedContacts?.length) {
              if (current[0]?.id === "deletedCount") {
                current[0].count += deletedContacts.length;
                current[0].firstName = `${current[0].count} Deleted Contacts`;
              } else {
                current.unshift({
                  id: "deletedCount",
                  count: deletedContacts.length,
                  firstName: `${deletedContacts.length} Deleted Contacts`,
                });
              }
            }
            return [...current, ...existingContacts];
          });
        })
        .finally(() => setIsLoading(false));
    },
    [folder.id]
  );

  useEffect(() => {
    loadFolderContacts();
  }, [loadFolderContacts]);

  const nextPage = contactCount > contacts.length;

  function clickedLoadNextPage(e) {
    e.stopPropagation(); // prevent contact search box from opening
    loadFolderContacts(contacts.length);
  }

  return (
    <Chip
      style={{
        margin: "3px",
        height: "24px",
        borderRadius: "5px",
        backgroundColor: "rgb(240, 241, 250)",
        border: "1px solid rgb(180, 187, 232)",
      }}
      label={
        <>
          <TrappTip
            placement="top"
            title={
              <div
                className="row col-12"
                style={{ maxHeight: "350px", overflowY: "scroll" }}>
                <div className="col-12" style={{ paddingBottom: "15px" }}>
                  <span style={{ fontSize: "18px" }}>{folder.name}</span>
                  <br />
                  <span style={{ fontSize: "14px", color: "grey" }}>
                    {contactCount} contacts
                  </span>
                </div>
                <div className="col-12">
                  {contacts.map((contact) => {
                    return (
                      <ContactItem
                        contact={contact}
                        key={contact.id}
                        mergeFields={props.mergeFields}
                      />
                    );
                  })}
                  {nextPage && (
                    <div
                      className="col-12 justify-content-center"
                      style={{ paddingTop: "10px" }}>
                      <Button
                        size="small"
                        onClick={(e) => clickedLoadNextPage(e, nextPage)}
                        variant="outlined">
                        Load More
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            }>
            <div style={{ cursor: "pointer", fontSize: "13px" }}>
              {folder.name}
              {!isLoading && <>({contactCount})</>}
            </div>
          </TrappTip>
        </>
      }
      onDelete={props.onRemoveFolder && (() => props.onRemoveFolder?.(props.folder))}
    />
  );
}

FolderItem.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  folder: PropTypes.object.isRequired,
  mergeFields: PropTypes.array,
  onRemoveFolder: PropTypes.func,
  noteHistoryId: PropTypes.string,
};
