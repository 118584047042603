import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import VideoEditor from "./videoEditor";
import TrimUploading from "./trimUploading";
import { trimVideo } from "services/api.service";

export default function TrimVideo(props) {
  const videoRef = useRef(null);
  let [state, setState] = useState({ buttonState: "regular" });
  let [rotate, setRotate] = useState("0");
  let [endTrimmedRatio, setEndTrimmedRatio] = useState(1);
  let [frontTrimmedRatio, setFrontTrimmedRatio] = useState(0);

  let submitTrimVideo = async () => {
    let video = videoRef.current;
    let fullVideoDuration = video.duration;

    if (frontTrimmedRatio === 0 && endTrimmedRatio === 1 && rotate === "0")
      throw new Error("Please make at least one edit (trim or rotate).");

    let startTime = fullVideoDuration * frontTrimmedRatio;
    let endTime = fullVideoDuration * endTrimmedRatio;
    let duration = endTime - startTime;
    let date = Date.now();
    let fileExtension = ".mp4";
    let newFileName = "video_" + date + fileExtension;

    setState({ buttonState: "loading" });

    try {
      await trimVideo(props.videoId, {
        duration,
        startTime,
        videoId: props.videoId,
        downloadURL: props.mediaURL,
        newFileName,
        rotate,
      });
      props.videoUploadStatusChanged(
        "trimUploading",
        null,
        null,
        null,
        "loading",
        null,
        null,
        null
      );
    } catch (err) {
      const errorMessage = err?.response?.data?.message ?? err.message;
      setState({ buttonState: "regular", errorMessage });
    }
  };

  if (props.status === "trimUploading") {
    return <TrimUploading {...props} />;
  }

  return (
    <div
      className="col-12 row pagePadding justify-content-center"
      style={{ marginTop: "10px" }}>
      <div className="notification-message-box">
        <p>
          Once you save your trim, you won&apos;t be able to access the original full
          video. We recommend{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6656642883612-How-to-Download-a-Video">
            downloading the original video
          </a>{" "}
          if you are worried about over trimming. You will also have to recreate captions
          if you trim or rotate your video.
        </p>
      </div>

      <div className="row col-12 justify-content-center" style={{ marginTop: "10px" }}>
        <VideoEditor
          mediaURL={props.mediaURL}
          endTrimmedRatio={endTrimmedRatio}
          setEndTrimmedRatio={setEndTrimmedRatio}
          frontTrimmedRatio={frontTrimmedRatio}
          setFrontTrimmedRatio={setFrontTrimmedRatio}
          videoRef={videoRef}
          rotate={rotate}
          setRotate={setRotate}
        />
      </div>

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="row col-12 justify-content-center" style={{ marginTop: "25px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel={"Edit Now"}
          label={"Save Edit"}
          clickedRegularButton={submitTrimVideo}
          clickedSavedButton={() => props.updateStatus("mediaURLUploaded")}
        />
      </div>

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}

TrimVideo.propTypes = {
  accountId: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  mediaURL: PropTypes.string,
  status: PropTypes.string.isRequired,
  videoUploadStatusChanged: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
};
