import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useParams } from "react-router";
import styles from "./roles.module.css";
import {
  deleteAccountUser,
  getAdminAccount,
  getAccountUsers,
} from "services/adminApi.service.js";

import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  Collapse,
} from "@mui/material";
import UserForm from "components/account/admin/components/userForm";
import Button from "components/util/button/button";
import SortableTableHead from "components/util/sortableTableHead/sortableTableHead";
import { sort, filterSearch } from "util/arrays";
import InputSearch from "components/util/inputSearch/inputSearch";

function initials(name) {
  const names = name.split(" ");
  return names[0][0] + (names[1]?.[0] ?? "");
}

function displayDate(date) {
  date = new Date(date);

  if (isNaN(date.getTime())) {
    return "";
  }

  return date.toLocaleDateString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export default function Roles() {
  const { accountUID } = useParams();

  const [account, setAccount] = useState();
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState(0);
  const [search, setSearch] = useState(null);
  const [editUser, setEditUser] = useState({});
  const [toDeleteUser, setToDeleteUser] = useState({});
  const [newUserModal, setNewUserModal] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    getAdminAccount(accountUID).then((data) => {
      setAccount(data);
    });
    getAccountUsers(accountUID).then((data) => {
      setUsers(data);
    });
  }, [accountUID]);

  const filteredUsers = useMemo(() => {
    return filterSearch(
      users,
      filter !== 0 &&
        ((user) =>
          user.roles.find((role) => role.accountId === accountUID)?.isAdmin === filter),
      ["fullName", "email"],
      search
    );
  }, [users, filter, search, accountUID]);

  // cannot delete last admin
  const allowDelete = useMemo(() => {
    return users.some((user) =>
      user.roles.find((role) => role.accountId === accountUID && role.isAdmin)
    );
  }, [users, accountUID]);

  const handleFilter = (_event, filter) => {
    setFilter(filter);
  };

  const handleEditUser = (user) => {
    setEditUser(editUser?.id === user.id ? {} : user);
  };

  const userChanged = (change) => {
    setUsers((users) =>
      users.map((user) => {
        if (user.id === change.id) {
          return { ...user, ...change };
        }
        return user;
      })
    );
    setEditUser({});
  };

  function userAdded(newUser) {
    newUser.createdDate = new Date();
    setNewUserModal(false);
    setUsers((users) => [...users, newUser]);
    setEditUser({});

    getAccountUsers(accountUID).then((data) => {
      setUsers(data);
    });
  }

  async function deleteUser() {
    try {
      await deleteAccountUser(accountUID, toDeleteUser.id);

      const index = users.findIndex((user) => user.id === toDeleteUser.id);
      users.splice(index, 1);
      setUsers([...users]);
    } catch {
      setShowError(true);
    }
    setToDeleteUser({});
  }

  function sortUsers(column, direction) {
    setUsers(sort(users, column, direction));
  }

  const checkIcon = <i className={"material-icons " + styles.checkIcon}>check</i>;

  return (
    <Paper elevation={3} className={styles.card}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>{account?.name}</h2>
          <h3 className={styles.subtitle}>Manage Users</h3>
        </div>

        <div className={styles.tools}>
          <InputSearch onSearch={setSearch} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setNewUserModal(true)}>
            <i className="material-icons">add</i>
            Add User
          </Button>
        </div>
      </div>

      <ToggleButtonGroup
        className={styles.filterGroup}
        value={filter}
        onChange={handleFilter}
        exclusive
        size="small"
        variant="contained"
        color="primary"
        aria-label="user filter">
        <ToggleButton value={0}>All</ToggleButton>
        <ToggleButton value={true}>Admins</ToggleButton>
        <ToggleButton value={false}>Users</ToggleButton>
      </ToggleButtonGroup>

      <Table size="small" className={styles.fixedTable}>
        <SortableTableHead
          columns={[
            { value: "avatar" },
            { name: "Name", value: "fullName" },
            {
              name: "User Type",
              value: "admin",
              sortable: false,
            },
            { name: "Date Added", value: "createdDate" },
            { name: "Email", value: "email" },
            { name: "Note Editor", value: "noteEditor", sortable: false },
            { name: "Bulk Sender", value: "bulkSender", sortable: false },
            { value: "actions", sortable: false },
          ]}
          onClick={sortUsers}
        />
        <TableBody>
          {filteredUsers.map((user) => (
            <Fragment key={user.id}>
              <TableRow
                className={[
                  styles.tableRow,
                  editUser?.id === user.id && styles.selectedRow,
                ].join(" ")}>
                <TableCell>
                  <Avatar className={styles.avatar}>
                    {initials(user?.fullName ?? "No Name")}
                  </Avatar>
                </TableCell>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>
                  {user?.roles.find((role) => role.accountId === accountUID)?.isAdmin
                    ? "Admin"
                    : "User"}
                </TableCell>
                <TableCell>{displayDate(user.createdDate)}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user?.roles.find((role) => role.accountId === accountUID)
                    ?.isNoteEditor && checkIcon}
                </TableCell>
                <TableCell>
                  {user?.roles.find((role) => role.accountId === accountUID)
                    ?.isBulkSender && checkIcon}
                </TableCell>
                <TableCell align="right" className={styles.actionButtons}>
                  <IconButton aria-label="edit" onClick={() => handleEditUser(user)}>
                    <i className="material-icons">edit</i>
                  </IconButton>
                  {allowDelete && (
                    <IconButton aria-label="delete" onClick={() => setToDeleteUser(user)}>
                      <i className="material-icons">delete</i>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>

              <TableRow className={styles.tableRow}>
                <TableCell colSpan="8" className={styles.editUser}>
                  <Collapse in={editUser?.id === user.id} unmountOnExit>
                    <div>
                      <h4>Edit User Info</h4>
                      <UserForm
                        accountUID={accountUID}
                        user={user}
                        onChange={userChanged}
                        onError={() => setShowError(true)}></UserForm>
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <div className={styles.footer}>
        Showing {filteredUsers.length} of {users.length} Users
      </div>

      <Dialog maxWidth="md" open={newUserModal} onClose={() => setNewUserModal(false)}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <UserForm
            accountUID={accountUID}
            onChange={userAdded}
            onError={() => setShowError(true)}
            onCancel={() => setNewUserModal(false)}></UserForm>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth="md" open={!!toDeleteUser.id} onClose={() => setToDeleteUser({})}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          Are you sure you want to delete user {toDeleteUser.fullName}?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={() => setToDeleteUser({})}>
            No
          </Button>
          <Button variant="contained" color="secondary" onClick={deleteUser}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={() => setShowError(false)}>
        <Alert severity="error">Something went wrong</Alert>
      </Snackbar>
    </Paper>
  );
}

Roles.propTypes = {};
