import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EditTask from "./editTask/editTask";
import { Dialog, FormControlLabel } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import TaskItem from "./item/taskItem";
import ActionRow from "./actions/actionRow";
import { Checkbox, Button } from "@mui/material";
import CreateTaskFromHyperlink from "./createTaskFromHyperlink";
import LoadingButton from "../../../util/buttons/loadingbutton";
import { getTasks } from "services/api.service";
import { getSearchParams } from "util/helpers";
import Card from "components/util/card/card";
import style from "./listTask.module.css";
import AssigneeChips from "./arrange/assigneeChips";

function getSortAttributes(sortBy) {
  let sortKey, sortDirection;
  if (sortBy === "newToOld") {
    sortKey = "createdDate";
    sortDirection = "desc";
  } else if (sortBy === "oldToNew") {
    sortKey = "createdDate";
    sortDirection = "asc";
  } else if (sortBy === "commentsAZ") {
    sortKey = "comments";
    sortDirection = "asc";
  } else if (sortBy === "commentsZA") {
    sortKey = "comments";
    sortDirection = "desc";
  } else if (sortBy === "noVids") {
    sortKey = "comments";
    sortDirection = "asc";
  }

  return { sortKey, sortDirection };
}

export default function ListTask(props) {
  let { accountCustomProperties, customContactProperties } = props;
  let [tasks, setTasks] = useState("loading");
  let [editTaskSelected, setEditTaskSelected] = useState(null);
  let [allSelected, setAllSelected] = useState(false);
  let [selectedItems, setSelectedItems] = useState([]);
  let [sortBy, setSortBy] = useState("newToOld");
  let urlAssignedToUID = getSearchParams().get("assignedTo") || "";
  let [assignedToUserUID, setAssignedToUserUID] = useState(urlAssignedToUID);
  let [state, setState] = useState({});
  let [totalTasks, setTotalTasks] = useState(0);
  let [loadMore, setLoadMore] = useState(null);
  let [loadingMoreList, setLoadingMoreList] = useState(null);
  let showTaskActionAsDialog = useMediaQuery("(max-width:900px)");
  let [userEmailProvider, setUserEmailProvider] = useState(
    props.userInfo.emailProvider || "default"
  );
  const [offset, setOffset] = useState(0);

  const getTask = async (isInit) => {
    try {
      const INITIAL_LIMIT = 20;
      let limit = INITIAL_LIMIT;
      let startIndex = offset;
      if (isInit) {
        startIndex = 0;
        setTasks("loading");
        setEditTaskSelected(null);
      } else {
        limit = 100;
        setLoadingMoreList(true);
      }

      const { sortKey, sortDirection } = getSortAttributes(sortBy);
      const data = await getTasks(
        assignedToUserUID,
        "UNSENT",
        {
          sortBy: sortKey,
          sortDirection,
          limit,
          offset: startIndex,
        },
        sortBy === "noVids"
      );

      if (startIndex === 0) {
        setOffset(INITIAL_LIMIT);
      } else {
        setOffset(startIndex + 100);
      }

      if (isInit) {
        //if mobile don't start with task open or else you get dialog takeover
        const startWithTaskOpen = window.innerWidth > 900 ? true : false;

        const taskIdFromUrl = getSearchParams().get("taskId") || null;
        const selectedTask = taskIdFromUrl
          ? data.rows.find((task) => task.id === taskIdFromUrl)
          : data.rows[0];

        setTotalTasks(data.count);
        setTasks(data.rows);

        //if the taskId passed in from url exist, then start with it selected and open
        //don't set a selected tasks if assignedTo user was passed in url. will throw error
        if (!getSearchParams().get("assignedTo") && selectedTask) {
          setSelectedItems([selectedTask]);

          if (taskIdFromUrl || (!taskIdFromUrl && startWithTaskOpen)) {
            // Open task panel if task Id came from URL or if we're not on mobile
            setEditTaskSelected(selectedTask);
          }
        }
      } else {
        setTasks([...tasks, ...data.rows]);
        setLoadingMoreList(false);
        if (allSelected) {
          setSelectedItems([...tasks, ...data.rows]);
        }
      }
    } catch (err) {
      console.error(err.message);
      setTasks([]);
      setState({ errorMessage: err.message });
    }
  };
  useEffect(() => {
    // Reset the list
    getTask(true);
  }, [sortBy, assignedToUserUID]);

  useEffect(() => {
    //this useEffect is called when the show more button is clicked
    if (!loadMore) {
      return;
    }

    getTask();
  }, [loadMore]);

  let selectAll = () => {
    setEditTaskSelected(null);
    setAllSelected(!allSelected);
    if (!allSelected) {
      setSelectedItems(tasks);
    } else {
      setSelectedItems([]);
    }
  };

  function updateTasks(tasks) {
    setTasks(tasks);

    if (editTaskSelected) {
      const task = tasks.find((task) => task.id === editTaskSelected.id);
      setEditTaskSelected(task);
    }

    if (selectedItems.length) {
      const selectedTasks = tasks.filter((task) =>
        selectedItems.find((selectedTask) => selectedTask.id === task.id)
      );
      setSelectedItems(selectedTasks);
    }
  }

  let selectedKeys = props.selectedTask ? Object.keys(props.selectedTask) : [];
  let selectedCount = selectedKeys.length;

  return (
    <div className={style.layout}>
      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <AssigneeChips
        {...props}
        assignedToUserUID={assignedToUserUID}
        setAssignedToUserUID={setAssignedToUserUID}
        selectedCount={selectedCount}
      />

      <Card>
        <div className={style.menu}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={allSelected}
                onClick={() => selectAll()}
              />
            }
            label="Select all"></FormControlLabel>

          <ActionRow
            {...props}
            tasks={tasks}
            users={props.users}
            setTasks={updateTasks}
            setEditTaskSelected={setEditTaskSelected}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            userEmailProvider={userEmailProvider}
            setUserEmailProvider={setUserEmailProvider}
            assignedToUserUID={assignedToUserUID}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </div>
      </Card>

      {allSelected && (
        <div className={style.selectAll}>
          {totalTasks > tasks.length ? (
            <>
              Selected all {tasks?.length || 0} tasks that are showing.
              <Button onClick={() => setLoadMore(Date.now())}>Select More</Button>
            </>
          ) : (
            <>All {tasks?.length || 0} tasks selected</>
          )}
        </div>
      )}

      <div className={style.list}>
        {tasks === "loading" ? (
          <div className={style.noTasks}>
            <LinearProgress />
          </div>
        ) : tasks.length === 0 ? (
          <Card>
            <div className={style.noTasks}>
              <p>No items on your task list.</p>
              <Button
                size="small"
                onClick={() => props.setAddNewDialogOpen(true)}
                variant="contained"
                color="primary"
                startIcon={<i className="material-icons">add</i>}>
                Add Task
              </Button>
            </div>
          </Card>
        ) : (
          <>
            <Card noPadding>
              {tasks.map((task) => {
                return (
                  <TaskItem
                    key={task.id}
                    tasks={tasks}
                    setTasks={updateTasks}
                    setEditTaskSelected={setEditTaskSelected}
                    editTaskSelected={editTaskSelected}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectAll={selectAll}
                    allSelected={allSelected}
                    setAllSelected={setAllSelected}
                    current={task}
                    {...props}
                  />
                );
              })}
              {totalTasks > tasks.length && (
                <div className={style.loadMore}>
                  <LoadingButton
                    type="button"
                    variant="outlined"
                    size="small"
                    buttonState={loadingMoreList ? "loading" : null}
                    savedLabel={"Loaded"}
                    label="Show more"
                    clickedRegularButton={() => setLoadMore(Date.now())}
                    clickedSavedButton={() => {
                      return null;
                    }}
                  />
                </div>
              )}
            </Card>

            {editTaskSelected &&
              (showTaskActionAsDialog ? (
                <Dialog
                  fullScreen={true}
                  open={true}
                  onClose={() => setEditTaskSelected(null)}
                  aria-labelledby="add note dialog">
                  <div className={style.editTaskDialog}>
                    <EditTask
                      currentTask={editTaskSelected}
                      key={editTaskSelected.id}
                      setEditTaskSelected={setEditTaskSelected}
                      tasks={tasks}
                      setTasks={updateTasks}
                      accountInfo={props.accountInfo}
                      userInfo={props.userInfo}
                      showTaskActionAsDialog={showTaskActionAsDialog}
                      ssoProvider={props.ssoProvider}
                      userEmailProvider={userEmailProvider}
                      setUserEmailProvider={setUserEmailProvider}
                      setTabSelected={props.setTabSelected}
                      accountCustomProperties={accountCustomProperties}
                      customContactProperties={customContactProperties}
                      userIsAdmin={props.userIsAdmin}
                    />
                  </div>
                </Dialog>
              ) : (
                <div className={style.editTask}>
                  <Card>
                    <EditTask
                      currentTask={editTaskSelected}
                      key={editTaskSelected.id}
                      setEditTaskSelected={setEditTaskSelected}
                      tasks={tasks}
                      setTasks={updateTasks}
                      accountInfo={props.accountInfo}
                      userInfo={props.userInfo}
                      ssoProvider={props.ssoProvider}
                      userEmailProvider={userEmailProvider}
                      setUserEmailProvider={setUserEmailProvider}
                      setTabSelected={props.setTabSelected}
                      accountCustomProperties={accountCustomProperties}
                      customContactProperties={customContactProperties}
                      userIsAdmin={props.userIsAdmin}
                    />
                  </Card>
                </div>
              ))}
          </>
        )}
      </div>

      <CreateTaskFromHyperlink
        accountInfo={props.accountInfo}
        refreshTaskList={getTask}
      />
    </div>
  );
}

ListTask.propTypes = {
  userInfo: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  accountCustomProperties: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  customContactProperties: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired,
  ]),
  setAddNewDialogOpen: PropTypes.func.isRequired,
  setTabSelected: PropTypes.func.isRequired,
  allSelected: PropTypes.bool,
  ssoProvider: PropTypes.string,
  selectedTask: PropTypes.string,
  users: PropTypes.array.isRequired,
};
