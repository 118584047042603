import React, { useRef, useEffect } from "react";
import { Button, LinearProgress } from "@mui/material";
import { getAccountIntegrations } from "services/api.service";

export default function Bloomerang(props) {
  let { integrationDetails, connectionInfo } = props;

  const connectionInterval = useRef(null);

  useEffect(() => {
    connectionInterval.current = setInterval(async () => {
      const integrations = await getAccountIntegrations();
      if (integrations?.endpoint?.bloomerang?.connectionInfo) {
        props.setConnectionInfo(integrations?.endpoint?.bloomerang?.connectionInfo);
        clearInterval(connectionInterval.current);
        connectionInterval.current = null;
      }
    }, 5000);

    return () => {
      if (connectionInterval?.current) {
        clearInterval(connectionInterval.current);
      }
    };
  }, []);

  let { header, logoSrc, brandHex } = integrationDetails;

  let openPopupAuth = async () => {
    try {
      let redirect_uri = `${process.env.REACT_APP_API_ENDPOINT}vendors/integrations-endpoint?source=bloomerang%26event=authenticate`;

      let bbFullAuthURL =
        `https://crm.bloomerang.co/Authorize?client_id=${process.env.REACT_APP_BLOOMERANG_CLIENT_ID}&response_type=code&redirect_uri=` +
        redirect_uri +
        "&state=" +
        props.userInfo.id +
        "_" +
        props.accountInfo.id;
      let popup = window.open(bbFullAuthURL, "login", "height=450,width=600,");
      if (window.focus) popup.focus();
    } catch (err) {
      console.error(err.message);
    }
  };

  if (connectionInfo === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        {" "}
        <LinearProgress />
      </div>
    );

  return (
    <div className="col-12 row">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            marginRight: "8px",
            width: "45px",
            height: "45px",
            backgroundColor: brandHex,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={logoSrc} width="50px" alt="logo" />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <h1>{header}</h1>
        <span style={{ display: "flex", alignItems: "center" }}>
          <i
            className="material-icons"
            title="check icon"
            style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
            cancel
          </i>
          Your account is not connected.
        </span>
        <Button
          onClick={() => openPopupAuth()}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}>
          Connect To Bloomerang
        </Button>
      </div>
    </div>
  );
}
