import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ItemAnalytics from "../../../../util/analytics/itemAnalytics";
import getMergedString from "../../../../util/contacts/getMergedString";
import { getVideo } from "services/api.service";
import { IconButton, Menu, MenuItem } from "@mui/material";

export default function NoteItem(props) {
  const [firstVideoData, setFirstVideoData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { accountId, contactId, videoId, accountCustomProperties = {} } = props;
  const { noteId, taskId, contentId } = props.current;

  const note =
    props.current.note.contents?.find((content) => content.id === contentId) ||
    props.current.note;

  useEffect(() => {
    let getVideoData = async () => {
      try {
        if (videoId) {
          setFirstVideoData(await getVideo(videoId));
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    getVideoData();
  }, [accountId, videoId]);

  if (!note) return null;

  let noteURL =
    `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
    noteId +
    "&cId=" +
    contactId +
    "&preview=true&showPreviewNotification=1";
  if (taskId) noteURL += "&tId=" + taskId;
  if (contentId) noteURL += "&contentId=" + contentId;

  const messageHtml =
    props.current.task?.message ||
    note.message ||
    note.messageHtml ||
    props.current.note.messageHtml;

  const message = getMergedString(
    accountCustomProperties,
    messageHtml,
    props.contactData
  );

  const showDate = new Date(props.current.updatedDate).toLocaleString();
  return (
    <div className="row col-12" style={{ marginBottom: "25px" }}>
      <div className="col-12 justify-content-center" style={{ margin: "12px 0px" }}>
        <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, .40)", fontWeight: 500 }}>
          {showDate}
        </span>
      </div>

      <div className="row col-12">
        {props.current.embedCodeCopied && (
          <span
            className="col-12 justify-content-end"
            style={{
              fontSize: "12px",
              color: "rgba(0, 0, 0, .60)",
              fontWeight: 500,
              paddingBottom: "5px",
            }}>
            <i
              className="material-icons"
              style={{
                fontSize: "13px",
                color: "rgba(0, 0, 0, .40)",
                marginRight: "3px",
              }}>
              file_copy
            </i>
            Embed video was copied
          </span>
        )}

        <div style={{ flex: 1 }} className="justify-content-end">
          <div
            style={{
              backgroundColor: "#0099FF",
              borderRadius: "1.3em",
              padding: "6px 12px",
              maxWidth: "70%",
              borderBottomRightRadius: "5px",
            }}>
            <span style={{ fontSize: "15px", color: "white", whiteSpace: "pre-line" }}>
              {message}
            </span>
          </div>
        </div>
        <div style={{ width: "0px" }} className="align-items-center">
          <IconButton
            aria-label="More"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            size="small">
            <i className="material-icons">more_vert</i>
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={anchorEl ? true : false}
            onClose={() => setAnchorEl(null)}
            PaperProps={{ style: { maxHeight: 200, width: 200 } }}>
            <MenuItem key={2} button onClick={() => window.open(noteURL, "_blank")}>
              Preview Link
            </MenuItem>
          </Menu>
        </div>
      </div>

      {firstVideoData?.thumbnailUrl && (
        <div className="col-12 justify-content-end" style={{ marginTop: "5px" }}>
          <a
            href={noteURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: "normal" }}>
            <img
              src={firstVideoData.thumbnailUrl}
              style={{
                width: "75px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="video thumbnail"
            />
          </a>
        </div>
      )}

      <div className="col-12 justify-content-end" style={{ marginTop: "5px" }}>
        <ItemAnalytics analytics={props.current.analytics} />
      </div>
    </div>
  );
}

NoteItem.propTypes = {
  accountId: PropTypes.string.isRequired,
  current: PropTypes.shape({
    noteId: PropTypes.string.isRequired,
    taskId: PropTypes.string,
    contentId: PropTypes.string,
    gvSendId: PropTypes.string.isRequired,
    embedCodeCopied: PropTypes.bool,
    noteHistoryVideos: PropTypes.arrayOf(
      PropTypes.shape({
        videoId: PropTypes.string.isRequired,
      })
    ).isRequired,
    createdDate: PropTypes.string.isRequired,
    updatedDate: PropTypes.string.isRequired,
    note: PropTypes.shape({
      messageHtml: PropTypes.string,
      contents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          videoIds: PropTypes.arrayOf(PropTypes.string),
          message: PropTypes.string,
        })
      ),
    }),
    task: PropTypes.shape({
      message: PropTypes.string,
    }),
    analytics: PropTypes.any,
  }).isRequired,
  videoId: PropTypes.string,
  contactId: PropTypes.string.isRequired,
  contactData: PropTypes.object.isRequired,
  accountCustomProperties: PropTypes.object,
};
