import React, { useState } from "react";
import PropTypes from "prop-types";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import SlatePublicUrl from "./slatePublicUrl";
import ListQueries from "./actions/listQueries";
import { Tabs, Tab } from "@mui/material";

export default function Slate(props) {
  let [tabSelected, setTabSelected] = useState(0);

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  return (
    <div className="col-12 row">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            marginRight: "8px",
            width: "45px",
            height: "45px",
            backgroundColor: props.integrationDetails.brandHex,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={props.integrationDetails.logoSrc} width="50px" alt="logo" />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div className="row col-12 align-items-center">
          <h1>{props.integrationDetails.header}</h1>
          <QuestionToLink id="slateIntegration" desc="Slate Integration" />
        </div>
      </div>

      <div
        className="col-12"
        style={{
          borderBottom: "1px #ddd solid",
          padding: "0px 8.5px",
          maxWidth: "100vw",
          marginTop: 25,
        }}>
        <Tabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="task tabs"
          variant="scrollable"
          scrollButtons="auto">
          <Tab label="Query Actions" {...a11yProps("Query Actions")} value={0} />
          <Tab label="Remote Server" {...a11yProps("Remote Server")} value={1} />
        </Tabs>
      </div>

      {tabSelected === 0 && (
        <ListQueries
          accountInfo={props.accountInfo}
          integration={props.integration}
          users={props.users}
        />
      )}
      {tabSelected === 1 && (
        <SlatePublicUrl accountId={props.accountInfo.id} apiKey={props.apiKey} />
      )}

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}

Slate.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  integrationDetails: PropTypes.shape({
    header: PropTypes.string.isRequired,
    logoSrc: PropTypes.string.isRequired,
    brandHex: PropTypes.string,
  }),
  integration: PropTypes.object.isRequired,
  apiKey: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};
