import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LinearProgress, IconButton, Dialog } from "@mui/material";
import EditInput from "./editInput";
import EditExistingTags from "../../../util/tags/editExistingTags";
import { toFullName } from "util/helpers";
import { getContactProperties, flattenCustomProperties } from "util/contacts";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function EditContact(props) {
  let { contactData, accountInfo } = props;
  contactData = flattenCustomProperties(accountInfo, contactData);
  let [state, setState] = useState({});
  let [showDialog, setShowDialog] = useState(false);
  const [contactProperties, setContactProperties] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fullScreen = useMobileDialog();

  useEffect(() => {
    const properties = getContactProperties(accountInfo);
    const formState = {};
    Object.keys(properties).forEach((key) => {
      formState[key] = contactData?.[key] || "";
    });
    setContactProperties(properties);
    setState(formState);
    setIsLoading(false);
  }, [accountInfo, contactData]);

  // try to remove this
  let passedSetState = (state, value) => {
    setState((prevState) => {
      return { ...prevState, [state]: value };
    });
  };

  if (isLoading)
    return (
      <div style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );

  // functionalize this
  let contactPropertiesKeys = contactProperties && Object.keys(contactProperties);
  let editProperties = contactPropertiesKeys.map((key) => {
    let current = contactProperties[key];
    return (
      <EditInput
        id={key}
        key={key}
        contactData={contactData}
        value={state[key]}
        passedSetState={passedSetState}
        setState={setState}
        label={current.label}
        fullScreen={fullScreen}
        accountId={accountInfo.id}
        accountInfo={accountInfo}
      />
    );
  });

  let { email, firstName, lastName, cellNumber } = contactData;
  const label = toFullName(firstName, lastName, "Deleted User");

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "55px" }}>
        <img
          src="/static/img/profile.svg"
          style={{ width: "40px" }}
          alt="generic contact profile pic"
        />
      </div>
      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        {contactData === "loading" ? (
          <LinearProgress />
        ) : (
          <div>
            <h1 style={{ fontSize: "18px" }}>{label}</h1>
            <span className="greyDesc" style={{ fontSize: "12px" }}>
              {email}
            </span>
            {cellNumber && (
              <span className="greyDesc" style={{ fontSize: "12px", marginLeft: "5px" }}>
                ({cellNumber})
              </span>
            )}
          </div>
        )}
      </div>
      <div style={{ width: "45px" }}>
        <IconButton onClick={() => setShowDialog(true)}>
          <i className="material-icons">edit</i>
        </IconButton>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="edit contact info">
        <div style={{ minWidth: "250px", padding: "20px" }}>
          <div
            className="row col-12 pagePadding"
            style={{ padding: "20px", minWidth: "250px" }}>
            <div className="col-10">
              <h2>{label}</h2>
              <p className="greyDesc" style={{ fontSize: "14px" }}>
                Edit properties for {label}
              </p>
            </div>

            <div className="col-2 justify-content-end">
              <IconButton aria-label="close" onClick={() => setShowDialog(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>

            <div className="col-12">{editProperties}</div>

            <div className="col-12" style={{ marginTop: "15px" }}>
              <EditExistingTags
                accountInfo={props.accountInfo}
                contactId={props.contactData.id}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

EditContact.propTypes = {
  contactData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    cellNumber: PropTypes.string,
  }),
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customContactProperties: PropTypes.object,
  }).isRequired,
  fullScreen: PropTypes.bool,
};
