import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ContactItemSendStatus from "./contactItemSendStatus";

const TrappTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "whitesmoke",
    maxWidth: 220,
    color: "black",
    fontSize: "12px",
    boxShadow:
      "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    border: "2px solid darkgrey",
    padding: "10px",
  },
}))(Tooltip);

export default function ContactItem(props) {
  let [state, setState] = useState({});

  let { contact } = props;

  let icon = "";
  let iconColor = "";
  let toolTipTitle;

  //if contact has optedIn, make sure they are still eligible and haven't been text within 3 days
  if (contact.textOptIn) {
    iconColor = "green";
    icon = "mobile_friendly";
    toolTipTitle = "Opted in to text.";
  } else {
    iconColor = "red";
    icon = "phonelink_erase";
    toolTipTitle = "Has not opted in to text.";
  }

  let { email, firstName, lastName, dobMonth, dobDay } = contact;
  firstName = firstName ? firstName : "";
  lastName = lastName ? lastName : "";
  let name = firstName + " " + lastName;
  let label = name === " " ? email : name;

  return (
    <div className="row col-12" style={{ padding: "15px 0px", display: "flex" }}>
      <div style={{ width: "55px", display: "flex", alignItems: "center" }}>
        <span style={{ fontSize: "13px", width: "55px", fontColor: "grey" }}>
          {dobMonth + "/" + dobDay}
        </span>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <TrappTip
          placement="top"
          title={
            <React.Fragment>
              <Typography color="inherit">
                {contact.firstName} {contact.lastName}
              </Typography>
              <Typography color="inherit">Birthday: {dobMonth + "/" + dobDay}</Typography>
              <Typography color="inherit">{contact.email}</Typography>
              <Typography color="inherit">{contact.cellNumber}</Typography>
              <div>
                <i
                  className="material-icons"
                  style={{ fontSize: "12px", color: iconColor, marginRight: "3px" }}>
                  {icon}
                </i>
                <span style={{ fontSize: "12px" }}>{toolTipTitle}</span>
              </div>
              <br />
              <a
                href={`/a/${props.accountInfo.id}/contacts/` + contact.id}
                style={{ fontSize: "12px" }}
                className="link"
                target="_blank"
                rel="noopener noreferrer">
                Edit Contact
              </a>
            </React.Fragment>
          }>
          <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "13px" }}>{label}</span>
            <i
              className="material-icons"
              style={{ fontSize: "18px", color: iconColor, marginRight: "3px" }}>
              {icon}
            </i>
          </div>
        </TrappTip>
      </div>

      <ContactItemSendStatus
        {...props}
        state={state}
        setState={setState}
        TrappTip={TrappTip}
      />

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}
    </div>
  );
}
