import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../util/buttons/loadingbutton";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import { deleteVideos } from "services/api.service";
import { useNavigate } from "react-router";

export default function DeleteVideoDialog(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);

  const fullScreen = useMobileDialog();
  const navigate = useNavigate();

  let deleteVideoObject = async (e) => {
    e.preventDefault();
    setButtonState("loading");

    try {
      await deleteVideos([props.id]);
      setButtonState("saved");
      setErrorMessage(null);
      navigate(`/a/${props.accountId}/videos`);
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.deleteDialogOpen}
      onClose={() => props.setDeleteDialogOpen(false)}
      aria-labelledby="delete-video-title">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10 align-items-center">
            <h2>Confirm Delete</h2>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton
              aria-label="Delete"
              onClick={() => props.setDeleteDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          <div className="col-12">
            <p style={{ paddingTop: "5px" }}>
              Are you sure you want to <b> delete {props.title}</b>? You can not undo this
              action. Any note this video has been used in will no longer show a video.
            </p>
          </div>

          {errorMessage && (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={buttonState}
              savedLabel={"Video Deleted"}
              label={"Delete Now"}
              clickedRegularButton={deleteVideoObject}
              clickedSavedButton={() => props.setDeleteDialogOpen(false)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

DeleteVideoDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  deleteDialogOpen: PropTypes.bool,
};
