import React, { useEffect, useState } from "react";
import { Popover, Button, IconButton, MenuItem, MenuList, Paper } from "@mui/material";

export default function ShareButtons(props) {
  let [shareAnchorEl, setShareAnchorEl] = useState(null);
  let [urls, setUrls] = useState({ fb: null, twitter: null, sms: null });

  useEffect(() => {
    getUrls();
  }, []);

  let getUrls = () => {
    let ua = navigator && navigator.userAgent.toLowerCase();
    let url =
      `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
      props.noteId +
      "&socialshare=true";
    url = props.contactId ? url + "&cId=" + props.contactId : url;
    url = props.taskId ? url + "&tId=" + props.taskId : url;
    let encodedUrl = encodeURIComponent(url);
    let fbURL = "https://www.facebook.com/sharer/sharer.php?u=" + encodedUrl;
    let twitterURL =
      "https://twitter.com/intent/tweet?url=" +
      encodedUrl +
      "&text=" +
      props.senderName +
      " shared a video ";
    let smsUrl;

    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      smsUrl = "sms:&body=" + props.senderName + " shared a video " + encodedUrl;
    } else {
      smsUrl = null;
    }

    setUrls({ fb: fbURL, twitter: twitterURL, sms: smsUrl });
  };

  let openShareOptions = (e) => {
    setShareAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  let closeShareOptions = (e) => {
    setShareAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <>
      {props.calledFromVideoDone ? (
        <Button
          variant="outlined"
          onClick={(e) => openShareOptions(e)}
          fullWidth
          aria-label="open share video options">
          <div
            className="align-items-center justify-content-center"
            style={{ borderRadius: "50%", width: "35px", height: "35px" }}>
            <img
              src="https://gratavid.com/static/icon/share.svg"
              width="35px"
              alt="watch again"
            />
          </div>
          <span style={{ color: "white", marginLeft: "5px" }}>Share</span>
        </Button>
      ) : (
        <IconButton
          size="small"
          style={{
            width: "36px",
            WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
            filter: "drop-shadow(0 6px 22px rgba(0,0,0,1))",
            cursor: "pointer",
          }}
          onClick={(e) => openShareOptions(e)}
          aria-label="open share video options">
          <img
            className="videoPlayPauseButton"
            src="https://gratavid.com/static/icon/share.svg"
            height="30px"
            alt="share icon"
          />
        </IconButton>
      )}

      <Popover
        style={{ zIndex: 1001 }}
        open={Boolean(shareAnchorEl)}
        anchorEl={shareAnchorEl}
        onClose={(e) => closeShareOptions(e)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Paper id="menu-list-share">
          <MenuList>
            <MenuItem
              component="a"
              href={urls.fb}
              target="_blank"
              rel="noopener noreferrer">
              <div
                className="socialShareButton facebookBtn align-items-center"
                style={{ padding: "10px 20px" }}>
                <img
                  src="/static/img/facebook_logo.svg"
                  style={{ height: "16px", width: "16px" }}
                  alt="Facebook logo"
                />
                <span className="socialShareButtonText">Share</span>
              </div>
            </MenuItem>

            <MenuItem
              component="a"
              href={urls.twitter}
              target="_blank"
              rel="noopener noreferrer">
              <div
                className="socialShareButton twitterBtn align-items-center"
                style={{ padding: "10px 20px" }}>
                <img
                  src="/static/img/twitter_logo.svg"
                  style={{ height: "16px", width: "16px" }}
                  alt="Twitter logo"
                />
                <span className="socialShareButtonText">Tweet</span>
              </div>
            </MenuItem>

            {urls.sms && (
              <MenuItem
                component="a"
                href={urls.sms}
                target="_blank"
                rel="noopener noreferrer">
                <div
                  className="socialShareButton smsBtn align-items-center"
                  style={{ padding: "10px 20px" }}>
                  <img
                    src="/static/img/chat_bubble.svg"
                    style={{ height: "16px", width: "16px" }}
                    alt="SMS logo"
                  />
                  <span className="socialShareButtonText">Text</span>
                </div>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}
