import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import ContactItem from "../contacts/selectedContacts/contactItem";
import FolderItem from "../contacts/selectedContacts/folderItem";
import EditNoteContentDialog from "./editNoteContentDialog";
import CancelSendDialog from "./cancelSendDialog";
import PreviewOrEmbedByContact from "../../../../util/shareOptions/previewOrEmbedByContact";
import timeBeforeDate from "../../../../util/functions/timeBeforeDate";
import PreviewNoteButton from "../../../../util/shareOptions/previewNoteButton";
import { useGetUserContactData } from "../../../../util/hooks/users/useGetUserContactData";
import styles from "./item.module.css";

export default function ScheduledHistoryItem(props) {
  let initalOpen = props.index === 0 && props.scheduledItems.length === 1 ? true : false;
  let [open, setOpen] = useState(initalOpen ? true : false);
  let [forceLoadFreshNoteContent, setForceLoadFreshNoteContent] = useState(null);
  let [editDialogOpen, setEditDialogOpen] = useState(false);
  let [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  let expansionPanelClass = open
    ? "expansionPanel-1000"
    : "expansionPanel-1000 collapsible";
  let formattedSendAtDate = timeBeforeDate(new Date(props.current.scheduledDate));
  let sendAtDateString = props.current.scheduledDate
    ? new Date(props.current.scheduledDate).toLocaleString()
    : "";
  let [userData] = useGetUserContactData(
    props.current.sentByUserId || props.current.createdByUserId
  );

  let contactsHTML = (
    props.calledFromTask ? props.current.taskContacts : props.current.noteHistoryContacts
  ).map((activityContact) => {
    if (activityContact.folder) {
      return (
        <FolderItem
          accountInfo={props.accountInfo}
          folder={activityContact.folder}
          key={activityContact.folderId}
          mergeFields={[]}
          noteHistoryId={activityContact.noteHistoryId}
        />
      );
    } else if (activityContact.contact) {
      return (
        <ContactItem
          contact={activityContact.contact}
          key={activityContact.contactId}
          mergeFields={[]}
        />
      );
    }
  });

  let scheduledByLabel = userData === "loading" ? "" : " by " + userData.label;

  let statusColor, helperText, headline;

  if (sendAtDateString) {
    headline = "Sends " + formattedSendAtDate;
    statusColor = "orange";
    helperText = "Send scheduled for " + sendAtDateString + scheduledByLabel;
  }

  let editNoteShowSettings = {
    showContacts: false,
    showBirthdayContacts: false,
    showBirthdaySettings: false,
    showSendNote: false,
    showCTA: false,
    showBranding: false,
    showText: true,
    showReply: false,
    showResend: false,
    showExternalShare: false,
    showEmail: true,
    showEmailLocalPart: false,
    showEmailReplyTo: false,
  };

  return (
    <div className="row col-12">
      <div
        className="col-12 row adSectionBox"
        style={{ marginTop: "10px", padding: "0px" }}>
        <div
          className="col-12"
          onClick={() => setOpen(!open)}
          style={{
            cursor: "pointer",
            display: "flex",
            padding: "20px 5px",
          }}>
          <div
            className="align-items-center justify-content-center"
            style={{ width: "50px" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: statusColor,
              }}
            />
          </div>

          <div style={{ flex: 1 }}>
            <div className="col-12 row">
              <div className="col-12 row align-items-center">
                <h3 style={{ marginRight: "2px", fontSize: "16px" }}>{headline}</h3>
              </div>
              <div className="col-12">
                <span className="greyDesc" style={{ fontSize: "14px" }}>
                  {helperText}
                </span>
              </div>
            </div>
          </div>

          <div style={{ width: "50px" }}>
            <IconButton onClick={() => setOpen(!open)}>
              <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
            </IconButton>
          </div>
        </div>

        <div className={"col-12 row " + expansionPanelClass}>
          <div className="col-12 row" style={{ padding: "20px 17px" }}>
            <div className="col-12 hidden-sm-down" style={{ marginBottom: "20px" }}>
              {open && (
                <PreviewNoteButton
                  accountId={props.accountInfo.id}
                  contentId={props.current.contentId}
                  taskId={props.calledFromTask ? props.current.id : null}
                  noteId={props.current.noteId}
                  forceLoadFreshNoteContent={forceLoadFreshNoteContent}
                />
              )}
            </div>

            <div className="col-12">
              <div className="col-12" style={{ marginTop: "5px" }}>
                <h2 style={{ marginLeft: "3px", fontWeight: "normal", fontSize: "15px" }}>
                  Sending To
                </h2>
              </div>

              <div className="col-12" style={{ margin: "5px 5px 25px 5px" }}>
                {contactsHTML}
              </div>
            </div>
          </div>

          <div
            className={`col-12 ${styles.scheduledSendActionRow}`}
            style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
            <PreviewOrEmbedByContact
              type="preview"
              title="Preview By Contact"
              contentId={props.current.contentId}
              taskId={props.calledFromTask ? props.current.id : null}
              noteId={props.current.noteId}
              accountInfo={props.accountInfo}
              taskContacts={[]}
            />

            <div className={styles.spacer}></div>

            <Button
              className={styles.scheduledSendButton}
              variant="outlined"
              onClick={() => setCancelDialogOpen(true)}>
              Cancel Send
            </Button>

            <Button
              className={`${styles.scheduledSendButton} ${styles.scheduledSendEditButton}`}
              variant="outlined"
              onClick={() => setEditDialogOpen(true)}>
              Edit Content
            </Button>
          </div>

          {cancelDialogOpen && (
            <CancelSendDialog
              dialogOpen={cancelDialogOpen}
              setDialogOpen={setCancelDialogOpen}
              setScheduledItems={props.setScheduledItems}
              scheduledItems={props.scheduledItems}
              itemId={props.current.id}
              noteId={props.current.noteId}
              taskId={props.calledFromTask ? props.current.id : props.current.taskId}
              resending={props.current.resending}
              contentId={props.current.contentId}
              calledFromTask={props.calledFromTask}
              accountId={props.accountInfo.id}
              totalItems={props.totalItems}
              setTotalItems={props.setTotalItems}
            />
          )}
          {editDialogOpen && (
            <EditNoteContentDialog
              dialogOpen={editDialogOpen}
              setDialogOpen={setEditDialogOpen}
              {...props}
              contentId={props.current.contentId}
              taskId={props.calledFromTask ? props.current.id : null}
              noteId={props.current.noteId}
              setForceLoadFreshNoteContent={setForceLoadFreshNoteContent}
              editNoteShowSettings={editNoteShowSettings}
            />
          )}
        </div>
      </div>
    </div>
  );
}
