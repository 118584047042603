import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LoadingButton from "components/util/buttons/loadingbutton";
import SelectTags from "components/util/tags/selectTags";
import { uploadBulkContacts, setContactFolders } from "services/api.service";
import { isCustomProperty } from "util/contacts";
import { toMonthDay } from "util/dates";

export default function FoldersAndConfirm(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonState, setButtonState] = useState("regular");
  const [updatedCount, setUpdatedCount] = useState(0);
  const [selectedFoldersArray, setSelectedFoldersArray] = useState([]);

  let confirmAndUploadList = async () => {
    try {
      setButtonState("loading");
      setErrorMessage(null);
      const { csvData, calledFromNotes, accountInfo, properties } = props;
      const { rows, errors } = csvData;
      if (!rows.length) {
        throw new Error("No contacts");
      }

      const { count, limit } = accountInfo.productCounts.contacts;
      const newCount = count + rows.length;
      if (newCount > limit) {
        throw new Error(
          `Did not upload. Trying to upload ${rows.length} contacts for a total of ${newCount} total contacts, but account limit is ${limit}. Please email support@gratavid.com if account limit is incorrect.`
        );
      }

      if (errors.length) {
        const errorMessage = errors.map(({ message }) => message).join(",");
        setErrorMessage(
          <span>
            {errors.length} warnings/errors.{" "}
            <button
              className="link-button"
              style={{ marginLeft: "10px" }}
              onClick={() => setErrorMessage(errorMessage)}>
              View Details
            </button>
          </span>
        );
        setButtonState("");
        setUpdatedCount(0);
      } else {
        const propertyValues = {};
        for (const key in properties) {
          if (properties[key].mapped) {
            propertyValues[properties[key].columnHeader || key] = key;
          }
        }
        const sanitizedContacts = rows.map((contact) => {
          const newContact = {};
          for (const key in contact) {
            if (!contact[key]) {
              continue;
            }

            if (Object.keys(propertyValues).includes(key)) {
              const mappedKey = propertyValues[key];
              if (["dobDay", "dobMonth"].includes(mappedKey)) {
                const num = Number(contact[key]);
                if (!isNaN(num)) {
                  newContact[mappedKey] = num;
                }
              } else if (mappedKey === "textOptIn") {
                newContact.textOptIn = ["y", "yes", "true"].includes(
                  contact[key].toLowerCase()
                );
              } else if (mappedKey === "birthday") {
                const birthday = toMonthDay(contact[key]);

                if (birthday?.dobMonth && birthday?.dobDay) {
                  newContact.dobMonth ||= birthday.dobMonth;
                  newContact.dobDay ||= birthday.dobDay;
                }
              } else if (isCustomProperty(props.accountInfo, mappedKey)) {
                if (!newContact.customProperties) {
                  newContact.customProperties = {};
                }
                newContact.customProperties[mappedKey] = contact[key];
              } else {
                newContact[mappedKey] = contact[key];
              }
            }
          }
          return newContact;
        });

        const newContacts = await uploadBulkContacts(sanitizedContacts);

        const folders = [];
        for (const contact of newContacts) {
          folders.push({
            contactId: contact.id,
            folderIds: (selectedFoldersArray || []).map((folder) => folder.id),
          });
        }
        if (folders?.length) {
          await setContactFolders(folders, true);
        }

        if (calledFromNotes) {
          props.passedSetState("addNewDialogOpen", false);
          setButtonState("saved");
        } else {
          setUpdatedCount(rows.length);
          setErrorMessage(null);
          setButtonState("saved");
        }
      }
    } catch (err) {
      let errorMessage = err?.data?.message ? err.data.message : err.message;
      setButtonState("");
      setErrorMessage(errorMessage);
    }
  };

  //if called from notes, we want have nbHits from contacts page to see how many contacts were added
  let saveLabel = updatedCount
    ? updatedCount + " Contacts Uploaded"
    : "Contacts Uploaded";
  let saveClick = props.calledFromNotes
    ? () => props.passedSetState("addNewDialogOpen", false)
    : () => props.bulkContactsAdded();
  return (
    <>
      <div className="col-12" style={{ padding: "20px 0px 10px 0px" }}>
        <h2>4) Add contacts to a list</h2>

        <div style={{ marginTop: "25px" }}>
          <SelectTags
            accountId={props.accountInfo.id}
            selectedFoldersArray={selectedFoldersArray}
            setSelectedFoldersArray={setSelectedFoldersArray}
            indexName="contact"
            inputVariant="outlined"
            label="List"
          />
        </div>
        <div className="notification-message-box">
          <span>
            If any imported emails/contacts are already on a list in your account, we will
            keep them on those lists and add them to any list selected above.
          </span>
        </div>

        <h2 style={{ marginTop: "25px" }}>5) Confirm Contact Data Source</h2>
        <p>
          I agree that I have written explicit consent to send a text message to contacts
          whose text opt-in status equals &quot;true&quot; in this import. If I have any
          doubts about a contacts text opt-in status, I will set this value to false and
          have contacts complete my opt-in form.
        </p>
        <br />
        <p>
          I agree that all contacts in this import are expecting to hear from my
          organization. I have a prior relationship with these contacts and have emailed
          them at least once in the past year. I can confirm this list wasn&apos;t
          purchased, rented, appended, or provided by a third party.
        </p>
      </div>
      <div className="col-12" style={{ height: "70px" }} />
      <div className="bulkUploadActionBar">
        <div style={{ flex: 1 }} className="justify-content-end">
          {errorMessage && (
            <div className="bulkUploadActionBarErrorMessageBox">
              <i
                className="material-icons"
                style={{ marginRight: "5px", color: "darkorange" }}>
                warning
              </i>
              <b>{errorMessage}</b>
            </div>
          )}
        </div>
        <div className="justify-content-end" style={{ width: "240px" }}>
          {buttonState === "regular" && (
            <Button
              variant="outlined"
              onClick={() => props.setActiveStep(1)}
              style={{ marginRight: "20px" }}>
              Back
            </Button>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={buttonState}
            savedLabel={saveLabel}
            label={"Upload"}
            clickedRegularButton={() => confirmAndUploadList()}
            clickedSavedButton={saveClick}
          />
        </div>
      </div>
    </>
  );
}

FoldersAndConfirm.propTypes = {
  csvData: PropTypes.shape({
    rows: PropTypes.array.isRequired,
    errors: PropTypes.array,
  }).isRequired,
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productCounts: PropTypes.object,
  }).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  calledFromNotes: PropTypes.bool,
  passedSetState: PropTypes.func,
  bulkContactsAdded: PropTypes.func,
  properties: PropTypes.object.isRequired,
};
