import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { createAccountFolder } from "services/api.service";

const FOLDER_TYPES = {
  contacts: "contact",
  contact: "contact",
  videos: "video",
  video: "video",
  notes: "note",
  note: "note",
};
export default function AddItem(props) {
  let [state, setState] = useState({ editing: false, errorMessage: null });
  let [itemLabel, setItemLabel] = useState("");

  let saveItem = async (e) => {
    e.preventDefault();
    setState({ editing: "loading", errorMessage: null });
    const folder = {
      name: itemLabel,
      type: FOLDER_TYPES[props.indexName],
    };
    createAccountFolder(folder)
      .then((createdFolder) => {
        createdFolder.selected = true;
        createdFolder.label = createdFolder.name;
        const newFolders = [...props.folders];
        newFolders.push(createdFolder);
        props.updateFolders(newFolders);
        setState({ editing: false, errorMessage: null });
        setItemLabel("");
      })
      .catch((e) => {
        setState({ editing: true, errorMessage: e.message });
      });
  };

  if (state.editing) {
    return (
      <form
        onSubmit={saveItem}
        className="row col-12"
        style={{
          display: "flex",
          padding: "10px",
          border: "2px #ff0000 solid",
          borderRadius: "2px",
        }}>
        <div style={{ width: "50px" }} className="row">
          {state.editing === "loading" ? (
            <div className="col-12 align-items-center ">
              <div style={{ height: "4px", width: "100%" }}>
                <LinearProgress />
              </div>
            </div>
          ) : (
            <>
              <div className="col-12 align-items-center justify-content-center">
                <IconButton
                  aria-label="save"
                  title="save"
                  type="submit"
                  onClick={(e) => saveItem(e)}>
                  <i className="material-icons" style={{ color: "green" }}>
                    check_circle
                  </i>
                </IconButton>
              </div>
              <div className="col-12 align-items-center justify-content-center">
                <IconButton
                  aria-label="cancel"
                  title="cancel"
                  onClick={() => setState({ editing: false, errorMessage: null })}>
                  <i className="material-icons" style={{ color: "red" }}>
                    close
                  </i>
                </IconButton>
              </div>
            </>
          )}
        </div>

        <div
          className="align-items-center"
          style={{ padding: "0px 0px 10px 10px", flex: 1 }}>
          <TextField
            id="addFolder"
            placeholder="e.g. Top Donors"
            label="New Name"
            style={{ width: "100%" }}
            value={itemLabel}
            onChange={(e) => setItemLabel(e.target.value)}
          />
        </div>

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
      </form>
    );
  } else {
    return (
      <div className="justify-content-center">
        <Button variant="text" onClick={() => setState({ editing: true })}>
          <i className="material-icons" style={{ marginRight: "5px", color: "#f76c6c" }}>
            add_box
          </i>
          Create New
        </Button>
      </div>
    );
  }
}

AddItem.propTypes = {
  accountId: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateFolders: PropTypes.func.isRequired,
  indexName: PropTypes.string.isRequired,
};
