import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingButton from "../../../../../../util/buttons/loadingbutton";
import LoadingSwitch from "../../../../../../util/buttons/loadingSwitch";
import DefaultAction from "../../../../actions/action";
import SalesforceAction from "./action";
import AddAction from "../../../../actions/addAction";
import SortableList from "components/util/sortable/SortableList";
import SelectList from "../../../util/selectList";
import { updateAccountIntegrations } from "services/api.service";

export default function PollCustomObject(props) {
  let { eventData = {}, users = [] } = props;
  eventData = eventData || {};
  let { customObjectId = "", customObjectLabel = "" } = eventData;
  let initialActions = eventData.actions || {};
  let [actions, setActions] = useState(initialActions);
  let initialExcludes = eventData.excludes ? eventData.excludes : {};
  let [excludes, setExcludes] = useState(initialExcludes);
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  let saveActions = async () => {
    try {
      ///accounts/act_-LlIHNeIQmLZ3k15vBJz/integrations/endpoint/salesforce/events/pollCustomObjects/list/GW_Volunteers__Volunteer_Hours__c/actions
      setState({ buttonState: "loading" });
      const updates = [
        {
          path: `endpoint/salesforce/events/pollCustomObjects/list/${customObjectId}/actions`,
          value: actions,
        },
        {
          path: `endpoint/salesforce/events/pollCustomObjects/list/${customObjectId}/excludes`,
          value: excludes,
        },
      ];
      await updateAccountIntegrations(updates);

      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleExcludeChange = (excludeKey, values) => {
    let tempExcludes = {};
    values.forEach((current) => {
      tempExcludes[current.id] = current;
    });

    setExcludes({ [excludeKey]: tempExcludes });
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });

      //add item to polling scheduler
      const updates = [
        {
          path: `endpoint/salesforce/events/pollCustomObjects/list/${customObjectId}/active`,
          value: !!newActiveValue,
        },
      ];

      await updateAccountIntegrations(updates);

      setState({});
      setActive(newActiveValue);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let excludeOptions = [
    {
      id: "gratavidCustomExclude1__c",
      label: "If the custom field name 'gratavidCustomExclude1__c' equals true",
    },
    {
      id: "gratavidCustomExclude2__c",
      label: "If the custom field name 'gratavidCustomExclude2__c' equals true",
    },
    {
      id: "gratavidCustomExclude3__c",
      label: "If the custom field name 'gratavidCustomExclude3__c' equals true",
    },
  ];

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>New {customObjectLabel} Added In Salesforce</h2>
          <span className="greyDesc">
            Create a task or send a note when a {customObjectLabel} record is created.
          </span>
          {active && (
            <div className="greyDesc" style={{ fontSize: "14px" }}>
              Status ON: Runs once per hour.
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className="row col-12" style={{ cursor: "auto" }}>
        <div className="row col-12">
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <span className="circleStep">1</span>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When a new record is added in Salesforce...
            </h2>
          </div>
        </div>

        <div className="col-12">
          <SortableList
            items={actions.list || []}
            onChange={(items) => setActions({ ...actions, list: items })}
            renderItem={(item, index) => (
              <SortableList.Item id={item.id}>
                <SalesforceAction
                  currentAction={item}
                  actionIndex={index}
                  order={index + 1}
                  accountInfo={props.accountInfo}
                  actions={actions}
                  setActions={setActions}
                  users={users}
                />
              </SortableList.Item>
            )}
          />
        </div>

        <AddAction
          {...props}
          actions={actions}
          setActions={setActions}
          event="pollCustomObject"
        />

        <DefaultAction
          {...props}
          users={users}
          defaultAction={true}
          currentAction={actions}
          actions={actions}
          setActions={setActions}
        />

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>

        <div
          className="row col-12"
          style={{ marginTop: "25px", paddingTop: "25px", borderTop: "1px #ddd solid" }}>
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <i
              className="material-icons"
              title="Exclude"
              style={{ fontSize: "18px", color: "#24305e" }}>
              filter_alt
            </i>
          </div>
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              Exclusions
            </h3>
            <p className="greyDesc">
              Gratavid will take no action if the donation matches any of these
              exclusions.
            </p>
          </div>
        </div>

        <div style={{ padding: "0px 20px" }} className="row col-12">
          <SelectList
            selectedItems={excludes && excludes.custom ? excludes.custom : {}}
            handleChange={(values) => handleExcludeChange("custom", values)}
            label="Exclusions"
            options={excludeOptions}
            width="100%"
          />
        </div>

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
