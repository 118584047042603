import React, { useState } from "react";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { DialogActions, DialogContent, Dialog, IconButton, Button } from "@mui/material";
import { cloneTask } from "services/api.service";

export default function ResendNowDialog({
  dialogOpen,
  currentTask,
  accountId,
  setDialogOpen,
}) {
  let [state, setState] = useState({});

  let doWork = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });
      await cloneTask(currentTask.id);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let { buttonState, errorMessage } = state;

  let closeDialog = () => {
    setState({});
    setDialogOpen(null);
  };

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="edit task dialog">
      <DialogContent
        style={{
          height: "550px",
          width: "600px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 22 }}>Clone Task</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12" style={{ marginTop: 15 }}>
          <p>
            Create a new task with the same video, contacts, and comments? You will be
            able to edit the task before sending.
          </p>
        </div>

        {errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button variant="outlined" color="primary" onClick={closeDialog}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          buttonState={buttonState}
          savedLabel={"Cloned!"}
          label={"Clone now"}
          clickedRegularButton={doWork}
          clickedSavedButton={closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
