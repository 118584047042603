import React, { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import ListSelectedContacts from "./selectedContacts/listSelectedContacts";
import SearchContacts from "./searchContacts/searchContacts";
import { updateTaskContacts } from "services/api.service";

export default function Contacts(props) {
  let { accountInfo, customContactProperties, taskContacts } = props;
  let [showSearchContacts, setShowSearchContacts] = useState(false);

  async function updateContacts(contactIds) {
    //save to db
    const updatedTaskContacts = await updateTaskContacts(props.task.id, contactIds);

    const tasks = props.tasks.map((currentTask) => {
      if (props.task.id === currentTask.id) {
        return {
          ...props.task,
          taskContacts: updatedTaskContacts,
        };
      }
      return currentTask;
    });

    props.setTasks(tasks);
  }

  let addContactToTask = async (contact) => {
    try {
      if (
        !props.taskContacts.some((taskContact) => taskContact.contactId === contact.id)
      ) {
        const contactIds = props.taskContacts.map((taskContact) => taskContact.contactId);
        contactIds.push(contact.id);
        await updateContacts(contactIds);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  let deleteContactFromTask = async (taskContact) => {
    try {
      const taskContacts = props.taskContacts.filter(
        (currentTaskContact) => currentTaskContact.id !== taskContact.id
      );

      const contactIds = taskContacts.map((taskContact) => taskContact.contactId);
      await updateContacts(contactIds);
    } catch (err) {
      console.error(err.message);
    }
  };

  if (customContactProperties === "loading" || taskContacts === "loading") {
    return (
      <div
        className="loading-pulse"
        style={{
          width: "100%",
          height: 50,
          backgroundColor: "ededed",
          borderRadius: 2,
          marginTop: 2,
        }}
      />
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setShowSearchContacts(false)}>
      <div className="col-12 row" style={{ position: "relative", marginTop: 2 }}>
        <ListSelectedContacts
          setShowSearchContacts={setShowSearchContacts}
          customContactProperties={customContactProperties}
          taskContacts={props.taskContacts}
          setTaskContacts={props.setTaskContacts}
          accountInfo={accountInfo}
          deleteContactFromTask={deleteContactFromTask}
          userIsAdmin={props.userIsAdmin}
        />

        {showSearchContacts && (
          <SearchContacts
            setShowSearchContacts={setShowSearchContacts}
            addContactToTask={addContactToTask}
            accountInfo={accountInfo}
            customContactProperties={customContactProperties}
            deleteContactFromTask={deleteContactFromTask}
            taskContacts={taskContacts}
            setTaskContacts={props.setTaskContacts}
            userIsAdmin={props.userIsAdmin}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}
