import React from "react";
import "./style/App.css";
import "./style/colors.css";
import "./style/boottrapp.css";
import "./style/adbuilder.css";
import muiTheme from "./muiTheme";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router";
import AuthOpenIdRedirect from "./components/util/logincreate/authOpenIdRedirect";
import ResetPassword from "./components/util/logincreate/resetpassword";
import LoginFromInvite from "./components/util/logincreate/invite/loginFromInvite";
import Terms from "./components/util/compliance/terms";
import UserContentTerms from "./components/util/compliance/userContentTerms";
import Privacy from "./components/util/compliance/privacy";
import Security from "./components/util/compliance/security";
import GDPR from "./components/util/compliance/gdpr";
import PageNotFound444 from "./components/util/pageNotFound444";
import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";
import Maintenance from "components/maintenance-window";
import AccountManager from "./components/account/account-manager.js";
import PendingAccountSignup from "./components/account/pending-account-signup.js";

if (process.env.REACT_APP_DD_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

//if (process.env.NODE_ENV !== "development") {
Sentry.init({
  dsn: "https://6322d32caf564f04867cf8fb10cdfa58@o227651.ingest.sentry.io/4504249408684032",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV7BrowserTracingIntegration({
      userEffect: React.useEffect,
      useLocation: React.useLocation,
      useNavigationType: React.useNavigationType,
      createRoutesFromChildren: React.createRoutesFromChildren,
      matchRoutes: React.matchRoutes,
    }),
  ],
  tracePropagationTargets: [
    "localhost",
    "account.sandbox.gratavid.com",
    "account.gratavid.com",
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
//}

export default function App() {
  if (process.env.REACT_APP_MAINTENANCE_END_DATE) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <Maintenance></Maintenance>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <Router>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <div style={{ minHeight: "calc(100vh - 40px)" }}>
            <Routes>
              <Route path={"/a/:accountId/*"} element={<AccountManager />} />

              <Route
                path={"/pending/:pendingAccountId/"}
                element={<PendingAccountSignup />}
              />

              <Route exact path={"/loginfrominvite"} element={<LoginFromInvite />} />
              <Route exact path={"/resetpassword"} element={<ResetPassword />} />
              <Route exact path={"/terms"} element={<Terms />} />
              <Route exact path={"/userContentTerms"} element={<UserContentTerms />} />
              <Route exact path={"/privacy"} element={<Privacy />} />
              <Route exact path={"/security"} element={<Security />} />
              <Route exact path={"/gdpr"} element={<GDPR />} />
              <Route exact path={"/openid/redirect"} element={<AuthOpenIdRedirect />} />

              <Route
                path="*"
                element={<PageNotFound444 errorMessage="This page does not exist" />}
              />
            </Routes>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}
