import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Chip, FormControlLabel, LinearProgress, Switch, Tooltip } from "@mui/material";
import DailyChartWithResends from "../../../analyticsv5/dailyChartWithResends";
import SummaryBox from "../../../analyticsv5/summaryBox";
import Replies from "../../../analyticsv5/replies";
import SendsDialog from "../../../analyticsv5/sendsDialog";
import DownloadContactsDialog from "../../../analyticsv5/downloadContactsDialog";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import { getNoteAnalytics } from "services/api.service";
import { toPercent } from "util/helpers";

export default function Analytics({
  noteId,
  accountInfo,
  analyticsSendItemSelected = {},
  setAnalyticsSendItemSelected,
}) {
  const [showUniqueData, setShowUniqueData] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [analyticsTotals, setAnalyticsTotals] = useState({});
  const [analyticsDaily, setAnalyticsDaily] = useState([]);
  const { gvSendId, gvSendIdLabel } = analyticsSendItemSelected;

  let handleUniqueToggle = (e) => {
    setShowUniqueData(e.target.checked);
  };

  let handleAnalyticsSendItemSelected = (gvSendId, gvSendIdLabel) => {
    setShowUniqueData(false); //make sure not showing unique data. resend doesn't have unique data
    setAnalyticsSendItemSelected({ gvSendId, gvSendIdLabel });
    setSendDialogOpen(false);
  };

  useEffect(() => {
    getNoteAnalytics(noteId, gvSendId, true).then((analytics) => {
      const { totals, uniqueTotals } = analytics;

      // combine totals and unique totals
      for (const total in uniqueTotals) {
        totals["unique_" + total] = uniqueTotals[total];
      }

      // email click-through rate (ctr)
      totals.emailctr = toPercent(totals.email_click, totals.email_delivered);
      totals.unique_emailctr = toPercent(
        uniqueTotals.email_click,
        uniqueTotals.email_delivered
      );

      // email open rate
      totals.emailopenrate = toPercent(totals.email_open, totals.email_delivered);
      totals.unique_emailopenrate = toPercent(
        uniqueTotals.email_open,
        uniqueTotals.email_delivered
      );

      // text click-through rate
      totals.textctr = toPercent(totals.text_click, totals.text_delivered);
      totals.unique_textctr = toPercent(
        uniqueTotals.text_click,
        uniqueTotals.text_delivered
      );

      // note watched
      totals.watchrate = toPercent(totals.playedFullVideo, totals.playVideo);
      totals.unique_watchrate = toPercent(
        uniqueTotals.playedFullVideo,
        uniqueTotals.playVideo
      );

      const dailies = [];

      for (const key in analytics.daily) {
        const month = key.substring(4, 6);
        const day = key.substring(6, 8);
        const date = month + "/" + day;
        const totals = analytics.daily[key] ?? {};
        dailies.push({ date, ...totals });
      }
      setAnalyticsTotals(totals);
      setAnalyticsDaily(dailies);
    });
  }, [noteId, gvSendId]);

  if (!analyticsTotals) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <>
      <div className="row pagePadding col-12">
        <div className="row col-12" style={{ padding: "10px 5px" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            {gvSendId ? (
              <Chip
                label={gvSendIdLabel}
                variant="outlined"
                icon={
                  <i style={{ fontSize: "16px" }} className="material-icons">
                    cancel
                  </i>
                }
                style={{ marginLeft: "5px" }}
                onClick={() => handleAnalyticsSendItemSelected(null, null)}
              />
            ) : (
              <Chip
                variant="outlined"
                label="All sends"
                icon={
                  <i style={{ fontSize: "16px" }} className="material-icons">
                    filter_alt
                  </i>
                }
                style={{ marginLeft: "5px" }}
                onClick={() => setSendDialogOpen(true)}
              />
            )}

            <Chip
              label="Past year"
              disabled
              icon={
                <i style={{ fontSize: "16px" }} className="material-icons">
                  date_range
                </i>
              }
              style={{ marginLeft: "5px" }}
            />
          </div>

          {gvSendId ? (
            <Tooltip title="Unique data not available when filtering by sends">
              <FormControlLabel
                control={<Switch checked={false} name="showUniqueData" disabled />}
                label="Show Unique Data"
              />
            </Tooltip>
          ) : (
            <FormControlLabel
              control={
                <Switch
                  checked={showUniqueData}
                  onChange={handleUniqueToggle}
                  name="showUniqueData"
                />
              }
              label="Show Unique Data"
            />
          )}

          <div style={{ height: "48px", display: "flex" }}>
            <QuestionToLink id="analytics" desc="analytics" size="medium" />
          </div>
          <div style={{ width: "55px" }}>
            <DownloadContactsDialog noteId={noteId} accountId={accountInfo.id} />
          </div>
        </div>

        <SendsDialog
          accountInfo={accountInfo}
          noteId={noteId}
          gvSendId={gvSendId}
          handleAnalyticsSendItemSelected={handleAnalyticsSendItemSelected}
          dialogOpen={sendDialogOpen}
          closeDialog={() => setSendDialogOpen(false)}
        />

        <div className="col-12 col-md-4">
          <SummaryBox
            dataType="video"
            analyticsTotals={analyticsTotals}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
            color="#24305e"
            gvSendId={gvSendId}
            indicatorWidth="100%"
            icon="slideshow"
            label="VIDEOS"
            kpiKey="playVideo"
            kpiLabel="VIDEO PLAYS"
            kpiTwoKey="playedHalfVideo"
            kpiThreeKey="playedFullVideo"
            kpiTwoLabel=" half video plays"
            kpiThreeLabel=" full video plays"
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            dataType="video-rate"
            analyticsTotals={analyticsTotals}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
            color="#24305e"
            gvSendId={gvSendId}
            indicatorWidth={analyticsTotals["watchrate"]}
            icon="slideshow"
            label="VIEW-THROUGH RATE"
            kpiKey="watchrate"
            kpiLabel="WATCH RATE"
            kpiTwoKey="playVideo"
            kpiThreeKey="playedFullVideo"
            kpiTwoLabel=" videos played"
            kpiThreeLabel=" full video plays"
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            dataType="note"
            analyticsTotals={analyticsTotals}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
            color="#24305e"
            gvSendId={gvSendId}
            indicatorWidth="100%"
            icon="web"
            label="NOTE"
            kpiKey="pageView"
            kpiTwoKey="reply"
            kpiThreeKey="ctaClick"
            kpiLabel="PAGE VIEWS"
            kpiTwoLabel=" replies"
            kpiThreeLabel=" call to action clicks"
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            dataType="email-open"
            analyticsTotals={analyticsTotals}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
            color="#087f23"
            gvSendId={gvSendId}
            icon="email"
            indicatorWidth={analyticsTotals["emailopenrate"]}
            label="EMAILS"
            kpiKey="emailopenrate"
            kpiTwoKey="email_delivered"
            kpiThreeKey="email_open"
            kpiLabel="OPEN RATE"
            kpiTwoLabel=" emails delivered"
            kpiThreeLabel=" emails opened"
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            dataType="email-ctr"
            analyticsTotals={analyticsTotals}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
            color="#087f23"
            gvSendId={gvSendId}
            indicatorWidth={analyticsTotals["emailctr"]}
            icon="email"
            label="EMAILS"
            kpiLabel="CLICK-THROUGH RATE"
            kpiTwoLabel=" emails delivered"
            kpiThreeLabel=" emails clicked"
            kpiKey="emailctr"
            kpiTwoKey="email_delivered"
            kpiThreeKey="email_click"
          />
        </div>

        <div className="col-12 col-md-4">
          <SummaryBox
            dataType="text"
            analyticsTotals={analyticsTotals}
            showUniqueData={showUniqueData}
            handleUniqueToggle={handleUniqueToggle}
            color="#087f23"
            gvSendId={gvSendId}
            indicatorWidth={analyticsTotals["textctr"]}
            icon="textsms"
            label="TEXTS"
            kpiLabel="CLICK-THROUGH RATE"
            kpiTwoLabel=" texts delivered"
            kpiThreeLabel=" texts clicked"
            kpiKey="textctr"
            kpiTwoKey="text_delivered"
            kpiThreeKey="text_click"
          />
        </div>

        {!showUniqueData && (
          <div className="col-12">
            <DailyChartWithResends analyticsDaily={analyticsDaily} />
          </div>
        )}

        {!showUniqueData && (
          <div className="col-12">
            <Replies noteId={noteId} gvSendId={gvSendId} accountInfo={accountInfo} />
          </div>
        )}
      </div>
    </>
  );
}

Analytics.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  noteId: PropTypes.string.isRequired,
  analyticsSendItemSelected: PropTypes.object,
  setAnalyticsSendItemSelected: PropTypes.func,
};
