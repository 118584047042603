import React, { useEffect, useState } from "react";
import ItemAnalytics from "./itemAnalytics";
import ContactItem from "../contacts/selectedContacts/contactItem";
import FolderItem from "../contacts/selectedContacts/folderItem";
import { CircularProgress, IconButton, Button, Chip } from "@mui/material";
import EditNoteContentDialog from "../scheduledHistory/editNoteContentDialog";
import PreviewOrEmbedByContact from "../../../../util/shareOptions/previewOrEmbedByContact";
import PreviewNoteButton from "../../../../util/shareOptions/previewNoteButton";
import CancelResendDialog from "./cancelResendDialog";
import timeSinceDate from "../../../../util/functions/timeSinceDate";
import { getNoteHistoryAnalytics } from "services/api.service";

export default function SendHistoryItem(props) {
  let [sendItemData, setSendItemData] = useState(props);
  let {
    status,
    updatedDate,
    taskId = null,
    gvSendId,
    noteId,
    sendTest: wasATest = false,
    resending: wasAResend = false,
    contentId = null,
    noteHistoryContacts = {},
    scheduledDate = null,
    outlookError = null,
    gmailError = null,
    uniqueEmails = 0,
    uniqueTexts = 0,
    createdByUser,
  } = sendItemData;
  let [open, setOpen] = useState(props.index === 0 ? true : false);
  let [editDialogOpen, setEditDialogOpen] = useState(false);
  let [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  let [forceLoadFreshNoteContent, setForceLoadFreshNoteContent] = useState(null);
  let { source, accountInfo } = props;
  const [noteHistoryAnalytics, setNoteHistoryAnalytics] = useState({});

  useEffect(() => {
    if (open) {
      getNoteHistoryAnalytics(noteId, sendItemData.id).then(setNoteHistoryAnalytics);
    }
  }, [open, noteId, sendItemData.id]);

  let handleResendCanceled = () => {
    //sendStatus is how front end knows which tabs have been sent or not
    setSendItemData({
      ...sendItemData,
      resendActive: false,
      resendDate: null,
    });
  };

  let handleItemAnalyticsClicked = () => {
    let sentToNumbers = "";
    if (uniqueEmails > 0) sentToNumbers = " to " + uniqueEmails + " emails";
    if (uniqueEmails > 0 && uniqueTexts > 0)
      sentToNumbers += " and to " + uniqueTexts + " texts";
    else if (uniqueTexts > 0) sentToNumbers = " to " + uniqueTexts + " texts";

    let label = wasAResend ? "Resent " : "Sent ";
    label += formattedLastStatusUpdate + sentToNumbers;

    props.setTabSelected("analytics");
    props.setAnalyticsSendItemSelected({ gvSendId, gvSendIdLabel: label });
  };

  let expansionPanelClass = open
    ? "expansionPanel-1000"
    : "expansionPanel-1000 collapsible";
  const userName = createdByUser?.fullName || createdByUser?.email;
  let sendByLabel = userName ? " by " + userName : "";
  sendByLabel = source ? " by " + source : sendByLabel;

  let formattedLastStatusUpdate = timeSinceDate(new Date(updatedDate).getTime(), "", "");
  let sendAtDateString = updatedDate ? new Date(updatedDate).toLocaleString() : "";

  let statusColor, helperText, headline;
  if (status === "error" || props.errorMessage) {
    headline = "Error sending " + formattedLastStatusUpdate;
    statusColor = "red";
    helperText = "ERROR sending: " + (props.errorMessage || "Your note was not sent.");
  } else if (status === "sending") {
    headline = "Sending " + formattedLastStatusUpdate;
    statusColor = "green";
    helperText = "Sending now...";
  } else if (status === "sent") {
    //let emailsLabel = uniqueEmails > 1 ? " emails" : " email"
    //headline = "Sent " + uniqueEmails + emailsLabel + " " + formattedLastStatusUpdate
    //let textsLabel = uniqueTexts > 1 ? " texts" : " text"
    //headline += uniqueTexts > 0 ? " and " + uniqueTexts + textsLabel : ""
    headline = "Sent " + formattedLastStatusUpdate;
    statusColor = "green";
    helperText = "Sent on " + sendAtDateString + sendByLabel;
  } else if (status === "queued" || status === "scheduled") {
    headline = "Queued " + formattedLastStatusUpdate;
    statusColor = "orange";
    helperText = "Queued for delivery and will send soon";
  } else if (status === "processing") {
    headline = "Processing " + formattedLastStatusUpdate;
    statusColor = "orange";
    helperText = "Processing your request...";
  }

  let contactsHTML = noteHistoryContacts.map((current, index) => {
    const key = current.id;

    if (index === 10) {
      let remaining = noteHistoryContacts.length - 10;
      return (
        <Chip
          key="more"
          label={"+" + remaining + " more"}
          disabled
          style={{
            margin: "3px",
            height: "24px",
            borderRadius: "5px",
            backgroundColor: "rgb(240, 241, 250)",
            border: "1px solid rgb(180, 187, 232)",
          }}
        />
      );
    }
    if (index > 10) {
      return null;
    } else if (current.folder) {
      return (
        <FolderItem
          accountInfo={accountInfo}
          folder={current.folder}
          key={key}
          noteHistoryId={current.noteHistoryId}
        />
      );
    } else if (current.contact) {
      return <ContactItem contact={current.contact} key={key} />;
    } else {
      return null;
    }
  });

  let editNoteShowSettings = {
    showContacts: false,
    showBirthdayContacts: false,
    showBirthdaySettings: false,
    showSendNote: false,
    showCTA: false,
    showBranding: false,
    showText: false,
    showReply: false,
    showResend: false,
    showExternalShare: false,
    showEmail: false,
    showEmailLocalPart: false,
    showEmailReplyTo: false,
  };
  let showCancelResend =
    new Date(scheduledDate) > Date.now() && status !== "sent" ? true : false;
  let resendAtDateString = scheduledDate ? new Date(scheduledDate).toLocaleString() : "";

  return (
    <div className="row col-12">
      <div
        className="col-12 row adSectionBox"
        style={{ marginTop: "10px", padding: "0px" }}>
        <div
          className="col-12"
          onClick={() => setOpen(!open)}
          style={{
            cursor: wasATest ? "auto" : "pointer",
            display: "flex",
            padding: "20px 5px",
          }}>
          <div
            className="align-items-center justify-content-center"
            style={{ width: "50px" }}>
            {status === "sending" ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: statusColor,
                }}
              />
            )}
          </div>

          <div style={{ flex: 1 }}>
            <div className="col-12 row">
              <div className="col-12 row align-items-center">
                {wasATest && (
                  <div
                    style={{
                      display: "flex",
                      color: "#636363",
                      fontSize: "12px",
                      background: "#ebebeb",
                      borderRadius: "2px",
                      padding: "5px 8px",
                      marginRight: "7px",
                      alignItems: "center",
                    }}>
                    Test
                  </div>
                )}
                {taskId && (
                  <div
                    style={{
                      display: "flex",
                      color: "#636363",
                      fontSize: "12px",
                      background: "#ebebeb",
                      borderRadius: "2px",
                      padding: "5px 8px",
                      marginRight: "7px",
                      alignItems: "center",
                    }}>
                    Task
                  </div>
                )}
                {wasAResend && (
                  <div
                    style={{
                      display: "flex",
                      color: "#636363",
                      fontSize: "12px",
                      background: "#ebebeb",
                      borderRadius: "2px",
                      padding: "5px 8px",
                      marginRight: "7px",
                      alignItems: "center",
                    }}>
                    Resend
                  </div>
                )}
                <h3 style={{ marginRight: "2px", fontSize: "16px" }}>{headline}</h3>
              </div>
              <div className="col-12">
                <span className="greyDesc" style={{ fontSize: "14px" }}>
                  {helperText}
                </span>
              </div>
            </div>
          </div>
          {!wasATest && (
            <div style={{ width: "50px" }}>
              <IconButton onClick={() => setOpen(!open)}>
                <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
              </IconButton>
            </div>
          )}
        </div>

        {!wasATest && (
          <div className={"col-12 row " + expansionPanelClass}>
            {showCancelResend && (
              <div className="col-12 row" style={{ padding: "0px 17px 10px" }}>
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                    display: "flex",
                    width: "100%",
                    padding: "0px 8px",
                  }}>
                  <div
                    style={{
                      width: "26px",
                      margin: "0px 8px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <i
                      className="material-icons"
                      style={{ color: "#5f6368", fontSize: "20px" }}>
                      schedule_send
                    </i>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <div>
                      <span style={{ fontSize: "12px", color: "#5f6368" }}>
                        Resend scheduled for {resendAtDateString}
                      </span>
                    </div>
                    <div>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => setCancelDialogOpen(true)}>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            color: "#1a73e8",
                          }}>
                          Cancel Resend
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 row" style={{ padding: "0px 17px 20px 17px" }}>
              {outlookError && (
                <div className="col-12 notification-message-box">
                  <span style={{ fontSize: "14px" }}>
                    Gratavid delivered but couldn't send via Outlook.{" "}
                    <a
                      style={{ fontSize: "14px" }}
                      href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6656192972572-Send-Via-Outlook-Issues"
                      target="_blank"
                      rel="noopener noreferrer">
                      Learn More
                    </a>
                  </span>
                </div>
              )}

              {gmailError && (
                <div className="col-12 notification-message-box">
                  <span style={{ fontSize: "14px" }}>
                    Gratavid delivered but couldn't send via Gmail.{" "}
                    <a
                      style={{ fontSize: "14px" }}
                      href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6655995087516-Send-Via-Gmail-Issues"
                      target="_blank"
                      rel="noopener noreferrer">
                      Learn More
                    </a>
                  </span>
                </div>
              )}

              {open && !(wasAResend && sendItemData.status === "scheduled") && (
                <ItemAnalytics
                  sendItemData={sendItemData}
                  wasAResend={wasAResend}
                  setRecipientsEventSelected={props.setRecipientsEventSelected}
                  setTabSelected={props.setTabSelected}
                  data={noteHistoryAnalytics}
                />
              )}

              <div className="col-12 hidden-sm-down" style={{ marginBottom: "20px" }}>
                {open && (
                  <PreviewNoteButton
                    accountId={accountInfo.id}
                    contentId={contentId}
                    taskId={taskId}
                    noteId={noteId}
                    forceLoadFreshNoteContent={forceLoadFreshNoteContent}
                  />
                )}
              </div>

              <div className="col-12" style={{ marginTop: "20px" }}>
                <h2 style={{ marginLeft: "3px", fontWeight: "normal", fontSize: "14px" }}>
                  Sent To
                </h2>
              </div>

              <div className="col-12" style={{ margin: "0px 5px 25px 5px" }}>
                {contactsHTML}
              </div>
            </div>

            <div
              className="col-12 justify-content-between"
              style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
              <PreviewOrEmbedByContact
                type="preview"
                title="Preview By Contact"
                noteId={noteId}
                contentId={contentId}
                taskId={taskId}
                accountInfo={accountInfo}
                taskContacts={[]}
              />

              <div>
                <Button
                  variant="outlined"
                  onClick={handleItemAnalyticsClicked}
                  style={{ marginLeft: "15px" }}>
                  View Analytics
                </Button>

                {(taskId || contentId) && (
                  <Button
                    variant="outlined"
                    onClick={() => setEditDialogOpen(true)}
                    style={{ marginLeft: "15px" }}>
                    Edit Content
                  </Button>
                )}
              </div>
            </div>

            {cancelDialogOpen && (
              <CancelResendDialog
                dialogOpen={cancelDialogOpen}
                setDialogOpen={setCancelDialogOpen}
                resendDate={scheduledDate}
                handleResendCanceled={handleResendCanceled}
                itemId={props.id}
                noteId={props.noteId}
                contentId={props.contentId}
                accountId={props.accountInfo.id}
              />
            )}
            {editDialogOpen && (
              <EditNoteContentDialog
                dialogOpen={editDialogOpen}
                setDialogOpen={setEditDialogOpen}
                {...props}
                contentId={contentId}
                isAResend={wasAResend}
                setForceLoadFreshNoteContent={setForceLoadFreshNoteContent}
                editNoteShowSettings={editNoteShowSettings}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
