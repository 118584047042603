import { useEffect, useState } from "react";
import { getNoteContent } from "../../../services/api.service";

const DEFAULT_COLOR = "#4285f4";

export const useGetNoteContent = (noteId, taskId, contentId, force) => {
  const [noteContent, setNoteContent] = useState("loading");

  useEffect(() => {
    setNoteContent("loading");

    if (!noteId) return;

    getNoteContent(noteId, taskId, contentId)
      .then((noteContent) => {
        // TODO: duplicate - can be removed once completely migrated
        noteContent.colors = {
          primaryColor: noteContent.primaryColor || DEFAULT_COLOR,
          secondaryColor: noteContent.secondaryColor || DEFAULT_COLOR,
        };
        noteContent.noteVideos =
          noteContent.videos?.map((video) => ({
            id: video.id,
            videoId: video.id,
            noteId,
            video,
          })) || [];

        setNoteContent(noteContent);
      })
      .catch((err) => {
        console.error(err.message);
        setNoteContent({
          errorMessage:
            "Error accessing note. Note possibly deleted or user does not have permission. " +
            err.message,
        });
      });
  }, [noteId, taskId, contentId, force]);

  return [noteContent];
};
