import React from "react";
import { FormControl, Select, IconButton, InputLabel } from "@mui/material";
import SelectItem from "../../util/selectItem";
import GetListSelectList from "../../util/getListSelectList";
import transactionAmountsOptions from "../../util/data/transactionAmountsOptions";

export default function Condition(props) {
  let { current, actionIndex, conditionId, actions, selectedSiteId, currencyLabel } =
    props;
  let { metric, metricValue = "" } = current;

  let handleMetricValueChange = (metric) => {
    if (metric === "transactionAmount")
      handleItemChange("transactionAmount", "", "greaterEqual");
    if (metric === "campaigns") handleListChange("campaigns", [], "includes");
  };

  let handleListChange = (metric, newSelectedItems, operator) => {
    let metricValue = {};
    newSelectedItems.forEach(({ id, label }) => {
      metricValue[id] = { id, label };
    });

    let item = { metric, metricValue, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let handleItemChange = (metric, value, operator) => {
    let item = { metric, metricValue: value, operator };
    let tempActions = { ...actions };
    tempActions.list[actionIndex].conditions[conditionId] = item;
    props.setActions(tempActions);
  };

  let deleteCondition = () => {
    let tempActions = { ...actions };
    delete tempActions.list[actionIndex].conditions[conditionId];
    props.setActions(tempActions);
  };

  let currentCondition = actions.list[actionIndex].conditions[conditionId];
  let selectedItems =
    currentCondition && currentCondition.metricValue ? currentCondition.metricValue : [];

  let giftAmountOptions = transactionAmountsOptions(currencyLabel);

  return (
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div
        style={{
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.order === 0 ? "IF" : "AND IF"}
        </h3>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <FormControl style={{ width: "350px", paddingRight: "15px" }} variant="filled">
          <InputLabel id="conditionType">Select Condition</InputLabel>
          <Select
            label="Select Condition"
            labelId="conditionType"
            value={metric}
            native
            onChange={(e) => handleMetricValueChange(e.target.value)}
            inputProps={{
              "aria-label": "Select condition type",
              name: "conditionType",
              id: "conditionType",
            }}>
            <option value="transactionAmount">
              The donation amount is greater than or equal to
            </option>
            <option value="campaigns">The donation is for any of these campaigns</option>
          </Select>
        </FormControl>

        {metric === "transactionAmount" && (
          <SelectItem
            options={giftAmountOptions}
            selectedItem={metricValue}
            handleChange={(value) =>
              handleItemChange("transactionAmount", value, "greaterEqual")
            }
            label="Amount"
            alt="Select gift amount"
            width="150px"
          />
        )}
        {metric === "campaigns" && (
          <GetListSelectList
            initialOptions="loading"
            optionsFetchNeeded={true}
            requestData={{
              source: "advance",
              event: "getCampaignList",
              siteId: selectedSiteId,
            }}
            accountId={props.accountId}
            selectedItems={selectedItems}
            handleChange={(value) => handleListChange("campaigns", value, "includes")}
            label="Campaigns"
            width="50%"
          />
        )}
      </div>
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.order > 0 && (
          <IconButton onClick={() => deleteCondition()} style={{ marginRight: "8px" }}>
            <i className="material-icons" style={{ fontSize: "18px" }}>
              close
            </i>
          </IconButton>
        )}
      </div>
    </div>
  );
}
