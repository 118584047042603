import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, FormControl, InputLabel, Select } from "@mui/material";
import { getAccountIntegrations } from "services/api.service";

const SALESFORCE_CLIENT_ID =
  "3MVG9l2zHsylwlpQHMWp5e4h4ggOImMAXUGDvkRXNs3sCj3XIKYb0C467L6f2YutpoFntfDcqFcIC5mWFDbJ8";

export default function SalesForceAuthenticate(props) {
  const [appEnvironment, setAppEnvironment] = useState("production");
  const { integrationDetails } = props;
  const { header, logoSrc, brandHex } = integrationDetails;

  const connectionInterval = useRef(null);

  useEffect(() => {
    connectionInterval.current = setInterval(async () => {
      const integrations = await getAccountIntegrations();
      if (integrations?.endpoint?.salesforce?.connectionInfo) {
        props.setConnectionInfo(integrations?.endpoint?.salesforce?.connectionInfo);
        clearInterval(connectionInterval.current);
        connectionInterval.current = null;
      }
    }, 5000);

    return () => {
      if (connectionInterval?.current) {
        clearInterval(connectionInterval.current);
      }
    };
  }, []);

  let openPopupAuth = async () => {
    try {
      //1) get token of logged in user. server will use this to validate gv user and get accountUID
      const userId = props.userInfo.id;
      const state =
        (appEnvironment === "sandbox" ? "sandbox" + userId : userId) +
        "_" +
        props.accountInfo.id;
      const redirect_uri = `${process.env.REACT_APP_API_ENDPOINT}vendors/integrations-endpoint?source=salesforce&event=authenticate`;

      const oauthBaseURL =
        appEnvironment === "production"
          ? "https://login.salesforce.com"
          : "https://test.salesforce.com";
      const oauthURL = `${oauthBaseURL}/services/oauth2/authorize?client_id=${SALESFORCE_CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=code&state=${state}`;

      const popup = window.open(oauthURL, "login", "height=565,width=500,");
      if (window.focus) popup.focus();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="col-12 row">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            marginRight: "8px",
            width: "45px",
            height: "45px",
            backgroundColor: brandHex,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={logoSrc} width="50px" alt="logo" />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <h1>{header}</h1>
        <span style={{ display: "flex", alignItems: "center" }}>
          <i
            className="material-icons"
            title="check icon"
            style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
            cancel
          </i>
          Your account is not connected.
        </span>

        <div className="col-12" style={{ marginTop: 25 }}>
          <FormControl style={{ width: "350px" }} variant="filled">
            <InputLabel id="select-app-env">Select App Environment</InputLabel>
            <Select
              label="Select App Environment"
              labelId="select-app-env"
              value={appEnvironment}
              native
              onChange={(e) => setAppEnvironment(e.target.value)}
              inputProps={{
                "aria-label": "Select App Environment",
                name: "appEnvironment",
                id: "appEnvironment",
              }}>
              <option value="production">Production</option>
              <option value="sandbox">Sandbox</option>
            </Select>
          </FormControl>
        </div>

        <Button
          onClick={() => openPopupAuth()}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}>
          Connect To Salesforce
        </Button>
      </div>
    </div>
  );
}

SalesForceAuthenticate.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  integrationDetails: PropTypes.object.isRequired,
  connectionInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setConnectionInfo: PropTypes.func.isRequired,
};
