import React, { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButtonCheckProductLimits from "../../../../util/buttons/productLimits/loadingbutton";
import { isValidEmail } from "util/helpers";
import { insertTasks } from "services/api.service";
import { mapRowToProperties } from "util/csv";
import { isCustomProperty } from "util/contacts";

export default function ConfirmAndAdd(props) {
  let { csvData, properties } = props;
  let { totalRowCount } = csvData;
  let [state, setState] = useState({});

  let confirmAndUploadList = async () => {
    try {
      setState({ buttonState: "loading" });

      const tasks = [];

      const standardProperties = [
        "email",
        "firstName",
        "lastName",
        "cellNumber",
        "textOptIn",
        "salutation",
        "customUserId",
        "customAccountId",
      ];

      const taskProperties = [
        "assigneeEmail",
        "comments",
        "noteId",
        "videoIds",
        "groupTaskId",
        "appendNoteVids",
      ];

      const boolProperties = ["textOptIn"];
      const boolValues = ["true", "y", "yes", "1"];
      const emailSet = new Set();
      let i = 1;

      for (const contact of props.csvData.rows) {
        i++;
        const { email, firstName, lastName } = mapRowToProperties(contact, properties);
        if (!email) {
          throw new Error(`Row ${i}: missing email. Email is required.`);
        }
        if (!isValidEmail(email)) {
          throw new Error(`Row ${i}: invalid email ${email}.`);
        }
        if (emailSet.has(email)) {
          throw new Error(
            `Row ${i}: duplicate email ${email}. Please make sure all emails are unique.`
          );
        }
        if (!firstName || !lastName) {
          throw new Error(`Row ${i}: ${email} is missing first name or last name.`);
        }
        const newTask = {
          contactData: [],
        };

        const contactData = {
          customProperties: {},
        };
        const propertyValues = {};
        for (const key in properties) {
          if (properties[key].mapped) {
            propertyValues[properties[key].columnHeader || key] = key;
          }
        }
        for (const key in contact) {
          if (Object.keys(propertyValues).includes(key)) {
            const mappedKey = propertyValues[key];

            if (contact[key]) {
              if (standardProperties.includes(mappedKey)) {
                if (boolProperties.includes(mappedKey)) {
                  contact[key] = boolValues.includes(contact[key]?.toLowerCase());
                }

                contactData[mappedKey] = contact[key];
              } else if (taskProperties.includes(mappedKey)) {
                newTask[mappedKey] = contact[key];
                if (mappedKey === "videoIds") {
                  newTask[mappedKey] = newTask[mappedKey].split(",");
                } else if (mappedKey === "groupTaskId") {
                  contact.groupTaskId = contact[key];
                } else if (mappedKey === "appendNoteVids") {
                  newTask[mappedKey] = boolValues.includes(contact[key]?.toLowerCase());
                }
              } else if (isCustomProperty(props.accountInfo, mappedKey)) {
                contactData.customProperties[mappedKey] = contact[key];
              }
            }
          }
        }

        newTask.contactData.push(contactData);

        if (contact.groupTaskId) {
          const index = tasks.findIndex(
            (task) => task.groupTaskId === contact.groupTaskId
          );

          if (index > -1) {
            // Add multiple contacts to task
            tasks[index].contactData = [
              ...tasks[index].contactData,
              ...newTask.contactData,
            ];
            continue;
          }
        }

        tasks.push(newTask);
      }

      await insertTasks(tasks);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.addTask" });

      //reset properties. if a user selects a column for "cellNumber",
      //uploads files, then reuploads a new file cellNumber our properties will still show that mapped unless we reset it here
      let tempNewProperties = {};
      let propertiesKeys = Object.keys(properties);
      propertiesKeys.forEach((key) => {
        let current = properties[key];
        current.mapped = false;
        tempNewProperties[key] = current;
      });

      props.setProperties(tempNewProperties);
      setState({ buttonState: "saved", errorMessage: null });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage });
    }
  };

  let { errorMessage, buttonState } = state;

  return (
    <>
      <div className="col-12" style={{ height: "100px" }} />
      <div className="bulkUploadActionBar">
        <div style={{ flex: 1 }} className="justify-content-end">
          {errorMessage && (
            <div className="bulkUploadActionBarErrorMessageBox">
              <i
                className="material-icons"
                style={{ marginRight: "5px", color: "darkorange" }}>
                warning
              </i>
              <b>{errorMessage}</b>
            </div>
          )}
        </div>
        <div className="justify-content-end" style={{ width: "240px" }}>
          {(!buttonState || buttonState === "regular") && (
            <Button
              variant="outlined"
              onClick={() => props.setActiveStep(0)}
              style={{ marginRight: "20px" }}>
              Back
            </Button>
          )}

          <LoadingButtonCheckProductLimits
            accountInfo={props.accountInfo}
            countToAdd={totalRowCount}
            productKey="contacts"
            productLabel="contact"
            userIsAdmin={props.userIsAdmin}
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="Task Added"
            label="Add Tasks"
            clickedRegularButton={confirmAndUploadList}
            clickedSavedButton={() => props.setAddNewDialogOpen(false)}
            disabled={!csvData.rows}
            showErrorAsTooltip={true}
          />
        </div>
      </div>
    </>
  );
}
