import React, { useEffect, useState } from "react";
import { Snackbar, CircularProgress } from "@mui/material";
import { insertTasks } from "services/api.service";
import { getSearchParams } from "util/helpers";

export default function CreateTaskFromHyperlink(props) {
  let [status, setStatus] = useState(null);
  let [open, setOpen] = useState(null);
  let [message, setMessage] = useState("");

  //createTaskFromHyperlink
  useEffect(() => {
    let createTaskFromHyperlink = async () => {
      try {
        const searchParams = getSearchParams();
        const e = searchParams.get("e");
        const fn = searchParams.get("fn");
        const ln = searchParams.get("ln");
        const src = searchParams.get("src");
        let comments = searchParams.get("comments");
        const crmCId = searchParams.get("crmCId");
        const crmAId = searchParams.get("crmAId");
        if (!comments) {
          comments = "Task created by " + src;
        }

        if (!e) {
          throw new Error("Missing required email");
        }

        if (!crmCId && !crmAId) {
          throw new Error("Missing required externalId");
        }

        setMessage("Creating task from " + src + " ...");
        setStatus("loading");
        setOpen(true);

        await insertTasks([
          {
            contactData: [
              {
                email: e,
                firstName: fn,
                lastName: ln,
                customUserId: crmCId,
                customAccountId: crmAId || undefined,
              },
            ],
            comments,
          },
        ]);

        setMessage("");
        setStatus("SUCCESS");
        props.refreshTaskList(true);

        window.history.replaceState("", "", `/a/${props.accountInfo.id}/task`);
      } catch (err) {
        let errorMessage = err.data?.message || err.message;
        console.error(errorMessage);
        setMessage(errorMessage);
        setStatus("ERROR");
        setOpen(true);
      }
    };

    if (window.location.pathname.includes("/createTaskFromHyperlink"))
      createTaskFromHyperlink();
  }, []);

  if (status === "loading") {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        message={message}>
        <div
          style={{
            height: 50,
            minWidth: 250,
            color: "white",
            backgroundColor: "rgb(49, 49, 49)",
            padding: "6px 16px",
            boxShadow:
              "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
          }}>
          <div style={{ width: 35, marginTop: 4 }}>
            <CircularProgress size={20} color="inherit" />
          </div>
          <div style={{ flex: 1, color: "white" }}>{message}</div>
        </div>
      </Snackbar>
    );
  } else if (status === "SUCCESS") {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={message}>
        <div
          style={{
            height: 50,
            minWidth: 250,
            backgroundColor: "#EFF7EE",
            padding: "6px 16px",
            boxShadow:
              "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            border: "1px #284524 solid",
          }}
          onClick={() => setOpen(false)}>
          <div style={{ width: 24, marginTop: 4 }}>
            <i
              className="material-icons"
              title="success"
              style={{
                color: "#284524",
                fontSize: "18px",
                marginRight: "16px",
                opacity: 0.9,
              }}>
              check_circle
            </i>
          </div>
          <div style={{ flex: 1, color: "#284524" }}>{message}</div>
        </div>
      </Snackbar>
    );
  } else if (status === "WARNING") {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={message}>
        <div
          style={{
            height: 50,
            minWidth: 250,
            backgroundColor: "#FDF4E7",
            padding: "6px 16px",
            boxShadow:
              "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            border: "1px #603E11 solid",
          }}
          onClick={() => setOpen(false)}>
          <div style={{ width: 24, marginTop: 4 }}>
            <i
              className="material-icons"
              title="warning"
              style={{
                color: "#F2A64A",
                fontSize: "18px",
                marginRight: "16px",
                opacity: 0.9,
              }}>
              warning
            </i>
          </div>
          <div style={{ flex: 1, color: "#603E11" }}>{message}</div>
        </div>
      </Snackbar>
    );
  } else if (status === "ERROR") {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={10000}
        onClose={() => setOpen(false)}
        message={message}>
        <div
          style={{
            height: 50,
            minWidth: 250,
            backgroundColor: "#FAEDEA",
            padding: "6px 16px",
            boxShadow:
              "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            border: "1px #591F19 solid",
          }}
          onClick={() => setOpen(false)}>
          <div style={{ width: 24, marginTop: 4 }}>
            <i
              className="material-icons"
              title="error"
              style={{
                color: "#E46252",
                fontSize: "18px",
                marginRight: "16px",
                opacity: 0.9,
              }}>
              error
            </i>
          </div>
          <div style={{ flex: 1, color: "#591F19" }}>{message}</div>
        </div>
      </Snackbar>
    );
  } else {
    return null;
  }
}
