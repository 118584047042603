import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "components/util/buttons/loadingbutton";
import Folders from "../folders";
import {
  getAccountFolders,
  setVideoFolders,
  setContactFolders,
  setNoteFolders,
} from "services/api.service";
import indexHelper from "util/index-helper";

export default function EditItemFolders(props) {
  const [editFolders, setEditFolders] = useState(null);
  const [buttonState, setButtonState] = useState("regular");

  useEffect(() => {
    let getData = async () => {
      try {
        setButtonState("loading");
        let tmpFolders = [];
        const accountFolders = await getAccountFolders(props.indexName);
        const itemFolders = props.selectedItems?.length
          ? []
          : indexHelper[props.indexName] === indexHelper.video
          ? props.currentItem.videoFolders
          : indexHelper[props.indexName] === indexHelper.note
          ? props.currentItem.noteFolders
          : props.currentItem.contactFolders;

        tmpFolders = accountFolders.map((folder) => ({
          ...folder,
          label: folder.name,
          selected: props.selectedItems?.length
            ? false
            : itemFolders.some(({ folderId }) => folderId === folder.id),
        }));

        setEditFolders(tmpFolders);
      } catch (err) {
        console.error(err?.data?.message ?? err.message);
      } finally {
        setButtonState("regular");
      }
    };

    getData();
  }, []);

  const onToggle = (itemId) => {
    const found = editFolders.find(({ id }) => id === itemId);
    found.selected = !found.selected;
    setEditFolders([...editFolders]);
  };

  const onSave = async (e) => {
    try {
      setButtonState("loading");
      const selectedFolderIds = editFolders
        .filter(({ selected }) => selected)
        .map(({ id }) => id);

      const ids = props.selectedItems?.length
        ? props.selectedItems.map(({ id }) => id)
        : [props.currentItem.id];

      if (!ids?.length) {
        setButtonState("saved");
        return;
      }

      const selectedItems = props.selectedItems || [];

      if (indexHelper[props.indexName] === indexHelper.contact) {
        const folders = [];
        for (const id of ids) {
          folders.push({ contactId: id, folderIds: selectedFolderIds || [] });
        }

        const newFolders = await setContactFolders(folders);

        if (props.currentItem) {
          props.currentItem.contactFolders = newFolders;
        } else {
          for (const item of selectedItems) {
            item.contactFolders = newFolders.filter(
              ({ contactId }) => contactId === item.id
            );
          }
        }
      }

      if (indexHelper[props.indexName] === indexHelper.video) {
        const videoFolders = [];
        for (const id of ids) {
          videoFolders.push({ videoId: id, folderIds: selectedFolderIds || [] });
        }

        const newFolders = await setVideoFolders(videoFolders);

        if (props.currentItem) {
          props.currentItem.videoFolders = newFolders;
        } else {
          for (const item of selectedItems) {
            item.videoFolders = newFolders.filter(({ videoId }) => videoId === item.id);
          }
        }
      }

      if (indexHelper[props.indexName] === indexHelper.note) {
        const noteFolders = [];
        for (const id of ids) {
          noteFolders.push({ noteId: id, folderIds: selectedFolderIds || [] });
        }

        const newFolders = await setNoteFolders(noteFolders);

        if (props.currentItem) {
          props.currentItem.noteFolders = newFolders;
        } else {
          for (const item of selectedItems) {
            item.noteFolders = newFolders.filter(({ noteId }) => noteId === item.id);
          }
        }
      }

      if (selectedItems.length) {
        const searchList = props.searchList.map((item) => {
          const selectedItem = selectedItems.find(({ id }) => id === item.id);
          if (selectedItem) {
            return {
              ...item,
              contactFolders: selectedItem.contactFolders,
              videoFolders: selectedItem.videoFolders,
              noteFolders: selectedItem.noteFolders,
            };
          } else {
            return item;
          }
        });
        props.setSearchList(searchList);
      }

      setButtonState("saved");
    } catch (err) {
      console.error(err);
      setButtonState("regular");
    }
  };
  return (
    <Dialog
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="edit-folders-dialog">
      <div className="row col-12 pagePadding" style={{ padding: "20px" }}>
        <div className="col-10 align-items-center">
          <h2>
            Edit {props.indexName === "contacts" ? "list" : "tags"} for{" "}
            {props?.selectedItems?.length || 1} {props.dialogTitle}
          </h2>
        </div>

        <div className="col-2 justify-content-end">
          <IconButton aria-label="Delete" onClick={() => props.setDialogOpen(false)}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>

        <Folders
          accountId={props.accountId}
          folders={editFolders}
          setFolders={setEditFolders}
          onToggle={onToggle}
          indexName={props.indexName}
        />

        <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={buttonState}
            savedLabel="Saved!"
            label="Save"
            clickedRegularButton={onSave}
            clickedSavedButton={() => props.setDialogOpen(false)}
          />
        </div>
      </div>
    </Dialog>
  );
}

EditItemFolders.propTypes = {
  accountId: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  currentItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contactFolders: PropTypes.array,
    videoFolders: PropTypes.array,
    noteFolders: PropTypes.array,
  }),
  selectedItems: PropTypes.array,
  dialogOpen: PropTypes.bool,
  indexName: PropTypes.string,
};
