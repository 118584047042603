import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ContactItem from "./contactItem";
import { listContactBirthdays } from "services/api.service";

const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function sortByDobDay(a, b) {
  return a.dobDay - b.dobDay;
}

export default function BirthdayContacts(props) {
  let [thisMonthContacts, setThisMonthContacts] = useState("loading");
  let [nextMonthContacts, setNextMonthContacts] = useState([]);
  let [monthLabels, setMonthLabels] = useState({
    thisMonthLabel: "",
    nextMonthLabel: "",
  });
  let [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth();
    const thisMonth = month + 1;
    const nextMonth = ((month + 1) % 12) + 1;

    const thisMonthLabel = months[thisMonth];
    const nextMonthLabel = months[nextMonth];
    setMonthLabels({ thisMonthLabel, nextMonthLabel });

    listContactBirthdays(thisMonth)
      .then((contacts) => {
        setThisMonthContacts(contacts.sort(sortByDobDay));
      })
      .catch((err) => setErrorMessage(err.errorMessage));
    listContactBirthdays(nextMonth)
      .then((contacts) => {
        setNextMonthContacts(contacts.sort(sortByDobDay));
      })
      .catch((err) => setErrorMessage(err.errorMessage));
  }, []);

  if (thisMonthContacts === "loading") {
    return (
      <div className="row col-12 adSectionBox" style={{ marginTop: "10px" }}>
        <div className="col-12" style={{ marginBottom: "15px" }}>
          <h2>Contacts</h2>
        </div>
        <div className="col-12" style={{ padding: "10px" }}>
          {errorMessage && (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          )}
          <LinearProgress />
        </div>
      </div>
    );
  }

  let listThisMonth =
    thisMonthContacts.length === 0 ? (
      <div
        className="col-12"
        style={{
          padding: "15px 0px",
          fontSize: "13px",
          width: "55px",
          fontColor: "grey",
        }}>
        No remaining birthdays this month
      </div>
    ) : (
      thisMonthContacts.map((current) => {
        return <ContactItem key={current.id} contact={current} {...props} />;
      })
    );

  let listNextMonth =
    nextMonthContacts.length === 0 ? (
      <div
        className="col-12"
        style={{
          padding: "15px 0px",
          fontSize: "13px",
          width: "55px",
          fontColor: "grey",
        }}>
        No birthdays this month
      </div>
    ) : (
      nextMonthContacts.map((current) => {
        return <ContactItem key={current.id} contact={current} {...props} />;
      })
    );

  return (
    <div className="row col-12 adSectionBox" style={{ marginTop: "10px" }}>
      <div className="col-12" style={{ marginBottom: "15px" }}>
        <h2>Upcoming Birthdays</h2>
      </div>

      <div className="col-12">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.shareViaEmail}
              onChange={(e) => props.saveNoteState("shareViaEmail", !props.shareViaEmail)}
              value={props.shareViaEmail}
            />
          }
          label="Send birthday email"
        />
      </div>
      <div className="col-12">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.shareViaText}
              onChange={(e) => props.saveNoteState("shareViaText", !props.shareViaText)}
              value={props.shareViaText}
            />
          }
          label="Send birthday text"
        />
      </div>

      <div
        className="row col-12"
        style={{
          padding: "5px 10px",
          fontSize: "12px",
          border: "1px #ddd solid",
          borderBottom: "none",
          borderRadius: "2px 2px 0px 0px",
        }}>
        <div style={{ width: "55px" }}>Date</div>

        <div style={{ flex: 1 }}>Contact</div>

        <div style={{ width: "65px", display: "flex", justifyContent: "center" }}>
          Send
        </div>
      </div>

      <div
        className="row col-12"
        style={{
          maxHeight: "275px",
          overflowY: "scroll",
          border: "1px #ddd solid",
          borderRadius: "0px 0px 2px 2px",
        }}>
        <div
          className="row col-12"
          style={{
            backgroundColor: "#f5f6f7",
            padding: "10px",
            color: "#676b73",
            fontSize: "12px",
          }}>
          {monthLabels.thisMonthLabel} Birthdays
        </div>

        <div className="row col-12" style={{ padding: "5px 10px" }}>
          {listThisMonth}
        </div>

        <div
          className="row col-12"
          style={{
            backgroundColor: "#f5f6f7",
            padding: "10px",
            color: "#676b73",
            fontSize: "12px",
          }}>
          {monthLabels.nextMonthLabel} Birthdays
        </div>

        <div className="row col-12" style={{ padding: "5px 10px" }}>
          {listNextMonth}
        </div>
      </div>
    </div>
  );
}

BirthdayContacts.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  saveNoteState: PropTypes.func.isRequired,
  shareViaEmail: PropTypes.bool.isRequired,
  shareViaText: PropTypes.bool.isRequired,
};
