import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export default function MaintenanceSnackbar() {
  const [showMaintenanceError, setShowMaintenanceError] = useState(false);

  return (
    <Snackbar open={showMaintenanceError} onClose={() => setShowMaintenanceError(false)}>
      <Alert severity="error">
        Gratavid has a scheduled maintenance window on Sunday 1/21/2024 from 1 PM EST to
        5PM EST. The application will be unavailable during this time
      </Alert>
    </Snackbar>
  );
}

MaintenanceSnackbar.propTypes = {};
