import React, { useState } from "react";
import PropTypes from "prop-types";
import ListSelectedContacts from "./selectedContacts/listSelectedContacts";
import SearchContacts from "./searchContacts/searchContacts";
import { ClickAwayListener, Tooltip, FormControlLabel, Checkbox } from "@mui/material";
import AddContacts from "./addContacts";

export default function Contacts(props) {
  const { accountInfo, noteContacts = [], contactCounts } = props;
  let [showSearchContacts, setShowSearchContacts] = useState(false);
  let [addNewDialogOpen, setAddNewDialogOpen] = useState(false);

  function addContact(contact) {
    noteContacts.push({ contactId: contact.id, contact });
    props.saveNoteState("noteContacts", noteContacts);
  }

  function addFolder(folder) {
    noteContacts.push({ folderId: folder.id, folder });
    props.saveNoteState("noteContacts", noteContacts);
  }

  function removeNoteContact(index) {
    noteContacts.splice(index, 1);
    props.saveNoteState("noteContacts", noteContacts);
  }

  function toggleContact(contact) {
    const index = noteContacts.findIndex((nc) => nc.contactId === contact.id);

    if (index >= 0) {
      removeNoteContact(index);
    } else {
      addContact(contact);
    }
  }

  function toggleFolder(folder) {
    const index = noteContacts.findIndex((nc) => nc.folderId === folder.id);

    if (index >= 0) {
      removeNoteContact(index);
    } else {
      addFolder(folder);
    }
  }

  return (
    <div className="row col-12 adSectionBox" style={{ marginTop: "10px" }}>
      <div className="col-12" style={{ marginBottom: "15px" }}>
        <h2>Send To</h2>
      </div>

      <>
        <ClickAwayListener onClickAway={() => setShowSearchContacts(false)}>
          <div className="col-12 row" style={{ position: "relative" }}>
            <ListSelectedContacts
              noteContacts={noteContacts}
              onClick={() => setShowSearchContacts(true)}
              customContactProperties={props.customContactProperties}
              accountInfo={accountInfo}
              onRemoveNoteContact={removeNoteContact}
            />

            {showSearchContacts && !addNewDialogOpen && (
              <SearchContacts
                setShowSearchContacts={setShowSearchContacts}
                setAddNewDialogOpen={setAddNewDialogOpen}
                accountInfo={accountInfo}
                noteContacts={noteContacts}
                onToggleFolder={toggleFolder}
                onToggleContact={toggleContact}
              />
            )}
          </div>
        </ClickAwayListener>
      </>

      <AddContacts
        contacts={Object.fromEntries(
          noteContacts.map(({ contactId, contact }) => [contactId, contact])
        )}
        addContactOrFolder={(type, contact) => addContact(contact)}
        accountInfo={accountInfo}
        addNewDialogOpen={addNewDialogOpen}
        setAddNewDialogOpen={setAddNewDialogOpen}
        userIsAdmin={props.userIsAdmin}
      />

      <>
        <div className="col-12">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.shareViaEmail}
                onChange={(e) =>
                  props.saveNoteState("shareViaEmail", !props.shareViaEmail)
                }
              />
            }
            label={
              contactCounts.email > 0
                ? "Send email to " + contactCounts.email + " people"
                : "Send emails"
            }
          />
        </div>
        <div className="col-12">
          {accountInfo.textEnabled ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.shareViaText}
                  onChange={(e) =>
                    props.saveNoteState("shareViaText", !props.shareViaText)
                  }
                />
              }
              label={
                contactCounts.text > 0
                  ? "Send text message to " + contactCounts.text + " people"
                  : "Send sms text"
              }
            />
          ) : (
            <Tooltip title="Upgrade account to send text messages" placement="top">
              <FormControlLabel
                disabled
                control={<Checkbox checked={false} value={false} />}
                label="Send text message"
              />
            </Tooltip>
          )}
        </div>
      </>
    </div>
  );
}

Contacts.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool,
  noteContacts: PropTypes.array,
  contactCounts: PropTypes.shape({
    email: PropTypes.number,
    text: PropTypes.number,
  }).isRequired,
  shareViaEmail: PropTypes.bool,
  shareViaText: PropTypes.bool,
  customContactProperties: PropTypes.array,
  saveNoteState: PropTypes.func,
};
