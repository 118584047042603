import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Button, LinearProgress, List, ListItem, TextField } from "@mui/material";

import { saveCustomMergeTags } from "../../../../services/api.service.js";
import styles from "./mergeTags.module.css";

export default function MergeTags({ accountInfo, passedSetStateFromApp, onSnackbar }) {
  const [isLoading, setIsLoading] = useState(true);
  const [mergeFields, setMergeFields] = useState([]);

  const defaultMergeFields = [
    {
      key: "firstName",
      label: "First Name",
      isReadonly: true,
    },
    {
      key: "lastName",
      label: "Last Name",
      isReadonly: true,
    },
    {
      key: "salutation",
      label: "Salutation",
      isReadonly: true,
    },
  ];

  const { customContactProperties } = accountInfo;
  useEffect(() => {
    setMergeFields(
      Object.entries(customContactProperties).map(([key, value]) => {
        return {
          id: crypto.randomUUID(),
          key,
          label: value?.label ?? key,
          dv: value?.dv ?? "",
        };
      })
    );
    setIsLoading(false);
  }, [customContactProperties]);

  function changeMergeTag(tag) {
    const newMergeFields = mergeFields.map((mf) => {
      if (mf.id === tag.id) {
        return tag;
      }
      return mf;
    });
    setMergeFields(newMergeFields);
  }

  async function saveMergeTags() {
    try {
      const dupeMap = new Map();
      for (const mergeField of mergeFields) {
        if (dupeMap.has(mergeField.key)) {
          onSnackbar("error", "Merge tags must have unique keys");
          return;
        }

        dupeMap.set(mergeField.key, mergeField.label);
      }

      const customProps = await saveCustomMergeTags(mergeFields);
      passedSetStateFromApp("accountInfo", {
        ...accountInfo,
        customContactProperties: customProps,
      });
      onSnackbar("success", "Custom merge fields saved successfully");
    } catch (e) {
      onSnackbar("error", e.data.message);
    }
  }

  if (isLoading) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className={styles.mergeTagsContainer}>
      <h2>Default Merge Tags</h2>

      <div className={styles.mergeTagsListWrapper}>
        <List>
          {defaultMergeFields.map((mergeField) => (
            <ListItem key={mergeField.key}>
              <div className={"col-lg-4 " + styles.mergeTagLabel}>
                <TextField disabled label="Label" value={mergeField.label} />
              </div>
              <div className={"col-lg-4 " + styles.mergeTagLabel}>
                <TextField disabled label="Key" value={mergeField.key} />
              </div>

              <div className="col-lg-4">
                <TextField disabled label="Default Value" value={mergeField.dv} />
              </div>
            </ListItem>
          ))}
        </List>
      </div>
      <h2>Custom Merge Tags</h2>
      <p className={styles.mergeTagDescription}>
        Label is the value users will see while selecting custom merge tags for use,
        whereas the key is a single word that can be used to reference the values (E.G:
        firstName, lastName, salutation)
      </p>

      <div className={styles.mergeTagsListWrapper}>
        <List aria-label="Merge tags">
          {mergeFields.map((mergeField) => (
            <ListItem key={mergeField.id}>
              <div className={"col-lg-4 " + styles.mergeTagLabel}>
                <TextField
                  label="Label"
                  value={mergeField.label}
                  onChange={(e) =>
                    changeMergeTag({ ...mergeField, label: e.target.value })
                  }
                />
              </div>
              <div className={"col-lg-4 " + styles.mergeTagLabel}>
                <TextField
                  label="Key"
                  value={mergeField.key}
                  onChange={(e) => changeMergeTag({ ...mergeField, key: e.target.value })}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  label="Default Value"
                  value={mergeField.dv}
                  onChange={(e) => changeMergeTag({ ...mergeField, dv: e.target.value })}
                />
              </div>

              <Button
                variant="text"
                title="Delete"
                onClick={() =>
                  setMergeFields(mergeFields.filter((mf) => mf.id !== mergeField.id))
                }>
                <i className={"material-icons " + styles.mergeTagButton}>cancel</i>
              </Button>
            </ListItem>
          ))}
        </List>

        <Button
          variant="text"
          onClick={() =>
            setMergeFields([
              ...mergeFields,
              { id: crypto.randomUUID(), key: "", label: "", dv: "" },
            ])
          }>
          <i className="material-icons" style={{ marginRight: "5px", color: "#f76c6c" }}>
            add_box
          </i>
          Create New
        </Button>

        <div className={styles.saveContainer}>
          <Button variant="contained" color="primary" onClick={saveMergeTags}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

MergeTags.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customContactProperties: PropTypes.object.isRequired,
  }),
  passedSetStateFromApp: PropTypes.func.isRequired,
  onSnackbar: PropTypes.func.isRequired,
};
