import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import TextInputToAPI from "../../../../../util/hooks/input/textInputToApi";
import QuestionToLink from "../../../../../util/explainers/questionToLink";
import SendTextEmail from "./sendTextEmail";
import SearchRecorderName from "./searchRecorderName";
import { LinearProgress } from "@mui/material";
import { IconButton, InputLabel, Snackbar } from "@mui/material";
import { getVideo, editVideo } from "services/api.service";
import { debounce } from "util/helpers";

export default function SendRequest(props) {
  let [requestId, setRequestId] = useState("loading");
  const [video, setVideo] = useState({
    speakerName: "",
    requestEmail: "",
    requestCell: "",
    requestDirections: "",
    requestHistory: [],
  });
  let [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const getRequestData = async () => {
      const video = await getVideo(props.videoId);
      setVideo(video);

      setRequestId(video.id);
    };

    getRequestData();
  }, []);

  async function onUpdateVideo(field, value, bulkUpdates) {
    const updates = bulkUpdates || { [field]: value };
    setVideo({ ...video, ...updates });
    debouncedEditVideo(props.videoId, updates);
  }

  const debouncedEditVideo = useCallback(debounce(editVideo), [props]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(video.shortLinkUrl);
    setSnackbarOpen(true);
  };

  if (requestId === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );

  return (
    <div className="row col-12">
      <div className="col-12" style={{ marginTop: "5px" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h1 style={{ fontSize: "22px" }}>Video Request</h1>
          <QuestionToLink id="video" desc="videos" />
        </div>
        <p className="greyDesc">
          Share the upload link directly with someone to record/upload their video, or
          send the link via Gratavid.
        </p>
      </div>

      <div className="col-12">
        <div style={{ flex: 1, marginTop: "25px" }}>
          <SearchRecorderName
            accountId={props.accountInfo.id}
            recorderName={video.speakerName || ""}
            onUpdateVideo={onUpdateVideo}
          />
        </div>

        <div style={{ flex: 1, margin: "15px 0px" }}>
          <TextInputToAPI
            initialValue={video.directions}
            label="Directions"
            variant="outlined"
            field="directions"
            apiCall={onUpdateVideo}
            multiline={true}
            startIcon="notes"
          />
        </div>
      </div>

      <div className="col-12 row" style={{ marginTop: "15px" }}>
        <div className="col-12">
          <InputLabel htmlFor="shortLinkUrl">Upload Link</InputLabel>
        </div>
        <div className="col-12" style={{ display: "flex" }}>
          <div style={{ width: "50px" }}>
            <IconButton aria-label="copy upload link" onClick={copyToClipboard}>
              <i className="material-icons">content_copy</i>
            </IconButton>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href={video.shortLinkUrl}>
              {video.shortLinkUrl}
            </a>
          </div>
        </div>
      </div>

      <SendTextEmail
        key={video.speakerName}
        shortLinkUrl={video.shortLinkUrl}
        requestCell={video.requestCell}
        onUpdateVideo={onUpdateVideo}
        requestEmail={video.requestEmail}
        requestDirections={video.directions}
        recorderName={video.speakerName}
        requestHistory={video.requestHistory}
        accountInfo={props.accountInfo}
        videoId={props.videoId}
      />

      <div className="col-12" style={{ height: "50px" }} />

      <Snackbar
        autoHideDuration={2500}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        ContentProps={{ "aria-describedby": "request url was copied" }}
        message={<span id="message-id">Upload link copied</span>}
      />
    </div>
  );
}

SendRequest.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  videoId: PropTypes.string.isRequired,
};
