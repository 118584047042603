import React, { useState, useEffect } from "react";
import PropType from "prop-types";
import { Tab, Tabs, LinearProgress } from "@mui/material";
import EditNote from "../notes/editNote/editNote";
import NoteAnalytics from "../notes/editNote/analytics/noteAnalytics";
import SendHistory from "../notes/editNote/sendHistory/sendHistory";
import QuestionToLink from "../../util/explainers/questionToLink";
import NoteRecipients from "../notes/editNote/analytics/recipients/recipients";
import {
  createBirthdayNote,
  getAccount,
  getNoteAnalytics,
  patchAccount,
} from "services/api.service";

function getTimeZoneOffset(timezone) {
  if (timezone === "America/Los_Angeles") return 8;
  else if (timezone === "America/Denver") return 7;
  else if (timezone === "America/Chicago") return 6;
  else if (timezone === "America/New_York") return 5;
  else return 0;
}

export default function Birthday(props) {
  const [state, setState] = useState({});
  const [account, setAccount] = useState({});
  const [noteAnalytics, setNoteAnalytics] = useState();
  const [tabSelected, setTabSelected] = useState("edit");
  const [analyticsSendItemSelected, setAnalyticsSendItemSelected] = useState({});
  const [recipientsEventSelected, setRecipientsEventSelected] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const noteTitle = "Birthday Note";

  useEffect(() => {
    getAccount().then((account) => setAccount(account));
  }, []);

  useEffect(() => {
    setState({
      active: account.birthdayActive || false,
      noteId: account.birthdayNoteId,
      timezone: account.birthdayTimezone || "America/Chicago",
      localSendTime: account.birthdayLocalSendTime || "9:30",
    });

    if (!account.birthdayNoteId) {
      createBirthdayNote().then(({ birthdayNoteId }) => {
        setState((current) => ({
          ...current,
          noteId: birthdayNoteId,
        }));
      });
    }
  }, [account]);

  useEffect(() => {
    if (
      ["analytics", "recipients", "history"].includes(tabSelected) &&
      noteAnalytics === undefined
    ) {
      setNoteAnalytics(null); // to prevent getting again if user switches tabs
      getNoteAnalytics(props.accountInfo.birthdayNoteId).then((analytics) => {
        setNoteAnalytics(analytics);
      });
    }
  }, [tabSelected, props.accountInfo.birthdayNoteId, noteAnalytics, setNoteAnalytics]);

  const updateBirthdaySettings = async (active, localSendTime, timezone) => {
    try {
      let hourOffset = getTimeZoneOffset(timezone);
      let localHour = localSendTime.split(":")[0];
      localHour = parseInt(localHour, 10);
      let utcHour = localHour + hourOffset;
      let utcMinutes = localSendTime.split(":")[1];
      utcHour = utcHour > 23 ? utcHour - 24 : utcHour;
      const utcSendTime = utcHour + ":" + utcMinutes;

      const update = {
        birthdayActive: active,
        birthdayTimezone: timezone,
        birthdayLocalSendTime: localSendTime,
        birthdayUtcSendTime: utcSendTime,
      };

      await patchAccount(update);
      setState({ ...state, active, localSendTime, timezone });
    } catch (err) {
      console.error(err.message);
      setErrorMessage(err.message);
    }
  };

  if (!state.noteId) {
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        <LinearProgress />

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  let editNoteShowSettings = {
    showContacts: false,
    showBirthdayContacts: true,
    showBirthdaySettings: true,
    showSendNote: false,
    showCTA: true,
    showBranding: true,
    showEmail: true,
    showText: true,
    showReply: true,
    showResend: false,
    showExternalShare: true,
    showEmailLocalPart: true,
    showEmailReplyTo: true,
  };

  return (
    <div className="row col-12">
      <div className="row col-12 " style={{ marginBottom: "10px" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h1>Birthday Note</h1>
          <QuestionToLink id="birthday" desc="birthdays" />
        </div>
      </div>

      <div
        className="col-12"
        style={{ borderBottom: "1px #ddd solid", padding: "0px 8.5px" }}>
        <Tabs
          value={tabSelected}
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="task tabs"
          variant="scrollable"
          scrollButtons="auto">
          <Tab label="Edit Note" {...a11yProps("edit")} value="edit" />
          <Tab label="Send History" {...a11yProps("history")} value="history" />
          <Tab label="Analytics" {...a11yProps("analytics")} value="analytics" />
          <Tab label="Recipients" {...a11yProps("recipients")} value="recipients" />
        </Tabs>
      </div>

      {tabSelected === "edit" && (
        <EditNote
          accountInfo={props.accountInfo}
          userInfo={props.userInfo}
          userRole={props.userRole}
          showBirthdaySettings={true}
          showBirthdayContacts={true}
          noteId={state.noteId}
          setState={setState}
          active={state.active}
          localSendTime={state.localSendTime}
          timezone={state.timezone}
          updateBirthdaySettings={updateBirthdaySettings}
          getTimeZoneOffset={getTimeZoneOffset}
          setTabSelected={setTabSelected}
          showSettings={editNoteShowSettings}
          calledFromBirthday={true}
          userIsAdmin={props.userInfo.isAdmin}
        />
      )}
      {tabSelected === "history" && (
        <SendHistory
          {...props}
          noteId={state.noteId}
          setRecipientsEventSelected={setRecipientsEventSelected}
          setTabSelected={setTabSelected}
          analyticsSendItemSelected={analyticsSendItemSelected}
          setAnalyticsSendItemSelected={setAnalyticsSendItemSelected}
          analytics={noteAnalytics}
        />
      )}
      {tabSelected === "analytics" && (
        <NoteAnalytics
          {...props}
          noteId={state.noteId}
          analyticsSendItemSelected={analyticsSendItemSelected}
          setAnalyticsSendItemSelected={setAnalyticsSendItemSelected}
          analytics={noteAnalytics}
        />
      )}
      {tabSelected === "recipients" && (
        <NoteRecipients
          {...props}
          noteId={state.noteId}
          noteTitle={noteTitle}
          recipientsEventSelected={recipientsEventSelected}
          setRecipientsEventSelected={setRecipientsEventSelected}
          analytics={noteAnalytics}
        />
      )}
    </div>
  );
}

Birthday.propTypes = {
  accountInfo: PropType.object.isRequired,
  userInfo: PropType.object.isRequired,
  userRole: PropType.object.isRequired,
};
