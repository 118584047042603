import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import AddBulkStepper from "./addBulkStepper";
import QuestionToLink from "../../../util/explainers/questionToLink";
import { Link } from "react-router";

export default function BulkRequest(props) {
  if (props.bulkVideosDisabled) {
    return (
      <div className="col-12 row " style={{ padding: "20px" }}>
        <div className="col-12">
          <h1>Request Videos In Bulk</h1>
        </div>

        <p style={{ fontSize: "15px" }}>
          Your current Gratavid plan does not include requesting videos in bulk. You must
          request videos one at a time or upgrade your plan.
        </p>

        <div className="col-12" style={{ margin: "15px 0px 30px 0px" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/a/${props.accountInfo.id}/subscription`}>
            Upgrade Plan
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12 row ">
      <div className="col-12" style={{ padding: "20px" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <h1>Request Videos In Bulk</h1>
          <QuestionToLink id="requestVidsBulk" desc="request videos in bulk" />
        </div>
      </div>

      <AddBulkStepper accountInfo={props.accountInfo} />
    </div>
  );
}

BulkRequest.propTypes = {
  bulkVideosDisabled: PropTypes.bool,
  accountInfo: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool,
};
