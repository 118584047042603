import React, { useState } from "react";
import { Button, Snackbar, CircularProgress } from "@mui/material";

export default function CopyEmbedCode(props) {
  let [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
  let [state, setState] = useState({});

  //this works accross browsers and on IOS!
  function copyToClipboard(html) {
    let textarea;
    let result;

    try {
      textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", true);
      textarea.setAttribute("contenteditable", true);
      textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = html;

      document.body.appendChild(textarea);

      let listener = (e) => {
        e.clipboardData.setData("text/html", html);
        e.clipboardData.setData("text/plain", html);
        e.preventDefault();
      };

      document.addEventListener("copy", listener, true);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand("copy");
      window.getSelection().removeAllRanges();
      document.removeEventListener("copy", listener, true);
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    //this will prompt a dialog screen
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const copyHotkey = isMac ? "⌘C" : "CTRL+C";
      result = prompt(`Press ${copyHotkey}`, html); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    return true;
  }

  let copyHTML = async () => {
    try {
      let { noteId, contactId, taskId, contentId = null, videoData } = props;
      let { gifUrl, width, height, thumbnailUrl } = videoData;

      let calcWidth;
      let calcHeight;
      if (!width || !height) {
        calcWidth = 250;
        calcHeight = 300;
      } else {
        let aspectRatio = width / height;
        //if its hotdog vid, then width can be wider
        let maxWidth = width > height ? 300 : 170;
        calcWidth = maxWidth;
        calcHeight = calcWidth / aspectRatio;
      }
      //used by analytics to group email and text events
      let gvSendId = "sid_" + noteId;
      let gvSendTimestamp = Date.now();
      //rec is how we record the email click

      //save gvSendId to noteHistory so our replies know where to go. this data point is used by sendNotifcation on server
      setState({ buttonState: "loading" });

      let buttonURL =
        `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
        noteId +
        "&sid=" +
        gvSendId +
        "&stm=" +
        gvSendTimestamp +
        "&rec=true";
      if (contactId) buttonURL += "&cId=" + contactId;
      if (taskId) buttonURL += "&tId=" + taskId;
      if (contentId) buttonURL += "&contentId=" + contentId;

      let html =
        "<a href='" +
        buttonURL +
        "' target='_blank' aria-label='Watch your video now'><img style='border-radius:24px;background-color:#ddd; background: url(" +
        thumbnailUrl +
        ") center cover;'  width='" +
        calcWidth +
        "' height='" +
        calcHeight +
        "' alt='Click to Play' src='" +
        gifUrl +
        "' /></a><br /><a href='" +
        buttonURL +
        "' target='_blank' aria-label='Watch your video now'>Watch video now</a>";
      let success = copyToClipboard(html);
      if (!success)
        throw new Error(
          "Error copying embed code. Try visiting this page on desktop Google Chrome"
        );
      setState({});
      setCopiedSnackbarOpen(true);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let hideEmbedBtn = navigator.userAgent.match(/ipad|ipod|iphone/i);
  if (hideEmbedBtn) return null;

  if (state.buttonState === "loading") {
    return (
      <Button style={{ color: "#24305e", fontWeight: 600 }}>
        <CircularProgress size={16} color="inherit" />
        Embed Video
      </Button>
    );
  } else if (state.errorMessage) {
    return (
      <>
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
        <Button style={{ color: "#24305e", fontWeight: 600 }}>
          <i className="material-icons" style={{ fontSize: "16px", marginRight: "3px" }}>
            file_copy
          </i>{" "}
          Embed Video
        </Button>
      </>
    );
  }

  return (
    <>
      <Button style={{ color: "#24305e", fontWeight: 600 }} onClick={copyHTML}>
        <i className="material-icons" style={{ fontSize: "16px", marginRight: "3px" }}>
          file_copy
        </i>{" "}
        Embed Video
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={copiedSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setCopiedSnackbarOpen(false)}
        message="Embed code copied. Now paste into email"
      />
    </>
  );
}
