import React from "react";
import AutoSuggestInput from "./autoSuggestInput";
import IconButton from "@mui/material/IconButton";

export default function SelectVideo(props) {
  let {
    title: videoTitle = null,
    thumbnailUrl,
    thumbnail,
  } = props.currentCampaignVideo ? props.currentCampaignVideo : {};

  return (
    <div className="row col-12" style={{ marginTop: "10px" }}>
      <div className="col-12" style={{ display: "flex" }}>
        {videoTitle ? (
          <div
            className="col-12 row"
            style={{ height: "56px", display: "flex", alignItems: "center" }}>
            <div style={{ width: 85 }}>
              <img
                src={thumbnailUrl || thumbnail}
                style={{
                  width: "75px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  backgroundColor: "#ddd",
                }}
                alt="video thumbnail"
              />
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              {videoTitle}
            </div>
            <div style={{ width: 48 }}>
              <IconButton
                aria-label="help button, view more info"
                onClick={() => props.itemSelected(null)}
                id="closeButton">
                <i className="material-icons">close</i>
              </IconButton>
            </div>
          </div>
        ) : (
          <div style={{ flex: 1, height: "56px" }}>
            <AutoSuggestInput
              type="note"
              key="note"
              index="notes"
              {...props}
              itemSelected={props.itemSelected}
            />
          </div>
        )}
      </div>
    </div>
  );
}
