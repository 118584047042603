import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, Select, InputLabel } from "@mui/material";
import { getAccountUsers } from "services/api.service";

export default function SelectUser(props) {
  let { users: initialUsers = "loading", label = "" } = props;
  let [users, setUsers] = useState(initialUsers);

  useEffect(() => {
    let getData = async () => {
      if (users !== "loading") return;

      try {
        const users = await getAccountUsers();
        setUsers(users.map((user) => ({ email: user.email, userUID: user.id })));
      } catch (err) {
        console.error(err.message);
      }
    };

    getData();
  }, []);

  let variant = props.variant ? props.variant : "standard";
  let emptyLabel = props.emptyLabel ? props.emptyLabel : "All assignees";

  return (
    <FormControl style={{ width: "185px" }} variant={variant}>
      <InputLabel id="assignedTo" shrink={true}>
        {label}
      </InputLabel>
      <Select
        label={label}
        labelId="assignedTo"
        value={props.assignedTo}
        native
        onChange={(e) => props.userSelected(e.target.value)}
        inputProps={{
          "aria-label": "Filter task by assignee",
          name: "assignedTo",
          id: "assignedTo",
        }}>
        <option aria-label="None" value="">
          {emptyLabel}
        </option>
        {users === "loading" ? (
          <option value="loading" disabled>
            loading...
          </option>
        ) : (
          users.map((current) => {
            return (
              <option value={current.userUID} key={current.userUID}>
                {current.email}
              </option>
            );
          })
        )}
      </Select>
    </FormControl>
  );
}

SelectUser.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  users: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
  assignedTo: PropTypes.string,
  userSelected: PropTypes.func.isRequired,
};
