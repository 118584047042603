import React, { useState, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
} from "@mui/material";
import SendNowDialog from "./sendNowDialog";
import ScheduleDialog from "./scheduleDialog";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function SendNowButton(props) {
  let [dialogOpen, setDialogOpen] = useState(false);
  let [sendOptionOpen, setSendOptionOpen] = useState(false);
  const anchorRef = useRef(null);

  const fullScreen = useMobileDialog();

  let closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="send now options">
        <Button onClick={() => setDialogOpen("sendNow")}>Send Now</Button>
        <Button
          style={{ width: "30px", minWidth: "30px" }}
          aria-controls={sendOptionOpen ? "send-options-menu" : undefined}
          aria-expanded={sendOptionOpen ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => setSendOptionOpen(!sendOptionOpen)}>
          <i className="material-icons">arrow_drop_down</i>
        </Button>
      </ButtonGroup>
      <Popper
        open={sendOptionOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: "1301" }}
        placement="top-end">
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper style={{ zIndex: "1302" }}>
              <ClickAwayListener onClickAway={() => setSendOptionOpen(false)}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    button="true"
                    style={{ width: "168px" }}
                    onClick={() => {
                      setDialogOpen("scheduleNow");
                      setSendOptionOpen(false);
                    }}>
                    <i className="material-icons" style={{ marginRight: "5px" }}>
                      schedule_send
                    </i>{" "}
                    Schedule Send
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {dialogOpen === "sendNow" && (
        <SendNowDialog
          {...props}
          fullScreen={fullScreen}
          closeDialog={closeDialog}
          dialogOpen={true}
        />
      )}
      {dialogOpen === "scheduleNow" && (
        <ScheduleDialog
          {...props}
          fullScreen={fullScreen}
          closeDialog={closeDialog}
          dialogOpen={true}
        />
      )}
    </div>
  );
}
