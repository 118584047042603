import React, { useEffect, useState } from "react";
import timeSinceDate from "../../../../util/functions/timeSinceDate";
import timeBeforeDate from "../../../../util/functions/timeBeforeDate";
import ItemAnalytics from "../../../../util/analytics/itemAnalytics";
import {
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
  Tooltip,
  Checkbox,
} from "@mui/material";
import EditNoteContentDialog from "../../../notes/editNote/scheduledHistory/editNoteContentDialog";
import Thumbnail from "./thumbnail";
import CancelResendDialog from "../../../notes/editNote/sendHistory/cancelResendDialog";
import ShareByContactDialog from "../../../../util/shareOptions/shareByContactDialog";
import CloneDialog from "../actions/cloneDialog";
import RecipientsDialog from "./recipients/recipientsDialog";
import { toFullName } from "util/helpers";
import style from "./historyItem.module.css";

export default function HistoryItem(props) {
  const sendDate = props.current.sendDate ? new Date(props.current.sendDate) : null;
  const resendNoteAtTimestamp =
    props.current.resendActive && props.current.resendInXDays && sendDate
      ? sendDate.setDate(sendDate.getDate() + props.current.resendInXDays)
      : null;
  let [firstVideoData, setFirstVideoData] = useState("loading");
  let [noteOrVideoDeleted, setNoteOrVideoDeleted] = useState(false);
  let [anchorEl, setAnchorEl] = useState(null);
  let [state, setState] = useState({});
  let [selectedContactsForPreview, setSelectedContactsForPreview] = useState([]);
  let [contactLabel, setContactLabel] = useState("");
  let [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  let [analytics, setAnalytics] = useState("loading");
  const [contactAnalytics, setContactAnalytics] = useState();
  let taskSentDate = timeSinceDate(props.current.sendDate, "", "");
  let taskReSendDate = timeBeforeDate(resendNoteAtTimestamp, "", "");
  let [editDialogOpen, setEditDialogOpen] = useState(false);
  let [previewByContactDialog, setPreviewByContactDialog] = useState(null);
  let [embedByContactDialog, setEmbedByContactDialog] = useState(false);
  let [showCancelResendBtn, setShowCancelResendBtn] = useState(null);
  let [cloneDialogOpen, setCloneDialogOpen] = useState(null);
  let [recipientsDialogOpen, setRecipientsDialogOpen] = useState(null);

  useEffect(() => {
    let analyticsResults = {};
    let showCancelResend =
      resendNoteAtTimestamp && resendNoteAtTimestamp > Date.now() ? true : false;

    if (props.current.analytics) {
      const analyticsData = props.current.analytics;
      if (analyticsData) {
        if (props.current.taskContacts?.length > 1) {
          analyticsResults = analyticsData.totals || {};
          setContactAnalytics(analyticsData.contacts);
        } else if (props.current.taskContacts?.length === 1) {
          analyticsResults =
            analyticsData.contacts[props.current.taskContacts[0].contactId] || {};
          if (analyticsResults.playVideo) {
            showCancelResend = false;
          }
        }
      }
    }
    setShowCancelResendBtn(showCancelResend);
    setAnalytics(analyticsResults);
  }, []);

  useEffect(() => {
    let getLabel = async () => {
      try {
        let tempContactLabel = "";

        props.current.taskContacts.forEach((taskContact, index) => {
          if (index >= 1) {
            tempContactLabel += " & ";
          }

          const { email, firstName = "", lastName = "" } = taskContact.contact;
          const name =
            props.current.taskContacts.length > 1
              ? firstName || email
              : toFullName(firstName, lastName, email);
          tempContactLabel += name;
        });

        if (props.current.taskContacts.length > 3) {
          tempContactLabel += "...";
        }

        setSelectedContactsForPreview(props.current.taskContacts);
        setContactLabel(tempContactLabel);
      } catch (err) {
        setState({ errorMessage: err.message });
      }
    };
    getLabel();

    if (props.current.taskVideos?.length) {
      setFirstVideoData(props.current.taskVideos[0].video);
    } else {
      setNoteOrVideoDeleted(true);
    }
  }, []);

  let clickedEditDialogOpen = () => {
    setEditDialogOpen(true);
    setAnchorEl(null);
  };

  let clickedEmbedDialogOpen = () => {
    setAnchorEl(null);
    setEmbedByContactDialog(true);
  };

  let clickedPreviewDialogOpen = () => {
    setAnchorEl(null);
    setPreviewByContactDialog(true);
  };

  let clickedCloneDialogOpen = () => {
    setAnchorEl(null);
    setCloneDialogOpen(true);
  };

  let checkboxSelected = (e, taskId) => {
    e.stopPropagation();
    if (props.selectedItems.some((selectedItem) => selectedItem.id === taskId)) {
      props.setSelectedItems(
        props.selectedItems.filter((selectedItem) => selectedItem.id !== taskId)
      );
    } else {
      props.setSelectedItems([...props.selectedItems, props.current]);
    }
  };

  let handleResendCanceled = () => {
    setShowCancelResendBtn(null);
  };

  if (noteOrVideoDeleted) return null;
  if (analytics === "loading")
    return (
      <div className="col-12" style={{ padding: "20px", height: "75px" }}>
        {" "}
        <LinearProgress />
      </div>
    );

  let editNoteShowSettings = {
    showContacts: false,
    showBirthdayContacts: false,
    showBirthdaySettings: false,
    showSendNote: false,
    showCTA: false,
    showBranding: false,
    showText: false,
    showReply: false,
    showResend: false,
    showExternalShare: false,
    showEmail: false,
    showEmailLocalPart: false,
    showEmailReplyTo: false,
  };
  let checked = props.selectedItems.some(
    (selectedItem) => props.current.id === selectedItem.id
  );
  let hideEmbedBtn = navigator.userAgent.match(/ipad|ipod|iphone/i) ? true : false;

  return (
    <div
      className="col-12"
      style={{
        maxWidth: "100%",
        padding: "4px 8px",
        display: "flex",
        height: "75px",
        borderBottom: "1px #ddd solid",
      }}>
      <div
        style={{ width: "40px", paddingRight: "10px" }}
        className="align-items-center justify-content-center">
        <Checkbox
          checked={checked}
          onClick={(e) => checkboxSelected(e, props.current.id)}
          color="default"
          size="small"
          style={{ padding: "10px" }}
        />
      </div>

      <div style={{ width: "85px" }} className="align-items-center">
        <Thumbnail videoData={firstVideoData} />
      </div>

      <div style={{ flex: 1, overflow: "hidden" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              fontSize: 15,
              height: "21px",
              display: "flex",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}>
            {props.current.taskContacts.length === 1 ? (
              <>
                To:{" "}
                <a
                  href={
                    `/a/${props.accountInfo.id}/contacts/` +
                    props.current.taskContacts?.[0]?.contactId
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  {contactLabel}
                </a>
              </>
            ) : (
              <>
                To: {contactLabel}
                <span
                  className="link-button"
                  style={{ marginLeft: 10 }}
                  onClick={() => setRecipientsDialogOpen(true)}>
                  {props.current.taskContacts.length} contacts
                </span>
              </>
            )}

            {props.current.status === "error" && !props.current.sendDate && (
              <span
                className={style.errorContainer}
                title="Task failed to send, confirm note template is configured to allow emails or texts to send and try resending">
                <i className="material-icons error">error_outline</i>
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            fontSize: "14px",
            height: "21px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}>
          <span
            style={{
              fontSize: "14px",
              color: "#5f6368",
              textOverflow: "ellipsis",
              lineHeight: "25px",
            }}>
            {props.current.comments}
          </span>
          <br />
        </div>
        <div style={{ display: "flex" }}>
          {showCancelResendBtn && (
            <Tooltip
              title={"Auto resends " + taskReSendDate + " if not played. Click to edit"}
              enterTouchDelay={0}
              onClick={() => setCancelDialogOpen(true)}>
              <IconButton aria-label="emailIcon" size="small">
                <i className="material-icons" style={{ fontSize: "18px" }}>
                  history
                </i>
              </IconButton>
            </Tooltip>
          )}

          <ItemAnalytics
            current={props.current}
            dataIsTotals={props.current.taskContacts.length > 1}
            analytics={analytics}
          />
        </div>
      </div>

      <div className="row" style={{ width: "120px" }}>
        <div className="col-12 justify-content-end">
          <span
            style={{
              fontSize: "12px",
              color: "#5f6368",
              textOverflow: "ellipsis",
              paddingRight: "22px",
            }}>
            {taskSentDate}
          </span>
        </div>

        <div className="col-12 justify-content-end">
          <IconButton
            aria-label="More"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}>
            <i className="material-icons">more_vert</i>
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={anchorEl ? true : false}
            onClose={() => setAnchorEl(null)}
            PaperProps={{ style: { maxHeight: 200, width: 200 } }}>
            <MenuItem key={0} button onClick={clickedEditDialogOpen}>
              Edit Task
            </MenuItem>
            {showCancelResendBtn && (
              <MenuItem key={1} button onClick={() => setCancelDialogOpen(true)}>
                Cancel Auto Resend
              </MenuItem>
            )}
            <MenuItem key={2} button onClick={clickedCloneDialogOpen}>
              Clone As New Task
            </MenuItem>
            {!hideEmbedBtn && (
              <MenuItem key={3} button onClick={() => clickedEmbedDialogOpen()}>
                Embed
              </MenuItem>
            )}

            {props.current.taskContacts.length === 1 ? (
              <MenuItem key={4} button>
                <a
                  href={
                    `${process.env.REACT_APP_FRONTEND}/note?noteId=` +
                    props.current.noteId +
                    "&tId=" +
                    props.current.id +
                    "&cId=" +
                    props.current.taskContacts?.[0]?.contactId +
                    "&preview=true&showPreviewNotification=1"
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  Preview{" "}
                  <i className="material-icons" style={{ fontSize: "12px" }}>
                    open_in_new
                  </i>
                </a>
              </MenuItem>
            ) : (
              <MenuItem key={4} button onClick={() => clickedPreviewDialogOpen()}>
                Preview
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>

      {editDialogOpen && (
        <EditNoteContentDialog
          dialogOpen={editDialogOpen}
          setDialogOpen={setEditDialogOpen}
          {...props}
          noteId={props.current.noteId}
          taskId={props.current.id}
          setForceLoadFreshNoteContent={null}
          editNoteShowSettings={editNoteShowSettings}
        />
      )}
      {cancelDialogOpen && (
        <CancelResendDialog
          taskId={props.current.id}
          accountId={props.accountInfo.id}
          dialogOpen={cancelDialogOpen}
          setDialogOpen={setCancelDialogOpen}
          resendNoteAtTimestamp={resendNoteAtTimestamp}
          handleResendCanceled={handleResendCanceled}
        />
      )}
      {cloneDialogOpen && (
        <CloneDialog
          dialogOpen={cloneDialogOpen}
          setDialogOpen={setCloneDialogOpen}
          currentTask={props.current}
          accountId={props.accountInfo.id}
        />
      )}
      {previewByContactDialog && (
        <ShareByContactDialog
          {...props}
          accountId={props.accountInfo.id}
          taskContacts={selectedContactsForPreview}
          type="preview"
          title="Preview By Contact"
          dialogOpen={previewByContactDialog}
          setDialogOpen={() => setPreviewByContactDialog(null)}
          noteId={props.current.noteId}
          taskId={props.current.id}
          contentId={null}
        />
      )}
      {embedByContactDialog && (
        <ShareByContactDialog
          {...props}
          accountId={props.accountInfo.id}
          taskContacts={selectedContactsForPreview}
          type="embed"
          title="Embed By Contact"
          dialogOpen={embedByContactDialog}
          setDialogOpen={() => setEmbedByContactDialog(null)}
          noteId={props.current.noteId}
          taskId={props.current.id}
          contentId={null}
          videoData={firstVideoData}
        />
      )}
      {recipientsDialogOpen && (
        <RecipientsDialog
          accountInfo={props.accountInfo}
          taskContacts={props.current.taskContacts}
          contactAnalytics={contactAnalytics}
          dialogOpen={recipientsDialogOpen}
          closeDialog={() => setRecipientsDialogOpen(null)}
        />
      )}
    </div>
  );
}
