import React, { useRef, useState, useCallback, useEffect } from "react";
import { TextField, CircularProgress, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { debounce } from "util/helpers";
import MergeFieldDialog from "./mergeFieldDialog";

function insertTextAtCursor(el, text) {
  const val = el.value;

  if (typeof el.selectionStart === "number" && typeof el.selectionEnd === "number") {
    return val.slice(0, el.selectionStart) + text + val.slice(el.selectionEnd);
  }
  return val + text;
}

const states = {
  ERROR: -1,
  INITIAL: 0,
  SAVING: 1,
  SUCCESS: 2,
};

export default function TextInputToApi(props) {
  const {
    initialValue,
    value,
    label,
    variant,
    multiline,
    parentErrorMessage = null,
    placeholder = null,
    apiCall,
    field,
  } = props;

  const [inputValue, setInputValue] = useState(initialValue || value);
  const [state, setState] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mergeFieldDialogOpen, setMergeFieldDialogOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (typeof value === "string") {
      setInputValue(value);
    }
  }, [value]);

  const debouncedApiCall = useCallback(
    debounce((value) => {
      setState(states.SAVING);
      setErrorMessage(null);
      apiCall(field, value)
        .then((res) => {
          if (res?.status >= 400) {
            return setState(states.ERROR);
          }
          setState(states.SUCCESS);
        })
        .catch((error) => {
          setState(states.ERROR);
          setErrorMessage(error.message);
        });
    }),
    [props]
  );

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedApiCall(value);
  };

  function insertMergeVariable(mergeField) {
    const newValue = insertTextAtCursor(inputRef.current, mergeField);
    setInputValue(newValue);
    debouncedApiCall(newValue);
  }

  const helperText = errorMessage || parentErrorMessage || props.helperText;
  const showError = !!(errorMessage || parentErrorMessage);
  let loadingComp = null;

  if (state === states.SAVING) {
    loadingComp = (
      <CircularProgress
        style={{ width: "15px", height: "15px", margin: "0px 4px 0px 6px" }}
      />
    );
  } else if (state === states.SUCCESS) {
    loadingComp = (
      <i
        className="material-icons"
        style={{ color: "green", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        check_circle
      </i>
    );
  } else if (state === states.ERROR) {
    loadingComp = (
      <i
        className="material-icons"
        style={{ color: "red", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        error
      </i>
    );
  }

  return (
    <>
      <TextField
        multiline={multiline}
        rows={props.rows}
        error={showError}
        helperText={helperText}
        inputRef={inputRef}
        label={
          <div className="align-items-center">
            {label}
            {loadingComp}
          </div>
        }
        variant={variant}
        size={props.size || "medium"}
        onChange={onChange}
        fullWidth
        value={inputValue || ""}
        placeholder={placeholder}
        autoComplete="off"
        inputProps={{ autoComplete: "new-password" }}
        InputProps={{
          maxLength: props.maxLength,
          startAdornment: props.startIcon && (
            <InputAdornment position="start">
              <i className="material-icons" style={{ fontSize: "14px", color: "grey" }}>
                {props.startIcon}
              </i>
            </InputAdornment>
          ),
        }}
      />

      {props.customContactProperties && (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <div style={{ marginTop: 2 }}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  alignItems: "center",
                  display: "flex",
                  padding: "5px 0px",
                }}
                onClick={() => setMergeFieldDialogOpen(true)}>
                <div className="link-button" style={{ fontWeight: 600, fontSize: 14 }}>
                  Merge Field
                </div>
                <i
                  className="material-icons"
                  style={{ fontSize: 18, color: "grey", cursor: "pointer" }}>
                  arrow_drop_down
                </i>
              </button>
            </div>
          </div>
          <div style={{ marginTop: 2, justifyContent: "flex-end" }}>
            {props.maxLength && (
              <p style={{ fontSize: 11.25, color: "rgba(0, 0, 0, 0.54)" }}>
                Max length: {inputValue.length + "/" + props.maxLength}
              </p>
            )}
          </div>
        </div>
      )}
      {mergeFieldDialogOpen && (
        <MergeFieldDialog
          customContactProperties={props.customContactProperties}
          insertMergeVariable={insertMergeVariable}
          handleClose={() => setMergeFieldDialogOpen(false)}
        />
      )}
    </>
  );
}

TextInputToApi.propTypes = {
  initialValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  parentErrorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  apiCall: PropTypes.func.isRequired,
  rows: PropTypes.number,
  size: PropTypes.string,
  maxLength: PropTypes.number,
  startIcon: PropTypes.string,
  helperText: PropTypes.node,
  customContactProperties: PropTypes.array,
};
