import React from "react";
import { Checkbox } from "@mui/material";

export default function Users(props) {
  let {
    userUID: signedInUserUID,
    current = {},
    selectedItems = {},
    allSelected = false,
  } = props;
  let { userUID, email, fullName } = current;

  let buttonDisabled = userUID === signedInUserUID ? true : false;
  const checked = selectedItems.includes(userUID) || allSelected ? true : false;

  return (
    <div
      className="col-12"
      style={{
        display: "flex",
        borderBottom: "1px #ddd solid",
        padding: "5px",
        alignItems: "center",
        backgroundColor: "white",
      }}>
      <div style={{ width: "40px" }} className="align-items-center">
        <Checkbox
          checked={checked}
          onClick={() => props.selectItem(userUID)}
          size="small"
          style={{ padding: "5px" }}
        />
      </div>

      <div style={{ flex: 1 }}>
        {buttonDisabled ? (
          <span style={{ opacity: 0.5 }}>
            {fullName ? fullName : email} (Your account)
            <br />
            <span style={{ fontSize: "12px", color: "grey" }}>{email}</span>
          </span>
        ) : (
          <span>
            {fullName ? fullName : email}
            <br />
            <span style={{ fontSize: "12px", color: "grey" }}>{email}</span>
          </span>
        )}
      </div>
    </div>
  );
}
