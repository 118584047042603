import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getNotes } from "services/api.service";

export default function AutoSuggestInput(props) {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getNotes(0, 0, "")
      .then(({ rows }) => {
        setResults(rows);
      })
      .catch((e) => setErrorMessage(e))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Autocomplete
        id="country-select-demo"
        options={results}
        loading={isLoading}
        onChange={(e, item) => props.itemSelected(item)}
        autoHighlight
        noOptionsText="No notes exist. Create a note first."
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            label="Select Note"
            variant="outlined"
            fullWidth
            placeholder="Search existing notes"
            autoComplete="off"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}
    </>
  );
}
