import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField, CircularProgress, InputAdornment } from "@mui/material";
import { getAccountContacts } from "services/api.service";
import { debounce } from "util/helpers";

export default function SearchRecorderName(props) {
  let [isLoading, setIsLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);

  async function getContacts() {
    setIsLoading(true);
    const contacts = await getAccountContacts(0, 100, searchTerm, null);
    setSearchList(contacts.rows);
    setIsLoading(false);
  }

  const debouncedGetContacts = useCallback(debounce(getContacts), [props]);

  useEffect(() => {
    debouncedGetContacts();
  }, [searchTerm]);

  let itemSelected = (item) => {
    if (!item) {
      props.onUpdateVideo(null, null, {
        requestEmail: "",
        requestCell: "",
        speakerName: "",
      });
    } else if (item && item.id) {
      let { email, firstName = "", lastName = "", cellNumber = "" } = item;
      let name = firstName + " " + lastName;
      let label = name === " " ? email : name;

      props.onUpdateVideo(null, null, {
        requestEmail: email,
        requestCell: cellNumber,
        speakerName: label,
      });
    } else {
      //just use value user typed in
      props.onUpdateVideo(null, null, {
        requestEmail: "",
        requestCell: "",
        speakerName: searchTerm,
      });
    }
  };

  let handleInputChange = (value, reason) => {
    if (reason === "reset" && value === "") return;
    props.onUpdateVideo(null, null, {
      requestEmail: "",
      requestCell: "",
      speakerName: value,
    });
    setSearchTerm(value);
  };

  let loadingComp;
  if (!isLoading) loadingComp = <></>;
  else if (isLoading === "saved")
    loadingComp = (
      <i
        className="material-icons"
        style={{ color: "green", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        check_circle
      </i>
    );
  else if (isLoading)
    loadingComp = (
      <CircularProgress
        style={{ width: "15px", height: "15px", margin: "0px 4px 0px 6px" }}
      />
    );

  return (
    <Autocomplete
      id="country-select-demo"
      options={searchList}
      loading={isLoading}
      filterOptions={(options) => options}
      onChange={(e, item) => itemSelected(item)}
      onInputChange={(e, value, reason) => handleInputChange(value, reason)}
      inputValue={props.recorderName}
      freeSolo
      getOptionLabel={(option) => {
        if (!option.email) return option;
        let { email, firstName = "", lastName = "" } = option;
        let name = firstName + " " + lastName;
        let label = name === " " ? email : name;
        return label;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <div>
              <span style={{ fontSize: "14px" }}>
                {option.firstName} {option.lastName}
              </span>{" "}
              <br />
              <span style={{ color: "grey", fontSize: "12px" }}>{option.email}</span>
            </div>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="recorderName"
          placeholder="Insert name or search contacts"
          label={
            <div className="align-items-center">
              <>Recorder Name</>
              <>{loadingComp}</>
            </div>
          }
          fullWidth
          value={props.recorderName}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <i className="material-icons" style={{ fontSize: "14px", color: "grey" }}>
                  face
                </i>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

SearchRecorderName.propTypes = {
  accountId: PropTypes.string.isRequired,
  recorderName: PropTypes.string.isRequired,
  onUpdateVideo: PropTypes.func.isRequired,
};
