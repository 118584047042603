import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";

export default function SearchInput(props) {
  let searchRef = useRef(null);

  useEffect(() => {
    searchRef.current.focus();
    //searchRef.current.scrollIntoView()
  }, []);

  let { searchTerm } = props;

  return (
    <div
      style={{ flex: 1, display: "flex", padding: "10px", backgroundColor: "#eaf0f6" }}>
      <TextField
        variant="outlined"
        fullWidth
        inputRef={searchRef}
        autoComplete="off"
        id="search input"
        placeholder="Type to add or search contacts"
        style={{ backgroundColor: "white" }}
        value={searchTerm}
        onChange={props.handleSearchTextField}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            props.setAddEditContactDialogOpen(true);
          }
        }}
        inputProps={{
          style: {
            height: "30px",
            padding: "5px 10px",
            autoComplete: "new-password",
          },
        }}
      />
    </div>
  );
}
