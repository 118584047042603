import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../buttons/loadingbutton";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import {
  deleteAllNotes,
  deleteNote,
  deleteAllVideos,
  deleteVideos,
} from "services/api.service";
import indexHelper from "util/index-helper";

//this is used by note and videos toolbar at top of search to select multiple or all items
//contacts has it's own
//this is called from bulkActionForSearchTableHeader and from the individual noteItem and videoItem page

export default function DeleteSearchItemDialog(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);

  const fullScreen = useMobileDialog();

  async function deleteItems(e) {
    try {
      e.preventDefault();
      setButtonState("loading");

      if (props.allSelected) {
        if (indexHelper[props.indexName] === indexHelper.note) {
          await deleteAllNotes(props.searchState.nbHits);
        } else if (indexHelper[props.indexName] === indexHelper.video) {
          await deleteAllVideos(
            props.accountId,
            props.searchState.nbHits,
            props.hideTaskVids
          );
        } else {
          console.error("Cannot delete " + props.indexName);
        }

        props.setSearchList([]);
      } else {
        if (indexHelper[props.indexName] === indexHelper.note) {
          await Promise.all(props.objectIdsToUpdate.map((id) => deleteNote(id)));
        } else if (indexHelper[props.indexName] === indexHelper.video) {
          await deleteVideos(props.objectIdsToUpdate);
        } else {
          console.error("Cannot delete " + props.indexName);
        }

        props.setSearchList(
          props.searchList.filter((item) => !props.objectIdsToUpdate.includes(item.id))
        );
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      props.setSelectedItems([]);
      props.setDeleteDialogOpen(false);
    }
  }

  const selectedCount = props.allSelected
    ? props.searchState.nbHits
    : props.objectIdsToUpdate.length;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.deleteDialogOpen}
      onClose={() => props.setDeleteDialogOpen(false)}
      aria-labelledby="delete-item-title">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10 align-items-center">
            <h2>Confirm Delete</h2>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton
              aria-label="Delete"
              onClick={() => props.setDeleteDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          <div className="col-12">
            <p style={{ paddingTop: "5px" }}>
              Are you sure you want to <b> delete {selectedCount} item(s)? </b> You can
              not undo this action.
            </p>
          </div>

          {errorMessage && (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={buttonState}
              savedLabel={"Note Deleted"}
              label={"Delete Now"}
              clickedRegularButton={deleteItems}
              clickedSavedButton={() => props.setDeleteDialogOpen(false)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

DeleteSearchItemDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  objectIdsToUpdate: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchList: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchList: PropTypes.func.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
  deleteDialogOpen: PropTypes.bool.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  hideTaskVids: PropTypes.bool,
  searchState: PropTypes.object,
};
