import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import DeleteVideoDialog from "../../account/videos/deleteVideo/deleteVideoDialog";
import DeleteNoteDialog from "../../account/notes/deleteNote/deleteNoteDialog";
import { editVideo } from "services/api.service";

export default function Breadcrumb(props) {
  let [state, setState] = useState({ viewState: "regular" });
  let [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let [editTitle, setEditTitle] = useState(props.title);

  const navigate = useNavigate();

  let deleteAction = async () => {
    try {
      setState({ viewState: "loading" });
      await editVideo(props.id, { title: "" });
      navigate(`/a/${props.accountId}/` + props.indexName);
    } catch (err) {
      console.log(err.message);
    }
  };

  let saveTitle = async () => {
    try {
      setState({ viewState: "loading" });
      await editVideo(props.id, { title: editTitle });
      props.passedSetState(props.titleKey, editTitle);
      setState({ viewState: "regular" });
    } catch (err) {
      console.log(err.message);
    }
  };

  if (state.viewState === "loading") {
    return (
      <div style={{ flex: 1, height: "40px" }}>
        <LinearProgress />
      </div>
    );
  } else if (state.viewState === "edit") {
    return (
      <div
        style={{
          display: "flex",
          padding: "5px 0px",
          alignItems: "center",
          height: "40px",
        }}
        className="">
        <div style={{ flex: 1, cursor: "pointer" }}>
          <input value={editTitle} onChange={(e) => setEditTitle(e.target.value)} />
        </div>
        <div className="" style={{ width: "36px" }}>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "red", padding: "0px" }}
            onClick={() => setState({ viewState: "regular" })}>
            cancel
          </i>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "green", padding: "0px" }}
            onClick={() => saveTitle()}>
            check_circle
          </i>
        </div>
      </div>
    );
  } else if (state.viewState === "delete") {
    return (
      <div
        style={{
          display: "flex",
          padding: "5px 0px",
          alignItems: "center",
          height: "40px",
        }}
        className="">
        <div style={{ flex: 1, cursor: "pointer" }} onClick={() => deleteAction()}>
          <span>Delete this note. You can&apos;t undo this action.</span>
        </div>
        <div className="" style={{ width: "36px" }}>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "red", padding: "0px" }}
            onClick={() => setState({ viewState: "regular" })}>
            cancel
          </i>
          <i
            className="material-icons folderButtons"
            style={{ fontSize: "17px", color: "green", padding: "0px" }}
            onClick={() => deleteAction()}>
            check_circle
          </i>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12" style={{ padding: "8px 0px" }}>
      <Breadcrumbs separator="›" aria-label="Breadcrumb">
        <Link
          className="link"
          color="inherit"
          to={`/a/${props.accountId}/` + props.indexName}>
          <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
            {props.indexName}
          </span>
        </Link>
        <div color="textPrimary">
          <div className="align-items-center">
            <Typography
              color="textPrimary"
              style={{ color: "#6d6e71", fontSize: "14px" }}>
              {props.title}
            </Typography>
            <i
              className="material-icons folderButtons"
              style={{
                marginLeft: "5px",
                fontSize: "17px",
                color: "grey",
                padding: "0px",
              }}
              onClick={() => setState({ viewState: "edit" })}>
              edit
            </i>
            <i
              className="material-icons folderButtons"
              style={{ fontSize: "17px", color: "grey", padding: "0px" }}
              onClick={() => setDeleteDialogOpen(true)}>
              delete
            </i>
          </div>
        </div>
      </Breadcrumbs>

      {props.indexName === "videos" && (
        <DeleteVideoDialog
          accountId={props.accountId}
          id={props.id}
          title={props.title}
          setDeleteDialogOpen={setDeleteDialogOpen}
          deleteDialogOpen={deleteDialogOpen}
        />
      )}

      {props.indexName === "notes" && (
        <DeleteNoteDialog
          currentNote={{ noteId: props.id, title: props.title }}
          {...props}
          dialogOpen={deleteDialogOpen}
          setDialogOpen={setDeleteDialogOpen}
        />
      )}
    </div>
  );
}

Breadcrumb.propTypes = {
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  passedSetState: PropTypes.func.isRequired,
  titleKey: PropTypes.string.isRequired,
};
