import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReplyDialog from "./replyDialog";
import { Button, LinearProgress } from "@mui/material";
import LoadingButton from "../../util/buttons/loadingbutton";
import { getUserReplies } from "services/api.service";

export default function Replies(props) {
  let { accountId, noteId, lastXDays } = props;
  let [replies, setReplies] = useState("loading");
  let [replySelected, setReplySelected] = useState(null);
  let [nextCursorDoc, setNextCursorDoc] = useState(null);
  let [loadMore, setLoadMore] = useState(null);
  let [loadingMoreList, setLoadingMoreList] = useState(null);

  useEffect(() => {
    let getReplies = async () => {
      try {
        setLoadingMoreList(true);

        let now = Date.now();
        let numberOfMilisecondsInOneDay = 86400000;
        let daysToPull = lastXDays;
        let startDate = now - numberOfMilisecondsInOneDay * daysToPull;

        const data = await getUserReplies(startDate, now, noteId, {
          offset: nextCursorDoc || 0,
          limit: 10,
        });
        let tempReplies = replies === "loading" ? [] : [...replies];
        let count = 1;
        let tempNextCursorDoc = null;

        data.rows.forEach((doc) => {
          let currentItem = doc;
          if (!currentItem) return;

          if (count === 11) {
            tempNextCursorDoc = doc;
          } else {
            currentItem.replyId = doc.id;
            tempReplies.push(currentItem);
          }

          count++;
        });

        setReplies(tempReplies);
        setNextCursorDoc(tempNextCursorDoc);
        setLoadingMoreList(false);
      } catch (err) {
        console.error(err.message);
        setReplies([]);
      }
    };

    getReplies();
  }, [loadMore]);

  useEffect(() => {
    setReplies("loading");
    setLoadMore(Date.now());
  }, [lastXDays]);

  if (replies === "loading") {
    return (
      <div className="row col-12" style={{ padding: "10px", minHeight: "100px" }}>
        <div
          className="boxShadow col-12"
          style={{ backgroundColor: "white", padding: "20px", borderRadius: "3px" }}>
          <LinearProgress />
        </div>
      </div>
    );
  }

  let repliesHTML = replies.map((current) => {
    let { replyId, replyVideoSrc, replyText } = current;

    return (
      <div key={replyId} style={{ padding: "5px" }}>
        <Button
          onClick={() => setReplySelected(current)}
          variant="contained"
          style={{
            textTransform: "initial",
            textAlign: "left",
            backgroundColor: "#E0E0E0",
            borderRadius: "20px",
          }}>
          {replyVideoSrc ? (
            <video
              src={replyVideoSrc + "#t=0.01"}
              style={{ maxHeight: "60px", borderRadius: "8px" }}
            />
          ) : (
            <i
              className="material-icons"
              style={{ backgroundColor: "#BCBCBC", borderRadius: "50%" }}>
              format_quote
            </i>
          )}

          {replyText && <span style={{ marginLeft: "10px" }}>{replyText}</span>}
        </Button>
      </div>
    );
  });

  return (
    <div className="row col-12" style={{ padding: "10px" }}>
      <div
        className="row col-12 boxShadow"
        style={{ backgroundColor: "white", padding: "15px", borderRadius: "3px" }}>
        <div className="col-12">
          <h2 style={{ textTransform: "capitalize" }}>Replies</h2>
        </div>

        <div className="col-12" style={{ marginTop: "25px" }}>
          {replies.length === 0 ? (
            <p>No replies within date range</p>
          ) : (
            <div className="row col-12">{repliesHTML}</div>
          )}
        </div>

        {nextCursorDoc && (
          <div
            className="col-12 justify-content-center"
            style={{ margin: "10px 0px 20px 0px" }}>
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              buttonState={loadingMoreList ? "loading" : null}
              savedLabel={"Loaded"}
              label="Show more"
              clickedRegularButton={() => setLoadMore(Date.now())}
              clickedSavedButton={() => {
                return null;
              }}
            />
          </div>
        )}

        {replySelected && (
          <ReplyDialog
            currentReply={replySelected}
            setReplySelected={setReplySelected}
            {...props}
          />
        )}
      </div>
    </div>
  );
}

Replies.propTypes = {
  accountId: PropTypes.string.isRequired,
  noteId: PropTypes.string,
  lastXDays: PropTypes.number.isRequired,
};
