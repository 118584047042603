import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import SelectTags from "../../../../../util/tags/selectTags";
import SendRequest from "../../../../videos/editVideo/request/send/sendRequest";
import { createVideos } from "services/api.service";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function RequestVideo(props) {
  let [videoTitle, setVideoTitle] = useState("");
  let [videoId, setVideoId] = useState(null);
  let [state, setState] = useState({});
  let [dialogOpen, setDialogOpen] = useState(true);
  let [selectedFoldersArray, setSelectedFoldersArray] = useState([]);

  const fullScreen = useMobileDialog();

  let createVideoObject = async (e) => {
    try {
      e.preventDefault();

      if (videoTitle === "") throw new Error("Insert video title");

      //only add the user selected folders to our firebase data
      let foldersFilter = selectedFoldersArray.map((current) => {
        return current.id;
      });

      setState({ buttonState: "loading" });
      let uploadType = "request";
      const videoIds = await createVideos([
        {
          title: videoTitle,
          foldersFilter,
          uploadType,
          createdByTask: !!props.currentTask?.id,
        },
      ]);

      const video = videoIds?.[0];

      if (!video) {
        throw new Error("Error creating video");
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.addVideo" });

      props.videoAdded({ id: video.id });
      setVideoId(video.id);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(err);
      setState({ buttonState: "regular", errorMessage });
    }
  };

  let closeDialog = () => {
    props.setAddState(null);
    setDialogOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="request new video dialog">
      <div style={{ display: "flex", padding: "16px" }}>
        <div
          style={{
            flex: 1,
            fontSize: "20px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}>
          Request Video
        </div>
        <div style={{ width: "55px" }}>
          <IconButton aria-label="close" onClick={closeDialog}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
      </div>
      <DialogContent dividers>
        <div className="row col-12">
          {videoId ? (
            <SendRequest videoId={videoId} {...props} />
          ) : (
            <>
              <div className="col-12" style={{ padding: "10px 0px", marginTop: "10px" }}>
                <TextField
                  variant="outlined"
                  id="videoTitle"
                  placeholder="e.g. John Smith Request"
                  label="New Video Title"
                  style={{ width: "100%" }}
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                />
              </div>

              <div className="col-12" style={{ marginTop: "15px" }}>
                <SelectTags
                  accountId={props.accountInfo.id}
                  selectedFoldersArray={selectedFoldersArray}
                  setSelectedFoldersArray={setSelectedFoldersArray}
                  indexName="video"
                />
              </div>
            </>
          )}
        </div>

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "16px" }}>
        {videoId ? (
          <Button onClick={closeDialog} color="primary" variant="contained">
            Save & Close
          </Button>
        ) : (
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Created"}
            label={"Next"}
            clickedRegularButton={createVideoObject}
            clickedSavedButton={closeDialog}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
