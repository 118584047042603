import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import DefaultAction from "../../../actions/action";
import BloomerangAction from "./action";
import AddAction from "../../../actions/addAction";
import SortableList from "components/util/sortable/SortableList";
import SelectList from "../../util/selectList";
import { updateAccountIntegrations } from "services/api.service";

export default function TransactionCreated(props) {
  let { eventData = {}, users } = props;
  eventData = eventData || {};
  //this allows front end to assign this task to whoever the assigned person is in blackbaud. our logic check on backend
  let newUserArrayWithBB = [...users];
  let initialActions = eventData.actions || {};
  let [actions, setActions] = useState(initialActions);
  let initialExcludes = eventData.excludes ? eventData.excludes : {};
  let [excludes, setExcludes] = useState(initialExcludes);
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  let saveActions = async () => {
    try {
      setState({ buttonState: "loading" });
      await updateAccountIntegrations([
        {
          path: "endpoint/bloomerang/events/transactionCreated/actions",
          value: actions,
        },
        {
          path: "endpoint/bloomerang/events/transactionCreated/excludes",
          value: excludes,
        },
      ]);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleExcludeChange = (excludeKey, values) => {
    console.log(values);
    let tempExcludes = {};
    values.forEach((current) => {
      tempExcludes[current.id] = current;
    });

    setExcludes({ [excludeKey]: tempExcludes });
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });
      await updateAccountIntegrations([
        {
          path: "endpoint/bloomerang/events/transactionCreated",
          value: { active: !!newActiveValue },
        },
      ]);

      setState({});
      setActive(newActiveValue);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  let transactionTypeOptions = [
    { id: "Donation", label: "Donation" },
    { id: "Pledge", label: "Pledge" },
    { id: "RecurringDonation", label: "Recurring Donation" },
    { id: "RecurringDonationPayment", label: "Recurring Donation Payment" },
    { id: "InKindGoods", label: "In Kind Goods" },
    { id: "InKindServices", label: "In Kind Services" },
  ];

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>New Donation Added In Bloomerang</h2>
          <span className="greyDesc">
            Create a task or send a note when a new donation is added in Bloomerang.
          </span>
          {active && (
            <div className="greyDesc" style={{ fontSize: "14px" }}>
              Status ON: Runs once per hour.
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className="row col-12" style={{ cursor: "auto" }}>
        <div className="row col-12">
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <span className="circleStep">1</span>
          </div>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              When a donation is added in Bloomerang...
            </h2>
          </div>
        </div>

        <div className="col-12">
          <SortableList
            items={actions.list || []}
            onChange={(items) => setActions({ ...actions, list: items })}
            renderItem={(item, index) => (
              <SortableList.Item id={item.id}>
                <BloomerangAction
                  currentAction={item}
                  actionIndex={index}
                  order={index + 1}
                  accountInfo={props.accountInfo}
                  actions={actions}
                  setActions={setActions}
                  users={props.users}
                />
              </SortableList.Item>
            )}
          />
        </div>

        <AddAction {...props} actions={actions} setActions={setActions} />

        <DefaultAction
          {...props}
          users={newUserArrayWithBB}
          defaultAction={true}
          currentAction={actions}
          actions={actions}
          setActions={setActions}
        />

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>

        <div
          className="row col-12"
          style={{ marginTop: "25px", paddingTop: "25px", borderTop: "1px #ddd solid" }}>
          <div
            style={{
              width: "70px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "5px",
            }}>
            <i
              className="material-icons"
              title="Exclude"
              style={{ fontSize: "18px", color: "#24305e" }}>
              filter_alt
            </i>
          </div>
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "3px" }}>
              Exclusions
            </h3>
            <p className="greyDesc">
              Exclude by donation type. Gratavid will take no action if the donation
              matches any of these exclusions.
            </p>
          </div>
        </div>

        <div style={{ padding: "0px 20px" }} className="row col-12">
          <SelectList
            selectedItems={
              excludes && excludes.transactionType ? excludes.transactionType : {}
            }
            handleChange={(values) => handleExcludeChange("transactionType", values)}
            label="Donation Types"
            options={transactionTypeOptions}
            width="100%"
          />
        </div>

        {state.errorMessage && (
          <div className="errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}

        <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel={"Saved"}
            label={"Save"}
            clickedRegularButton={saveActions}
            clickedSavedButton={() => {
              setState({});
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
