import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./sortableTableHead.module.css";

import { TableHead, TableRow, TableCell } from "@mui/material";

export default function SortableTableHead({
  columns,
  onClick,
  activeColumn = "",
  activeSortDirection = 0,
  hasSorted = true,
}) {
  const [sortColumn, setSortColumn] = useState(activeColumn);
  const [sortDirection, setSortDirection] = useState(activeSortDirection);

  function handleClick(column) {
    if (column.sortable === false) {
      return;
    }

    let newDirection = -sortDirection;

    if (hasSorted && column.value !== sortColumn) {
      setSortColumn(column.value);
      newDirection = 1;
    }
    setSortDirection(newDirection);
    onClick(column.value, newDirection);
  }

  return (
    <TableHead>
      <TableRow className={styles.tableHead}>
        {columns.map((column) => (
          <TableCell key={column.value} onClick={() => handleClick(column)}>
            <div
              className={[
                styles.tableCell,
                column.sortable !== false && styles.sortable,
              ].join(" ")}>
              {column.name}

              {column.sortable !== false && (
                <i
                  className={[
                    "material-icons",
                    sortColumn === column.value && styles.active,
                  ].join(" ")}>
                  {sortDirection === -1 ? "arrow_downward" : "arrow_upward"}
                </i>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SortableTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  activeColumn: PropTypes.string,
  activeSortDirection: PropTypes.number,
  style: PropTypes.object,
};
