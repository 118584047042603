import React, { useState, useEffect, useCallback } from "react";
import { Dialog, DialogContent, IconButton, LinearProgress, Button } from "@mui/material";
import useMobileDialog from "components/util/hooks/useMobileDialog";
import {
  createUploadByAccount,
  getUploadsByAccount,
} from "../../../services/api.service.js";
import ImageItem from "./imageItem";
import tempImages from "./tempImages";

export default function ListImageDialog(props) {
  let { type, logo, primaryColor, secondaryColor, background } = props;
  let [images, setImages] = useState("loading");
  let [state, setState] = useState({});

  const fullScreen = useMobileDialog();

  const fetchImages = useCallback(async () => {
    try {
      let images;
      if (type === "logo") {
        images = tempImages(primaryColor, secondaryColor).logos;
      } else {
        images = tempImages(primaryColor, secondaryColor).backgrounds;
      }

      const uploads = await getUploadsByAccount(type);

      uploads.forEach((upload) => {
        images[upload.id] = {
          id: upload.id,
          name: upload.file_name,
          imgSrc: upload.source,
        };
      });

      setImages(images);
    } catch (err) {
      console.error(err);
      setState({ errorMessage: err.message });
    }
  }, [type, primaryColor, secondaryColor]);

  let uploadFile = async (e) => {
    try {
      if (!e) {
        return null;
      }
      console.log("uploadFile");
      let file = e.target.files[0];

      // Create a FormData object
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", props.type);

      // Call the createUploadByAccount function to upload the file
      await createUploadByAccount(formData);

      fetchImages();
      setState({ buttonState: "regular" });
    } catch (err) {
      console.error(err);
      setState({ errorMessage: err.message });
    }
  };

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  if (images === "loading") {
    return (
      <div style={{ padding: "20px", minWidth: "350px" }}>
        <LinearProgress />
      </div>
    );
  }

  let tempImagesKeys = Object.keys(images);
  let listLogos = tempImagesKeys.map((key) => {
    let current = images[key];
    return (
      <ImageItem
        current={current}
        key={key}
        id={key}
        images={images}
        background={background}
        setImages={setImages}
        setState={setState}
        logo={logo}
        type={type}
        passedSetState={props.passedSetState}
        accountInfo={props.accountInfo}
      />
    );
  });

  let { errorMessage, buttonState } = state;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="add note dialog">
      <div style={{ minWidth: "320px", padding: "16px 24px" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}>
            Select Image
          </div>
          <div style={{ width: "55px" }}>
            <IconButton aria-label="close" onClick={() => props.setDialogOpen(false)}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <DialogContent style={{ padding: "10px" }}>
          {errorMessage && (
            <div className="errorMessageBox">
              <b>{errorMessage}</b>
            </div>
          )}

          <div className="row">
            <div style={{ padding: "5px" }}>
              <div style={{ padding: "6px 8px" }}>
                {buttonState === "loading" ? (
                  <div style={{ width: "100px", height: "100px", marginTop: "50px" }}>
                    <LinearProgress />
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="uploadInput-1"
                      onClick={(e) => (e.target.value = null)}
                      onChange={uploadFile}
                    />
                    <label htmlFor="uploadInput-1">
                      <Button
                        component="span"
                        variant="contained"
                        style={{ width: "100px", height: "100px" }}>
                        <i className="material-icons">upload_file</i>
                        Upload
                      </Button>
                    </label>
                  </>
                )}
              </div>
            </div>

            {listLogos}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
}
