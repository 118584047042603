import React, { useState } from "react";
import LoadingButtonCheckProductLimits from "../../../util/buttons/productLimits/loadingbutton";
import LoadingButton from "../../../util/buttons/loadingbutton";
import {
  Switch,
  FormControlLabel,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import { addUser } from "services/api.service";
import { isValidEmail, toFullName } from "util/helpers";

export default function SingleUser(props) {
  const { userType } = props;
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [admin, setAdmin] = useState(false);
  const [disableNoteWrite, setDisableNoteWrite] = useState(false);
  const [disableBulkSends, setDisableBulkSends] = useState(false);
  const [state, setState] = useState({ buttonState: "regular", errorMessage: "" });

  const submitAddUser = async () => {
    try {
      if (!isValidEmail(email)) {
        throw new Error("Provide valid email");
      }
      if (!firstName) {
        throw new Error("Provide first name");
      }
      if (!lastName) {
        throw new Error("Provide last name");
      }

      setState({ buttonState: "loading" });

      const payload = {
        user: {
          fullName: toFullName(firstName, lastName),
          email,
        },
        role: {
          isAdmin: admin,
          isBulkSender: !disableNoteWrite,
          isNoteEditor: !disableNoteWrite,
          userType,
        },
      };

      const data = await addUser(payload);

      // Add successes back to users
      const tempUsers = { ...props.users };
      const tempExternalUsers = { ...props.externalUsers };

      const { id: newUserUID, email: newUserEmail, fullName } = data;

      if (userType === "internal") {
        tempUsers[newUserUID] = {
          userUID: newUserUID,
          email: newUserEmail,
          fullName,
          admin,
          isBulkSender: !disableNoteWrite,
          isNoteEditor: !disableNoteWrite,
        };
      } else {
        tempExternalUsers[newUserUID] = {
          userUID: newUserUID,
          email: newUserEmail,
          fullName,
        };
      }

      // Update state with new users
      if (userType === "internal") {
        props.setUsers(tempUsers);
      } else {
        props.setExternalUsers(tempExternalUsers);
      }

      setState({ buttonState: "saved" });
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || err.message || "Error adding user.";
      setState({ errorMessage });
      console.error(errorMessage);
    }
  };

  const refreshData = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setAdmin(false);
    setState({ errorMessage: "" });
  };

  return (
    <>
      <DialogContent>
        <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Insert info</h3>

        <div className="col-12" style={{ paddingTop: "20px" }}>
          <TextField
            variant="outlined"
            id="email"
            placeholder="e.g. john@gmail.com"
            label="Email"
            style={{ width: "100%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ paddingTop: "20px" }}>
          <TextField
            variant="outlined"
            id="firstName"
            placeholder="e.g. John"
            label="First Name"
            style={{ width: "100%" }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ padding: "20px 0px" }}>
          <TextField
            variant="outlined"
            id="lastName"
            placeholder="e.g. Smith"
            label="Last Name"
            style={{ width: "100%" }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        {userType === "internal" && (
          <>
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center", margin: "5px 0px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={admin}
                    color="primary"
                    onChange={(e) => setAdmin(e.target.checked)}
                    value="admin"
                  />
                }
                label="Make user an admin"
              />
              <Tooltip title="Admins can add/delete users (including you), manage sign-in methods, billing, and integrations.">
                <i
                  className="material-icons"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "rgb(142, 148, 157)",
                  }}>
                  info
                </i>
              </Tooltip>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center", margin: "5px 0px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!disableNoteWrite}
                    color="primary"
                    onChange={(e) => setDisableNoteWrite(!e.target.checked)}
                    value="admin"
                  />
                }
                label="Allow user to edit, create, and delete note templates"
              />
              <Tooltip title="Allow the user to edit, create, and delete note templates. The default setting is yes. You can edit this later.">
                <i
                  className="material-icons"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "rgb(142, 148, 157)",
                  }}>
                  info
                </i>
              </Tooltip>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center", margin: "5px 0px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!disableBulkSends}
                    color="primary"
                    onChange={(e) => setDisableBulkSends(!e.target.checked)}
                    value="admin"
                  />
                }
                label="Allow user to send in bulk"
              />
              <Tooltip title="Allow the user to send a Gratavid to 4 or more contacts at once. The default setting is yes. You can edit this later.">
                <i
                  className="material-icons"
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "rgb(142, 148, 157)",
                  }}>
                  info
                </i>
              </Tooltip>
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        {state.errorMessage && (
          <div className="bulkUploadActionBarErrorMessageBox">
            <i className="material-icons" style={{ marginRight: "5px", color: "red" }}>
              error
            </i>
            <b>{state.errorMessage}</b>
          </div>
        )}

        <Button aria-label="cancel" onClick={props.closeDialog} variant="outlined">
          Close
        </Button>

        {state.buttonState === "saved" && (
          <Button
            aria-label="cancel"
            onClick={refreshData}
            variant="contained"
            color="secondary">
            Add Another
          </Button>
        )}

        {userType === "internal" ? (
          <LoadingButtonCheckProductLimits
            countToAdd={1}
            productKey="users"
            productLabel="user"
            userIsAdmin={props.userIsAdmin}
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="User Added"
            label="Add User"
            clickedRegularButton={submitAddUser}
            clickedSavedButton={props.closeDialog}
            showErrorAsTooltip={true}
            {...props}
          />
        ) : (
          <LoadingButton
            variant="contained"
            color="primary"
            buttonState={state.buttonState}
            savedLabel="User Added"
            label="Add User"
            clickedRegularButton={submitAddUser}
            clickedSavedButton={props.closeDialog}
          />
        )}
      </DialogActions>
    </>
  );
}
