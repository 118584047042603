import React from "react";
import { Tooltip } from "@mui/material";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";
import TextInputToApi from "components/util/hooks/input/textInputToApi";
import SelectInputToApi from "components/util/hooks/input/selectInputToApi";
import { updateSelf } from "services/api.service";

export default function Email(props) {
  let {
    shareViaEmail,
    noteContent,
    customContactProperties,
    contactCountForTask,
    userInfo = {},
    ssoProvider = null,
    userEmailProvider = "",
  } = props;
  const userEmail = userInfo?.email ?? "";
  let { emailSubject, emailLocalPart } = noteContent;
  emailLocalPart = emailLocalPart || props.accountInfo?.domainLocalPart || "hello";

  let onUpdateUser = async (field, value) => {
    //change it at top level listTask
    props.setUserEmailProvider(value);
    updateSelf({ [field]: value });
  };

  let emailProviderOptions = [
    {
      value: "default",
      label: `Default - ${emailLocalPart}@${props.accountInfo?.domain || "gratavid.com"}`,
    },
  ];
  if (ssoProvider === "microsoft")
    emailProviderOptions.push({ value: "outlook", label: "Outlook - " + userEmail });
  if (ssoProvider === "google")
    emailProviderOptions.push({ value: "gmail", label: "Gmail - " + userEmail });

  async function setShareViaEmail(field, value, bulkUpdates) {
    props.setShareViaEmail(value);
    props.onUpdateTask(field, value, bulkUpdates);
  }
  return (
    <>
      <div className="col-12" style={{ marginTop: "25px" }}>
        <CheckBoxToApi
          initialValue={props.noteContent.shareViaEmail}
          field="shareViaEmail"
          apiCall={setShareViaEmail}
          label="Send email"
          bold={true}
        />
      </div>

      {shareViaEmail && (
        <>
          <div className="col-12" style={{ marginTop: "15px" }}>
            <TextInputToApi
              initialValue={emailSubject}
              customContactProperties={customContactProperties}
              field="emailSubject"
              apiCall={props.onUpdateTask}
              label="Email Subject"
              variant="outlined"
            />
          </div>

          {ssoProvider ? (
            contactCountForTask >= 6 ? (
              <Tooltip
                title="Can't send via Gmail/Outlook to 6 or more contacts at once"
                placement="bottom">
                <div className="col-12" style={{ marginTop: "25px" }}>
                  <SelectInputToApi
                    label="Send Via"
                    initialValue={"default"}
                    options={emailProviderOptions}
                    variant="outlined"
                    disabled={true}
                  />
                </div>
              </Tooltip>
            ) : (
              <div className="col-12" style={{ marginTop: "25px" }}>
                <SelectInputToApi
                  label="Send Via"
                  field="emailProvider"
                  initialValue={userEmailProvider}
                  options={emailProviderOptions}
                  apiCall={onUpdateUser}
                  variant="outlined"
                />
              </div>
            )
          ) : null}

          <span className="col-12" style={{ height: "20px" }} />
        </>
      )}
    </>
  );
}
