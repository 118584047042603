import React from "react";
import PropTypes from "prop-types";
import styles from "./inputSearch.module.css";

import { TextField, InputAdornment } from "@mui/material";

const InputSearch = ({ className, onSearch }) => {
  const handleSearch = (e) => {
    onSearch(e.target.value.toLowerCase());
  };
  return (
    <div className={className}>
      <TextField
        className={styles.inputSearch}
        onChange={handleSearch}
        placeholder="Search"
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <i className="material-icons">search</i>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

InputSearch.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

InputSearch.defaultProps = {};

export default InputSearch;
