import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from "@mui/material";
import gcISOLanguages from "../../../../util/data/gcISOLanguages";
import AddLanguageDialog from "./addLanguageDialog";
import EditCaptionsDialog from "./edit/editCaptionsDialog";
import LoadingSwitch from "../../../../util/buttons/loadingSwitch";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import { editVideo } from "services/api.service";

export default function ListCaptions(props) {
  let [buttonState, setButtonState] = useState("regular");
  let [editCaptionISOLanguageCodeSelected, setEditCaptionISOLanguageCodeSelected] =
    useState("");

  const handleSwitch = async (e, isoLanguageCode) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setButtonState("loading");
      let tempCaptions = { ...props.captions };
      tempCaptions.languages.list[isoLanguageCode].published = newActiveValue;
      props.passedSetEditVideoState("captions", tempCaptions);
      await editVideo(props.videoId, { captions: tempCaptions });
      setButtonState("regular");
    } catch (err) {
      console.error(err?.response?.data?.message ?? err.message);
    }
  };

  const listLanguages = Object.keys(props?.captions?.languages?.list ?? {}).map(
    (currentCode) => {
      const current = props.captions.languages.list[currentCode];
      const isoLanguageCode = current.primaryISOLanguageCode;
      const language = gcISOLanguages.itemsMap[isoLanguageCode]?.language || "NA";
      return (
        <ListItem
          key={isoLanguageCode}
          style={{ border: "1px #ddd solid", borderRadius: "5px" }}
          secondaryAction={
            <Button
              edge="end"
              aria-label="edit"
              onClick={() => setEditCaptionISOLanguageCodeSelected(currentCode)}>
              Edit
              <i className="material-icons" style={{ marginLeft: "5px" }}>
                edit
              </i>
            </Button>
          }>
          <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
            <LoadingSwitch
              checked={current?.published}
              handleSwitch={(e) => handleSwitch(e, currentCode)}
              buttonState={buttonState}
            />
          </div>
          <ListItemText primary={language} />
        </ListItem>
      );
    }
  );

  return (
    <div className="row col-12 pagePadding">
      <div className="col-12" style={{ marginTop: "15px" }}>
        <h2 style={{ display: "flex" }}>
          Video Languages <QuestionToLink id="captions" desc="captions" />
        </h2>
        <p style={{ color: "grey" }}>
          Add captions in one or many languages to your video. Once created and saved, you
          can add your video to a note and preview the note to view the captions.
        </p>
      </div>

      <List className="col-12" component="nav" aria-label="list languages">
        {listLanguages}
      </List>

      <div className="col-12 justify-content-center" style={{ marginTop: "15px" }}>
        <AddLanguageDialog
          accountId={props.accountId}
          captions={props.captions}
          videoId={props.videoId}
          passedSetEditVideoState={props.passedSetEditVideoState}
        />
      </div>

      <EditCaptionsDialog
        accountId={props.accountId}
        captions={props.captions}
        passedSetEditVideoState={props.passedSetEditVideoState}
        dialogOpen={Boolean(editCaptionISOLanguageCodeSelected)}
        editCaptionISOLanguageCodeSelected={editCaptionISOLanguageCodeSelected}
        currentCaption={props.captions.languages.list[editCaptionISOLanguageCodeSelected]}
        setEditCaptionISOLanguageCodeSelected={setEditCaptionISOLanguageCodeSelected}
        videoId={props.videoId}
        mediaURL={props.mediaURL}
        duration={props.duration}
      />
    </div>
  );
}

ListCaptions.propTypes = {
  accountId: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  mediaURL: PropTypes.string.isRequired,
  captions: PropTypes.shape({
    languages: PropTypes.shape({
      list: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  passedSetEditVideoState: PropTypes.func.isRequired,
};
