import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import { updateAccountIntegrations } from "services/api.service";

export default function ImportContacts(props) {
  let { eventData = {}, accountInfo } = props;
  eventData = eventData || {};
  let [state, setState] = useState({});
  let initialActive = eventData.allowAllUsers ? eventData.allowAllUsers : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });
      const updates = [
        {
          path: "endpoint/salesforce/events/importContacts/allowAllUsers",
          value: newActiveValue,
        },
      ];
      await updateAccountIntegrations(updates);
      setState({});
      setActive(newActiveValue);
    } catch (err) {
      let errorMessage = err.response?.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>Allow Users To Import List From Salesforce</h2>
          <span className="greyDesc">
            Allow Gratavid users to import list directly from Salesforce.
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className="row col-12"
        style={{ cursor: "auto", padding: "8px 20px 16px" }}>
        <p>
          When OFF, only admins can import Salesforce list directly into Gratavid. When
          ON, any of your Gratavid account users can import list directly from Salesforce.
          Salesforce list can be imported under Contacts>Add Contact(+)>Import From
          Salesforce.
        </p>
      </AccordionDetails>
    </Accordion>
  );
}

ImportContacts.propTypes = {
  accountInfo: PropTypes.object,
  eventData: PropTypes.object,
};
