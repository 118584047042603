import React from "react";
import { Button } from "@mui/material";
import AddVideoButton from "./addVideoButton";
import SearchVideo from "./search/searchVideo";
import RequestVideo from "./request/requestVideo";
import VideoRecorder from "../../editVideo/upload/videoRecorder";
import styles from "./addVideo.module.css";

export default function AddVideo(props) {
  let {
    addState,
    videoAdded,
    setAddState,
    videoIds,
    calledFromTask = false,
    currentTask,
    userInfo = {},
  } = props;
  let recorderName = userInfo?.fullName ?? "";
  let usingiOS =
    !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
  let mediaRecorderAvailable = window.MediaRecorder ? true : false;
  let isIOS =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(window.navigator.platform) ||
    (window.navigator.userAgent.includes("Mac") && "ontouchend" in document);
  if (isIOS) mediaRecorderAvailable = false;

  const getVideoTitle = () => {
    const { currentTask = {}, noteTitle = "New Video", contactData = {} } = props;
    const { comments = "Say thanks", id } = currentTask;
    let videoTitle = noteTitle;
    if (id) {
      var trimmedComments = comments.substring(0, 100); //if title is comments cap at 100 charactres
      videoTitle = "Task: " + trimmedComments;
      if (contactData && contactData.label) {
        videoTitle = "Video for " + contactData.label;
        if (recorderName) videoTitle += " by " + recorderName;
      }
    }
    return videoTitle;
  };

  if (addState === "record")
    return (
      <div className={styles.video_wrapper}>
        <VideoRecorder
          video={{
            title: getVideoTitle(),
            foldersFilter: [],
            uploadType: "upload",
            createdByTask: !!props.currentTask?.id,
          }}
          recorderName={recorderName}
          onComplete={(video) => {
            videoAdded(video);
            setAddState(null);
          }}
        />

        <Button
          className="trappbtn btn-red"
          onClick={() => setAddState(null)}
          size="large">
          Cancel
        </Button>
      </div>
    );
  else if (addState === "search")
    return (
      <SearchVideo
        videos={props.videos}
        videoSelected={videoAdded}
        accountInfo={props.accountInfo}
        setAddState={setAddState}
      />
    );
  else if (addState === "request")
    return (
      <RequestVideo
        videoAdded={videoAdded}
        accountInfo={props.accountInfo}
        setAddState={setAddState}
        currentTask={currentTask}
      />
    );
  //upload is handled on AddVideoButton
  return (
    <>
      {!mediaRecorderAvailable && !usingiOS && (
        <div className="notification-message-box">
          <span>
            Recording is not supported in this browser. Open this page in Google Chrome,
            or visit from your smartphone.
          </span>
        </div>
      )}
      <AddVideoButton
        videoIds={videoIds}
        setAddState={setAddState}
        calledFromTask={calledFromTask}
      />
    </>
  );
}
