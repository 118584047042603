import React from "react";
import PropTypes from "prop-types";
import styles from "./button.module.css";

import MuiButton from "@mui/material/Button";

const Button = function (props) {
  return <MuiButton {...props} className={styles.button} disableElevation></MuiButton>;
};

Button.propTypes = {
  size: PropTypes.oneOf(["small"]),
};

export default Button;
