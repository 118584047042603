import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import { sendNote } from "services/api.service";

export default function ScheduleDialog(props) {
  let { dialogOpen, closeDialog, fullScreen } = props;
  let [day, setDay] = useState(0);
  let [time, setTime] = useState("08:00");
  let [state, setState] = useState({});
  let [gvSendId, setGvSendId] = useState(null);

  useEffect(() => {
    //we only create a new gvSendId if dialog is open. this is to prevent double sending on accident
    if (!dialogOpen) return;
    let tempGvSendId = "sid_" + crypto.randomUUID();
    setGvSendId(tempGvSendId);
  }, [dialogOpen]);

  let scheduleNow = async () => {
    try {
      if (day === 0) throw new Error("Please select a day.");
      let scheduledDate = new Date(parseInt(day, 10));

      //get miliseconds of time selection
      let timeMiliseconds =
        Number(time.split(":")[0]) * 60 * 60 * 1000 +
        Number(time.split(":")[1]) * 60 * 1000;
      let scheduledDateTimestamp = scheduledDate.getTime();
      scheduledDateTimestamp += timeMiliseconds;

      let { contacts, videoIds, noteId, shareViaText, shareViaEmail } = props;
      let contactsKeys = contacts && Object.keys(contacts) ? Object.keys(contacts) : [];

      if (contactsKeys.length === 0)
        throw new Error(
          'No contacts selected. You must add contacts under the "Send To" section before sending.'
        );
      if (!videoIds || videoIds.length === 0)
        throw new Error("No video. You must select a video before sending a note.");
      if (!shareViaText && !shareViaEmail)
        throw new Error(
          "You must select either send email or send text under Send To section."
        );

      let sixtyDaysMilliseconds = 86400000 * 60;
      let differenceInDates = scheduledDateTimestamp - Date.now();

      // Schedule time can not be in the past.
      let oneMinuteMiliseconds = 60000;

      if (differenceInDates < oneMinuteMiliseconds) {
        throw new Error("Scheduled date must be in the future.");
      } else if (differenceInDates > sixtyDaysMilliseconds) {
        throw new Error("Scheduled date must be within 60 days from now.");
      } else {
        //save scheduled content

        setState({ buttonState: "loading" });

        //save send content data
        //these ensures content is unique for each send. users can edit in sendhistory
        const sendObject = {
          scheduledDateTimestamp: new Date(scheduledDateTimestamp),
          noteId,
          gvSendId,
          contentId: nanoid(10),
          status: "processing",
          calledFrom: "editNote",
          contactIds: contacts
            .filter(({ contactId }) => contactId)
            .map(({ contactId }) => contactId),
          folderIds: contacts
            .filter(({ folderId }) => folderId)
            .map(({ folderId }) => folderId),
          type: "sendNote",
          emailProvider: "default",
        };
        await sendNote(sendObject);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "gv.sendNote" });

        //this will autosegue into sendhistory
        setState({ buttonState: "saved" });
        closeDialog();
        props.setTabSelected("schedule");
      }
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage, buttonState: "regular" });
    }
  };

  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth();
  var date = today.getDate();
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let dayOptionsHTML = [];
  for (var i = 0; i < 60; i++) {
    var currentDay = new Date(year, month, date + i);
    let formattedDate = currentDay.toDateString();
    let timeStamp = currentDay.getTime();
    dayOptionsHTML.push(
      <option key={i} value={timeStamp}>
        {formattedDate}
      </option>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="Schedule send dialog">
      <DialogContent
        style={{
          height: fullScreen ? "auto" : "550px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "22px" }} role="heading">
              Schedule send
            </span>
            <p className="greyDesc">Timezone: {timeZone}</p>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "25px" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="day-native-select">Select Day</InputLabel>
            <Select
              value={day}
              name="Select Day"
              label="Select Day"
              labelId="day-native-select"
              native
              onChange={(e) => setDay(e.target.value)}
              inputProps={{ id: "day-native-select" }}>
              <option key={i} value={0} disabled>
                Select Day
              </option>
              {dayOptionsHTML}
            </Select>
          </FormControl>
        </div>

        <div style={{ display: "flex", marginTop: "25px" }}>
          <TextField
            variant="outlined"
            onChange={(e) => setTime(e.target.value)}
            id="time"
            label="Time"
            fullWidth
            type="time"
            value={time}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        {state.errorMessage && (
          <div className="col-12 errorMessageBox">
            <b>{state.errorMessage}</b>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button variant="outlined" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Scheduled"
          label="Schedule now"
          clickedRegularButton={scheduleNow}
          clickedSavedButton={() => props.setDialogOpen(false)}
        />
      </DialogActions>
    </Dialog>
  );
}
