import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  CircularProgress,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { debounce } from "util/helpers";

const states = {
  ERROR: -1,
  INITIAL: 0,
  SAVING: 1,
  SUCCESS: 2,
};

export default function SelectInputToApi(props) {
  const [inputValue, setInputValue] = useState(props.initialValue?.toString());
  const [state, setState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const debouncedApiCall = useCallback(
    debounce((value) => {
      setState(states.SAVING);
      setErrorMessage(null);

      props
        .apiCall(props.field, value)
        .then((res) => {
          if (res?.status >= 400) {
            return setState(states.ERROR);
          }
          setState(states.SUCCESS);
        })
        .catch((err) => {
          setState(states.ERROR);
          setErrorMessage(err.message);
        });
    }),
    [props]
  );

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedApiCall(value);
  };

  let loadingComp;

  if (state === states.SUCCESS) {
    loadingComp = (
      <i
        className="material-icons"
        style={{ color: "green", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        check_circle
      </i>
    );
  } else if (state === states.ERROR) {
    loadingComp = (
      <i
        className="material-icons"
        style={{ color: "green", fontSize: "15px", margin: "0px 4px 0px 6px" }}>
        error
      </i>
    );
  } else if (state === states.SAVING) {
    loadingComp = (
      <CircularProgress
        style={{ width: "15px", height: "15px", margin: "0px 4px 0px 6px" }}
      />
    );
  }

  let showError, helperText;
  if (errorMessage) {
    showError = true;
    helperText = errorMessage;
  } else if (props.parentErrorMessage) {
    showError = true;
    helperText = props.parentErrorMessage;
  } else {
    showError = false;
    helperText = props.helperText;
  }

  const optionsHTML = props.options.map((current) => {
    return (
      <option key={current.value} value={current.value}>
        {current.label}
      </option>
    );
  });

  return (
    <FormControl error={showError} variant={props.variant}>
      <InputLabel id={props.label + "-native-select"}>
        <div className="align-items-center">
          <>{props.label}</>
          <>{loadingComp}</>
        </div>
      </InputLabel>
      <Select
        label={props.label}
        labelId={props.label + "-native-select"}
        value={inputValue}
        name={props.label}
        native
        onChange={onChange}
        disabled={props.disabled ?? false}
        inputProps={{ id: props.label + "-native-select" }}>
        {optionsHTML}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

SelectInputToApi.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  apiCall: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  bold: PropTypes.bool,
  parentErrorMessage: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};
