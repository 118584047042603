import React, { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { getAdvanceCampaignList, getAccountVideos } from "services/api.service";
import CampaignItem from "./campaignItem";

export default function MatchedVideos(props) {
  const { selectedSiteId } = props;
  const [campaigns, setCampaigns] = useState("loading");
  const [accountVideos, setAccountVideos] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setCampaigns("loading");
        const campaigns = await getAdvanceCampaignList(selectedSiteId);

        // Sort alphabetically
        campaigns.sort((a, b) => a.label.localeCompare(b.label));
        const { rows } = await getAccountVideos("", false, true);
        setAccountVideos(rows);
        setCampaigns(campaigns);
      } catch (err) {
        console.error(err.message);
      }
    };

    getData();
  }, [props.accountInfo.id, selectedSiteId]);

  if (campaigns === "loading")
    return (
      <div className="col-12" style={{ padding: "20px 0px" }}>
        <LinearProgress />
      </div>
    );

  return (
    <div className="col-12 row" style={{ marginTop: 10 }}>
      <div
        className="col-12 row"
        style={{ border: "2px solid rgb(153, 153, 153)", padding: 5, borderRadius: 2 }}>
        <div className="col-6">
          <h3 style={{ fontWeight: "bold", color: "rgb(153, 153, 153)", margin: 0 }}>
            Advance Campaign
          </h3>
        </div>
        <div className="col-6">
          <h3 style={{ fontWeight: "bold", color: "rgb(153, 153, 153)", margin: 0 }}>
            Gratavid Video
          </h3>
        </div>
      </div>

      {campaigns.map((current) => {
        return (
          <CampaignItem
            key={current.id}
            {...current}
            {...props}
            selectedSiteId={selectedSiteId}
            accountVideos={accountVideos}
          />
        );
      })}
    </div>
  );
}
