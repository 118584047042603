import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import VideoReplyExpireDialog from "../contacts/profile/messenger/videoReplyExpireDialog";
import { getContact } from "services/api.service";
import { toFullName } from "util/helpers";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function ReplyDialog(props) {
  let { currentReply, setReplySelected } = props;
  let {
    replyId,
    replyVideoSrc = null,
    replyText = "",
    convertedToVideoId,
    createdDate,
  } = currentReply;
  let { contactId, noteId } = currentReply;
  const [label, setLabel] = useState("Anonymous");
  const [contactData, setContactData] = useState({});

  const fullScreen = useMobileDialog();

  useEffect(() => {
    async function loadContactInfo() {
      const contact = await getContact(contactId);
      setContactData(contact || {});

      if (contact) {
        setLabel(
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={`/a/${props.accountId}/contacts/` + contactId}>
            {toFullName(contact.firstName, contact.lastName, contact.email)}
          </a>
        );
      }
    }

    loadContactInfo();
  }, []);

  let date = new Date(createdDate);
  let showDate = date.toLocaleString();

  let source = currentReply.source === "lp" ? "note" : currentReply.source;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={() => setReplySelected(null)}
      aria-labelledby="view reply dialog">
      <DialogContent
        style={{
          height: "auto",
          width: fullScreen ? "auto" : "600px",
          padding: "40px",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <span className="greyDesc" style={{ fontSize: "14px" }}>
              {label} replied at {showDate} via {source}
            </span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton
              aria-label="close"
              onClick={() => setReplySelected(false)}
              size="small">
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="row col-12">
          <div className="col-12" style={{ fontSize: "22px", paddingBottom: "5px" }}>
            {replyText && '"' + replyText + '"'}
          </div>
        </div>

        {replyVideoSrc && (
          <VideoReplyExpireDialog
            dateCreated={createdDate}
            replyVideoSrc={replyVideoSrc}
            noteId={noteId}
            replyId={replyId}
            convertedToVideoId={convertedToVideoId}
            contactData={contactData}
            accountId={props.accountId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
ReplyDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  currentReply: PropTypes.shape({
    replyId: PropTypes.string.isRequired,
    replyVideoSrc: PropTypes.string,
    replyText: PropTypes.string,
    convertedToVideoId: PropTypes.string,
    createdDate: PropTypes.string,
    contactId: PropTypes.string,
    noteId: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  setReplySelected: PropTypes.func.isRequired,
};
