import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import CheckBoxToApi from "components/util/hooks/input/checkBoxToApi";

export default function ReplySettings(props) {
  let [open, setOpen] = useState(false);
  let expansionPanelClass = open ? "expansionPanel" : "expansionPanel collapsible";

  return (
    <div className="col-12 row adSectionBox" style={{ marginTop: "10px" }}>
      <div
        className="col-12 row"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}>
        <div style={{ flex: 1 }}>
          <div className="col-12 row">
            <div className="col-12 row align-items-center">
              <h2 style={{ marginRight: "2px" }}>Reply Settings</h2>
              <QuestionToLink id="replySettings" desc="reply settings" size="small" />
            </div>
            <div className="col-12">
              <span className="greyDesc">Disable replies or allow video replies</span>
            </div>
          </div>
        </div>
        <div style={{ width: "50px" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <i className="material-icons">{open ? "expand_less" : "expand_more"}</i>
          </IconButton>
        </div>
      </div>

      <div className={"col-12 row " + expansionPanelClass}>
        <div className="col-12" style={{ marginTop: "15px" }}>
          <CheckBoxToApi
            field="hideNoteReplyInput"
            initialValue={props.hideNoteReplyInput}
            apiCall={props.passedSetState}
            label="Disable replies"
          />
        </div>

        <div className="col-12">
          <CheckBoxToApi
            field="showVideoReply"
            initialValue={props.showVideoReply}
            apiCall={props.passedSetState}
            label="Allow video replies"
          />
        </div>
      </div>
    </div>
  );
}

ReplySettings.propTypes = {
  passedSetState: PropTypes.func.isRequired,
  showVideoReply: PropTypes.bool,
  hideNoteReplyInput: PropTypes.bool,
};
