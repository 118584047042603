import { useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

export default function useMobileDialog(breakpoint = "sm") {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(breakpoint));
  return fullScreen;
}

useMobileDialog.propTypes = {
  breakpoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
