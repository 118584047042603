import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  List,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import useMobileDialog from "../useMobileDialog";

export default function MergeFieldDialog({
  insertMergeVariable,
  handleClose,
  customContactProperties,
}) {
  const fullScreen = useMobileDialog();

  let handleClick = (mergeField) => {
    insertMergeVariable(mergeField);
    handleClose();
  };

  let customMergeFieldsHTML = customContactProperties.map((current) => {
    if (current.noMerge) return null;
    else
      return (
        <ListItemButton
          onClick={() => handleClick("%" + current.id + "%")}
          key={current.id}>
          <ListItemText primary={current.label} />
        </ListItemButton>
      );
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="edit task dialog">
      <DialogContent
        style={{
          height: fullScreen ? "auto" : "550px",
          width: fullScreen ? "auto" : "600px",
        }}>
        <div style={{ display: "flex", borderBottom: "1px #ddd solid" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Insert A Merge Field</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={handleClose}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton onClick={() => handleClick("%firstName%")}>
            <ListItemText primary="First Name" />
          </ListItemButton>
          <ListItemButton onClick={() => handleClick("%lastName%")}>
            <ListItemText primary="Last Name" />
          </ListItemButton>
          <ListItemButton onClick={() => handleClick("%salutation%")}>
            <ListItemText primary="Salutation" />
          </ListItemButton>
          {customMergeFieldsHTML}
        </List>
      </DialogContent>
      <DialogActions
        style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}
        onClick={handleClose}>
        <Button variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
