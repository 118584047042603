import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import RuleKeys from "./rule-keys";
import { setContactFolders, uploadBulkContacts } from "services/api.service";

const convertContacts = function (contacts) {
  return contacts.map((contact) => ({
    email: contact.Email,
    firstName: contact["First name"],
    lastName: contact["Last name"],
    customProperties: {
      currentAffiliation: contact.Affiliation,
      currentIndustry: contact.Industry,
      currentJobFunction: contact["Job function"],
    },
  }));
};

const getContactIds = function (contacts, emails) {
  return contacts.filter(({ email }) => emails.has(email)).map(({ id }) => id);
};

export default function UploadLists(props) {
  const [buttonState, setButtonState] = useState("regular");
  const [errorMessage, setErrorMessage] = useState(null);

  let uploadLists = async () => {
    try {
      setButtonState("loading");

      const newContacts = await uploadBulkContacts(convertContacts(props.contacts));

      let contactIds = [];
      const folderIds = new Set();
      Object.entries(props.folderEmails).map(([folderId, emails]) => {
        contactIds = getContactIds(newContacts, emails);
        folderIds.add(folderId);
        return true;
      });

      const folders = [];
      for (const contactId of contactIds) {
        folders.push({ contactId, folderIds: Array.from(folderIds) });
      }

      if (folders?.length) {
        await setContactFolders(folders);
      }

      setButtonState("saved");
    } catch (err) {
      const message = err?.data?.message ?? err?.message;
      setErrorMessage(message);
      setButtonState("regular");
    }
  };

  let metricLookupReason = {
    [RuleKeys.affiliations]: " have the affiliation ",
    [RuleKeys.industries]: " work in the industries ",
    [RuleKeys.functions]: " have the job functions ",
    [RuleKeys.groups]: " are members of the group ",
  };

  return (
    <div className="col-12 row" style={{ display: "flex", paddingTop: "5px" }}>
      <div className="col-12">
        {props.rules.map((rule, index) => {
          const folders = rule[0].folders;

          return (
            <div className="col-12 row" style={{ marginBottom: 15 }} key={index}>
              <div
                style={{
                  width: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: "50%",
                    backgroundColor: "rgb(0, 164, 189)",
                  }}
                />
              </div>

              <div
                style={{
                  flex: 1,
                  border: "rgb(0, 164, 189) 2px solid",
                  borderRadius: 3,
                  backgroundColor: "#E8F4F7",
                  padding: 12,
                }}>
                <span>
                  <b>{props.ruleHits[index]} Engage contacts</b> will be imported into the
                  the Gratavid&nbsp;
                </span>
                {folders.length > 1 ? (
                  <span>
                    lists&nbsp;
                    <b>
                      {folders.slice(0, 1).map(({ label }) => label) +
                        folders.slice(1, -1).map(({ label }) => `, ${label}`) +
                        folders.slice(-1).map(({ label }) => ` and ${label}`)}
                    </b>
                  </span>
                ) : (
                  <span>
                    list&nbsp;<b>{folders[0].label}</b>
                  </span>
                )}

                <span>{` because ${props.ruleHits[index]} contacts `}</span>

                {rule.map(({ key, values }, conditionIndex) => (
                  <span key={key}>
                    {conditionIndex > 0 && " AND "}
                    {metricLookupReason[key]}
                    {values.length > 1 ? (
                      <b>
                        {values.slice(0, 1).map((value) => value) +
                          values.slice(1, -1).map((value) => `${value}, `) +
                          values.slice(-1).map((value) => ` and ${value}`)}
                      </b>
                    ) : (
                      <span>
                        <b>{values[0]}</b>
                      </span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {buttonState === "saved" ? (
        <div
          className="col-12 row"
          style={{ display: "flex", justifyContent: "space-around", marginTop: 25 }}>
          <div className="col-12 justify-content-center">
            <>
              <img
                style={{ width: "35px", marginRight: "5px" }}
                alt="completed checkmark"
                src="https://gratavid.com/static/img/greenCircleCheck.svg"
              />
              <h2 style={{ fontSize: 22, marginTop: 3 }}>SUCCESS!</h2>
            </>
          </div>
          <div className="col-12" style={{ padding: "10px 20px 20px 20px" }}>
            <p style={{ textAlign: "center" }}>
              Your lists are now processing and will finish uploading in a few minutes.
            </p>
          </div>
          <div className="col-12 justify-content-center" style={{ marginBottom: 50 }}>
            <Button
              onClick={() => props.setAddNewDialogOpen(false)}
              variant="contained"
              color="primary">
              Go Home
            </Button>
          </div>
        </div>
      ) : (
        <div
          className="col-12"
          style={{ display: "flex", justifyContent: "space-around", marginTop: 25 }}>
          <Button
            onClick={() => props.setActiveStep(1)}
            variant="outlined"
            disabled={buttonState === "loading"}>
            <i className="material-icons" style={{ marginRight: 5 }}>
              arrow_back
            </i>
            Go Back
          </Button>

          <LoadingButton
            mode="contained"
            buttonState={buttonState}
            savedLabel="Created!"
            label="Import Now"
            clickedRegularButton={uploadLists}
            color="primary"
          />
        </div>
      )}

      {errorMessage && (
        <div className="errorMessageBox">
          <b>{errorMessage}</b>
        </div>
      )}
    </div>
  );
}

UploadLists.propTypes = {
  accountId: PropTypes.string.isRequired,
  contacts: PropTypes.array.isRequired,
  folderEmails: PropTypes.object.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.string).isRequired,
        folders: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          })
        ),
      })
    )
  ).isRequired,
  ruleHits: PropTypes.arrayOf(PropTypes.number).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setAddNewDialogOpen: PropTypes.func.isRequired,
};
