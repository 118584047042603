import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { MenuItem } from "@mui/material";
import timeSinceDate from "../../../util/functions/timeSinceDate";

export default function GeneralItem(props) {
  let timeSinceLastSent = timeSinceDate(new Date(props.current.createdDate));
  let icon = props.icon ? props.icon : "notifications";

  return (
    <MenuItem
      style={{ display: "flex" }}
      component={Link}
      to={props.current.href}
      onClick={() => props.setAnchorEl(null)}>
      <div style={{ width: "30px" }}>
        <i
          className="material-icons"
          style={{ marginRight: "10px", color: "grey", fontSize: "20px" }}>
          {icon}
        </i>
      </div>
      <div style={{ flex: 1 }} className="row">
        <div className="col-12">{props.current.body}</div>
        <div className="col-12">
          <p className="greyDesc" style={{ fontSize: "14px" }}>
            {timeSinceLastSent}
          </p>
        </div>
      </div>
    </MenuItem>
  );
}

GeneralItem.propTypes = {
  current: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
};
