import { cleanSearchParams } from "util/helpers";
import http, { setToken } from "./http.service";

const REFRESH_ID_TOKEN_BUFFER = 2 * 60 * 1000; // 2 minutes

const observers = [];
let refreshIdTokenTimer;
let accountId;

export function onAuthStateChange(observer) {
  if (typeof observer === "function") {
    observers.push(observer);
  }
}
function authStateChanged(authState) {
  for (const observer of observers) {
    observer(authState);
  }
}

function refreshIdToken(authState) {
  // schedule to get new id token
  const delay = new Date(authState.expiration) - new Date() - REFRESH_ID_TOKEN_BUFFER;

  clearTimeout(refreshIdTokenTimer);
  refreshIdTokenTimer = setTimeout(() => {
    getIdToken();
  }, delay);
}

export function setAccountId(id) {
  accountId = id;
}

export function login(email, password) {
  return http
    .post("/auth/login", { email, password }, { withCredentials: true })
    .then((data) => {
      setToken(data);
      authStateChanged(data);
      refreshIdToken(data);
      return data;
    });
}

export function oAuthLogin(code, provider, redirectUri) {
  return http
    .post("/auth/oauth", { code, provider, redirectUri }, { withCredentials: true })
    .then((data) => {
      setToken(data);
      authStateChanged(data);
      refreshIdToken(data);
      return data;
    });
}

export function logout() {
  return http.post("/auth/logout", undefined, { withCredentials: true }).then(() => {
    setToken();
    authStateChanged();
    clearTimeout(refreshIdTokenTimer);
  });
}

export function getIdToken() {
  return http
    .get("/auth/token", { withCredentials: true })
    .then((data) => {
      if (data) {
        setToken(data);
        refreshIdToken(data);
        return data;
      }
      authStateChanged();
    })
    .catch(() => authStateChanged());
}

export function getSelf() {
  return http.get("/users/me");
}

export function updateSelf(user) {
  return http.patch(`/users/me`, user);
}

export function updateSelfRole(role) {
  return http.patch(`/accounts/${accountId}/users/me/role`, role);
}

/**
 * Gets account info by id.
 * @returns {Promise<Object>}
 */
export function getAccount(id) {
  return http.get(`/accounts/${id || accountId}`);
}

/**
 * Retrieves uploads for a specific account.
 * @returns {Promise<Object>} - Promise representing the uploads data.
 */
export function getUploadsByAccount(type) {
  return http.get(`/accounts/${accountId}/uploads`, { params: { type } });
}

/**
 * Creates a new upload for a specific account.
 * @param {Object} data - Upload data.
 * @returns {Promise<Object>} - Promise representing the created upload.
 */
export function createUploadByAccount(formData) {
  return http.post(`/accounts/${accountId}/uploads`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * Delete an upload folder
 * @param {string} uploadId Upload id to be deleted
 * @return {Promise<string>} Deleted upload id
 */
export function deleteAccountUpload(uploadId) {
  return http.delete(`/accounts/${accountId}/uploads/${uploadId}`);
}

/**
 * Updates organization data, not including integrations.
 * @param {Object} updates - Organization settings object.
 * @returns {Promise<Object>}
 */
export function patchAccount(updates) {
  return http.patch(`/accounts/${accountId}`, updates);
}

/**
 * Gets account subscription
 * @returns {Promise<Object>}
 */
export function getAccountSubscription(id) {
  return http.get(`/accounts/${id}/subscription`);
}

export function addUser(user) {
  return http.post(`/accounts/${accountId}/users`, user);
}

/**
 * Updates organization data, not including integrations.
 * @param {string} userId - Organization's unique identifier.
 * @param {Object} updates - Organization settings object.
 * @returns {Promise<Object>}
 */
export function patchAccountUser(userId, updates) {
  return http.patch(`/accounts/${accountId}/users/${userId}/user-roles`, updates);
}

export function createPassword(newPassword, token) {
  return http.post(`/users/password`, { newPassword, token });
}

export function updatePassword(currentPassword, newPassword) {
  return http.patch(`/users/me/password`, {
    currentPassword,
    newPassword,
  });
}

export function forgotPassword(email) {
  return http.post(`/users/forgot-password`, { email });
}

/**
 * Get an account's user and role
 * @param {string} userId user uid
 * @return {Promise<User>} user
 */
export function getAccountUser(userId) {
  return http.get(`/accounts/${accountId}/users/${userId}`);
}

/**
 * Get an account's users with roles
 * @param {string} userType - can be either "internal", "external", "all"
 * @return {Promise<User[]>} array of users
 */
export function getAccountUsers(userType = "internal") {
  return http.get(`/accounts/${accountId}/users?userType=${userType}`);
}

/**
 * Query an account's contacts and paginate
 * @param {number} offset starting row number to return
 * @param {number} limit chunk of contacts to return, ignores pagination if 0
 * @param {string} searchTerm Search input to search First/last name on
 * @param {string[]} filters Folder id to filter results on
 * @return {Promise<{count: number, rows: User[]}>} Paginated return object
 */
export function getAccountContacts(offset, limit, searchTerm, filters) {
  const params = new URLSearchParams();
  params.append("offset", offset);
  params.append("limit", limit);
  if (searchTerm) {
    params.append("searchTerm", searchTerm);
  }

  if (filters) {
    filters.forEach((filter) => {
      params.append("filters", filter);
    });
  }

  return http.get(`/accounts/${accountId}/contacts`, { params });
}

/**
 * Query an account's contacts for search suggestions
 * @param {number} limit chunk of contacts to return
 * @param {string} searchTerm Search input to search First/last name on
 * @return {Promise<User[]>} Row of user suggestions
 */
export function suggestAccountContacts(limit, searchTerm) {
  const params = new URLSearchParams();
  params.append("limit", limit);
  if (searchTerm) {
    params.append("searchTerm", searchTerm);
  }
  return http.get(`/accounts/${accountId}/contacts/suggestions`, { params });
}

/**
 * Count an account's folders
 * @param {string} type label for grouping type (note, contact, video)
 * @return {Promise<Folder[]>} an accounts folders
 */
export function getAccountFolders(type) {
  return http.get(`/accounts/${accountId}/folders?type=${type}`);
}

/**
 * Insert an account's folder
 * @param {Folder} folder the new folder to insert
 * @return {Promise<Folder>} the inserted folder
 */
export function createAccountFolder(folder) {
  const payload = { ...folder };
  return http.post(`/accounts/${accountId}/folders`, payload);
}

/**
 * Update an account's folder
 * @param {string} folder Folder UID
 * @param {Partial<Folder>} updates Properties to be updated
 * @return {Promise<Folder>} the inserted folder
 */
export function updateAccountFolder(folderId, updates) {
  return http.patch(`/accounts/${accountId}/folders/${folderId}`, updates);
}

/**
 * Delete an account's folder
 * @param {string} folderId Folder UID to be deleted
 * @return {Promise<string>} Deleted folders UID
 */
export function deleteAccountFolder(folderId) {
  return http.delete(`/accounts/${accountId}/folders/${folderId}`);
}

export function getFolderContacts(folderId, offset, limit, noteHistoryId) {
  if (noteHistoryId) {
    return http.get(
      `/accounts/${accountId}/folders/${folderId}/contact-history/${noteHistoryId}`,
      {
        params: { offset, limit },
      }
    );
  }

  return http.get(`/accounts/${accountId}/folders/${folderId}/contacts`, {
    params: { offset, limit },
  });
}

/**
 * Get a user by their auth token
 * @param {string} token
 * @returns {Promise<User>} User
 */
export function getUserByToken(token) {
  return http.get(`/users/token/${token}`);
}

/**
 * Gets tasks by account id that have not been sent.
 * @param {string} assignedUserId user id task is assigned to
 * @param {string} status status of task "UNSENT" | "SENT"
 * @param {object} paginationOptions { sortBy, sortDirection, limit, offset }
 * @param {boolean} hasNoVideos - flag used for the video query to filter tasks with no videos
 * @returns {Promise<Object>}
 */
export function getTasks(
  assignedUserId,
  status = "UNSENT",
  paginationOptions = {
    sortBy: "createdDate",
    sortDirection: "desc",
    limit: 100,
    offset: 0,
  },
  hasNoVideos = false
) {
  const params = new URLSearchParams();
  params.set("offset", paginationOptions.offset);
  params.set("limit", paginationOptions.limit);
  params.set("assignedUserId", assignedUserId);
  params.set("status", status);
  params.set("sortBy", paginationOptions.sortBy);
  params.set("sortDirection", paginationOptions.sortDirection);
  params.set("hasNoVideos", hasNoVideos);

  if (paginationOptions.ignorePagination) {
    params.set("ignorePagination", true);
  }
  cleanSearchParams(params);

  return http.get(`/accounts/${accountId}/tasks`, { params });
}

/**
 * Gets task by id
 * @param {string} taskId user id task is assigned to
 * @returns {Promise<Object>}
 */
export function getTask(taskId) {
  return http.get(`/accounts/${accountId}/tasks/${taskId}`);
}

/**
 * Gets task count with user and assignee link
 * @returns {Promise<Object>}
 */
export function getUserTaskCount() {
  return http.get(`/accounts/${accountId}/users/tasks/count`);
}

/**
 * Gets notifications for user
 * @returns {Promise<Object>}
 */
export function getUserNotifications(offset, limit) {
  return http.get(`/accounts/${accountId}/users/notifications`, {
    params: { offset, limit },
  });
}

/**
 * Sends a note or task to contacts
 * @param {object} payload Note, task, and content info
 * @returns {Promise<Object>}
 */
export function sendNote(payload) {
  return http.post(`/accounts/${accountId}/tasks/send`, payload);
}

/**
 * Reminds selected users that they have unfinished tasks
 * @param {array} usersToNotify list of users to notify
 * @returns {Promise<Object>}
 */
export function remindUsers(usersToNotify) {
  return http.post(`/accounts/${accountId}/users/remind`, usersToNotify);
}

/**
 * Patches task
 * @param {string} taskId task id
 * @param {object} updates partial task object containing updates
 * @returns {Promise<Object>}
 */
export function patchTask(taskId, updates) {
  return http.patch(`/accounts/${accountId}/tasks/${taskId}`, updates);
}

/**
 * Patches tasks
 * @param {array} taskIds
 * @param {object} updates partial task object containing updates
 * @param {boolean} allSelected Update all tasks for account
 * @returns {Promise<Object>}
 */
export function bulkPatchTask(taskIds, updates, allSelected) {
  return http.patch(`/accounts/${accountId}/tasks/bulk`, {
    taskIds,
    allSelected,
    updates,
  });
}

/**
 * Clones task
 * @param {string} taskId task id
 * @returns {Promise<Object>}
 */
export function cloneTask(taskId) {
  return http.post(`/accounts/${accountId}/tasks/${taskId}/clone`);
}

/**
 * Moves task to send history
 * @param {string} taskId task id
 * @returns {Promise<Object>}
 */
export function markTaskAsDone(taskId) {
  return http.post(`/accounts/${accountId}/tasks/${taskId}/done`);
}

/**
 * Updates task contacts
 * @param {string} taskId task id
 * @param {object} contactIds list of contactIds assigned to task
 * @returns {Promise<Object>} new taskContact list
 */
export function updateTaskContacts(taskId, contactIds) {
  return http.put(`/accounts/${accountId}/tasks/${taskId}/contacts`, { contactIds });
}

/**
 * Get account integrations JSON
 * @returns {Promise<Object>} account integrations JSON
 */
export function getAccountIntegrations() {
  return http.get(`/accounts/${accountId}/integrations`);
}

/**
 * Update account integrations by array of updates
 * @param {Array<Object>} updates Array of updates with path and value
 * @returns {Promise<Object>} Updated integrations
 */
export function updateAccountIntegrations(updates) {
  return http.patch(`accounts/${accountId}/update-integrations`, { updates });
}

/**
 * Post to authenticate Advance
 * @param {Object} data - API data
 */
export function authenticateAdvance(formData) {
  return http.post(`/accounts/${accountId}/advance/authenticate`, formData);
}

/**
 * Post to authenticate Graduway
 * @param {Object} data - API data
 */
export function authenticateGraduway(formData) {
  return http.post(`/accounts/${accountId}/graduway/authenticate`, formData);
}

export function authenticateNeon(formData) {
  return http.post(`/accounts/${accountId}/neon/authenticate`, formData);
}

export function disconnectNeon() {
  return http.delete(`/accounts/${accountId}/neon/authenticate`);
}

export function subscribeNeon() {
  return http.post(`/accounts/${accountId}/neon/subscribe`);
}

export function unsubscribeNeon() {
  return http.delete(`/accounts/${accountId}/neon/subscribe`);
}

export function updateNeonEvents(actions) {
  return http.put(`/accounts/${accountId}/neon/events`, actions);
}

/**
 * Get Advance campaign List
 * @param {string} siteId - Advance site id
 */
export function getAdvanceCampaignList(siteId) {
  return http.post(`/accounts/${accountId}/advance/${siteId}/campaigns`);
}

/**
 * Get Advance every forms
 * @param {string} siteId - Advance site id
 */
export function getAdvanceEverydayForms(siteId) {
  return http.get(`/accounts/${accountId}/advance/${siteId}/everyday-forms`);
}

/**
 * Find account contacts by field
 * @param {object} fieldToUpdate field to update in db
 * @param {string} fieldToUpdateValue
 * @returns {Promise<Object>} new contacts list
 */
export function updateAccountContactsUnsubscribed(messagingType, findByMessagingArray) {
  return http.patch(`accounts/${accountId}/contacts/unsubscribes`, {
    messagingType: messagingType,
    findByMessagingArray: findByMessagingArray,
  });
}

export function getAccountContactsUnsubscribed() {
  return http.get(`/accounts/${accountId}/contacts/unsubscribes`);
}
/**
 *
 * @param {object} contact data for insert or update
 * @returns id of updated contact
 */
export function upsertContact(contact) {
  return http.put(`/accounts/${accountId}/contacts`, contact);
}

/**
 * Create a new contact
 * @param {object} contact data for insert or update
 * @returns id of updated contact
 */
export function createContact(contact) {
  return http.post(`/accounts/${accountId}/contacts`, contact);
}

/**
 * Bulk associate contacts with folders
 * @param {{contactId: string, folderId: string}[]} contactFolders
 * @param {boolean} [append] Add contact to folders instead of replacing
 * @returns - A list of the contactFolders that were created
 */
export function setContactFolders(contactFolders, append) {
  return http.put(`accounts/${accountId}/contacts/folders`, { contactFolders, append });
}

/**
 * List all folders related to a contact for an account
 * @param {string} contactId
 * @returns - A list of folders
 */
export function listContactFolders(contactId) {
  return http.get(`accounts/${accountId}/contacts/${contactId}/folders`);
}

/**
 *
 * @param {string} taskId
 * @param {string} videoId
 * @returns taskVideo object
 */
export function getTaskVideo(taskId, videoId) {
  return http.get(`/accounts/${accountId}/tasks/${taskId}/video/${videoId}`);
}

/**
 *
 * @param {string} taskId
 * @param {string} videoId
 * @param {object} updates Video object updates
 * @returns object with id of updated video
 */
export function editTaskVideo(taskId, videoId, updates) {
  return http.patch(`/accounts/${accountId}/tasks/${taskId}/video/${videoId}`, updates);
}

/**
 *
 * @param {string} taskId
 * @param {array} videoIds
 * @returns taskVideo array with videos
 */
export function updateTaskVideosIds(taskId, videoIds) {
  return http.put(`/accounts/${accountId}/tasks/${taskId}/videos`, { videoIds });
}

/**
 *
 * @param {string} searchTerm
 * @param {boolean} hideTaskVids remove videos already attached to tasks
 * @param {boolean} hideIncompleteVids remove incomplete vids
 * @param {int} offset
 * @param {int} limit
 * @param {string[]} filters - folder ids to filter by
 * @returns list of videos
 */
export function getAccountVideos(
  searchTerm,
  hideTaskVids,
  hideIncompleteVids,
  offset,
  limit,
  filters
) {
  const params = new URLSearchParams();
  params.append("offset", offset);
  params.append("limit", limit);
  params.append("hideTaskVids", hideTaskVids);
  params.append("hideIncompleteVids", hideIncompleteVids);

  if (searchTerm) {
    params.append("searchTerm", searchTerm);
  }

  if (filters) {
    filters.forEach((filter) => {
      params.append("filters", filter);
    });
  }
  return http.get(`/accounts/${accountId}/videos`, { params });
}

/**
 * Get a single video by id
 * @param {string} videoId
 * @returns video
 */
export function getVideo(videoId) {
  return http.get(`/accounts/${accountId}/videos/${videoId}`);
}

/**
 * Get Videos by date range
 * @param {number} startDate - The most historic day in milliseconds
 * @param {number} endDate - The most current day in milliseconds
 * @returns list of videos
 */
export function getVideosByDateRange(startDate, endDate) {
  const params = new URLSearchParams();
  params.append("startDate", startDate);
  params.append("endDate", endDate);
  return http.get(`/accounts/${accountId}/videos/date-range`, { params });
}

/**
 * Delete a list of contacts
 * @param {string[]} contactIds
 * @returns - A list of deleted Contacts
 */
export function deleteContacts(contactIds) {
  return http.delete(`/accounts/${accountId}/contacts`, { data: { contactIds } });
}

/**
 * Bulk delete contacts
 * @param {number} count Number of contacts to delete
 * @param {string[]} [folderIds] Contact folders to delete
 * @param {string} [searchTerm] Filter contacts by search term
 * @returns - Number of deleted contacts
 */
export function deleteContactsBulk(count, folderIds, searchTerm) {
  return http.delete(`/accounts/${accountId}/contacts`, {
    data: { count, folderIds, searchTerm },
  });
}

/**
 * Delete a list of users.
 *
 * @param {string[]} userIds - An array of user IDs to be deleted.
 * @returns {Promise} - A list of deleted users.
 */
export function deleteUsers(userIds) {
  return http.delete(`/accounts/${accountId}/users`, { data: { userIds } });
}

/**
 * Get Accounts custom contact props
 * @returns object containing all the custom properties
 */
export function getContactCustomProps() {
  return http.get(`accounts/${accountId}/contactCustomProps`);
}

/**
 * Creates contacts from a csv string
 * @param {string} contacts
 * @returns - A list of inserted contacts
 */
export function uploadBulkContacts(contacts) {
  return http.post(`accounts/${accountId}/contacts/bulk`, contacts);
}

export function listContactBirthdays(month) {
  return http.get(`accounts/${accountId}/contacts/birthdays`, { params: { month } });
}

/**
 * Bulk associate videos with folders
 * @param {{videoId: string, folderId: string}[]} payload
 * @returns - A list of inserted videoFolders
 */
export function setVideoFolders(videoFolders) {
  return http.put(`accounts/${accountId}/videos/folders`, videoFolders);
}

/**
 * Associate notes with folders
 * @param {{noteId: string, folderId: string}[]} payload
 * @returns - A list of inserted noteFolders
 */
export function setNoteFolders(noteFolders) {
  return http.put(`accounts/${accountId}/notes/folders`, noteFolders);
}

/**
 *
 * @param {string} videoId
 * @param {Partial<Video>} updates
 */
export function editVideo(videoId, updates) {
  return http.patch(`/accounts/${accountId}/videos/${videoId}`, updates);
}

/**
 * Trim the length and update the gif and thumbnail accordingly
 * @param {string} videoId
 * @param {string} payload.videoId
 * @param {number} payload.duration
 * @param {string} payload.downloadUrl
 * @param {string} payload.newFileName
 * @param {string} payload.rotate
 * @param {string} payload.startTime
 * @return Success or failure
 */
export function trimVideo(videoId, payload) {
  return http.patch(`/accounts/${accountId}/videos/${videoId}/trim`, payload);
}

/**
 * Begin the transcription job for a video
 * @param {string} videoId
 * @param {string} payload.primaryBcpLanguageCode
 * @param {string} payload.primaryIsoLanguageCode
 * @param {string[]} payload.alternativeBcpLanguageCodes
 * @return Success or failure of the start of transcribing process
 */
export function transcribeVideo(videoId, payload) {
  return http.put(`/accounts/${accountId}/videos/${videoId}/transcribe`, payload);
}

/**
 * Send a list of video requests to notify the recorders to record
 * @param {request[]} requestBodies - a list of requests to send
 * @param {string}    requestBodies.videoId
 * @param {boolean}   requestBodies.shareViaEmail
 * @param {boolean}   requestBodies.shareViaText
 */
export function requestVideos(requestBodies) {
  return http.post(`/accounts/${accountId}/videos/request`, requestBodies);
}

/**
 * Create empty video objects to be recorded
 * @param {video[]}   videos
 * @param {string}    video.title
 * @param {string[]}  video.foldersFilter
 * @param {string}    video.uploadType
 * @returns string[]  array of created video ids
 */
export function createVideos(videos) {
  return http.post(`/accounts/${accountId}/videos`, videos);
}

/**
 * Get contact by contact id
 * @param {*} contactId
 * @returns contact
 */
export function getContact(contactId) {
  return http.get(`accounts/${accountId}/contacts/${contactId}`);
}

/**
 * Get contact reply
 * @param {*} contactId
 * @param {*} replyId
 * @returns contact reply
 */
export function getContactReply(contactId, replyId) {
  return http.get(`accounts/${accountId}/contacts/${contactId}/replies/${replyId}`);
}

/**
 * Get contact activity by contact id
 * @param {*} contactId
 * @returns {noteHistory: *, userReplies: *} Returns related "activity" objects
 */
export function getContactActivity(contactId) {
  return http.get(`accounts/${accountId}/contacts/${contactId}/activity`);
}

/**
 * Update an accounts contact by contact id
 * @param {string} contactId
 * @param {*} contact - updates to be patched
 */
export function updateContact(contactId, contact) {
  return http.patch(`accounts/${accountId}/contacts/${contactId}`, contact);
}

/**
 *
 * @param {object} videoData
 * @returns { presignedPost, readUrl: string }
 */
export function createVideoSignedUrl(videoData) {
  return http.post(`/accounts/${accountId}/videos/signed-url`, videoData);
}

/**
 *
 * @param {object} taskInfo [{contactData, comments}]
 */
export function insertTasks(taskInfo) {
  return http.post(`/accounts/${accountId}/tasks`, taskInfo);
}

/**
 * Delete a list of tasks
 * @param {string[]} taskIds
 * @returns - A list of deleted Tasks
 */
export function deleteTasks(taskIds) {
  return http.delete(`/accounts/${accountId}/tasks`, { data: { taskIds } });
}

export function getNotes(offset, limit, search, filters) {
  const params = new URLSearchParams();
  params.set("offset", offset);
  params.set("limit", limit);
  if (search) {
    params.set("search", search);
  }
  if (filters) {
    for (const filter of filters) {
      params.append("filters", filter);
    }
  }
  return http.get(`/accounts/${accountId}/notes`, { params });
}

export function addNote(note) {
  return http.post(`/accounts/${accountId}/notes`, note);
}

export function getNote(noteId) {
  return http.get(`/accounts/${accountId}/notes/${noteId}`);
}

export function getNoteContent(noteId, taskId = null, contentId = null) {
  let url = `/accounts/${accountId}/notes/${noteId}/content`;

  if (taskId) {
    url += `?taskId=${taskId}`;
  } else if (contentId) {
    url += `?contentId=${contentId}`;
  }

  return http.get(url);
}

export function getNoteVideos(noteId) {
  return http.get(`/accounts/${accountId}/notes/${noteId}/videos`);
}

export function patchNote(noteId, updates) {
  return http.patch(`/accounts/${accountId}/notes/${noteId}`, updates);
}

/**
 * Delete a single Note
 * @param {string} noteId
 * @returns
 */
export function deleteNote(noteId) {
  return http.delete(`/accounts/${accountId}/notes/${noteId}`);
}

/**
 * Delete all Account Notes
 * @param {number} count - Number of notes on the account
 * @returns
 */
export function deleteAllNotes(count) {
  return http.delete(`/accounts/${accountId}/notes`, { data: { count } });
}

/**
 * Delete Videos
 * @param {string} videoId
 * @returns
 */
export function deleteVideos(videoIds) {
  return http.delete(`/accounts/${accountId}/videos`, { data: { videoIds } });
}

/**
 * Delete all Account Videos
 * @returns
 */
export function deleteAllVideos(count, hideTaskVids) {
  return http.delete(`/accounts/${accountId}/videos/all`, {
    data: { count, hideTaskVids },
  });
}

/**
 * Resend all video requests from a list of video ids
 * @param {string[]} videoIds
 * @returns a count of how many requests were resent
 */
export function resendVideoRequests(videoIds) {
  return http.patch(`accounts/${accountId}/videos/resend-requests`, { videoIds });
}

export function cloneNote(noteId, data) {
  return http.post(`/accounts/${accountId}/notes/${noteId}/clone`, data);
}

export function patchNoteTaskContent(noteId, taskId, contentId, updates) {
  if (taskId) {
    return http.patch(`/accounts/${accountId}/tasks/${taskId}`, updates);
  } else if (contentId) {
    return http.patch(
      `/accounts/${accountId}/notes/${noteId}/contents/${contentId}`,
      updates
    );
  } else {
    return http.patch(`/accounts/${accountId}/notes/${noteId}`, updates);
  }
}

export function updateNoteTaskContentVideos(noteId, taskId, contentId, videoIds) {
  if (taskId) {
    return http.put(`/accounts/${accountId}/tasks/${taskId}/videos`, { videoIds });
  } else if (contentId) {
    return http.put(
      `/accounts/${accountId}/notes/${noteId}/contents/${contentId}/videos`,
      { videoIds }
    );
  } else {
    return http.put(`/accounts/${accountId}/notes/${noteId}/videos`, { videoIds });
  }
}

export function updateNoteContacts(noteId, folderIds, contactIds) {
  return http.put(`/accounts/${accountId}/notes/${noteId}/contacts`, {
    folderIds,
    contactIds,
  });
}

export function getNoteContactCounts(noteId) {
  return http.get(`/accounts/${accountId}/notes/${noteId}/contacts/count`);
}

export function getNoteReplies(noteId, gvSendId, limit, offset) {
  const params = { gvSendId, limit, offset };
  return http.get(`/accounts/${accountId}/notes/${noteId}/replies`, { params });
}

export function getNoteHistory(noteId, limit, offset, details, status) {
  const params = { limit, offset, details, status };
  return http.get(`/accounts/${accountId}/notes/${noteId}/history`, { params });
}

export function getNoteHistoryAnalytics(noteId, historyId) {
  return http.get(
    `/accounts/${accountId}/notes/${noteId}/history/${historyId}/analytics`
  );
}

export function getNoteAnalytics(noteId, gvSendId, daily) {
  const params = { sid: gvSendId };
  if (daily) {
    try {
      daily = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      daily = "America/New_York";
    }
    params.daily = daily;
  }
  return http.get(`/accounts/${accountId}/notes/${noteId}/analytics`, { params });
}

export function getNoteAnalyticsEvent(
  noteId,
  event,
  limit,
  offset,
  sortBy,
  sortDirection
) {
  const params = { limit, offset, sortBy, sortDirection };
  // workaround for ad block
  if (event === "pageView") {
    event = "pv";
  }
  return http.get(`/accounts/${accountId}/notes/${noteId}/analytics/${event}`, {
    params,
  });
}

export function emailNoteAnalytics(noteId, lastXDays) {
  return http.post(`/accounts/${accountId}/notes/${noteId}/analytics`, { lastXDays });
}

export function createBirthdayNote() {
  return http.post(`/accounts/${accountId}/birthday`);
}

/**
 * Send a text to a particular contact through our integration and create an AppReply record
 * @param {string} contactId
 * @param {string} textBody - body representing the text message
 * @returns the created AppReply
 */
export function replyText(contactId, textBody) {
  return http.post(`accounts/${accountId}/contacts/${contactId}/reply-text`, {
    message: textBody,
  });
}

/**
 * Send an email to a particular contact through our integration and create an AppReply record
 * @param {string} contactId
 * @param {string} emailBody - body representing the email
 * @returns the created AppReply
 */
export function replyEmail(contactId, emailBody) {
  return http.post(`accounts/${accountId}/contacts/${contactId}/reply-email`, {
    message: emailBody,
  });
}

/**
 * Get a list of Blackbaud campaign contact lists
 * @returns a list of Blackbaud contact lists
 */
export function getBlackbaudContactLists() {
  return http.get(`accounts/${accountId}/blackbaud/contact-lists`);
}

/**
 * Get unique set of the top 50 Blackbaud contact column headers and the first 5 rows of sample data
 * @param {*} listId - Blackbaud list id
 * @returns unique set of headers and 5 Blackbaud contacts
 */
export function getBlackbaudListColumns(listId) {
  return http.get(`accounts/${accountId}/blackbaud/contact-lists/${listId}/columns`);
}

/**
 * Import Max 12k Contacts from a Blackbaud list using the provided mapping
 * @param {string} listId - Blackbaud list id
 * @param {*} propertyMap - map of blackbaud columns to gratavid columns
 * @param {string} listName - Blackbaud list name
 * @returns ids of all successfully imported contacts
 */
export function importBlackbaudListContacts(listId, propertyMap, listName) {
  return http.post(`accounts/${accountId}/blackbaud/contact-lists/${listId}/import`, {
    propertyMap,
    listName,
  });
}

/**
 * Get a list of Salesforce campaign contact lists
 * @returns a list of Salesforce contact lists
 */
export function getSalesforceContactLists() {
  return http.get(`accounts/${accountId}/salesforce/contact-lists`);
}

/**
 * Get first 5 Contacts and return them along with column header samples
 * @param {*} listId - Salesforce list id
 * @returns unique set of headers and 5 Blackbaud contacts
 */
export function getSalesforceListColumns(listId) {
  return http.get(`accounts/${accountId}/salesforce/contact-lists/${listId}/columns`);
}

/**
 * Import Max 12k Contacts from a Salesforce list using the provided mapping
 * @param {string} listId - Salesforce list id
 * @param {*} propertyMap - map of Salesforce columns to Gratavid columns
 * @param {string} listName - Salesforce list name
 * @returns ids of all successfully imported contacts
 */
export function importSalesforceListContacts(listId, propertyMap, listName) {
  return http.post(`accounts/${accountId}/salesforce/contact-lists/${listId}/import`, {
    propertyMap,
    listName,
  });
}

/**
 * Cancel scheduled task send
 * @param {string} taskId
 */
export function cancelSendTask(taskId, isResend = false) {
  return http.delete(`accounts/${accountId}/scheduler/task/${taskId}`, {
    params: { isResend },
  });
}

export function cancelSendNote(noteHistoryId) {
  return http.delete(`accounts/${accountId}/scheduler/note/${noteHistoryId}`);
}

/**
 * Calls the manageIntegrations endpoint (used by many different integrations)
 * @param {object} params key value pairs for query parameters
 * @param {object} body object to be passed as body of request
 */
export function manageIntegrations(params, body) {
  return http.post(`vendors/manage-integrations`, body, { params });
}

/**
 *
 * @param {number} lastXDays
 * @param {number} timezoneDifference
 * @param {string} noteId
 * @returns
 */
export function getAnalytics(lastXDays, timezoneDifference, noteId) {
  const params = new URLSearchParams();
  params.set("lastXDays", lastXDays);
  params.set("timezoneDifference", timezoneDifference);
  if (noteId) {
    params.set("noteId", noteId);
  }
  return http.get(`/accounts/${accountId}/analytics/`, { params });
}

export function emailAnalytics(lastXDays) {
  return http.post(`/accounts/${accountId}/analytics`, { lastXDays });
}

/**
 * Gets user replies for a specific account.
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {string} noteId
 * @param {object} paginationOptions { sortBy, sortDirection, limit, offset, ignorePagination }
 * @returns
 */
export function getUserReplies(
  startDate,
  endDate,
  noteId = null,
  paginationOptions = {
    sortBy: "createdDate",
    sortDirection: "desc",
    limit: 10,
    offset: 0,
    ignorePagination: false,
  }
) {
  const params = new URLSearchParams();
  params.append("startDate", startDate);
  params.append("endDate", endDate);
  params.append("noteId", noteId);
  params.append("offset", paginationOptions.offset);
  params.append("limit", paginationOptions.limit);
  params.append("sortBy", paginationOptions.sortBy);
  params.append("sortDirection", paginationOptions.sortDirection);

  return http.get(`/accounts/${accountId}/analytics/replies`, { params });
}

export function createVideoFromReplyVideo(data) {
  return http.post(`/accounts/${accountId}/videos/reply`, data);
}

export function collectUsageEvent(params) {
  return http.post(`/collect/usage-event`, params);
}

export function addCaptionsLanguage(
  videoId,
  sourceIsoLanguageCode,
  targetIsoLanguageCode
) {
  return http.post(`/accounts/${accountId}/videos/${videoId}/captions-language`, {
    sourceIsoLanguageCode,
    targetIsoLanguageCode,
  });
}

export function deleteVideo(videoId) {
  return http.delete(`/accounts/${accountId}/videos/${videoId}`);
}

/**
 * Converts a pendingAccount to an account.
 * @param {Object} payload - Organization settings object - account, user, pendingAccountId.
 * @returns {Promise<Object>}
 */
export function createPendingAccount(payload) {
  return http.post("/accounts", payload);
}

/**
 * Get pending account info
 * @param {string} pendingAccountId
 * @returns Pending account id and account id
 */
export function getPendingAccount(pendingAccountId) {
  return http.get(`pending-accounts/${pendingAccountId}`);
}

/**
 * Save custom merge tags for an account
 * @param {Array} mergeTags - Array containing custom merge tags
 */
export function saveCustomMergeTags(mergeTags) {
  const customContactProperties = new Map();

  for (const tag of mergeTags) {
    customContactProperties.set(tag.key, { label: tag.label, dv: tag.dv || "" });
  }

  return http.post(
    `/accounts/${accountId}/merge-tags`,
    Object.fromEntries(customContactProperties)
  );
}
