import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingSwitch from "../../../../../util/buttons/loadingSwitch";
import SaveConfig from "./saveConfig";
import { updateAccountIntegrations } from "services/api.service";

export default function SaveReply(props) {
  let { eventData = {}, accountInfo } = props;
  eventData = eventData || {};
  let [state, setState] = useState({});
  let initialActive = eventData.active ? eventData.active : false;
  let [active, setActive] = useState(initialActive);
  let [open, setOpen] = useState(false);

  const handleChange = (event, isExpanded) => {
    setOpen(isExpanded ? true : false);
  };

  const handleSwitch = async (e) => {
    e.stopPropagation();
    let newActiveValue = e.target.checked;

    try {
      setState({ buttonState: "loading" });
      const updates = [];

      updates.push({
        path: "webhooks/urls/reply/pcsr_001",
        value: newActiveValue
          ? {
              dateCreated: Date.now(),
              event: "reply",
              source: "planningCenter",
              type: "function",
            }
          : null,
      });

      updates.push({
        path: "endpoint/planningCenter/events/reply/active",
        value: !!newActiveValue,
      });

      await updateAccountIntegrations(updates);
      setState({});
      setActive(newActiveValue);
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      style={{
        cursor: "pointer",
        borderRadius: "8px",
        marginTop: "25px",
        width: "100%",
      }}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_less</i>}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div style={{ width: "80px", display: "flex", alignItems: "center" }}>
          <LoadingSwitch
            checked={active}
            handleSwitch={handleSwitch}
            buttonState={state && state.buttonState}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>Save Gratavid Replies In Planning Center</h2>
          <span className="greyDesc">
            Create a note in Planning Center when a constituent replies to a Gratavid.
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className="row col-12"
        style={{ cursor: "auto", padding: "8px 20px 16px" }}>
        <SaveConfig event="reply" accountInfo={accountInfo} eventData={eventData} />
      </AccordionDetails>
    </Accordion>
  );
}
