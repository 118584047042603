import React, { useState } from "react";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import { DialogActions, DialogContent, Dialog, Button, IconButton } from "@mui/material";
import { sendNote, updateSelf } from "services/api.service";
import SelectInputToApi from "components/util/hooks/input/selectInputToApi";
import useMobileDialog from "components/util/hooks/useMobileDialog";

export default function SendTasks(props) {
  let { accountInfo, userEmailProvider, ssoProvider } = props;
  let [state, setState] = useState({});
  let [sendResults, setSendResults] = useState([]);
  let [resultTotals, setResultTotals] = useState({ success: 0, errors: 0 });
  const userEmail = props.userInfo?.email ?? "";

  const fullScreen = useMobileDialog();

  let requestSendTaskNow = async (taskId, scheduledDateTimestamp) => {
    let label;

    try {
      let currentTask = props.tasks.find((task) => task.id === taskId);
      let { noteId, comments: label = "Say thanks" } = currentTask;

      if (!currentTask.taskVideos?.length)
        return { errorMessage: "Missing video. Did not send.", taskId, label };
      else if (!noteId)
        return { errorMessage: "Missing note. Did not send.", taskId, label };
      else if (!currentTask.taskContacts?.length)
        return {
          errorMessage:
            "Missing contact. Did not send. Fatal error, delete task and create new.",
          taskId,
          label,
        };
      else {
        const sendObject = {
          noteId,
          taskId,
          gvSendId: `sid_${taskId}`,
          status: "processing",
          calledFrom: "editNote",
          contactIds: currentTask.taskContacts.map(
            (taskContact) => taskContact.contactId
          ),
          type: "sendNote",
          scheduledDateTimestamp,
          emailProvider: userEmailProvider,
        };

        if (currentTask.shareViaText !== null) {
          sendObject.shareViaText = currentTask.shareViaText;
        }
        if (currentTask.shareViaEmail !== null) {
          sendObject.shareViaEmail = currentTask.shareViaEmail;
        }
        await sendNote(sendObject);
        return { taskId, label };
      }
    } catch (err) {
      return { errorMessage: err.message, taskId, label };
    }
  };

  let doWork = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });

      if (props.selectedItems.length === 0) {
        throw new Error("No items selected");
      }

      if (props.selectedItems.length > 500) {
        throw new Error("Can only send up to 500 items at once");
      }

      let sendPromisesArray = [];
      let twoMinuteMiliseconds = 60000 * 2; //have to schedule in atleast one minute else will throw error
      let scheduledDateTimestamp = new Date().getTime() + twoMinuteMiliseconds;
      props.selectedItems.forEach((selectedTask) => {
        scheduledDateTimestamp += 5000; //add 5 seconds to each send
        sendPromisesArray.push(
          requestSendTaskNow(selectedTask.id, new Date(scheduledDateTimestamp))
        );
      });

      let results = await Promise.all(sendPromisesArray);

      let tempTasks = [...props.tasks];
      let tempSendResults = [];
      let success = 0;
      let errors = 0;

      results.forEach((current, index) => {
        let { taskId, errorMessage } = current;
        if (errorMessage) {
          errors++;
          tempSendResults.push(current);
        } else {
          success++;
          const sentIndex = tempTasks.findIndex((task) => task.id === taskId);
          tempTasks[sentIndex].sentByUserId = props.userInfo.id;
        }
      });

      setResultTotals({ success, errors });
      setSendResults(tempSendResults);
      setState({ buttonState: "saved" });
      props.setEditTaskSelected(null);
      props.setTasks(tempTasks);
      props.setSelectedItems([]);
      props.setAllSelected(false);
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  async function onUpdateUser(field, value) {
    //change it at top level listTask
    props.setUserEmailProvider(value);
    updateSelf({ [field]: value });
  }

  let { buttonState, errorMessage } = state;

  let closeSendTask = () => {
    setSendResults([]);
    props.closeDialog();
  };

  let emailProviderOptions = [
    { value: "default", label: `Default - @${accountInfo?.domain ?? "gratavid.com"}` },
  ];
  if (ssoProvider === "microsoft")
    emailProviderOptions.push({ value: "outlook", label: "Outlook - " + userEmail });
  if (ssoProvider === "google")
    emailProviderOptions.push({ value: "gmail", label: "Gmail - " + userEmail });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete task dialog">
      <DialogContent style={{ minHeight: "500px", width: fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Send Task</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        {!buttonState && (
          <div className="col-12">
            <p>Are you sure you want to send {props.selectedCount} task(s)?</p>
          </div>
        )}

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}

        {ssoProvider && (
          <div className="col-12" style={{ marginTop: "25px" }}>
            <SelectInputToApi
              label="Send Via"
              field="emailProvider"
              initialValue={userEmailProvider}
              options={emailProviderOptions}
              apiCall={onUpdateUser}
              variant="outlined"
            />
          </div>
        )}

        {buttonState === "saved" && (
          <>
            {resultTotals.success > 0 && (
              <div style={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
                <i
                  className="material-icons"
                  style={{ color: "green", marginRight: "4px" }}>
                  check_circle
                </i>
                {resultTotals.success} task(s) sent. Please allow a few minutes to
                process.
              </div>
            )}

            <div style={{ marginTop: "15px" }}>{resultTotals.errors} errors:</div>

            {sendResults.map((current) => {
              return (
                <div
                  key={current.taskId}
                  style={{
                    width: "100%",
                    display: "flex",
                    borderTop: "1px #ddd solid",
                    alignItems: "center",
                    padding: "6px 0px",
                  }}>
                  <div style={{ width: "28px" }}>
                    <i className="material-icons" style={{ color: "red" }}>
                      error
                    </i>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span style={{ cursor: "pointer" }}>
                      {current.errorMessage} Task: '{current.label}'
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <Button onClick={props.closeDialog} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel={"Sent"}
          label={"Send All"}
          clickedRegularButton={doWork}
          clickedSavedButton={closeSendTask}
        />
      </DialogActions>
    </Dialog>
  );
}
